import React, { useEffect, useRef, useState } from 'react'

import Tooltip from 'react-bootstrap/Tooltip'
import { OverlayTrigger } from 'react-bootstrap';

const ActivityCustomScheduler = React.forwardRef((props, ref) => {
    let { isAlertTemplateEdit, sevenDaysRef, resource, sevenDaysData, setSevenDaysData, workflows, severities, gettingData, setGettingData } = props;
    const isFirstRender = useRef(true);

    //#region useState start
    const [days, setDays] = useState([
        { id: 0, hour: 0, selected: false, backgroundColor: "none", hourLabel: "00", severity: "", workflow: "", hourLabelFull: "00:00",severityName: "", workflowName: "" },
        { id: 1, hour: 1, selected: false, backgroundColor: "none", hourLabel: "01", severity: "", workflow: "", hourLabelFull: "01:00",severityName: "", workflowName: "" },
        { id: 2, hour: 2, selected: false, backgroundColor: "none", hourLabel: "02", severity: "", workflow: "", hourLabelFull: "02:00",severityName: "", workflowName: "" },
        { id: 3, hour: 3, selected: false, backgroundColor: "none", hourLabel: "03", severity: "", workflow: "", hourLabelFull: "03:00",severityName: "", workflowName: "" },
        { id: 4, hour: 4, selected: false, backgroundColor: "none", hourLabel: "04", severity: "", workflow: "", hourLabelFull: "04:00",severityName: "", workflowName: "" },
        { id: 5, hour: 5, selected: false, backgroundColor: "none", hourLabel: "05", severity: "", workflow: "", hourLabelFull: "05:00",severityName: "", workflowName: "" },
        { id: 6, hour: 6, selected: false, backgroundColor: "none", hourLabel: "06", severity: "", workflow: "", hourLabelFull: "06:00",severityName: "", workflowName: "" },
        { id: 7, hour: 7, selected: false, backgroundColor: "none", hourLabel: "07", severity: "", workflow: "", hourLabelFull: "07:00",severityName: "", workflowName: "" },
        { id: 8, hour: 8, selected: false, backgroundColor: "none", hourLabel: "08", severity: "", workflow: "", hourLabelFull: "08:00",severityName: "", workflowName: "" },
        { id: 9, hour: 9, selected: false, backgroundColor: "none", hourLabel: "09", severity: "", workflow: "", hourLabelFull: "09:00",severityName: "", workflowName: "" },
        { id: 10, hour: 10, selected: false, backgroundColor: "none", hourLabel: "10", severity: "", workflow: "", hourLabelFull: "10:00", severityName: "", workflowName: "" },
        { id: 11, hour: 11, selected: false, backgroundColor: "none", hourLabel: "11", severity: "", workflow: "", hourLabelFull: "11:00", severityName: "", workflowName: "" },
        { id: 12, hour: 12, selected: false, backgroundColor: "none", hourLabel: "12", severity: "", workflow: "", hourLabelFull: "12:00", severityName: "", workflowName: "" },
        { id: 13, hour: 13, selected: false, backgroundColor: "none", hourLabel: "13", severity: "", workflow: "", hourLabelFull: "13:00", severityName: "", workflowName: "" },
        { id: 14, hour: 14, selected: false, backgroundColor: "none", hourLabel: "14", severity: "", workflow: "", hourLabelFull: "14:00", severityName: "", workflowName: "" },
        { id: 15, hour: 15, selected: false, backgroundColor: "none", hourLabel: "15", severity: "", workflow: "", hourLabelFull: "15:00", severityName: "", workflowName: "" },
        { id: 16, hour: 16, selected: false, backgroundColor: "none", hourLabel: "16", severity: "", workflow: "", hourLabelFull: "16:00", severityName: "", workflowName: "" },
        { id: 17, hour: 17, selected: false, backgroundColor: "none", hourLabel: "17", severity: "", workflow: "", hourLabelFull: "17:00", severityName: "", workflowName: "" },
        { id: 18, hour: 18, selected: false, backgroundColor: "none", hourLabel: "18", severity: "", workflow: "", hourLabelFull: "18:00", severityName: "", workflowName: "" },
        { id: 19, hour: 19, selected: false, backgroundColor: "none", hourLabel: "19", severity: "", workflow: "", hourLabelFull: "19:00", severityName: "", workflowName: "" },
        { id: 20, hour: 20, selected: false, backgroundColor: "none", hourLabel: "20", severity: "", workflow: "", hourLabelFull: "20:00", severityName: "", workflowName: "" },
        { id: 21, hour: 21, selected: false, backgroundColor: "none", hourLabel: "21", severity: "", workflow: "", hourLabelFull: "21:00", severityName: "", workflowName: "" },
        { id: 22, hour: 22, selected: false, backgroundColor: "none", hourLabel: "22", severity: "", workflow: "", hourLabelFull: "22:00", severityName: "", workflowName: "" },
        { id: 23, hour: 23, selected: false, backgroundColor: "none", hourLabel: "23", severity: "", workflow: "", hourLabelFull: "23:00", severityName: "", workflowName: "" },
    ])
    const [isUpdating, setIsUpdating] = useState(false);
    const [counting, setCounting] = useState(0);
    const [isUpdatingDiv, setIsUpdatingDiv] = useState([]);
    const [ogStartValue, setOgStartValue] = useState("");
    const [ogEndValue, setOgEndValue] = useState("");
    const [startValue, setStartValue] = useState("");
    const [endValue, setEndValue] = useState("");
    const [show, setShow] = useState(false);
    const [severity, setSeverity] = useState("");
    const [workflowSel, setWorkflowSel] = useState("");
    const [severityName, setSeverityName] = useState("")
    const [workflowName, setWorkflowName] = useState("");
    const [countTwo, setCountTwo] = useState(0);
    //#endregion useState end

    //#region useEffect start
    useEffect(() => {
        isFirstRender.current = false;
    }, []);

    useEffect(() => {
        console.log("updateMaster in useEffect", isFirstRender.current);
        if(gettingData){
           return; 
        }
        if(!isFirstRender.current){
            console.log("generateJson updating master");
            console.log("REVERSE data object");
            updateMaster();
        }
    }, [counting]);

    useEffect(() => {
        // console.log("REVERSE", sevenDaysData);
        let individualData = sevenDaysData.filter((item) => item.day == resource);
        console.log("REVERSE resource", resource, individualData[0].dayResource);
        let tempArr = individualData[0].dayResource
        console.log("REVERSE data object", resource, tempArr, sevenDaysData);
        // let arr = days; Old
        // New
        let arr = [
            { id: 0, hour: 0, selected: false, backgroundColor: "none", hourLabel: "00", severity: "", workflow: "", hourLabelFull: "00:00",severityName: "", workflowName: "" },
            { id: 1, hour: 1, selected: false, backgroundColor: "none", hourLabel: "01", severity: "", workflow: "", hourLabelFull: "01:00",severityName: "", workflowName: "" },
            { id: 2, hour: 2, selected: false, backgroundColor: "none", hourLabel: "02", severity: "", workflow: "", hourLabelFull: "02:00",severityName: "", workflowName: "" },
            { id: 3, hour: 3, selected: false, backgroundColor: "none", hourLabel: "03", severity: "", workflow: "", hourLabelFull: "03:00",severityName: "", workflowName: "" },
            { id: 4, hour: 4, selected: false, backgroundColor: "none", hourLabel: "04", severity: "", workflow: "", hourLabelFull: "04:00",severityName: "", workflowName: "" },
            { id: 5, hour: 5, selected: false, backgroundColor: "none", hourLabel: "05", severity: "", workflow: "", hourLabelFull: "05:00",severityName: "", workflowName: "" },
            { id: 6, hour: 6, selected: false, backgroundColor: "none", hourLabel: "06", severity: "", workflow: "", hourLabelFull: "06:00",severityName: "", workflowName: "" },
            { id: 7, hour: 7, selected: false, backgroundColor: "none", hourLabel: "07", severity: "", workflow: "", hourLabelFull: "07:00",severityName: "", workflowName: "" },
            { id: 8, hour: 8, selected: false, backgroundColor: "none", hourLabel: "08", severity: "", workflow: "", hourLabelFull: "08:00",severityName: "", workflowName: "" },
            { id: 9, hour: 9, selected: false, backgroundColor: "none", hourLabel: "09", severity: "", workflow: "", hourLabelFull: "09:00",severityName: "", workflowName: "" },
            { id: 10, hour: 10, selected: false, backgroundColor: "none", hourLabel: "10", severity: "", workflow: "", hourLabelFull: "10:00", severityName: "", workflowName: "" },
            { id: 11, hour: 11, selected: false, backgroundColor: "none", hourLabel: "11", severity: "", workflow: "", hourLabelFull: "11:00", severityName: "", workflowName: "" },
            { id: 12, hour: 12, selected: false, backgroundColor: "none", hourLabel: "12", severity: "", workflow: "", hourLabelFull: "12:00", severityName: "", workflowName: "" },
            { id: 13, hour: 13, selected: false, backgroundColor: "none", hourLabel: "13", severity: "", workflow: "", hourLabelFull: "13:00", severityName: "", workflowName: "" },
            { id: 14, hour: 14, selected: false, backgroundColor: "none", hourLabel: "14", severity: "", workflow: "", hourLabelFull: "14:00", severityName: "", workflowName: "" },
            { id: 15, hour: 15, selected: false, backgroundColor: "none", hourLabel: "15", severity: "", workflow: "", hourLabelFull: "15:00", severityName: "", workflowName: "" },
            { id: 16, hour: 16, selected: false, backgroundColor: "none", hourLabel: "16", severity: "", workflow: "", hourLabelFull: "16:00", severityName: "", workflowName: "" },
            { id: 17, hour: 17, selected: false, backgroundColor: "none", hourLabel: "17", severity: "", workflow: "", hourLabelFull: "17:00", severityName: "", workflowName: "" },
            { id: 18, hour: 18, selected: false, backgroundColor: "none", hourLabel: "18", severity: "", workflow: "", hourLabelFull: "18:00", severityName: "", workflowName: "" },
            { id: 19, hour: 19, selected: false, backgroundColor: "none", hourLabel: "19", severity: "", workflow: "", hourLabelFull: "19:00", severityName: "", workflowName: "" },
            { id: 20, hour: 20, selected: false, backgroundColor: "none", hourLabel: "20", severity: "", workflow: "", hourLabelFull: "20:00", severityName: "", workflowName: "" },
            { id: 21, hour: 21, selected: false, backgroundColor: "none", hourLabel: "21", severity: "", workflow: "", hourLabelFull: "21:00", severityName: "", workflowName: "" },
            { id: 22, hour: 22, selected: false, backgroundColor: "none", hourLabel: "22", severity: "", workflow: "", hourLabelFull: "22:00", severityName: "", workflowName: "" },
            { id: 23, hour: 23, selected: false, backgroundColor: "none", hourLabel: "23", severity: "", workflow: "", hourLabelFull: "23:00", severityName: "", workflowName: "" },
        ]
        if(tempArr.length > 0){
            for (let i = 0; i < tempArr.length; i++) {
                let startIndex = days.findIndex(x => x.hourLabel == tempArr[i].monitoringStartTime.slice(0, 2))
                console.log("REVERSE startIndex", startIndex);
                let endIndex = days.findIndex(x => x.hourLabel == tempArr[i].monitoringEndTime.slice(0, 2));
                console.log("REVERSE startIndex", endIndex);
                let severity = tempArr[i].severityId;
                let baC = "6fa8dc"
                if (severity == "1") {
                    baC = "#DD2021";
                } else if (severity == "2") {
                    baC = "#D06841";
                } else if (severity == "3") {
                    baC = "#DAA925";
                } else if (severity == "4") {
                    baC = "#83BB2B"
                } else {
                    baC = "#83BB2B"
                }
                console.log("REVERSE data object endIndex", endIndex);
                if(endIndex == 0){
                    for (let j = startIndex; j <= 23; j++) {
                        console.log("REVERSE updating array properties");
                        arr[j].selected = true;
                        arr[j].severity = severity;
                        arr[j].workflow = tempArr[i].workflowId;
                        arr[j].backgroundColor = baC
                    }
                }else{
                    for (let j = startIndex; j < endIndex; j++) {
                        console.log("REVERSE updating array properties");
                        arr[j].selected = true;
                        arr[j].severity = severity;
                        arr[j].workflow = tempArr[i].workflowId;
                        arr[j].backgroundColor = baC
                    }
                }
            }
            // let c = counting + 1;
            // setCounting(c);
            console.log("ISSUEREV", arr);
            setDays(arr);
        }else{
            setDays([
                { id: 0, hour: 0, selected: false, backgroundColor: "none", hourLabel: "00", severity: "", workflow: "", hourLabelFull: "00:00",severityName: "", workflowName: "" },
                { id: 1, hour: 1, selected: false, backgroundColor: "none", hourLabel: "01", severity: "", workflow: "", hourLabelFull: "01:00",severityName: "", workflowName: "" },
                { id: 2, hour: 2, selected: false, backgroundColor: "none", hourLabel: "02", severity: "", workflow: "", hourLabelFull: "02:00",severityName: "", workflowName: "" },
                { id: 3, hour: 3, selected: false, backgroundColor: "none", hourLabel: "03", severity: "", workflow: "", hourLabelFull: "03:00",severityName: "", workflowName: "" },
                { id: 4, hour: 4, selected: false, backgroundColor: "none", hourLabel: "04", severity: "", workflow: "", hourLabelFull: "04:00",severityName: "", workflowName: "" },
                { id: 5, hour: 5, selected: false, backgroundColor: "none", hourLabel: "05", severity: "", workflow: "", hourLabelFull: "05:00",severityName: "", workflowName: "" },
                { id: 6, hour: 6, selected: false, backgroundColor: "none", hourLabel: "06", severity: "", workflow: "", hourLabelFull: "06:00",severityName: "", workflowName: "" },
                { id: 7, hour: 7, selected: false, backgroundColor: "none", hourLabel: "07", severity: "", workflow: "", hourLabelFull: "07:00",severityName: "", workflowName: "" },
                { id: 8, hour: 8, selected: false, backgroundColor: "none", hourLabel: "08", severity: "", workflow: "", hourLabelFull: "08:00",severityName: "", workflowName: "" },
                { id: 9, hour: 9, selected: false, backgroundColor: "none", hourLabel: "09", severity: "", workflow: "", hourLabelFull: "09:00",severityName: "", workflowName: "" },
                { id: 10, hour: 10, selected: false, backgroundColor: "none", hourLabel: "10", severity: "", workflow: "", hourLabelFull: "10:00", severityName: "", workflowName: "" },
                { id: 11, hour: 11, selected: false, backgroundColor: "none", hourLabel: "11", severity: "", workflow: "", hourLabelFull: "11:00", severityName: "", workflowName: "" },
                { id: 12, hour: 12, selected: false, backgroundColor: "none", hourLabel: "12", severity: "", workflow: "", hourLabelFull: "12:00", severityName: "", workflowName: "" },
                { id: 13, hour: 13, selected: false, backgroundColor: "none", hourLabel: "13", severity: "", workflow: "", hourLabelFull: "13:00", severityName: "", workflowName: "" },
                { id: 14, hour: 14, selected: false, backgroundColor: "none", hourLabel: "14", severity: "", workflow: "", hourLabelFull: "14:00", severityName: "", workflowName: "" },
                { id: 15, hour: 15, selected: false, backgroundColor: "none", hourLabel: "15", severity: "", workflow: "", hourLabelFull: "15:00", severityName: "", workflowName: "" },
                { id: 16, hour: 16, selected: false, backgroundColor: "none", hourLabel: "16", severity: "", workflow: "", hourLabelFull: "16:00", severityName: "", workflowName: "" },
                { id: 17, hour: 17, selected: false, backgroundColor: "none", hourLabel: "17", severity: "", workflow: "", hourLabelFull: "17:00", severityName: "", workflowName: "" },
                { id: 18, hour: 18, selected: false, backgroundColor: "none", hourLabel: "18", severity: "", workflow: "", hourLabelFull: "18:00", severityName: "", workflowName: "" },
                { id: 19, hour: 19, selected: false, backgroundColor: "none", hourLabel: "19", severity: "", workflow: "", hourLabelFull: "19:00", severityName: "", workflowName: "" },
                { id: 20, hour: 20, selected: false, backgroundColor: "none", hourLabel: "20", severity: "", workflow: "", hourLabelFull: "20:00", severityName: "", workflowName: "" },
                { id: 21, hour: 21, selected: false, backgroundColor: "none", hourLabel: "21", severity: "", workflow: "", hourLabelFull: "21:00", severityName: "", workflowName: "" },
                { id: 22, hour: 22, selected: false, backgroundColor: "none", hourLabel: "22", severity: "", workflow: "", hourLabelFull: "22:00", severityName: "", workflowName: "" },
                { id: 23, hour: 23, selected: false, backgroundColor: "none", hourLabel: "23", severity: "", workflow: "", hourLabelFull: "23:00", severityName: "", workflowName: "" },
            ])
        }
        let c = countTwo + 1;
        setCountTwo(c);
    }, [sevenDaysData]);
    //#endregion useEffect end
    const handleShow = (e, item) => {
        setGettingData(false);
        let elementId = item.selected;
        let severityId = item.severity;
        let workflowId = item.workflow;
        if (elementId) {
            setIsUpdating(true);
            //It is already selected element.
            let newStart = item.id;
            let newEnd = item.id + 1;
            let selectedSeverity = "";
            let selectedWorkflow = "";
            let sampleArr = [];
            // First we need to check the left part 
            for (let i = item.id; i >= 0; i--) {
                if (days[i].selected && days[i].severity == severityId && days[i].workflow == workflowId) {
                    newStart = days[i].id;
                    selectedSeverity = days[i].severity;
                    selectedWorkflow = days[i].workflow
                } else {
                    break;
                }
            }

            // Then we need to check the right part.
            for (let j = item.id + 1; j <= 23; j++) {
                if (days[j].selected && days[j].severity == severityId && days[j].workflow == workflowId) {
                    newEnd = days[j].id + 1;
                    selectedSeverity = days[j].severity;
                    selectedWorkflow = days[j].workflow
                } else {
                    break;
                }
            }
            for (let k = newStart; k < newEnd; k++) {
                sampleArr.push(days[k].id);
            }
            setIsUpdatingDiv(sampleArr);
            if (newStart > 9) {
                setStartValue(`${days[newStart].hourLabel}:00`);
                setOgStartValue(`${days[newStart].hourLabel}:00`);
            } else {
                setStartValue(`0${newStart}:00`)
                setOgStartValue(`0${newStart}:00`)
            }
            if(newEnd > 9 && newEnd != 24){
                setEndValue(`${parseInt(days[newEnd - 1].hourLabel) + 1}:00`)
                setOgEndValue(`${parseInt(days[newEnd - 1].hourLabel) + 1}:00`)
            }else if(newEnd == 24){
                setEndValue("00:00")
                setOgEndValue("00:00")
            }else{
                setEndValue(`0${parseInt(newEnd)}:00`)
                setOgEndValue(`0${parseInt(newEnd)}:00`)
            }
            setSeverity(selectedSeverity);
            setWorkflowSel(selectedWorkflow);
            setShow(true);
        }
        else {
            if (item.id == 23) {
                setStartValue(`23:00`)
                setOgStartValue(`23:00`)
                setEndValue(`00:00`)
                setOgEndValue(`00:00`)
                setShow(true);
                return;
            }
            if (item.id > 9) {
                setStartValue(`${item.hourLabel}:00`);
                setOgStartValue(`${item.hourLabel}:00`);
                setEndValue(`${parseInt(item.hourLabel) + 1}:00`)
                setOgEndValue(`${parseInt(item.hourLabel) + 1}:00`)
            } else if (item.id == 9) {
                setStartValue("09:00");
                setOgStartValue("09:00");
                setEndValue("10:00");
                setOgEndValue("10:00");
            }
            else {
                setStartValue(`${item.hourLabel}:00`)
                setOgStartValue(`${item.hourLabel}:00`)
                setEndValue(`0${parseInt(item.id) + 1}:00`)
                setOgEndValue(`0${parseInt(item.id) + 1}:00`)
            }
            setShow(true);
        }
    }

    const updateMaster = () => {
        console.log("updateMaster inside func")
        let data = generateJson();
        let arr = sevenDaysData;
        let sampleArr = arr.map((item) => {
            let obj;
            if (item.day == resource) {
                obj = {
                    ...item,
                    dayResource: data
                }
            } else {
                obj = {
                    ...item
                }
            }
            return obj;
        })
        console.log("updateMaster", sampleArr);
        console.log("REVERSE data object", sampleArr);
        setSevenDaysData(sampleArr);
    }

    const generateJson = () => {
        // console.log("generateJson");
        let arr = [];
        console.log("updateMaster", days);
        for (let i = 0; i < days.length; i++) {
            let obj = {}
            let startTime = ""
            let endTime = ""
            let extraEndtime = ""
            let severity = ""
            let workflow = ""
            if (days[i].selected) {
                startTime = days[i].hourLabelFull;
                severity = days[i].severity
                workflow = days[i].workflow;
                for (let j = i; j < days.length; j++) {
                    // endTime = days[j].hourLabelFull;
                    extraEndtime = days[j].hourLabelFull;
                    if (days[j].selected && severity == days[j].severity && workflow == days[j].workflow) {
                        endTime = days[j].hourLabelFull;
                        continue;
                    } else {
                        i = j - 1;
                        break;
                    }
                }
                obj = {
                    monitoringStartTime: startTime,
                    monitoringEndTime: endTime == "23:00" ? "00:00" : extraEndtime,
                    severityId: severity,
                    workflowId: workflow
                }
                arr.push(obj);
                if(endTime == "23:00"){
                    return arr;   
                }
            }
        }
        return arr;
        // console.log("generateJson", arr);
    }

    return (
        <div className='SliderDiv'>
                            {
                                days.map((item) => (
                                    <OverlayTrigger
                                    key={item.id}
                                    placement='top'
                                    overlay={
                                        item.selected ?
                                        (
                                            <Tooltip>
                                            {
                                                <div>
                                                    <span>Severity: {severities?.find((i) => i.SeverityId == item.severity)?.SeverityTitle}</span>
                                                    <br />
                                                    <span>Workflow: {workflows?.find((i) => i.workflowID == item.workflow)?.workflowName}</span>
                                                </div>
                                            }
                                        </Tooltip>
                                        )
                                        : 
                                        (
                                            <span></span>
                                        )     
                                    }
                                >
                                    <div
                                        draggable={true}
                                        id={`Slide_${item.id}`} key={item.id} className='TimeDiv' style={{ backgroundColor: item.backgroundColor, cursor: 'pointer' }}
                                        onClick={(e) => {
                                            if(!isAlertTemplateEdit){
                                                return;
                                            }else{
                                                handleShow(e, item)
                                            }
                                        }}
                                        >
                                    </div>
                                    </OverlayTrigger>
                                ))
                            }
                        </div>
    )
})

export default ActivityCustomScheduler