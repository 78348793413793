import React, { useEffect, useImperativeHandle, useState, useRef, useCallback } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import InlineEditItem from "../../components/InlineEditListItem/InlineEditItem";
import { Row, Col, OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import { parse } from "papaparse";
import Select from "react-select";
import { ToastContainer } from 'react-toastify';
import { CSVLink, CSVDownload } from "react-csv";
import Papa from 'papaparse';
import { SearchIcon, EditShape, SampleDownload, Location, Cross, EditQc, DeleteQc, SiteDisable, SiteDefault, SiteSelected, InterfaceDisable, InterfaceDefault, InterfaceSelected, DevicesDisable, DevicesDefault, DevicesSelected, BackArrow, BackArrowDevice, AddNode, DVRNVR, SearchIconWhite, RemoveIcon } from '../../assets/images'
import Modal from "react-bootstrap/Modal";
import { addCustomerName, getStateDetails, getInterfaceSubTypeData, Getsitetypelist } from "../../services/configurationServices";
import { ErrorToast } from '../../../src/CustomHooks/ErrorToast'
import { SuccessToast } from "../../CustomHooks/SuccessToast";
import { WarningToast } from '../../CustomHooks/WarningToast'
import { Mention, MentionsInput } from "react-mentions";
import mentionsInputStyles from "../NotificationMenu/mentionsInputStyles";
import { HIDE_STUFF } from "../../config";

let tempSiteObj = {
  "SiteID": 0,
  "SiteName": '',
  "BranchID": '',
  "SiteAddress": '',
  "clientID": 0,
  "AddCustomerName": '',
  "CountryName": 0,
  "StateName": 0,
  "CityName": '',
  "SiteRegionName": '',
  "SitePincode": '',
  "SiteArea": '',
  "SiteModeID":0
}

let interfaceObj = {
  "siteid": 0,
  "InterfaceId": 0,
  "InterfaceName": '',
  "InterfaceType": 0,
  "InterfaceSubType": 0,
  "InterfaceIP": '',
  "InterfacePort": '',
  "InterfaceUsername": '',
  "InterfacePassword": '',
  "UserId": 0,
  "EntityTypeID": 0,
  "CCTVTypeIDInterface": 0,
  "InterfaceTwoWayEnabled": '',
  "CheckInternalHooter": 0,
  "CheckExternalHooter": 0,
  "IsHooterEnabled": 0,
  "PlaybackTimeStampsToUTC": '',
  "PlaybackLongDateFormat": '',
  "LiveRTSPURL": '',
  "PlaybackRTSPURL": '',
  "CCTVType": ''
}

let deviceObj = {
  "ParentCameraID": 0,
  "cameraName": '',
  "privateIPAddress": '',
  "SiteID": 0,
  "userName": '',
  "password": '',
  "port": '',
  "channelNumber": '',
  "liveStreamTypeNumber":'',
  "playbackStreamTypeNumber":'',
  // "playbackChannelNumber": '',
  "rTSPURL": '',
  "location": '',
  "PanelMasterId": 0,
  "CameraBrandId": 0,
  "communicationPort": '',
  "httpPort": '',
  "isTranscoded": '',
  "interfaceId": '',
  "CCTVTypeID": 0,
  'isTwoWayEnabledDevice': '',
  'isPlaybackTranscoded':''
}

export default function SiteTemplateStep({
  onNavigate,
  pageState,
  onSaveSite,
  selectedSite,
  deviceslist,
  cameradeviceslist,
  paneldeviceslist,
  onMoveNext,

  sites,
  onSelectSite,
  onDeleteSite,
  isSiteEdit,
  setIsSiteEdit,
  siteRef,
  sitesToBind,
  siteGroups,
  selectedSiteGroup,
  onSiteGroupSelection,
  selectedSites,
  setSelectedSites,
  updateSitesToSiteGroup,
  onDeleteSiteGroup,
  onAddNewSiteGroup,
  onUpdateSiteGroup,
  selectedSiteTypeList,
  setselectedSiteTypeList,
  onSearchChange,
  filteredSites,
  filteredSiteGroups,
  selectedDevice,
  OnSaveDevice,
  onDeviceSelection,
  getdeviceslistdata,
  onAddNewDevice,
  isDeviceEdit,
  setIsDeviceEdit,
  devicesmasterlist,
  OnAddIDSPanel,
  clients,
  getCliets,
  country,
  siteTypes,
  setSiteTypes,
  countryState,
  setCountryState,
  ertLevels,
  siteERTOnClick,
  selectedERT,
  onAddERTMemberClick,
  addNewERTMemberClick,
  emeContactList,
  onDeleteERT,
  isERTSectionVisible,
  setImportedPanelHeaders,
  onSensorSelection,
  associatedDevices,
  onAssociate,
  selectedSensor,
  associateCamSecVisibility,
  importedPanelHeaders,
  setBulkDevToUpload,
  bulkDevToUpload,
  OnAddBulkDevicesClick,
  importedFileHeaders,
  selectedHeaders,
  setImportedFileHeaders,
  onPanelBulkUpload,
  setSelectedHeaders,
  setFilteredSites,
  bulkUpload,
  setBulkUpload,
  isSingleOrBulkSelection,
  setIsSingleOrBulkSelection,
  clearImportedPanelHeaders,
  checkForAlertsAndDelDevice,
  selectedZone,
  setselectedZone,
  userOptions,
  onUserRoleChange,
  selectedUserRole,
  setSelectedUserRole,
  siteUserFieldsVisibility,
  selectedSiteTypeClick,
  selectedCamHeaders,
  setSelectedCamHeaders,
  clearImportedCamHeaders,
  deviceBulkUploadResponse,
  setImportedFileData,
  panelBulkUploadResponse,
  setPanelBulkUploadResponse,
  setPanelBulkUploadError,
  panelBulkUploadError,
  setDeviceBulkUploadResponse,
  selectedItems,
  setSelectedItems,
  isSubmitting,
  setSelectedSite,
  setParentIsEncoding,
  setParentIsPlaybackEncoding,
  parentIsEnablingTwoWayDevice,
  parentIsEncoding,
  parentIsPlaybackEncoding,
  setParentIsEnablingTwoWayDevice,
  setParentIsEnablingTwoWayInterface,
  parentIsEnablingTwoWayInterface,
  setParentIsEnablingInternalHooter,
  setParentIsEnablingExternalHooter,
  setParentPanelIsEnablingHooter,
  parentIsEnablingInternalHooter,
  setSelectedEntitySubtype,
  setParentIsEnablingPlaybackTimeStampsToUTC,
  parentIsEnablingExternalHooter,
  parentIsEnablingPlaybackTimeStampsToUTC,
  setParentIsEnablingPlaybackLongDateFormat,
  parentIsEnablingPlaybackLongDateFormat,
  parentPanelIsEnablingHooter,
  interfaceTypeData,
  interfaceSubTypeData,
  setInterfaceSubTypeData,
  interfaceDataList,
  onInterfaceFormSubmit,
  onSelectInterface,
  selectedInterface,
  setSelectedInterface,
  onDeleteInterface,
  isInterfaceEdit,
  setIsInterfaceEdit,
  getInterfaceData,
  setSelectedDevice,
  setAssociateCamSecVisibility,
  cctvTypeData,
  setInterfaceDataList,
  setcameradeviceslist,
  setpaneldeviceslist,
  rtspValueChanged,
  setRtspValueChanged,
  isDarkTheme,
  setUnknownBrandUrl,
  unknownBrandUrl,
  siteFormObj,
  setSiteFormObj,
  validationError,
  setInputValidationError,
  savebtnClick,
  setSavebtnClick,
  buttonDisable,
}) {
  // const [selectedSiteTypeList, setselectedSiteTypeList] = useState('Site List')
  // const [siteTypes, setSiteTypes] = useState(null);
  //const [SiteTypeState, setSiteTypesState] = useState(null)
  const focusSearchDiv = useRef();

  function onDeleteChange(e, siteid, name) {
    e.stopPropagation();
    Swal.fire({
      title: "Are you sure?",
      text: `You really want to delete ${name} site!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteSite(siteid);
      }
    });
  }

  function onDeleteInterfaceChange(e, siteid, name) {
    
    e.stopPropagation();
    Swal.fire({
      title: "Are you sure?",
      text: `Deleting the Interface will delete all the entites associated with it. Do you really want to delete ${name} interface!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteInterface(siteid)
      }
    });
  }


  const [issearch, setissearch] = useState(true);


  function ToggeleSearch() {
    try {
      setissearch(!issearch);
      onSearchChange("", selectedSiteTypeList);
      if (focusSearchDiv.current) focusSearchDiv.current.focus();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <div className="col-lg-12 mt-2">
        <>
          {pageState == "Site Configuration" && (
            <>
              <AddSiteForm
                onSubmit={onSaveSite}
                site={selectedSite}
                isSiteEdit={isSiteEdit}
                setIsSiteEdit={setIsSiteEdit}
                ref={siteRef}
                clients={clients}
                getCliets={getCliets}
                country={country}
                siteTypes={siteTypes}
                setSiteTypes={setSiteTypes}
                countryState={countryState}
                setCountryState={setCountryState}
                onSelectSite={onSelectSite}
                ertLevels={ertLevels}
                siteERTOnClick={siteERTOnClick}
                selectedERT={selectedERT}
                onAddERTMemberClick={onAddERTMemberClick}
                addNewERTMemberClick={addNewERTMemberClick}
                emeContactList={emeContactList}
                onDeleteERT={onDeleteERT}
                isERTSectionVisible={isERTSectionVisible}
                userOptions={userOptions}
                onUserRoleChange={onUserRoleChange}
                selectedUserRole={selectedUserRole}
                setSelectedUserRole={setSelectedUserRole}
                siteUserFieldsVisibility={siteUserFieldsVisibility}
                filteredSites={filteredSites}
                cameradeviceslist={cameradeviceslist}
                onDeviceSelection={onDeviceSelection}
                selectedDevice={selectedDevice}
                devicesmasterlist={devicesmasterlist}
                isSubmitting={isSubmitting}
                sites={sites}
                OnAddIDSPanel={OnAddIDSPanel}
                paneldeviceslist={paneldeviceslist}
                OnSaveDevice={OnSaveDevice}
                getdeviceslistdata={getdeviceslistdata}
                onSearchChange={onSearchChange}
                setSelectedSite={setSelectedSite}
                setSelectedDevice={setSelectedDevice}
                onDeleteChange={onDeleteChange}
                onAddNewDevice={onAddNewDevice}
                checkForAlertsAndDelDevice={checkForAlertsAndDelDevice}
                interfaceTypeData={interfaceTypeData}
                interfaceSubTypeData={interfaceSubTypeData}
                setInterfaceSubTypeData={setInterfaceSubTypeData}
                interfaceDataList={interfaceDataList}
                onInterfaceFormSubmit={onInterfaceFormSubmit}
                onSelectInterface={onSelectInterface}
                interfaceDevice={selectedInterface}
                setSelectedInterface={setSelectedInterface}
                setBulkUpload={setBulkUpload}
                setBulkDevToUpload={setBulkDevToUpload}
                setIsSingleOrBulkSelection={setIsSingleOrBulkSelection}
                bulkUpload={bulkUpload}
                selectedCamHeaders={selectedCamHeaders}
                OnAddBulkDevicesClick={OnAddBulkDevicesClick}
                setSelectedCamHeaders={setSelectedCamHeaders}
                clearImportedCamHeaders={clearImportedCamHeaders}
                deviceBulkUploadResponse={deviceBulkUploadResponse}
                setImportedFileData={setImportedFileData}
                setDeviceBulkUploadResponse={setDeviceBulkUploadResponse}
                selectedHeaders={selectedHeaders}
                onPanelBulkUpload={onPanelBulkUpload}
                setSelectedHeaders={setSelectedHeaders}
                clearImportedPanelHeaders={clearImportedPanelHeaders}
                panelBulkUploadResponse={panelBulkUploadResponse}
                setPanelBulkUploadResponse={setPanelBulkUploadResponse}
                panelBulkUploadError={panelBulkUploadError}
                setPanelBulkUploadError={setPanelBulkUploadError}
                // selectedInterface = {selectedInterface}
                isInterfaceEdit={isInterfaceEdit}
                setIsInterfaceEdit={setIsInterfaceEdit}
                setIsDeviceEdit={setIsDeviceEdit}
                getInterfaceData={getInterfaceData}
                onDeleteInterfaceChange={onDeleteInterfaceChange}
                isDeviceEdit={isDeviceEdit}
                cctvTypeData={cctvTypeData}
                setFilteredSites={setFilteredSites}
                importedFileHeaders={importedFileHeaders}
                setImportedFileHeaders={setImportedFileHeaders}
                importedPanelHeaders={importedPanelHeaders}
                setImportedPanelHeaders={setImportedPanelHeaders}
                selectedZone={selectedZone}
                setselectedZone={setselectedZone}
                associateCamSecVisibility={associateCamSecVisibility}
                setAssociateCamSecVisibility={setAssociateCamSecVisibility}
                onSensorSelection={onSensorSelection}
                associatedDevices={associatedDevices}
                onAssociate={onAssociate}
                selectedSensor={selectedSensor}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                setInterfaceDataList={setInterfaceDataList}
                setcameradeviceslist={setcameradeviceslist}
                setpaneldeviceslist={setpaneldeviceslist}
                parentIsEncoding={parentIsEncoding}
                parentIsPlaybackEncoding={parentIsPlaybackEncoding}
                setParentIsEncoding={setParentIsEncoding}
                setParentIsPlaybackEncoding={setParentIsPlaybackEncoding}
                setParentIsEnablingTwoWayDevice={setParentIsEnablingTwoWayDevice}
                setParentIsEnablingTwoWayInterface={setParentIsEnablingTwoWayInterface}
                parentIsEnablingTwoWayInterface={parentIsEnablingTwoWayInterface}
                parentIsEnablingTwoWayDevice={parentIsEnablingTwoWayDevice}
                setParentIsEnablingInternalHooter={setParentIsEnablingInternalHooter}
                setParentIsEnablingExternalHooter={setParentIsEnablingExternalHooter}
                setParentPanelIsEnablingHooter={setParentPanelIsEnablingHooter}
                setSelectedEntitySubtype={setSelectedEntitySubtype}
                setParentIsEnablingPlaybackTimeStampsToUTC={setParentIsEnablingPlaybackTimeStampsToUTC}
                parentIsEnablingPlaybackTimeStampsToUTC={parentIsEnablingPlaybackTimeStampsToUTC}
                parentIsEnablingExternalHooter={parentIsEnablingExternalHooter}
                parentIsEnablingInternalHooter={parentIsEnablingInternalHooter}
                setParentIsEnablingPlaybackLongDateFormat={setParentIsEnablingPlaybackLongDateFormat}
                parentIsEnablingPlaybackLongDateFormat={parentIsEnablingPlaybackLongDateFormat}
                parentPanelIsEnablingHooter={parentPanelIsEnablingHooter}
                rtspValueChanged={rtspValueChanged}
                setRtspValueChanged={setRtspValueChanged}
                isDarkTheme={isDarkTheme}
                setUnknownBrandUrl = {setUnknownBrandUrl}
                siteFormObj={siteFormObj}
                setSiteFormObj={setSiteFormObj}
                validationError={validationError}
                savebtnClick={savebtnClick}
                setSavebtnClick={setSavebtnClick}
                buttonDisable={buttonDisable}
              />
            </>
          )}
          {
            pageState == "Interface" && (
              <>
                <AddInterfaceForm

                />
              </>
            )
          }
          {pageState == "Manage Devices" && (
            <>
              {
                // deviceslist &&
                <AddDevices
                  deviceslist={deviceslist}
                  cameradeviceslist={cameradeviceslist}
                  paneldeviceslist={paneldeviceslist}
                  onFinish={onMoveNext}
                  sites={sites}
                  selectedDevice={selectedDevice}
                  OnSaveDevice={OnSaveDevice}
                  onDeviceSelection={onDeviceSelection}
                  onAddNewDevice={onAddNewDevice}
                  isDeviceEdit={isDeviceEdit}
                  setIsDeviceEdit={setIsDeviceEdit}
                  devicesmasterlist={devicesmasterlist}
                  OnAddIDSPanel={OnAddIDSPanel}
                  onSensorSelection={onSensorSelection}
                  associatedDevices={associatedDevices}
                  onAssociate={onAssociate}
                  selectedSensor={selectedSensor}
                  associateCamSecVisibility={associateCamSecVisibility}
                  setBulkDevToUpload={setBulkDevToUpload}
                  bulkDevToUpload={bulkDevToUpload}
                  OnAddBulkDevicesClick={OnAddBulkDevicesClick}
                  selectedHeaders={selectedHeaders}
                  onPanelBulkUpload={onPanelBulkUpload}
                  setSelectedHeaders={setSelectedHeaders}
                  getdeviceslistdata={getdeviceslistdata}
                  bulkUpload={bulkUpload}
                  setBulkUpload={setBulkUpload}
                  isSingleOrBulkSelection={isSingleOrBulkSelection}
                  setIsSingleOrBulkSelection={setIsSingleOrBulkSelection}
                  checkForAlertsAndDelDevice={checkForAlertsAndDelDevice}
                  selectedZone={selectedZone}
                  setselectedZone={setselectedZone}
                  clearImportedPanelHeaders={clearImportedPanelHeaders}
                  selectedCamHeaders={selectedCamHeaders}
                  setSelectedCamHeaders={setSelectedCamHeaders}
                  clearImportedCamHeaders={clearImportedCamHeaders}
                  deviceBulkUploadResponse={deviceBulkUploadResponse}
                  setImportedFileData={setImportedFileData}
                  panelBulkUploadResponse={panelBulkUploadResponse}
                  setPanelBulkUploadResponse={setPanelBulkUploadResponse}
                  panelBulkUploadError={panelBulkUploadError}
                  setPanelBulkUploadError={setPanelBulkUploadError}
                  setDeviceBulkUploadResponse={setDeviceBulkUploadResponse}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  isSubmitting={isSubmitting}
                  importedFileHeaders={importedFileHeaders}
                  setImportedFileHeaders={setImportedFileHeaders}
                />
              }
            </>
          )}
          {pageState == "Manage Site Groups" && (
            <>
              {
                <AddSiteGroup
                  sites={sites}
                  siteGroups={siteGroups}
                  sitesToBind={sitesToBind}
                  onFinish={onMoveNext}
                  selectedSiteGroup={selectedSiteGroup}
                  onSiteGroupSelection={onSiteGroupSelection}
                  selectedSites={selectedSites}
                  setSelectedSites={setSelectedSites}
                  onAddSelectedSites={updateSitesToSiteGroup}
                  onDeleteSiteGroup={onDeleteSiteGroup}
                  onAddNewSiteGroup={onAddNewSiteGroup}
                  onUpdateSiteGroup={onUpdateSiteGroup}
                />
              }

              <div className="RightListSide">
                <div className="m-2 HeaderSiteList">
                  {issearch && (
                    <>
                      <p
                        className={
                          selectedSiteTypeList == "Site List"
                            ? "active-all-light active-block active-text button-common-margin"
                            : "inactive-text button-common-margin"
                        }
                        onClick={() => selectedSiteTypeClick("Site List")}
                      >
                        Site List
                      </p>
                      <p
                        className={
                          selectedSiteTypeList == "Site Group"
                            ? "active-all-light active-block active-text button-common-margin"
                            : "inactive-text button-common-margin"
                        }
                        onClick={() => selectedSiteTypeClick("Site Group")}
                      >
                        Site Group
                      </p>
                    </>
                  )}
                  {!issearch && selectedSiteTypeList == "Site List" && (
                    <input
                      id="Search_txt"
                      className="SearchViewInput1"
                      ref={focusSearchDiv}
                      type="text"
                      onChange={(e) =>
                        onSearchChange(e.target.value, selectedSiteTypeList)
                      }
                      placeholder="Search Site"
                      autoFocus
                    />
                  )}
                  {!issearch && selectedSiteTypeList == "Site Group" && (
                    <input
                      id="Search_txt"
                      className="SearchViewInput1"
                      ref={focusSearchDiv}
                      type="text"
                      onChange={(e) =>
                        onSearchChange(e.target.value, selectedSiteTypeList)
                      }
                      placeholder="Search Site Group"
                      autoFocus
                    />
                  )}

                  <div className="SearchTextDivConfigTool">
                    {issearch && (
                      <img
                        className="EyeIcon"
                        alt="search icon"
                        src={SearchIcon}
                        onClick={() => ToggeleSearch()}
                      />
                    )}
                    {!issearch && (
                      <img
                        className="EyeIcon"
                        alt="cancel icon"
                        src={Cross}
                        onClick={() => ToggeleSearch()}
                      />
                    )}
                  </div>
                </div>
                <div className="SiteListMain">
                  {selectedSiteTypeList &&
                    selectedSiteTypeList == "Site List" &&
                    filteredSites &&
                    filteredSites.map((site) => {
                      return (
                        <div
                          className="OneList"
                          key={site.SiteID}
                          onClick={(e) => {
                            e.stopPropagation();
                            onSelectSite(site.SiteID, true);
                          }}
                        >
                          <p className="ListofText">
                            {site.SiteName}
                            <span className="SiteNameText"> - {site.BranchID}</span>
                          </p>
                          <div>
                            <img className="m-2" src={EditQc} alt="edit icon" />
                            <img
                              src={DeleteQc}
                              alt="delete icon"
                              onClick={(e) =>
                                onDeleteChange(e, site.SiteID, site.SiteName)
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                  {!filteredSites && selectedSiteTypeList == "Site List" && (
                     <div class="no-report-found"><p class="noRecordFoundText">No Sites Available.</p></div>
                  )}

                  {selectedSiteTypeList &&
                    selectedSiteTypeList == "Site Group" &&
                    filteredSiteGroups &&
                    filteredSiteGroups.map((siteGroup) => {
                      return (
                        <div
                          className="OneList"
                          key={siteGroup.siteGroupID}
                          onClick={(e) => {
                            e.stopPropagation();
                            onSelectSite(siteGroup.siteGroupID);
                          }}
                        >
                          <p className="ListofText">{siteGroup.groupTitle}</p>
                          <div>
                            <img
                              src={DeleteQc}
                              id={siteGroup.siteGroupID}
                              title="Delete site group"
                              onClick={(e) => onDeleteSiteGroup(e, siteGroup)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  {!filteredSiteGroups && selectedSiteTypeList == "Site Group" && (
                    <div className="no-data-found">No Site Groups Available.</div>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      </div>
    </>
  );
}

const AddDevices = ({
  deviceslist,
  cameradeviceslist,
  paneldeviceslist,
  onFinish,
  sites,
  selectedDevice,
  OnSaveDevice,
  onDeviceSelection,
  onAddNewDevice,
  isDeviceEdit,
  setIsDeviceEdit,
  devicesmasterlist,
  OnAddIDSPanel,
  onSensorSelection,
  associatedDevices,
  getdeviceslistdata,
  onAssociate,
  selectedSensor,
  associateCamSecVisibility,
  setBulkDevToUpload,
  bulkDevToUpload,
  OnAddBulkDevicesClick,
  selectedHeaders,
  onPanelBulkUpload,
  setSelectedHeaders,
  bulkUpload,
  setBulkUpload,
  isSingleOrBulkSelection,
  setIsSingleOrBulkSelection,
  checkForAlertsAndDelDevice,
  selectedZone,
  setselectedZone,
  clearImportedPanelHeaders,
  selectedCamHeaders,
  setSelectedCamHeaders,
  clearImportedCamHeaders,
  deviceBulkUploadResponse,
  setImportedFileData,
  panelBulkUploadResponse,
  setPanelBulkUploadResponse,
  panelBulkUploadError,
  setPanelBulkUploadError,
  setDeviceBulkUploadResponse,
  selectedItems,
  setSelectedItems,
  isSubmitting,
  importedFileHeaders,
  setImportedFileHeaders,
  importedPanelHeaders,
  setImportedPanelHeaders,
}) => {
  const [devicetype, setdevicetype] = useState(true);

  const [refreshDeviceForm, setRefreshDeviceForm] = useState(false);

  //#region bulk upload props
  const hiddenFileInput = React.useRef(null);

  //#endregion bulk upload props

  const handleSingleUploadClick = () => {
    setBulkUpload(false);
    setIsSingleOrBulkSelection("single_device");
  };
  //#region bulk upload methods
  const handleBulkUploadClick = (e) => {
    setBulkUpload(true);
    setBulkDevToUpload([]);
    setIsSingleOrBulkSelection("bulk_upload");
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    setRefreshDeviceForm(true);
  }, [selectedDevice]);

  return (
    <div className="LeftAddSide">
      <div className="LeftAddSideHeight">
        <div className="AddDevices1">
          <div>
            <Row>
              <Col lg={7} className="headerContainer-1">
                <h1 className="MainHeaderName mb-0">1. Manage Devices</h1>
              </Col>
              <Col lg={5} className="headerContainer-2">
                <div style={{ float: "right", padding: "8px" }}>
                  <div
                    className={
                      devicetype == true
                        ? "active-all-light active-block active-text"
                        : "inactive-text"
                    }
                    onClick={() => {
                      onAddNewDevice();
                      setdevicetype(true);
                    }}
                  >
                    NVR / DVR
                  </div>
                  <div
                    className={
                      devicetype == false
                        ? "active-all-light active-block active-text"
                        : "inactive-text"
                    }
                    onClick={() => {
                      onAddNewDevice();
                      setdevicetype(false);
                    }}
                  >
                    Panel
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <div className="DevicesListScroll">
              <div className="DevicesHeader">
                <p className="mb-0" style={{ width: "40%" }}>
                  Devices
                  <br />
                </p>
                <div className="bulkDevices1">
                  <div className="HeaderSiteList mb-0">
                    <span
                      className={
                        isSingleOrBulkSelection == "single_device"
                          ? "active-all-light active-block active-text button-common-margin"
                          : "inactive-text button-common-margin"
                      }
                      onClick={() => handleSingleUploadClick()}
                    >
                      Single Device
                    </span>
                    <span
                      className={
                        isSingleOrBulkSelection == "bulk_upload"
                          ? "active-all-light active-block active-text"
                          : "inactive-text"
                      }
                      onClick={() => handleBulkUploadClick()}
                    >
                      Bulk Upload
                    </span>
                  </div>
                </div>
              </div>
              {devicetype && (
                <>
                  <div className="site-group">
                    <div className="DeviceDivScroll">
                      {cameradeviceslist &&
                        cameradeviceslist.map((item) => {
                          return (
                            <div
                              id={item.ParentCameraID}
                              key={item.ParentCameraID}
                              className={`cameraList user-list-item ${selectedDevice &&
                                item.ParentCameraID ==
                                selectedDevice?.ParentCameraID &&
                                "selected-user"
                                }`}
                              onClick={(e) => onDeviceSelection(item)}
                            >
                              <p className="mb-0">{item.CameraName}jj</p>
                              <div style={{ float: "right" }}>
                                {/* <img src={DeleteShape} onClick={(e) => onDeleteChange(e, site.SiteID, site.SiteName)} /> */}
                                <img
                                  onClick={(e) => onDeviceSelection(item)}
                                  src={EditQc}
                                  className="m-1"
                                  alt="edit icon"
                                />
                                <img
                                  className="m-1"
                                  onClick={() => checkForAlertsAndDelDevice(item)}
                                  src={DeleteQc}
                                  alt="delete icon"
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div className="AddMore" style={{ float: "left" }}>
                      <a
                        className="UserAddPlus mt-1"
                        onClick={() => onAddNewDevice()}
                      >
                        Add New NVR/DVR +
                      </a>
                    </div>
                    {/* <ToastContainer
                      position="bottom-left"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    /> */}

                  </div>
                  {!bulkUpload && (
                    <AddCameraDeviceForm
                      onSubmit={OnSaveDevice}
                      sites={sites}
                      selectedDevice={selectedDevice}
                      isDeviceEdit={isDeviceEdit}
                      setIsDeviceEdit={setIsDeviceEdit}
                      devicesmasterlist={devicesmasterlist}
                      isSubmitting={isSubmitting}
                    />
                  )}
                  {bulkUpload && (
                    <>
                      <CameraBulkUpload
                        selectedCamHeaders={selectedCamHeaders}
                        OnAddBulkDevicesClick={OnAddBulkDevicesClick}
                        setSelectedCamHeaders={setSelectedCamHeaders}
                        clearImportedCamHeaders={clearImportedCamHeaders}
                        deviceBulkUploadResponse={deviceBulkUploadResponse}
                        setImportedFileData={setImportedFileData}
                        setDeviceBulkUploadResponse={setDeviceBulkUploadResponse}
                        importedFileHeaders={importedFileHeaders}
                        setImportedFileHeaders={setImportedFileHeaders}
                      />
                    </>
                  )}
                </>
              )}
              {!devicetype && (
                <>
                  <div className="site-group">
                    <div className="DeviceDivScroll DeviceDivScroll-Panel">
                      {paneldeviceslist &&
                        paneldeviceslist.map((item) => {
                          return (
                            <div
                              id={item.ParentCameraID}
                              key={item.ParentCameraID}
                              className={`cameraList user-list-item ${selectedDevice &&
                                item.ParentCameraID ==
                                selectedDevice?.ParentCameraID &&
                                "selected-user"
                                }`}
                              onClick={(e) => onDeviceSelection(item)}
                            >
                              <p className="mb-0">{item.CameraName}</p>
                              <div style={{ float: "right" }}>
                                <img
                                  style={{ paddingRight: "10px" }}
                                  onClick={(e) => onDeviceSelection(item)}
                                  src={EditQc}
                                  alt="edit icon"
                                  className="m-1"
                                />
                                <img
                                  src={DeleteQc}
                                  alt="delete icon"
                                  onClick={() =>
                                    OnAddIDSPanel(
                                      null,
                                      item.ParentCameraID,
                                      "deletePanel"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div className="AddMore" style={{ float: "left" }}>
                      <a
                        className="UserAddPlus mt-1"
                        onClick={() => onAddNewDevice()}
                      >
                        Add New Panel +
                      </a>
                    </div>
                  </div>
                  {!bulkUpload && (
                    <AddPanelDeviceForm
                      onSubmit={OnSaveDevice}
                      sites={sites}
                      selectedDevice={selectedDevice}
                      isDeviceEdit={isDeviceEdit}
                      devicesmasterlist={devicesmasterlist}
                      OnAddIDSPanel={OnAddIDSPanel}
                    />
                  )}
                  {bulkUpload && (
                    <>
                      <PanelBulkUpload
                        selectedHeaders={selectedHeaders}
                        onPanelBulkUpload={onPanelBulkUpload}
                        setSelectedHeaders={setSelectedHeaders}
                        clearImportedPanelHeaders={clearImportedPanelHeaders}
                        setImportedFileData={setImportedFileData}
                        panelBulkUploadResponse={panelBulkUploadResponse}
                        setPanelBulkUploadResponse={setPanelBulkUploadResponse}
                        panelBulkUploadError={panelBulkUploadError}
                        setPanelBulkUploadError={setPanelBulkUploadError}
                        setBulkUpload={setBulkUpload}
                        setIsSingleOrBulkSelection={setIsSingleOrBulkSelection}
                        importedPanelHeaders={importedPanelHeaders}
                        setImportedPanelHeaders={setImportedPanelHeaders}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="ButtonDiv">
            <button className="AlertViewButton  mr-3" hidden>
              <p>Previous</p>
            </button>
          </div>
        </div>

        {!devicetype && associateCamSecVisibility && (
          <ConfigurePanelZones
            selectedDevice={selectedDevice}
            devicesmasterlist={devicesmasterlist}
            OnAddIDSPanel={OnAddIDSPanel}
            setselectedZone={setselectedZone}
            selectedZone={selectedZone}
          />
        )}
        {!devicetype && associateCamSecVisibility && (
          <AssociateCameraOnSensor
            selectedDevice={selectedDevice}
            onSensorSelection={onSensorSelection}
            associatedDevices={associatedDevices}
            onAssociate={onAssociate}
            selectedSensor={selectedSensor}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
          />
        )}
      </div>
    </div>
  );
};

const CameraBulkUpload = React.forwardRef((props, ref) => {
  let {
    selectedCamHeaders,
    OnAddBulkDevicesClick,
    setSelectedCamHeaders,
    clearImportedCamHeaders,
    deviceBulkUploadResponse,
    setImportedFileData,
    setDeviceBulkUploadResponse,
    handleSingleUploadClick,
    setBulkUpload,
    setIsSingleOrBulkSelection,
    importedFileHeaders,
    setImportedFileHeaders,
  } = { ...props };

  const { register, handleSubmit } = useForm({
    defaultValue: { ...selectedCamHeaders },
  });
  console.log("selectedCamHeadersss", selectedCamHeaders);

  const hiddenCamFileInput = React.useRef(null);
  // const [importedFileData, setImportedFileData] = useState(null);
  // const [importedFileHeaders, setImportedFileHeaders] = useState(null);

  //brand,ipaddress,username,password,port,channelnumber,playbackChannelNumber,devicename,branch
  const [defaultBrand, setDefaultBrand] = useState(null);
  const [defaultIp, setDefaultIp] = useState(null);
  const [defaultCommunicationPort, setDefaultCommunicationPort] = useState(null);
  const [defaultHttpPort, setDefaultHttpPort] = useState(null);
  const [defaultUserName, setDefaultUserName] = useState(null);
  const [defaultPassword, setDefaultPassword] = useState(null);
  const [defaultPort, setDefaultPort] = useState(null);
  const [defaultChannelNum, setDefaultChannelNum] = useState(null);
  // const [defaultPlaybackChannelNum, setDefaultPlaybackChannelNum] = useState(null);
  const [defaultLiveStreamType, setDefaultLiveStreamType] = useState(null);
  const [defaultPlaybackStreamType, setDefaultPlaybackStreamType] = useState(null);
  const [defaultDeviceName, setDefaultDeviceName] = useState(null);
  const [defaultBranch, setDefaultBranch] = useState(null);
  const [defaultEnablePlaybackTranscoding, setDefaultEnablePlaybackTranscoding] = useState(null);
  const [defaultEnableTranscoding, setDefaultEnableTranscoding] = useState(null);
  const [defaultTwoWayEnableDevice, setDefaultTwoWayEnableDevice] = useState(null);
  const [defaultInterfaceName, setDefaultInterfaceName] = useState(null);
  const [defaultcctvtype, setDefaultcctvtype] = useState(null);
  const [totalDevices, setTotalDevices] = useState(null);
  const [failedDevices, setFailedDevices] = useState(null);
  const [successDevices, setSuccessDevices] = useState(null);
  const [camImportedFileName, setCamImportedFileName] = useState(null)
  const [faildListToDownload, setFaildListToDownload] = useState(null)
  const [sucessListToDownload, setSucessListToDownload] = useState(null)

  const handleUploadCSVClick = (event) => {
    // debugger
    event.preventDefault()
    hiddenCamFileInput.current.click();
  };

  const handleUploaderChange = (event) => {
    try {
      event.preventDefault()
      setImportedFileData(null);
      Array.from(event.target.files)
        .filter(
          (file) =>
            file.type === "text/csv" || file.type === "application/vnd.ms-excel"
        )
        .forEach(async (file) => {

          let camHeadObj = {
            brand: null,
            ipaddress: null,
            communicationport: null,
            httpport: null,
            username: null,
            password: null,
            port: null,
            channelnumber: null,
            livestreamtype: null,
            playbackstreamtype: null,
            // playbackchannelnumber: null,
            devicename: null,
            branch: null,
            enablelivetranscoding: null,
            enableplaybacktranscoding: null,
            // interfacename: null,
            cctvtype: null,
            enabletwowaydevice : null
          };


          setSelectedCamHeaders(camHeadObj);
          const text = await file.text();
          // const result1 = parse(text, { header: true });
          // const head = pars

          //*********tej 27-02-2023********* */
          // parse the CSV data into an array of arrays using PapaParse
          const parsedData = Papa.parse(text).data;
          // filter out empty or comma-separated rows from the parsed data
          const result = parsedData.filter(row => {
            const lastElement = row[row.length - 1];
            if (lastElement === '' || lastElement === null || lastElement === undefined) {
              row.pop();
            }
            return row.join('').trim() !== '';
          });
          // format the filtered data back into CSV format using PapaParse
          // const filteredCsv = Papa.unparse(filteredData);
          //****************** */

          setTotalDevices(null)
          setCamImportedFileName(null)
          setImportedFileHeaders(null)
          setImportedFileData(null)
          setFailedDevices(null)
          setSuccessDevices(null)

          const actualData = result.slice(1, result.length);
          setTotalDevices(actualData.length)
          setCamImportedFileName(file['name'])
          setDeviceBulkUploadResponse([])
          if (result && actualData) {
            setImportedFileHeaders(result[0]);
            const dataToUpload = actualData;
            setImportedFileData(dataToUpload);
          }
        });
      // reset the value of the file input element to an empty string
      event.target.value = "";
    } catch (error) {
      console.error("handleUploaderChange", error)
    }
  };

  useEffect(() => {
    console.log("rupesh checks importedFileHeaders",importedFileHeaders)
    if (importedFileHeaders) {
      var brand = importedFileHeaders.find((x) => x == "brand");
      if (brand) {
        setDefaultBrand(brand);
      } else {
        brand = null;
      }

      var ip = importedFileHeaders.find((x) => x == "ipaddress");
      if (ip) {
        setDefaultIp(ip);
      } else {
        ip = null;
      }

      var communicationport = importedFileHeaders.find((x) => x == "communicationport");
      if (communicationport) {
        setDefaultCommunicationPort(communicationport);
      } else {
        communicationport = null;
      }
      var httpport = importedFileHeaders.find((x) => x == "httpport");
      if (httpport) {
        setDefaultHttpPort(httpport);
      } else {
        httpport = null;
      }

      var username = importedFileHeaders.find((x) => x == "username");
      if (username) {
        setDefaultUserName(username);
      } else {
        username = null;
      }

      var password = importedFileHeaders.find((x) => x == "password");
      if (password) {
        setDefaultPassword(password);
      } else {
        password = null;
      }

      var port = importedFileHeaders.find((x) => x == "port");
      if (port) {
        setDefaultPort(port);
      } else {
        port = null;
      }

      var channelnumber = importedFileHeaders.find((x) => x == "channelnumber");
      if (channelnumber) {
        setDefaultChannelNum(channelnumber);
      } else {
        channelnumber = null;
      }
      // var playbackchannelnumber = importedFileHeaders.find((x) => x == "playbackchannelnumber");
      // if (playbackchannelnumber) {
      //   setDefaultPlaybackChannelNum(playbackchannelnumber);
      // } else {
      //   playbackchannelnumber = null;
      // }
      var livestreamtype = importedFileHeaders.find((x) => x == "livestreamtype");
      if (livestreamtype) {
        setDefaultLiveStreamType(livestreamtype);
      } else {
        livestreamtype = null;
      }
      var playbackstreamtype = importedFileHeaders.find((x) => x == "playbackstreamtype");
      if (playbackstreamtype) {
      setDefaultPlaybackStreamType(playbackstreamtype);
      } else {
        playbackstreamtype = null;
      }

      var devicename = importedFileHeaders.find((x) => x == "devicename");
      if (devicename) {
        setDefaultDeviceName(devicename);
      } else {
        devicename = null;
      }

      var branch = importedFileHeaders.find((x) => x == "branch");
      if (branch) {
        setDefaultBranch(branch);
      } else {
        branch = null;
      }

      var enablelivetranscoding = importedFileHeaders.find((x) => x == "enablelivetranscoding");
      if (enablelivetranscoding) {
        setDefaultEnableTranscoding(enablelivetranscoding);
      } else {
        branch = null;
      }

      var enableplaybacktranscoding = importedFileHeaders.find((x) => x == "enableplaybacktranscoding");
      if (enableplaybacktranscoding) {
        setDefaultEnablePlaybackTranscoding(enableplaybacktranscoding);
      } else {
        branch = null;
      }

      var enabletwowaydevice = importedFileHeaders.find((x) => x == "enabletwowaydevice");
      if (enabletwowaydevice) {
        setDefaultTwoWayEnableDevice(enabletwowaydevice);
      } else {
        branch = null;
      }

      // let interfacename = importedFileHeaders.find((x) => x == "interfacename");
      // if (interfacename) {
      //   setDefaultInterfaceName(interfacename);
      // } else {
      //   interfacename = null;
      // }

      let cctvtype = importedFileHeaders.find((x) => x == "cctvtype");
      if (cctvtype) {
        setDefaultcctvtype(cctvtype);
      } else {
        cctvtype = null;
      }

      let camHeadObj = {
        brand: brand,
        ipaddress: ip,
        communicationport: communicationport,
        httpport: httpport,
        username: username,
        password: password,
        port: port,
        channelnumber: channelnumber,
        // playbackchannelnumber: playbackchannelnumber,
        livestreamtype: livestreamtype,
        playbackstreamtype: playbackstreamtype,
        devicename: devicename,
        branch: branch,
        enablelivetranscoding: enablelivetranscoding,
        enableplaybacktranscoding: enableplaybacktranscoding,
        enabletwowaydevice: enabletwowaydevice,
        // interfacename: interfacename,
        cctvtype : cctvtype
      };
      setSelectedCamHeaders(camHeadObj);
    }
  }, [importedFileHeaders]);

  useEffect(() => {
    setImportedFileHeaders(null);
    hiddenCamFileInput.current.value = null;
  }, [clearImportedCamHeaders]);

  useEffect(() => {
    if (deviceBulkUploadResponse) {
      var failedDevList = deviceBulkUploadResponse && deviceBulkUploadResponse['CameraFailedList'] && deviceBulkUploadResponse['CameraFailedList'].length
      var successDevList = deviceBulkUploadResponse && deviceBulkUploadResponse['CameraSuccessList'] && deviceBulkUploadResponse['CameraSuccessList'].length
      if (failedDevList && failedDevList != undefined) {
        setFailedDevices(failedDevList)

      }
      if (successDevList && successDevList != undefined) {
        setSuccessDevices(successDevList)
      }

      // setTotalDevices(successDevList)
      if ((deviceBulkUploadResponse['CameraFailedList'] && deviceBulkUploadResponse['CameraFailedList'].length > 0) ||
          (deviceBulkUploadResponse['CameraSuccessList'] && deviceBulkUploadResponse['CameraSuccessList'].length > 0)) {
        const failedList = deviceBulkUploadResponse['CameraFailedList']
        const successList = deviceBulkUploadResponse['CameraSuccessList']
        const newList = [];
        const newList2 = [];
        console.log("failedList", newList);
        // newList.push(["brand", "ipaddress", "communicationport","httpport", "username", "password", "port", "channelnumber", "devicename", "branch", "enablelivetranscoding", "reason"])
        // newList.push(["brand", "ipaddress", "communicationport","httpport", "username", "password", "port", "channelnumber","livestreamtype","playbackstreamtype","playbackchannelnumber", "devicename", "branch", "enablelivetranscoding","enableplaybacktranscoding"/* , "interfacename" */,"cctvtype","enabletwowaydevice", "reason"])
        newList.push(["brand", "ipaddress", "communicationport","httpport", "username", "password", "port", "channelnumber","livestreamtype","playbackstreamtype", "devicename", "branch", "enablelivetranscoding","enableplaybacktranscoding"/* , "interfacename" */,"cctvtype","enabletwowaydevice", "reason"])
        for (let i = 0; i < failedList.length; i++) {
          const element = failedList[i];
          let covertLiveStreamType = "";
          let convertPlaybackStreamType = "";
          
          if (element["brandName"].toLowerCase() == "hikvision" || element["brandName"].toLowerCase() == "hikvision-hybrid") {
            if (element["liveStreamTypeNumber"] == "01") {
              covertLiveStreamType = "main"
            }else if (element["liveStreamTypeNumber"] == "02") {
              covertLiveStreamType = "sub"
            }else if (element["liveStreamTypeNumber"] == "03") {
              covertLiveStreamType = "ter"
            }

            if (element["playbackStreamTypeNumber"] == "01") {
              convertPlaybackStreamType = "main"
            }else if (element["playbackStreamTypeNumber"] == "02") {
              convertPlaybackStreamType = "sub"
            }else if (element["playbackStreamTypeNumber"] == "03") {
              convertPlaybackStreamType = "ter"
            }
          }else {
            if (element["liveStreamTypeNumber"] == "0") {
              covertLiveStreamType = "main"
            }else if (element["liveStreamTypeNumber"] == "1") {
              covertLiveStreamType = "sub"
            }else if (element["liveStreamTypeNumber"] == "2") {
              covertLiveStreamType = "ter"
            }

            if (element["playbackStreamTypeNumber"] == "0") {
              convertPlaybackStreamType = "main"
            }else if (element["playbackStreamTypeNumber"] == "1") {
              convertPlaybackStreamType = "sub"
            }else if (element["playbackStreamTypeNumber"] == "2") {
              convertPlaybackStreamType = "ter"
            }
          }


          // newList.push([element["brandName"], element["privateIPAddress"], element["communicationPort"],element["httpPort"], element["userName"], element["password"], element["port"], element["channelNumber"], element["liveStreamTypeNumber"], element["playbackStreamTypeNumber"],element["playbackChannelNumber"], element["cameraName"], element["siteName"], element["isTranscoded"],element["isPlaybackTranscoded"], /* element['interfaceName'], */ element["cctvtype"],element["isTwoWayEnabled"], element["message"]])
          newList.push([element["brandName"], element["privateIPAddress"], element["communicationPort"],element["httpPort"], element["userName"], element["password"], element["port"], element["channelNumber"], covertLiveStreamType, convertPlaybackStreamType, element["cameraName"], element["siteName"], element["isTranscoded"],element["isPlaybackTranscoded"], /* element['interfaceName'], */ element["cctvtype"],element["isTwoWayEnabled"], element["message"]])
          // newList.push([element["brand"], element["ipaddress"], element["communicationport"], element["username"], element["password"], element["port"], element["channelnumber"], element["cameraName"], element["branch"], element["enablelivetranscoding"], element["message"]])
        }

        // Success Count
        // newList2.push(["brand", "ipaddress", "communicationport","httpport", "username", "password", "port", "channelnumber","livestreamtype", "playbackstreamtype","playbackchannelNumber", "devicename", "branch", "enablelivetranscoding","enableplaybacktranscoding"/* , "interfacename" */,"cctvtype", "enabletwowaydevice","reason"])
        newList2.push(["brand", "ipaddress", "communicationport","httpport", "username", "password", "port", "channelnumber","livestreamtype", "playbackstreamtype", "devicename", "branch", "enablelivetranscoding","enableplaybacktranscoding"/* , "interfacename" */,"cctvtype", "enabletwowaydevice","reason"])
        for (let i = 0; i < successList.length; i++) {
          const element = successList[i];
          console.log("elementtt", element);
          let covertLiveStreamType = "";
          let convertPlaybackStreamType = "";
          
          if (element["brandName"].toLowerCase() == "hikvision"|| element["brandName"].toLowerCase() == "hikvision-hybrid") {
            if (element["liveStreamTypeNumber"] == "01") {
              covertLiveStreamType = "main"
            }else if (element["liveStreamTypeNumber"] == "02") {
              covertLiveStreamType = "sub"
            }else if (element["liveStreamTypeNumber"] == "03") {
              covertLiveStreamType = "ter"
            }

            if (element["playbackStreamTypeNumber"] == "01") {
              convertPlaybackStreamType = "main"
            }else if (element["playbackStreamTypeNumber"] == "02") {
              convertPlaybackStreamType = "sub"
            }else if (element["playbackStreamTypeNumber"] == "03") {
              convertPlaybackStreamType = "ter"
            }
          }else {
            if (element["liveStreamTypeNumber"] == "0") {
              covertLiveStreamType = "main"
            }else if (element["liveStreamTypeNumber"] == "1") {
              covertLiveStreamType = "sub"
            }else if (element["liveStreamTypeNumber"] == "2") {
              covertLiveStreamType = "ter"
            }

            if (element["playbackStreamTypeNumber"] == "0") {
              convertPlaybackStreamType = "main"
            }else if (element["playbackStreamTypeNumber"] == "1") {
              convertPlaybackStreamType = "sub"
            }else if (element["playbackStreamTypeNumber"] == "2") {
              convertPlaybackStreamType = "ter"
            }
          }

          // newList2.push([element["brandName"], element["privateIPAddress"], element["communicationPort"], element["httpPort"], element["userName"], element["password"], element["port"], element["channelNumber"],element["liveStreamTypeNumber"], element["playbackStreamTypeNumber"], element["playbackChannelNumber"], element["cameraName"], element["siteName"], element["isTranscoded"],element["isPlaybackTranscoded"], /* element['interfaceName'], */ element["cctvtype"],element["isTwoWayEnabled"], element["message"]])
          newList2.push([element["brandName"], element["privateIPAddress"], element["communicationPort"], element["httpPort"], element["userName"], element["password"], element["port"], element["channelNumber"],covertLiveStreamType, convertPlaybackStreamType, element["cameraName"], element["siteName"], element["isTranscoded"],element["isPlaybackTranscoded"], /* element['interfaceName'], */ element["cctvtype"],element["isTwoWayEnabled"], element["message"]])
          // newList.push([element["brand"], element["ipaddress"], element["communicationport"], element["username"], element["password"], element["port"], element["channelnumber"], element["cameraName"], element["branch"], element["enablelivetranscoding"], element["message"]])
        }

        if (newList && newList.length > 1) {
          setFaildListToDownload(newList)
        }
        if (newList2 && newList2.length > 1) {
          setSucessListToDownload(newList2)
        }

      }
    }


  }, [deviceBulkUploadResponse]);
  const headers = [
    { label: "brand", key: "brandName" },
    { label: "ipaddress", key: "privateIPAddress" },
    { label: "username", key: "userName" },
    { label: "Password", key: "password" },
    { label: "Port", key: "port" },
    { label: "channelnumber", key: "channelNumber" },
    { label: "livestreamtype", key: "livestreamtype" },
    { label: "playbackstreamtype", key: "playbackstreamtype" },
    // { label: "playbackchannelnumber", key: "playbackchannelNumber" },
    { label: "devicename", key: "cameraName" },
    { label: "branch", key: "siteName" }
  ];

  return (
    <>
      <div>
        <OverlayTrigger
          placement='bottom'
          overlay={
            <Tooltip>
              Go to back
            </Tooltip>
          }
        >
          <button className="DataAccessEdit"
            onClick={() => {
              setBulkUpload(false);
              setIsSingleOrBulkSelection("single_device");
            }} >
            <img src={BackArrowDevice} alt="back arrow icon" className="EditedIcon" />
          </button>
        </OverlayTrigger>


      </div>
      <div style={{ width: "75%", margin: "0 auto" }}>
        <div className="bulk-upload-msg-text" style={{ textAlign: "right", margin: "0px" }}>Click <a
          // href="https://astrostaticfilesbucket.s3.ap-south-1.amazonaws.com/CameraBulkUploadSample2New.csv"
          // href="https://astrostaticfilesbucket.s3.ap-south-1.amazonaws.com/CameraBulkUploadSampleData.csv"
          // href="https://astrostaticfilesbucket.s3.ap-south-1.amazonaws.com/Camera-Bulk-Upload-Sample-Data.csv"
          // href="https://astrostaticfilesbucket.s3.ap-south-1.amazonaws.com/Camera-Bulk-Upload-Sample-file.csv"
          href="https://astrostaticfilesbucket.s3.ap-south-1.amazonaws.com/Camera-Bulk-Upload-Sample-file+V_2_1_0.csv"
          download
        >
          here
        </a> to download template</div>
        <div className="BulkUploadBrowseDiv">
          <input
            className="BulkUploadInput"
            disabled={true}
            value={camImportedFileName}
          />
          <button className="DataAccessEdit" style={{ width: "100px" }} onClick={handleUploadCSVClick}>
            <span className="ViewUpdate">Browse</span>
          </button>
        </div>
      </div>

      <div style={{ marginTop: "15px" }}>
        <div className="DeviceList">
          <div>

            <form
              id="hook-form"
              onSubmit={handleSubmit((e) => {
                OnAddBulkDevicesClick(e, importedFileHeaders);
              })}
            >
              <div className="DeviceTextBox DeviceListBox">
                <Row className="header-width" style={{ margin: "0 auto" }}>
                  <div className="Add_Devices add-devices-heading">
                    <p>Match the headers</p>
                  </div>
                  {/* <div className="column Add_Devices add-devices-heading">
                  <p className="ml-2">Camera Fields</p>
                </div>
                <div className="column Add_Devices add-devices-heading">
                  <p>Imported File Headers</p>
                </div> */}
                </Row>
                <div className="lst-section-width">
                  <Row className="lst-header-width">
                    <div className="column">
                      <p className="SiteNameText BulkUploadTextName ml-2">
                        Brand Name<span style={{ color: "red" }}> *</span>
                      </p>
                    </div>
                    <div className="column1">
                      {!defaultBrand && (
                        <SelectHeaderEmpty
                          importedFileHeaders={importedFileHeaders}
                          setDefaultBrand={setDefaultBrand}
                          label="brand"
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                      {defaultBrand && (
                        <SelectHeaderCommon
                          defaultVal={defaultBrand}
                          importedFileHeaders={importedFileHeaders}
                          label="brand"
                          setDefaultBrand={setDefaultBrand}
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                    </div>
                  </Row>
                  <Row className="lst-header-width">
                    <div className="column">
                      <p className="SiteNameText BulkUploadTextName ml-2">
                        IP Address<span style={{ color: "red" }}> *</span>
                      </p>
                    </div>
                    <div className="column1">
                      {!defaultIp && (
                        <SelectHeaderEmpty
                          importedFileHeaders={importedFileHeaders}
                          setDefaultIp={setDefaultIp}
                          label="ipaddress"
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                      {defaultIp && (
                        <SelectHeaderCommon
                          defaultVal={defaultIp}
                          importedFileHeaders={importedFileHeaders}
                          label="ipaddress"
                          setDefaultIp={setDefaultIp}
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                    </div>
                  </Row>
                  {/*  Rupesh Start*/}
                  <Row className="lst-header-width">
                    <div className="column">
                      <p className="SiteNameText BulkUploadTextName ml-2">
                        Communication Port<span style={{ color: "red" }}> *</span>
                      </p>
                    </div>
                    <div className="column1">
                      {!defaultCommunicationPort && (
                        <SelectHeaderEmpty
                          importedFileHeaders={importedFileHeaders}
                          setDefaultCommunicationPort={setDefaultCommunicationPort}
                          label="communicationport"
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                      {defaultCommunicationPort && (
                        <SelectHeaderCommon
                          defaultVal={defaultCommunicationPort}
                          importedFileHeaders={importedFileHeaders}
                          label="communicationport"
                          setDefaultCommunicationPort={setDefaultCommunicationPort}
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                    </div>
                  </Row>
                  {/* Rupesh end*/}

                  {/*  Rupesh Start*/}
                  <Row className="lst-header-width">
                    <div className="column">
                      <p className="SiteNameText BulkUploadTextName ml-2">
                        Http Port<span style={{ color: "red" }}> *</span>
                      </p>
                    </div>
                    <div className="column1">
                      {!defaultHttpPort && (
                        <SelectHeaderEmpty
                          importedFileHeaders={importedFileHeaders}
                          setDefaultHttpPort={setDefaultHttpPort}
                          label="httpport"
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                      {defaultHttpPort && (
                        <SelectHeaderCommon
                          defaultVal={defaultHttpPort}
                          importedFileHeaders={importedFileHeaders}
                          label="httpport"
                          setDefaultHttpPort={setDefaultHttpPort}
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                    </div>
                  </Row>
                  {/* Rupesh end*/}

                  <Row className="lst-header-width">
                    <div className="column">
                      <p className="SiteNameText BulkUploadTextName ml-2">
                        User Name<span style={{ color: "red" }}> *</span>
                      </p>
                    </div>
                    <div className="column1">
                      {!defaultUserName && (
                        <SelectHeaderEmpty
                          importedFileHeaders={importedFileHeaders}
                          setDefaultUserName={setDefaultUserName}
                          label="username"
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                      {defaultUserName && (
                        <SelectHeaderCommon
                          defaultVal={defaultUserName}
                          importedFileHeaders={importedFileHeaders}
                          label="username"
                          setDefaultUserName={setDefaultUserName}
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                    </div>
                  </Row>
                  <Row className="lst-header-width">
                    <div className="column">
                      <p className="SiteNameText BulkUploadTextName ml-2">
                        Password<span style={{ color: "red" }}> *</span>
                      </p>
                    </div>
                    <div className="column1">
                      {!defaultPassword && (
                        <SelectHeaderEmpty
                          importedFileHeaders={importedFileHeaders}
                          setDefaultPassword={setDefaultPassword}
                          label="password"
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                      {defaultPassword && (
                        <SelectHeaderCommon
                          defaultVal={defaultPassword}
                          importedFileHeaders={importedFileHeaders}
                          label="password"
                          setDefaultPassword={setDefaultPassword}
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                    </div>
                  </Row>
                  <Row className="lst-header-width">
                    <div className="column">
                      <p className="SiteNameText BulkUploadTextName ml-2">
                        Port<span style={{ color: "red" }}> *</span>
                      </p>
                    </div>
                    <div className="column1">
                      {!defaultPort && (
                        <SelectHeaderEmpty
                          importedFileHeaders={importedFileHeaders}
                          setDefaultPort={setDefaultPort}
                          label="port"
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                      {defaultPort && (
                        <SelectHeaderCommon
                          defaultVal={defaultPort}
                          importedFileHeaders={importedFileHeaders}
                          label="port"
                          setDefaultPort={setDefaultPort}
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                    </div>
                  </Row>
                  {/* live channel number */}
                  <Row className="lst-header-width">
                    <div className="column">
                      <p className="SiteNameText BulkUploadTextName ml-2">
                        Channel Number<span style={{ color: "red" }}> *</span>
                      </p>
                    </div>
                    <div className="column1">
                      {!defaultChannelNum && (
                        <SelectHeaderEmpty
                          importedFileHeaders={importedFileHeaders}
                          setDefaultChannelNum={setDefaultChannelNum}
                          label="channelnumber"
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                      {defaultChannelNum && (
                        <SelectHeaderCommon
                          defaultVal={defaultChannelNum}
                          importedFileHeaders={importedFileHeaders}
                          label="channelnumber"
                          setDefaultChannelNum={setDefaultChannelNum}
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                    </div>
                  </Row>

{/* live stream type  Number */}
<Row className="lst-header-width">
                    <div className="column">
                      <p className="SiteNameText BulkUploadTextName ml-2">
                        Live Stream Type<span style={{ color: "red" }}> *</span>
                      </p>
                    </div>
                    <div className="column1">
                      {!defaultLiveStreamType && (
                        <SelectHeaderEmpty
                          importedFileHeaders={importedFileHeaders}
                          setDefaultLiveStreamType={setDefaultLiveStreamType}
                          label="livestreamtype"
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                      {defaultLiveStreamType && (
                        <SelectHeaderCommon
                          defaultVal={defaultLiveStreamType}
                          importedFileHeaders={importedFileHeaders}
                          label="livestreamtype"
                          setDefaultLiveStreamType={setDefaultLiveStreamType}
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                    </div>
                  </Row>

{/* live stream type Number */}


{/* Playback live stream type Number */}
<Row className="lst-header-width">
                    <div className="column">
                      <p className="SiteNameText BulkUploadTextName ml-2">
                        Playback Stream Type<span style={{ color: "red" }}> *</span>
                      </p>
                    </div>
                    <div className="column1">
                      {!defaultPlaybackStreamType && (
                        <SelectHeaderEmpty
                          importedFileHeaders={importedFileHeaders}
                          setDefaultPlaybackStreamType={setDefaultPlaybackStreamType}
                          label="playbackstreamtype"
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                      {defaultPlaybackStreamType && (
                        <SelectHeaderCommon
                          defaultVal={defaultPlaybackStreamType}
                          importedFileHeaders={importedFileHeaders}
                          label="playbackstreamtype"
                          setDefaultPlaybackStreamType={setDefaultPlaybackStreamType}
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                    </div>
                  </Row>
{/* Playback live stream type Number */}

{/* Playback Channel Number */}
                  {/* <Row className="lst-header-width">
                    <div className="column">
                      <p className="SiteNameText BulkUploadTextName ml-2">
                        Playback Channel Number<span style={{ color: "red" }}> *</span>
                      </p>
                    </div>
                    <div className="column1">
                      {!defaultPlaybackChannelNum && (
                        <SelectHeaderEmpty
                          importedFileHeaders={importedFileHeaders}
                          setDefaultPlaybackChannelNum={setDefaultPlaybackChannelNum}
                          label="playbackchannelnumber"
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                      {defaultPlaybackChannelNum && (
                        <SelectHeaderCommon
                          defaultVal={defaultPlaybackChannelNum}
                          importedFileHeaders={importedFileHeaders}
                          label="playbackchannelnumber"
                          setDefaultPlaybackChannelNum={setDefaultPlaybackChannelNum}
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                    </div>
                  </Row> */}
{/* Playback Channel Number */}

                  <Row className="lst-header-width">
                    <div className="column">
                      <p className="SiteNameText BulkUploadTextName ml-2">
                        Device Name<span style={{ color: "red" }}> *</span>
                      </p>
                    </div>
                    <div className="column1">
                      {!defaultDeviceName && (
                        <SelectHeaderEmpty
                          importedFileHeaders={importedFileHeaders}
                          setDefaultDeviceName={setDefaultDeviceName}
                          label="devicename"
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                      {defaultDeviceName && (
                        <SelectHeaderCommon
                          defaultVal={defaultDeviceName}
                          importedFileHeaders={importedFileHeaders}
                          label="devicename"
                          setDefaultDeviceName={setDefaultDeviceName}
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                    </div>
                  </Row>
                  <Row className="lst-header-width">
                    <div className="column">
                      <p className="SiteNameText BulkUploadTextName ml-2">
                        Branch<span style={{ color: "red" }}> *</span>
                      </p>
                    </div>
                    <div className="column1">
                      {!defaultBranch && (
                        <SelectHeaderEmpty
                          importedFileHeaders={importedFileHeaders}
                          setDefaultBranch={setDefaultBranch}
                          label="branch"
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                      {defaultBranch && (
                        <SelectHeaderCommon
                          defaultVal={defaultBranch}
                          importedFileHeaders={importedFileHeaders}
                          label="branch"
                          setDefaultBranch={setDefaultBranch}
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                    </div>
                  </Row>

                  {/*  Rupesh Start*/}
                  <Row className="lst-header-width">
                    <div className="column">
                      <p className="SiteNameText BulkUploadTextName ml-2">
                        Enable Live Transcoding to H.264<span style={{ color: "red" }}> *</span>
                      </p>
                    </div>
                    <div className="column1">
                      {!defaultEnableTranscoding && (
                        <SelectHeaderEmpty
                          importedFileHeaders={importedFileHeaders}
                          setDefaultEnableTranscoding={setDefaultEnableTranscoding}
                          label="enablelivetranscoding"
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                      {defaultEnableTranscoding && (
                        <SelectHeaderCommon
                          defaultVal={defaultEnableTranscoding}
                          importedFileHeaders={importedFileHeaders}
                          label="enablelivetranscoding"
                          setDefaultEnableTranscoding={setDefaultEnableTranscoding}
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                    </div>
                  </Row>

{/*  */}

{/*  Rupesh Start*/}
<Row className="lst-header-width">
                    <div className="column">
                      <p className="SiteNameText BulkUploadTextName ml-2">
                        Enable Playback Transcoding to H.264<span style={{ color: "red" }}> *</span>
                      </p>
                    </div>
                    <div className="column1">
                      {!defaultEnablePlaybackTranscoding && (
                        <SelectHeaderEmpty
                          importedFileHeaders={importedFileHeaders}
                          setDefaultEnablePlaybackTranscoding={setDefaultEnablePlaybackTranscoding}
                          label="enableplaybacktranscoding"
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                      {defaultEnablePlaybackTranscoding && (
                        <SelectHeaderCommon
                          defaultVal={defaultEnablePlaybackTranscoding}
                          importedFileHeaders={importedFileHeaders}
                          label="enableplaybacktranscoding"
                          setDefaultEnablePlaybackTranscoding={setDefaultEnablePlaybackTranscoding}
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                    </div>
                  </Row>

{/*  */}
<Row className="lst-header-width">
                    <div className="column">
                      <p className="SiteNameText BulkUploadTextName ml-2">
                        Two way Enable Device<span style={{ color: "red" }}> *</span>
                      </p>
                    </div>
                    <div className="column1">
                      {!defaultTwoWayEnableDevice && (
                        <SelectHeaderEmpty
                          importedFileHeaders={importedFileHeaders}
                          setDefaultTwoWayEnableDevice={setDefaultTwoWayEnableDevice}
                          label="enabletwowaydevice"
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                      {defaultTwoWayEnableDevice && (
                        <SelectHeaderCommon
                          defaultVal={defaultTwoWayEnableDevice}
                          importedFileHeaders={importedFileHeaders}
                          label="enabletwowaydevice"
                          setDefaultTwoWayEnableDevice={setDefaultTwoWayEnableDevice}
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                    </div>
                  </Row>
{/*  */}
                {/*   <Row className="lst-header-width">
                    <div className="column">
                      <p className="SiteNameText BulkUploadTextName ml-2">
                        Interface Name<span style={{ color: "red" }}> *</span>
                      </p>
                    </div>
                    <div className="column1">
                      {!defaultInterfaceName && (
                        <SelectHeaderEmpty
                          importedFileHeaders={importedFileHeaders}
                          setDefaultInterfaceName={setDefaultInterfaceName}
                          label="interfacename"
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                      {defaultInterfaceName && (
                        <SelectHeaderCommon
                          defaultVal={defaultInterfaceName}
                          importedFileHeaders={importedFileHeaders}
                          label="interfacename"
                          setDefaultInterfaceName={setDefaultInterfaceName}
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                    </div>
                  </Row> */}

                  <Row className="lst-header-width">
                    <div className="column">
                      <p className="SiteNameText BulkUploadTextName ml-2">
                        CCTV Type<span style={{ color: "red" }}> *</span>
                      </p>
                    </div>
                    <div className="column1">
                      {!defaultcctvtype && (
                        <SelectHeaderEmpty
                          importedFileHeaders={importedFileHeaders}
                          setDefaultcctvtype={setDefaultcctvtype}
                          label="interfacename"
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                      {defaultcctvtype && (
                        <SelectHeaderCommon
                          defaultVal={defaultcctvtype}
                          importedFileHeaders={importedFileHeaders}
                          label="interfacename"
                          setDefaultcctvtype={setDefaultcctvtype}
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                    </div>
                  </Row>

                  {/* <Row className="lst-header-width">
                    <div className="column">
                      <p className="SiteNameText BulkUploadTextName ml-2">
                        Live Stream Type<span style={{ color: "red" }}> *</span>
                      </p>
                    </div>
                    <div className="column1">
                      {!defaultcctvtype && (
                        <SelectHeaderEmpty
                          importedFileHeaders={importedFileHeaders}
                          setDefaultcctvtype={setDefaultcctvtype}
                          label="interfacename"
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                      {defaultcctvtype && (
                        <SelectHeaderCommon
                          defaultVal={defaultcctvtype}
                          importedFileHeaders={importedFileHeaders}
                          label="interfacename"
                          setDefaultcctvtype={setDefaultcctvtype}
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                    </div>
                  </Row>

                  <Row className="lst-header-width">
                    <div className="column">
                      <p className="SiteNameText BulkUploadTextName ml-2">
                        PlayBack Stream Type<span style={{ color: "red" }}> *</span>
                      </p>
                    </div>
                    <div className="column1">
                      {!defaultcctvtype && (
                        <SelectHeaderEmpty
                          importedFileHeaders={importedFileHeaders}
                          setDefaultcctvtype={setDefaultcctvtype}
                          label="interfacename"
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                      {defaultcctvtype && (
                        <SelectHeaderCommon
                          defaultVal={defaultcctvtype}
                          importedFileHeaders={importedFileHeaders}
                          label="interfacename"
                          setDefaultcctvtype={setDefaultcctvtype}
                          selectedCamHeaders={selectedCamHeaders}
                        />
                      )}
                    </div>
                  </Row> */}

                  {/* Rupesh end*/}
                </div>
                <div
                  className="BulkText1 AlignBulk"
                >
                  {/* <div>
                  <p>Bulk Import Devices?</p>
                  <p className="SampleTextDocument">
                    
                    <span className="ml-4">
                      This below URl is commented by rupesh for adding new CSV file in S3
                      <a
                        href="https://astrostaticfilesbucket.s3.ap-south-1.amazonaws.com/CameraBulkUploadSample.csv"
                        href="https://astrostaticfilesbucket.s3.ap-south-1.amazonaws.com/CameraBulkUploadSample2New.csv"
                        download
                      >
                        Sample Document
                        <img
                          src={SampleDownload}
                          className="sample-doc-download"
                        />
                      </a>
                    </span>
                  </p>
                </div> */}
                  {/* <button
                  className="upload-csv-button uploadcsv-panel"
                  onClick={handleUploadCSVClick}
                >
                  {" "}
                  Upload CSV
                </button> */}
                  <input
                    type="file"
                    multiple={false}
                    ref={hiddenCamFileInput}
                    onChange={handleUploaderChange}
                    style={{ display: "none" }}
                    value=""
                  />
                </div>

                {/* <Row className="add-devices-btn-div bulk-upload-main-row-length"> */}
                  {/* Commenting below Block rgr 18/01/23  */}
                  {/* <div className="BlkUploadDiv"> */}
                    <p style={{textAlign: "center"}}>

                      {camImportedFileName && totalDevices && deviceBulkUploadResponse &&
                        !(deviceBulkUploadResponse['CameraSuccessList'] || deviceBulkUploadResponse['CameraFailedList']) &&
                        <OverlayTrigger
                          placement='bottom'
                          overlay={<Tooltip>{camImportedFileName}</Tooltip>}>
                          {/* <Row className="bulk-upload-msg-text " >
                            <Col lg={7} className="template-name">{camImportedFileName}</Col>
                            <Col lg={5}>({totalDevices} Devices)</Col>
                          </Row> */}
                          <span className="bulk-upload-msg-text">({totalDevices} Devices)</span>
                        </OverlayTrigger>
                      }
                      {deviceBulkUploadResponse && failedDevices && failedDevices != 0 && totalDevices &&
                        <span className="bulk-upload-msg-text template-name">
                          <CSVLink filename={"CameraBulkUploadFailedList.csv"} className="mendat-fields" data={faildListToDownload}>{failedDevices} /
                            {totalDevices} Failed Uploads</CSVLink> </span>

                      }
                      {deviceBulkUploadResponse && successDevices && successDevices !=0 && totalDevices &&
                      //  <span className="bulk-upload-msg-text template-name">
                      //     {successDevices} /
                      //     {totalDevices} Uploads Successful</span>
                          //setSucessListToDownload

                    <span className="bulk-upload-msg-text template-name">
                      <CSVLink filename={"CameraBulkUploadSuccessList.csv"}
                        className="mendat-fields"
                        data={sucessListToDownload}>
                        {successDevices} /{totalDevices} Uploads Successful
                      </CSVLink>
                    </span>
                      }
               
                    </p>
                    {/* <button
                    type="submit"
                    className="NextMoveButton addCameras-btn"
                    form="hook-form"
                  >
                    <span className="ViewUpdate add-cameras">Add Cameras</span>
                  </button> */}
                  {/* </div> */}
                {/* </Row> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
});

const PanelBulkUpload = React.forwardRef((props, ref) => {
  let {
    selectedHeaders,
    onPanelBulkUpload,
    setSelectedHeaders,
    clearImportedPanelHeaders,
    setImportedFileData,
    panelBulkUploadResponse,
    setPanelBulkUploadResponse,
    panelBulkUploadError,
    setPanelBulkUploadError,
    setBulkUpload,
    setIsSingleOrBulkSelection,
    importedPanelHeaders,
    setImportedPanelHeaders
  } = { ...props };

  const { register, handleSubmit } = useForm({
    defaultValue: { ...selectedHeaders },
  });

  const hiddenPanelFileInput = React.useRef(null);
  // const [importedFileData, setImportedFileData] = useState(null);
  // const [importedFileHeaders, setImportedFileHeaders] = useState(null);

  const [defaultBrand, setDefaultBrand] = useState(null);
  const [defaultIp, setDefaultIp] = useState(null);
  const [defaultBranch, setDefaultBranch] = useState(null);
  const [defaultPanelName, setDefaultPanelName] = useState(null);
  const [defaultZoneType, setDefaultZoneType] = useState(null);
  const [defaultZoneNumber, setDefaultZoneNumber] = useState(null);
  const [defaultZoneName, setDefaultZoneName] = useState(null);
  const [defaultInterface, setDefaultInterface] = useState(null);
  const [defaultPortNumber, setDefaultPortNumber] = useState(null);
  const [defaultInterfaceUsername, setDefaultInterfaceUsername] = useState(null);
  const [defaultInterfacePassword, setDefaultInterfacePassword] = useState(null);

  const [totalDevices, setTotalDevices] = useState(null);
  const [failedDevices, setFailedDevices] = useState(null);
  const [successDevices, setSuccessDevices] = useState(null);
  const [panelImportedFileName, setPanelImportedFileName] = useState(null)
  const [faildListToDownload, setFaildListToDownload] = useState(null)
  const [sucessListToDownload, setSucessListToDownload] = useState(null)
  const [errorListToDownload, setErrorListToDownload] = useState(null)

  const handleUploadCSVClick = (event) => {
    event.preventDefault()
    setErrorListToDownload(null)
    hiddenPanelFileInput.current.click();
  };

  const handleUploaderChange = (event) => {
    event.preventDefault()
    setImportedFileData(null);

    Array.from(event.target.files)
      .filter(
        (file) =>
          file.type === "text/csv" || file.type === "application/vnd.ms-excel"
      )
      .forEach(async (file) => {
        let panelHeadObj = {
          brand: null,
          ipaddress: null,
          branch: null,
          zonetype: null,
          zonenumber: null,
          zonename: null,
          // interfacename: null
          portnumber: null,
          interfaceusername: null,
          interfacepassword: null,
        };
        setSelectedHeaders(panelHeadObj);
        const text = await file.text();
        //*********tej 27-02-2023********* */
        // parse the CSV data into an array of arrays using PapaParse
        const parsedData = Papa.parse(text).data;
        // filter out empty or comma-separated rows from the parsed data
        const result = parsedData.filter(row => {
          return row.join('').trim() !== '';
        });
        // format the filtered data back into CSV format using PapaParse
        // const filteredCsv = Papa.unparse(filteredData);
        //****************** */
        setTotalDevices(null)
        setPanelImportedFileName(null)
        setImportedPanelHeaders(null)
        setImportedFileData(null)
        setFailedDevices(null)
        setSuccessDevices(null)

        // const text = await file.text();
        // const result = parse(text, { header: true });
        const actualData = result.slice(1, result.length);
        setTotalDevices(actualData.length)
        setPanelImportedFileName(file['name'])
        setPanelBulkUploadResponse([])
        setPanelBulkUploadError([])
        if (result && actualData) {
          setImportedPanelHeaders(result[0]);
          const dataToUpload = actualData;
          setImportedFileData(dataToUpload);
        }
      });
  };

  useEffect(() => {
    if (importedPanelHeaders) {
      var brand = importedPanelHeaders.find((x) => x == "brand");
      if (brand) {
        setDefaultBrand(brand);
      } else {
        brand = null;
      }

      var ip = importedPanelHeaders.find((x) => x == "ipaddress");
      if (ip) {
        setDefaultIp(ip);
      } else {
        ip = null;
      }

      var branch = importedPanelHeaders.find((x) => x == "branch");
      if (branch) {
        setDefaultBranch(branch);
      } else {
        branch = null;
      }

      var name = importedPanelHeaders.find((x) => x == "name");
      if (branch) {
        setDefaultPanelName(name);
      } else {
        name = null;
      }

      var zonetype = importedPanelHeaders.find((x) => x == "zonetype");
      if (zonetype) {
        setDefaultZoneType(zonetype);
      } else {
        zonetype = null;
      }

      var zonenumber = importedPanelHeaders.find((x) => x == "zonenumber");
      if (zonenumber) {
        setDefaultZoneNumber(zonenumber);
      } else {
        zonenumber = null;
      }

      var zonename = importedPanelHeaders.find((x) => x == "zonename");
      if (zonename) {
        setDefaultZoneName(zonename);
      } else {
        zonename = null;
      }
/* rg */
     /*  var interfacename = importedPanelHeaders.find((x) => x == "interfacename")
      if (interfacename) {
        setDefaultInterface(interfacename);
      } else {
        interfacename = null;
      } */

      var portnumber = importedPanelHeaders.find((x) => x == "portnumber");
      if (portnumber) {
        setDefaultPortNumber(portnumber);
      } else {
        portnumber = null;
      }

      var interfaceusername = importedPanelHeaders.find((x) => x == "interfaceusername");
      if (interfaceusername) {
        setDefaultInterfaceUsername(interfaceusername);
      } else {
        interfaceusername = null;
      }

      var interfacepassword = importedPanelHeaders.find((x) => x == "interfacepassword");
      if (interfacepassword) {
        setDefaultInterfacePassword(interfacepassword);
      } else {
        interfacepassword = null;
      }

      let panelHeadObj = {
        brand: brand,
        ipaddress: ip,
        branch: branch,
        name: name,
        zonetype: zonetype,
        zonenumber: zonenumber,
        zonename: zonename,
        // interfacename: interfacename
        portnumber: portnumber,
        interfaceusername: interfaceusername,
        interfacepassword: interfacepassword,
      };
      setSelectedHeaders(panelHeadObj);
    }
  }, [importedPanelHeaders]);

  useEffect(() => {
    setImportedPanelHeaders(null);
    hiddenPanelFileInput.current.value = null;
  }, [clearImportedPanelHeaders]);

  useEffect(() => {
    if (panelBulkUploadResponse) {
      // var sensorFailedDevList = panelBulkUploadResponse && panelBulkUploadResponse['sensorFailedList'] && panelBulkUploadResponse['sensorFailedList'].length;

      // var panelFailedDevList = panelBulkUploadResponse && panelBulkUploadResponse['panelFailedList'] && panelBulkUploadResponse['panelFailedList'].length;

      // const sensorFailedLst = panelBulkUploadResponse['sensorFailedList']
      // const panelFailedLst = panelBulkUploadResponse['panelFailedList']

      const panelFailedLst = panelBulkUploadResponse.CameraFailedList;
      // if (panelFailedLst && panelFailedLst.length > 0) {
      //   for (let i = 0; i < panelFailedLst.length; i++) {
      //     panelFailedLst[i]['sensorLst'] = panelFailedLst[i]['sensorLst'][0]
      //   }
      // }
      // if ((sensorFailedLst && sensorFailedLst.length > 0) || (panelFailedLst && panelFailedLst.length > 0)) {
      if (panelFailedLst && panelFailedLst.length > 0) {
        let failedList = []
        // if (sensorFailedLst.length > 0) failedList = failedList.concat(sensorFailedLst)
        if (panelFailedLst.length > 0) {
          for (let i = 0; i < panelFailedLst.length; i++) {
            // for (let j = 0; j < panelFailedLst[i].sensorLst.length; j++) {
              let temp = {};

              temp['branch'] = panelFailedLst[i]['branch']
              temp['brand'] = panelFailedLst[i]['brand']
              temp['deviceID'] = panelFailedLst[i]['deviceID']
              temp['message'] = panelFailedLst[i]['message']
              // temp['panelIp'] = panelFailedLst[i]['panelIp']  
              temp['panelIp'] = panelFailedLst[i]['ipAddress']  
              // temp['panelName'] = panelFailedLst[i]['panelName']
              temp['panelName'] = panelFailedLst[i]['name']
              temp['status'] = panelFailedLst[i]['status']
             /*  temp['sensorLst'] = {
                'zoneName': panelFailedLst[i].sensorLst[j]['zoneName'],
                'zoneNumber': panelFailedLst[i].sensorLst[j]['zoneNumber'],
                'zoneType': panelFailedLst[i].sensorLst[j]['zoneType']
              } */
              temp['zoneName'] = panelFailedLst[i]['zoneName']
              temp['zoneNumber'] = panelFailedLst[i]['zoneNumber']
              temp['zoneType'] = panelFailedLst[i]['zoneType']
              // temp['interfaceName'] = panelFailedLst[i]['interfaceName']
              temp['portNumber'] = panelFailedLst[i]['portNumber']
              temp['interfaceUsername'] = panelFailedLst[i]['interfaceUsername']
              temp['interfacePassword'] = panelFailedLst[i]['interfacePassword']

              failedList.push(temp)
            // }
          }
        }
        var failedDevList = failedList.length;
        if (failedDevList && failedDevList !== undefined) {
          setFailedDevices(failedDevList)
        }

        const newList = []
        newList.push(["brand", "ipaddress", "branch", "name", "zonetype", "zonename", "zonenumber", "portnumber","interfaceusername","interfacepassword",
        /* "interfacename",  */
        "errorcause"])
        for (let i = 0; i < failedList.length; i++) {
          const element = failedList[i];
          // newList.push([element["brand"], element["panelIp"], element["branch"], element["panelName"], element['sensorLst']["zoneType"], element['sensorLst']["zoneName"], element['sensorLst']["zoneNumber"], element['interfaceName'], element["message"]])
          newList.push([element["brand"], element["panelIp"], element["branch"], element["panelName"], element["zoneType"], element["zoneName"], element["zoneNumber"], /* element['interfaceName'], */ 
          element["portNumber"], element["interfaceuserName"], element["interfacePassword"],
          element["message"]])
        }

        if (newList && newList.length > 1) {
          setFaildListToDownload(newList)
        }
      } //end

      /* success starts */
    
      // RUPESH PANEL BULK UPLOAD SUCCESS RESPONSE

        /* -------------- To be commented for bulk upload success data -------------- */

        // const sensorSuccessList = panelBulkUploadResponse['sensorSuccessList']
        // const panelSuccessList = panelBulkUploadResponse['panelSuccessList']
        // let successListTemp = [];

        // if ((sensorSuccessList && sensorSuccessList.length > 0) || (panelSuccessList && panelSuccessList.length > 0)) {
        //   if(sensorSuccessList.length > 0){
        //     successListTemp = successListTemp.concat(sensorSuccessList);
        //   }
        //   if(panelSuccessList.length > 0){
        //     for(let i=0; i<panelSuccessList.length; i++){
        //       for(let j=0; j<panelSuccessList[i].sensorLst.length; i++){
        //         let temp = {}
        //         temp['branch'] = panelSuccessList[i]['branch']
        //         temp['brand'] = panelSuccessList[i]['brand']
        //         temp['deviceID'] = panelSuccessList[i]['deviceID']
        //         temp['message'] = panelSuccessList[i]['message']
        //         temp['panelIp'] = panelSuccessList[i]['panelIp']
        //         temp['panelName'] = panelSuccessList[i]['panelName']
        //         temp['status'] = panelSuccessList[i]['status']
        //         temp['sensorLst'] = {
        //           'zoneName': panelSuccessList[i].sensorLst[j]['zoneName'],
        //           'zoneNumber': panelSuccessList[i].sensorLst[j]['zoneNumber'],
        //           'zoneType': panelSuccessList[i].sensorLst[j]['zoneType']
        //         }
        //         successListTemp.push(temp)
        //       }
        //     }
        //   }
        // }
        // let successDevList = successListTemp.length;
        // var successDevList = panelBulkUploadResponse && panelBulkUploadResponse['sensorSuccessList'] && panelBulkUploadResponse['sensorSuccessList'].length


        const panelSuccessList = panelBulkUploadResponse.CameraSuccessList

        if(panelSuccessList && panelSuccessList.length > 0){
        let successList = []

          if(panelSuccessList.length > 0){
          for(let i = 0; i< panelSuccessList.length; i++){
          let temp = {}
            temp['branch'] = panelSuccessList[i]['branch']
            temp['brand'] = panelSuccessList[i]['brand']
            temp['deviceID'] = panelSuccessList[i]['deviceID']
            temp['message'] = panelSuccessList[i]['message']
            temp['panelIp'] = panelSuccessList[i]['ipAddress']
            temp['panelName'] = panelSuccessList[i]['name']
            temp['status'] = panelSuccessList[i]['status']
            temp['zoneName'] = panelSuccessList[i]['zoneName']
            temp['zoneNumber'] = panelSuccessList[i]['zoneNumber']
            temp['zoneType'] = panelSuccessList[i]['zoneType']
            // temp['interfaceName'] = panelSuccessList[i]['interfaceName']
            temp['portNumber'] = panelSuccessList[i]['portNumber']
            temp['interfaceUsername'] = panelSuccessList[i]['interfaceUsername']
            temp['interfacePassword'] = panelSuccessList[i]['interfacePassword']
            successList.push(temp);
          }
          }
          var successDevList = successList.length;

          if (successDevList && successDevList !== undefined) {
          setSuccessDevices(successDevList)
        }
        
        const newList2 = []
        newList2.push(["brand", "ipaddress", "branch", "name", "zonetype", "zonename", "zonenumber", /* "interfacename", */ "portnumber","interfaceusername","interfacepassword","reason"])
        for (let i = 0; i < panelSuccessList.length; i++) {
          const element = panelSuccessList[i];

          newList2.push([element["brand"], element["ipAddress"], element["branch"], element["name"], element["zoneType"], element["zoneName"], element["zoneNumber"], /* element['interfaceName'],  */
          element["portNumber"], element["interfaceUsername"], element["interfacePassword"],
          element["message"]])
        }

        if (newList2 && newList2.length > 1) {
          setSucessListToDownload(newList2)
        }
        }
        // let successListTemp = [];

        // var successDevList = panelBulkUploadResponse.CameraSuccessList.length;

        // for(let i=0; i<successListTemp.length; i++){
        //   const element = successListTemp[i];
        //   newList.push([element["brand"], element["panelIp"], element["branch"], element["panelName"], element['sensorLst']["zoneType"], element['sensorLst']["zoneName"], element['sensorLst']["zoneNumber"], element['interfaceName'], element["message"]])
        // }
        
      
      /* success ends */
    }
  }, [panelBulkUploadResponse]);  

  useEffect(() => {
    const fetchData = async () => {
      if (panelBulkUploadError.length > 0) {
        const newList = [["brand", "ipaddress", "branch", "name", "zonetype", "zonename", "zonenumber", /* "interfacename", */ "portnumber","interfaceusername","interfacepassword","errorcause"]];
        for (let i = 0; i < panelBulkUploadError.length; i++) {
          const element = panelBulkUploadError[i];
          newList.push(element);
        }
        if (newList && newList.length >= 1) {
          setErrorListToDownload(newList);
        }
      }
    };

    fetchData();
  }, [panelBulkUploadError]);
  const currentDate = new Date().toLocaleDateString().replace(/\//g, "-");
  const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");

  return (
    <>
      <div>
        <OverlayTrigger
          placement='bottom'
          overlay={
            <Tooltip>
              Go to back
            </Tooltip>
          }
        >
          <button className="DataAccessEdit" onClick={() => {
            setBulkUpload(false);
            setIsSingleOrBulkSelection("single_device");
          }}>
            <img src={BackArrowDevice} alt="back arrow icon" className="EditedIcon" />
          </button>
        </OverlayTrigger>
      </div>
      <div style={{ width: "75%", margin: "0 auto" }}>
        <div className="bulk-upload-msg-text" style={{ textAlign: "right", margin: "0px" }}>Click <a
          // href="https://astrostaticfilesbucket.s3.ap-south-1.amazonaws.com/PanelBulkUploadSample.csv"
          href="https://astrostaticfilesbucket.s3.ap-south-1.amazonaws.com/Panel-Bulk-Upload-SampleFile.csv"
          download
        >
          here
        </a> to download template</div>
        <div className="BulkUploadBrowseDiv">
          <input
            className="BulkUploadInput"
            value={panelImportedFileName}
            disabled={true}
          />
          <button className="DataAccessEdit" style={{ width: "100px" }} onClick={handleUploadCSVClick}>
            <span className="ViewUpdate">Browse</span>
          </button>
        </div>
      </div>
      <div style={{ marginTop: "15px" }}>
        <div className="DeviceList">
          <form
            id="hook-form"
            onSubmit={handleSubmit((e) =>
              onPanelBulkUpload(e, importedPanelHeaders)
            )}
          >
            <div className="DeviceTextBox DeviceListBox">
              <Row className="header-width" style={{ margin: "0 auto" }}>
                <div className="Add_Devices add-devices-heading">
                  <p>Match the headers</p>
                </div>
                {/* <div className="column Add_Devices">
                  <p className="ml-2">Panel Fields</p>
                </div>
                <div className="column Add_Devices">
                  <p>Imported File Headers</p>
                </div> */}
              </Row>
              <div className="lst-section-width">
                <Row className="lst-header-width">
                  <div className="column">
                    <p className="SiteNameText BulkUploadTextName ml-2">
                      Brand Name<span style={{ color: "red" }}> *</span>
                    </p>
                  </div>
                  <div className="column1">
                    {!defaultBrand && (
                      <SelectHeaderEmpty
                        importedFileHeaders={importedPanelHeaders}
                        setDefaultBrand={setDefaultBrand}
                        label="brand"
                        selectedHeaders={selectedHeaders}
                      />
                    )}
                    {defaultBrand && (
                      <SelectHeaderCommon
                        defaultVal={defaultBrand}
                        importedFileHeaders={importedPanelHeaders}
                        label="brand"
                        setDefaultBrand={setDefaultBrand}
                        selectedHeaders={selectedHeaders}
                      />
                    )}
                  </div>
                </Row>
                <Row className="lst-header-width">
                  <div className="column">
                    <p className="SiteNameText BulkUploadTextName ml-2">
                      IP Address<span style={{ color: "red" }}> *</span>
                    </p>
                  </div>
                  <div className="column1">
                    {!defaultIp && (
                      <SelectHeaderEmpty
                        importedFileHeaders={importedPanelHeaders}
                        setDefaultIp={setDefaultIp}
                        label="ipaddress"
                        selectedHeaders={selectedHeaders}
                      />
                    )}
                    {defaultIp && (
                      <SelectHeaderCommon
                        defaultVal={defaultIp}
                        importedFileHeaders={importedPanelHeaders}
                        label="ipaddress"
                        setDefaultIp={setDefaultIp}
                        selectedHeaders={selectedHeaders}
                      />
                    )}
                  </div>
                </Row>
                <Row className="lst-header-width">
                  <div className="column">
                    <p className="SiteNameText BulkUploadTextName ml-2">
                      Branch<span style={{ color: "red" }}> *</span>
                    </p>
                  </div>
                  <div className="column1">
                    {!defaultBranch && (
                      <SelectHeaderEmpty
                        importedFileHeaders={importedPanelHeaders}
                        setDefaultBranch={setDefaultBranch}
                        label="branch"
                        selectedHeaders={selectedHeaders}
                      />
                    )}
                    {defaultBranch && (
                      <SelectHeaderCommon
                        defaultVal={defaultBranch}
                        importedFileHeaders={importedPanelHeaders}
                        label="branch"
                        setDefaultBranch={setDefaultBranch}
                        selectedHeaders={selectedHeaders}
                      />
                    )}
                  </div>
                </Row>
                <Row className="lst-header-width">
                  <div className="column">
                    <p className="SiteNameText BulkUploadTextName ml-2">
                      Panel Name<span style={{ color: "red" }}> *</span>
                    </p>
                  </div>
                  <div className="column1">
                    {!defaultPanelName && (
                      <SelectHeaderEmpty
                        importedFileHeaders={importedPanelHeaders}
                        setDefaultPanelName={setDefaultPanelName}
                        label="branch"
                        selectedHeaders={selectedHeaders}
                      />
                    )}
                    {defaultPanelName && (
                      <SelectHeaderCommon
                        defaultVal={defaultPanelName}
                        importedFileHeaders={importedPanelHeaders}
                        label="branch"
                        setDefaultPanelName={setDefaultPanelName}
                        selectedHeaders={selectedHeaders}
                      />
                    )}
                  </div>
                </Row>
                <Row className="lst-header-width">
                  <div className="column">
                    <p className="SiteNameText BulkUploadTextName ml-2">
                      Zone Type<span style={{ color: "red" }}> *</span>
                    </p>
                  </div>
                  <div className="column1">
                    {!defaultZoneType && (
                      <SelectHeaderEmpty
                        importedFileHeaders={importedPanelHeaders}
                        setDefaultZoneType={setDefaultZoneType}
                        label="zonetype"
                        selectedHeaders={selectedHeaders}
                      />
                    )}
                    {defaultZoneType && (
                      <SelectHeaderCommon
                        defaultVal={defaultZoneType}
                        importedFileHeaders={importedPanelHeaders}
                        label="zonetype"
                        setDefaultZoneType={setDefaultZoneType}
                        selectedHeaders={selectedHeaders}
                      />
                    )}
                  </div>
                </Row>
                <Row className="lst-header-width">
                  <div className="column">
                    <p className="SiteNameText BulkUploadTextName ml-2">
                      Zone Number<span style={{ color: "red" }}> *</span>
                    </p>
                  </div>
                  <div className="column1">
                    {!defaultZoneNumber && (
                      <SelectHeaderEmpty
                        importedFileHeaders={importedPanelHeaders}
                        setDefaultZoneNumber={setDefaultZoneNumber}
                        label="zonenumber"
                        selectedHeaders={selectedHeaders}
                      />
                    )}
                    {defaultZoneNumber && (
                      <SelectHeaderCommon
                        defaultVal={defaultZoneNumber}
                        importedFileHeaders={importedPanelHeaders}
                        label="zonenumber"
                        setDefaultZoneNumber={setDefaultZoneNumber}
                        selectedHeaders={selectedHeaders}
                      />
                    )}
                  </div>
                </Row>
                <Row className="lst-header-width">
                  <div className="column">
                    <p className="SiteNameText BulkUploadTextName ml-2">
                      Zone Name<span style={{ color: "red" }}> *</span>
                    </p>
                  </div>
                  <div className="column1">
                    {!defaultZoneName && (
                      <SelectHeaderEmpty
                        importedFileHeaders={importedPanelHeaders}
                        setDefaultZoneName={setDefaultZoneName}
                        label="zonename"
                        selectedHeaders={selectedHeaders}
                      />
                    )}
                    {defaultZoneName && (
                      <SelectHeaderCommon
                        defaultVal={defaultZoneName}
                        importedFileHeaders={importedPanelHeaders}
                        label="zonename"
                        setDefaultZoneName={setDefaultZoneName}
                        selectedHeaders={selectedHeaders}
                      />
                    )}
                  </div>
                </Row>
                {/* rg 5sept */}
                {/* <Row className="lst-header-width">
                  <div className="column">
                    <p className="SiteNameText BulkUploadTextName ml-2">
                      Interface Name<span style={{ color: "red" }}> *</span>
                    </p>
                  </div>
                  <div className="column1">
                    {!defaultInterface && (
                      <SelectHeaderEmpty
                        importedFileHeaders={importedPanelHeaders}
                        setDefaultInterface={setDefaultInterface}
                        label="interfacename"
                        selectedHeaders={selectedHeaders}
                      />
                    )}
                    {defaultInterface && (
                      <SelectHeaderCommon
                        defaultVal={defaultInterface}
                        importedFileHeaders={importedPanelHeaders}
                        label="interfacename"
                        setDefaultInterface={setDefaultInterface}
                        selectedHeaders={selectedHeaders}
                      />
                    )}
                  </div>
                </Row> */}

                {/* <div className="BulkUploadBrowseDiv">
                  <input 
                    className="BulkUploadInput"
                    value={panelImportedFileName}
                    disabled={true}
                  />
                  <button className="DataAccessEdit" style={{width: "100px"}} onClick={handleUploadCSVClick}>
                          <span className="ViewUpdate">Browse</span>
                        </button> 
              </div> */}

              {/* rupesh interface new implementation */}
              <Row className="lst-header-width">
                  <div className="column">
                    <p className="SiteNameText BulkUploadTextName ml-2">
                      Port Number<span style={{ color: "red" }}> *</span>
                    </p>
                  </div>
                  <div className="column1">
                    {!defaultPortNumber && (
                      <SelectHeaderEmpty
                        importedFileHeaders={importedPanelHeaders}
                        setDefaultPortNumber={setDefaultPortNumber}
                        label="portnumber"
                        selectedHeaders={selectedHeaders}
                      />
                    )}
                    {defaultZoneName && (
                      <SelectHeaderCommon
                        defaultVal={defaultPortNumber}
                        importedFileHeaders={importedPanelHeaders}
                        label="portnumber"
                        setDefaultPortNumber={setDefaultPortNumber}
                        selectedHeaders={selectedHeaders}
                      />
                    )}
                  </div>
                </Row>

                <Row className="lst-header-width">
                  <div className="column">
                    <p className="SiteNameText BulkUploadTextName ml-2">
                      Interface Username<span style={{ color: "red" }}> *</span>
                    </p>
                  </div>
                  <div className="column1">
                    {!defaultInterfaceUsername && (
                      <SelectHeaderEmpty
                        importedFileHeaders={importedPanelHeaders}
                        setDefaultInterfaceUsername={setDefaultInterfaceUsername}
                        label="interfaceusername"
                        selectedHeaders={selectedHeaders}
                      />
                    )}
                    {defaultInterfaceUsername && (
                      <SelectHeaderCommon
                        defaultVal={defaultInterfaceUsername}
                        importedFileHeaders={importedPanelHeaders}
                        label="interfaceusername"
                        setDefaultInterfaceUsername={setDefaultInterfaceUsername}
                        selectedHeaders={selectedHeaders}
                      />
                    )}
                  </div>
                </Row>

                <Row className="lst-header-width">
                  <div className="column">
                    <p className="SiteNameText BulkUploadTextName ml-2">
                      Interface Password<span style={{ color: "red" }}> *</span>
                    </p>
                  </div>
                  <div className="column1">
                    {!defaultInterfacePassword && (
                      <SelectHeaderEmpty
                        importedFileHeaders={importedPanelHeaders}
                        setDefaultInterfacePassword={setDefaultInterfacePassword}
                        label="interfacepassowrd"
                        selectedHeaders={selectedHeaders}
                      />
                    )}
                    {defaultInterfaceUsername && (
                      <SelectHeaderCommon
                        defaultVal={defaultInterfacePassword}
                        importedFileHeaders={importedPanelHeaders}
                        label="interfacepassowrd"
                        setDefaultInterfacePassword={setDefaultInterfacePassword}
                        selectedHeaders={selectedHeaders}
                      />
                    )}
                  </div>
                </Row>
              {/* rupesh interface new implementation */}
              </div>
              <div
                className="BulkText1 AlignBulk"
              >
                {/* <div>
                  <p>Bulk Import Panels?</p>
                  <p className="SampleTextDocument">

                    <span>
                      <a
                        href="https://astrostaticfilesbucket.s3.ap-south-1.amazonaws.com/PanelBulkUploadSample.csv"
                        download
                      >
                        Sample Document
                        <img
                          src={SampleDownload}
                          className="sample-doc-download"
                        />
                      </a>
                    </span>
                  </p>
                </div> */}
                {/* <button
                  className="upload-csv-button uploadcsv-panel"
                  onClick={(e) => handleUploadCSVClick(e)}
                >
                  {" "}
                  Upload CSV
                </button> */}
                <input
                  type="file"
                  multiple={false}
                  ref={hiddenPanelFileInput}
                  onChange={(e) => handleUploaderChange(e)}
                  style={{ display: "none" }}
                  value=""
                />
              </div>

              {/* <Row className="add-devices-btn-div bulk-upload-main-row-length"> */}
                {/* Commenting below Block rgr 18/01/23  */}
                {/* <Col lg={8} > */}
                  <p style={{textAlign: "center"}}>

                    {
                   /*  panelImportedFileName && totalDevices && panelBulkUploadResponse &&
                      !(panelBulkUploadResponse['sensorSuccessList'] || panelBulkUploadResponse['sensorFailedList']) && */

                    panelImportedFileName && totalDevices && panelBulkUploadResponse &&
                        !(panelBulkUploadResponse['CameraSuccessList'] || panelBulkUploadResponse['CameraFailedList']) &&
                      <OverlayTrigger
                        placement='bottom'
                        overlay={<Tooltip>{panelImportedFileName}</Tooltip>}>
                        {/* <Row className="bulk-upload-msg-text " >
                          <Col lg={7} className="template-name">{panelImportedFileName}</Col>
                          <Col lg={5}>({totalDevices} Devices)</Col>
                        </Row> */}
                        <span className="bulk-upload-msg-text">({totalDevices} Devices)</span>
                      </OverlayTrigger>
                    }
                    
                    {panelBulkUploadResponse && failedDevices && failedDevices != 0 && totalDevices &&
                      <span className="bulk-upload-msg-text template-name">
                        <CSVLink filename={`Panels Failed List_${currentDate}_${currentTime}.csv`} className="mendat-fields" data={faildListToDownload}>{failedDevices} /
                          {totalDevices} Failed Uploads</CSVLink> </span>

                    }


                    {panelBulkUploadError && panelImportedFileName && errorListToDownload &&
                      <span className="bulk-upload-msg-text template-name">
                        <CSVLink filename={`Panels Error List_${currentDate}_${currentTime}.csv`} className="mendat-fields" data={errorListToDownload}>Error List</CSVLink> </span>
                    }

                   {/*  {panelBulkUploadResponse && successDevices && totalDevices && !failedDevices &&
                      <span className="bulk-upload-msg-text template-name">
                        {successDevices} /
                        {totalDevices} Uploads Successful</span>
                    } */}

                {panelBulkUploadResponse && successDevices && successDevices !=0  &&totalDevices &&
                      <span className="bulk-upload-msg-text template-name">
                   <CSVLink filename={`Panels Success List_${currentDate}_${currentTime}.csv`} className="mendat-fields" data={sucessListToDownload}>{successDevices} /
                   {totalDevices} Uploads Successful</CSVLink> </span>
                }

                  </p>
                {/* </Col> */}
                {/* <Col lg={4} className="col-div">
                  <button
                    type="submit"
                    className="NextMoveButton add-cameras add-panels-btn manageDevices-bulkUpload-addPanels-btn"
                    form="hook-form"
                  >
                    <span className="ViewUpdate">Add Panels</span>
                  </button>

                </Col> */}
              {/* </Row> */}
            </div>
          </form>
          {/* <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          /> */}


        </div>
      </div>
    </>
  );
});

function setSelectedHeader(e, props) {
  if (props.label == "brand") {
    props.selectedHeaders.brand = e.target.value;
    props.setDefaultBrand(e.target.value);
  }
  if (props.label == "ipaddress") {
    props.selectedHeaders.ipaddress = e.target.value;
    props.setDefaultIp(e.target.value);
  }
  if (props.label == "branch") {
    props.selectedHeaders.branch = e.target.value;
    props.setDefaultBranch(e.target.value);
  }
  if (props.label == "name") {
    props.selectedHeaders.name = e.target.value;
    props.setDefaultPanelName(e.target.value);
  }
  if (props.label == "zonetype") {
    props.selectedHeaders.zonetype = e.target.value;
    props.setDefaultZoneType(e.target.value);
  }
  if (props.label == "zonenumber") {
    props.selectedHeaders.zonenumber = e.target.value;
    props.setDefaultZoneNumber(e.target.value);
  }
  if (props.label == "zonename") {
    props.selectedHeaders.zonename = e.target.value;
    props.setDefaultZoneName(e.target.value);
  }
 /*  if (props.label == "interfacename") {
    props.selectedHeaders.interfacename = e.target.value;
    props.setDefaultInterface(e.target.value);
  } */
}

const SelectHeaderCommon = (props) => {
console.log("SelectHeaderCommon", props);
  const onChange = (e, props) => {
    setSelectedHeader(e, props);
  };

  return (
    <>
      <select
        required={true}
        className="SiteNameTextBox mb-0"
        placeholder="Select Header"
        value={props.defaultVal}
        onChange={(e) => onChange(e, props)}
      >
        <option key="0" value="Select">
          Select
        </option>
        {props.importedFileHeaders &&
          props.importedFileHeaders.map((val) => (
            <option value={val} key={val}>
              {val}
            </option>
          ))}
      </select>
    </>
  );
};
const SelectHeaderEmpty = (props) => {

  const onChange = (e, props) => {
    setSelectedHeader(e, props);
  };
  return (
    <>
      <select
        required={true}
        className="SiteNameTextBox mb-0"
        placeholder="Select Header"
        // onChange={(event) => props.setDefaultVal(event.target.value)}
        onChange={(e) => onChange(e, props)}
      >
        <option key="0" value="Select">
          Select
        </option>
        {props.importedFileHeaders &&
          props.importedFileHeaders.map((val) => (
            <option value={val} key={val}>
              {val}
            </option>
          ))}
      </select>
    </>
  );
};

const AddCameraDeviceForm = React.forwardRef((props, ref) => {
  let {
    onSubmit,
    sites,
    selectedDevice,
    isDeviceEdit,
    setIsDeviceEdit,
    devicesmasterlist,
    isSubmitting,
  } = { ...props };

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch
  } = useForm({ defaultValue: { ...selectedDevice } });
  console.log("selectedDevice", selectedDevice);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [channelNumberPlaceholder, setChannelNumberPlaceholder] = useState('Channel Number* e.g. 1');

  useEffect(() => {
    if (selectedDevice && selectedDevice != null) {
      console.log("DeviceData", selectedDevice)
      setValue("SiteID", selectedDevice.SiteID) ||
        setValue("cameraName", selectedDevice.CameraName) ||
        setValue("privateIPAddress", selectedDevice.privateIPAddress) ||
        setValue("communicationPort", selectedDevice.communicationPort) ||
        setValue("httpPort", selectedDevice.httpPort) ||
        setValue("userName", selectedDevice.camUserName) ||
        setValue("password", selectedDevice.camPassword) ||
        setValue("port", selectedDevice.camPort) ||
        setValue("channelNumber", selectedDevice.channelNum) ||
        // setValue("playbackChannelNumber", selectedDevice.playbackChannelNum) ||
        setValue("liveStreamTypeNumber", selectedDevice.SubTypeLive)
        setValue("playbackStreamTypeNumber", selectedDevice.SubTypePlayback
        ) ||
        setValue("rTSPURL", selectedDevice.RTSPURL && selectedDevice.RTSPURL.replace("%40", "@")) ||
        setValue("location", selectedDevice.Location) ||
        setValue("ParentCameraID", selectedDevice.ParentCameraID) ||
        setValue("CameraBrandId", selectedDevice.cameraBrandId)

      if (selectedDevice.isTranscoded == "1") {
        setValue("isTranscoded", 1);
      } else {
        setValue("isTranscoded", 0);
      }
      
      if (selectedDevice.isPlaybackTranscoded == "1") {
        setValue("isPlaybackTranscoded", 1);
      } else {
        setValue("isPlaybackTranscoded", 0);
      }
      
      if (selectedDevice.isTwoWayEnabled == "1") {
        setValue("isTwoWayEnabledDevice", 1);
      } else {
        setValue("isTwoWayEnabledDevice", 0);
      }
      // setValue("isTranscoded", selectedDevice.isTranscoded);
    }
  }, [selectedDevice]);

  useEffect(() => {
    console.log("rupesh checks",)
    if (watch("CameraBrandId") && watch("privateIPAddress") && watch("userName") && watch("password") && watch("port") && watch("channelNumber")) {
      let temp = "";
      if (getValues("CameraBrandId") == 2) {
        temp =
          "rtsp://" +
          getValues("userName") +
          ":" +
          getValues("password") +
          "@" +
          getValues("privateIPAddress") +
          ":" +
          getValues("port") +
          "/Streaming/Channels/" +
          getValues("channelNumber");
      } else if (getValues("CameraBrandId") == 1 || getValues("CameraBrandId") == 4) {
        temp =
          "rtsp://" +
          getValues("userName") +
          ":" +
          getValues("password") +
          "@" +
          getValues("privateIPAddress") +
          ":" +
          getValues("port") +
          "/cam/realmonitor?channel=" +
          getValues("channelNumber") +
          "&subtype=1"; //+getValues("channelNumber")
      } else if (getValues("CameraBrandId") == 3) {
        temp =
          "rtsp://" +
          getValues("userName") +
          ":" +
          getValues("password") +
          "@" +
          getValues("privateIPAddress") +
          ":" +
          getValues("port") +
          "/avstream/channel=" +
          getValues("channelNumber") +
          "/stream=0.sdp";
      }
      setValue("rTSPURL", temp)
    } else {
      setValue("rTSPURL", "")
    }
  }, [watch("CameraBrandId"), watch("privateIPAddress"), watch("userName"), watch("password"), watch("port"), watch("channelNumber")]);

  useImperativeHandle(ref, () => ({
    isDataChanged() {
      Swal.fire({ text: getValues("SiteID") });
      return (
        getValues("SiteID") != selectedDevice.SiteID ||
        getValues("cameraName") != selectedDevice.CameraName ||
        getValues("privateIPAddress") != selectedDevice.privateIPAddress ||
        getValues("communicationPort") != selectedDevice.communicationPort ||
        getValues("httpPort") != selectedDevice.httpPort ||
        getValues("userName") != selectedDevice.userName ||
        getValues("password") != selectedDevice.password ||
        getValues("port") != selectedDevice.port ||
        getValues("channelNumber") != selectedDevice.channelNum ||
        // getValues("playbackChannelNumber") != selectedDevice.playbackChannelNum ||
        getValues("liveStreamTypeNumber") != selectedDevice.SubTypeLive ||
        getValues("playbackStreamTypeNumber") != selectedDevice.SubTypePlayback ||
        getValues("rTSPURL") != selectedDevice.RTSPURL ||
        getValues("location") != selectedDevice.Location ||
        getValues("ParentCameraID") != selectedDevice.ParentCameraID ||
        getValues("CameraBrandId") != selectedDevice.cameraBrandId ||
        getValues("isTranscoded") != selectedDevice.isTranscoded ||
        getValues("isPlaybackTranscoded") != selectedDevice.isPlaybackTranscoded ||
        getValues("isTwoWayEnabledDevice") != selectedDevice.isTwoWayEnabled
        
      );
    },
  }));

  function resetrtspurl(e) {
    try {
      if (getValues("CameraBrandId") == 2) {
        e.value =
          "rtsp://" +
          getValues("userName") +
          ":" +
          // getValues("password").replace("@", "%40") +
          getValues("password")+
          "@" +
          getValues("privateIPAddress") +
          ":" +
          getValues("port") +
          "/Streaming/Channels/" +
          getValues("channelNumber");
      } else if (getValues("CameraBrandId") == 1 || getValues("CameraBrandId") == 4) {
        e.value =
          "rtsp://" +
          getValues("userName") +
          ":" +
          // getValues("password").replace("@", "%40") +
          getValues("password")+
          "@" +
          getValues("privateIPAddress") +
          ":" +
          getValues("port") +
          "/cam/realmonitor?channel=" +
          getValues("channelNumber") +
          "&subtype=1"; 
          //+getValues("channelNumber")
      } else if (getValues("CameraBrandId") == 3) {
        e.value =
          "rtsp://" +
          getValues("userName") +
          ":" +
        // getValues("password").replace("@", "%40") +
        getValues("password") +
        "@" +
          getValues("privateIPAddress") +
          ":" +
          getValues("port") +
          "/avstream/channel=" +
          getValues("channelNumber") +
          "/stream=0.sdp";
      } else {
        e.placeholder = "Select brand name first";
      }
    } catch (error) {
      console.error(error);
    }
  }
  const handleOptionChange = (option) => {
    setSelectedBrand(option);
    // Determine the placeholder for the next input based on the selected option
    if (+option === 1) {
      setChannelNumberPlaceholder("Channel Number* e.g. 101");
    } else if (+option === 2) {
      setChannelNumberPlaceholder("Channel Number* e.g. 1");
    } else {
      setChannelNumberPlaceholder("Channel Number* e.g. 1");
    }
  };
  //   const numberInputKeyDown = (event) => {
  //     const eventCode = event.code.toLowerCase();
  //     if (!(event.code !== null
  //     && (eventCode.includes("digit")
  //         || eventCode.includes("arrow")
  //         || eventCode.includes("home")
  //         || eventCode.includes("end")
  //         || eventCode.includes("backspace") 
  //         || (eventCode.includes("numpad") && eventCode.length === 10)))
  //     ) {
  //     event.preventDefault();
  //     }
  // };

  const onNumberOnlyChange = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValid = new RegExp("[0-9]").test(keyValue);
    if (!isValid) {
      event.preventDefault();
      return;
    }
  };

  return (
    <div className="SiteList">
      <div>
        <form
          onSubmit={handleSubmit((e) =>
            onSubmit(e, selectedDevice.ParentCameraID)
          )}
        >
          <div className="DeviceTextBox cam-device-form">
            <select
              {...register("CameraBrandId")}
              className="SiteNameTextBox"
              placeholder="Select Brand Name"
              defaultValue={selectedDevice.cameraBrandId}
              onChange={(e) => {
                setValue("CameraBrandId", e.target.value);
                handleOptionChange(e.target.value)
              }}
            >
              <option key="0" disabled={false} value="">Select Brand Name*</option>

              {devicesmasterlist &&
                devicesmasterlist.cameraTypeMasterList &&
                devicesmasterlist.cameraTypeMasterList.map((val) => (
                  <option value={val.brandId} key={val.brandId}>
                    {val.brandName}
                  </option>
                ))}
            </select>
            <input
              {...register("privateIPAddress")}
              className="SiteNameTextBox"
              placeholder="Private IP Address* e.g. 192.168.0.54"
              defaultValue={selectedDevice.privateIPAddress}
            />
            <input
              {...register("communicationPort")}
              className="SiteNameTextBox "
              placeholder="Communication Port* e.g. 8080"
              // pattern="[0-9]*"
              // inputMode="numeric"  
              onKeyPress={onNumberOnlyChange}
              defaultValue={selectedDevice.communicationPort}
            />
            <input
              {...register("httpPort")}
              className="SiteNameTextBox "
              placeholder="Http Port* e.g. 443"
              // pattern="[0-9]*"
              // inputMode="numeric"  
              onKeyPress={onNumberOnlyChange}
              defaultValue={selectedDevice.httpPort}
            />

            <input
              {...register("userName")}
              className="SiteNameTextBox"
              placeholder="User Name* e.g. Admin"
              defaultValue={selectedDevice.camUserName}
            />
            <input
              {...register("password")}
              className="SiteNameTextBox"
              placeholder="Password* e.g. Abc@123"
              defaultValue={selectedDevice.camPassword}
            />
            <input
              {...register("port")}
              className="SiteNameTextBox"
              placeholder="RTSP Port* e.g. 554"
              // pattern="[0-9]*"
              // inputMode="numeric"
              onKeyPress={onNumberOnlyChange}
              defaultValue={selectedDevice.camPort}
            />
            <input
              {...register("channelNumber")}
              className="SiteNameTextBox"
              // placeholder="Channel Number* e.g. 1"
              placeholder={channelNumberPlaceholder}
              defaultValue={selectedDevice.channelNum}
            />
            <input
              {...register("rTSPURL")}
              className="SiteNameTextBox"
              onClick={(e) => resetrtspurl(e.target)}
              disabled={false}
              placeholder="RTSP URL* e.g. rtsp://Admin:Abc@123@192.168.0.58:554/Streaming/Channels/1"
              defaultValue={selectedDevice.RTSPURL}
            />
            <input
              {...register("cameraName")}
              className="SiteNameTextBox"
              placeholder="Device Name* e.g. Lobby Camera"
              defaultValue={selectedDevice.CameraName}
            />
            <select
              {...register("SiteID")}
              className="SiteNameTextBox"
              placeholder="Select Branch*"
              defaultValue={selectedDevice.SiteID}
              onChange={(e) => setValue("SiteID", e.target.value)}
            >
              <option key="0" disabled={false} value="0">Select Branch*</option>
              {sites &&
                sites.map((site) => (
                  <option value={site.SiteID} key={site.SiteID}>
                    {site.SiteName}
                  </option>
                ))}
            </select>
            {/* <p className="SiteNameText onlyforEnableCheckbox">
                <input
                  className="checkbox_custom"
                  type="checkbox"
                  {...register("EnableTranscoding")}
                  onChange={(e) => setValue("EnableTranscoding", e.target.value)}
                />
                <p className="mb-0">
                  Enable Transcoding to H.264 <sup>(Optional)</sup>
                </p>
             </p> */}
            <div className="SiteNameText onlyforEnableCheckbox">
              <input
                className="checkbox_custom"
                type="checkbox"
                {...register("isTranscoded")}
                onChange={(e) => {
                  setValue("isTranscoded", Number(e.target.checked))
                  
                }}
                defaultValue={Number(selectedDevice.isTranscoded)}
              />
              <p className="mb-0">
                Enable Transcoding to H.264 <sup>(Optional)</sup>
              </p>
            </div>
          </div>
          <div className="ButtonDiv">
            {selectedDevice.ParentCameraID == 0 ? (
              <button type="submit" className="NextMoveButton addUpdateCamera-btn manageDevices-addCamera-btn"
                disabled={isSubmitting} >
                <span className="ViewUpdate">Add Camera</span>
              </button>
            ) : (
              <button type="submit" className="NextMoveButton addUpdateCamera-btn manageDevices-updateCamera-btn">
                <span className="ViewUpdate">Update Camera</span>
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
});

const AddPanelDeviceForm = React.forwardRef((props, ref) => {
  let {
    onSubmit,
    sites,
    selectedDevice,
    isDeviceEdit,
    devicesmasterlist,
    OnAddIDSPanel,
  } = { ...props };

  const { register, handleSubmit, getValues, setValue } = useForm();

  useEffect(() => {
    if (selectedDevice && selectedDevice != null) {
      setValue("PanelMasterId", selectedDevice.panelMasterId) ||
        setValue("SiteID", selectedDevice.SiteID) ||
        setValue("privateIPAddress", selectedDevice.privateIPAddress) ||
        setValue("communicationPort", selectedDevice.communicationPort) ||
        setValue("httpPort", selectedDevice.httpPort) ||
        setValue("cameraName", selectedDevice.CameraName);
    }
  }, [selectedDevice]);

  return (
    <div className="SiteList">
      <div>
        <form>
          <div className="DeviceTextBox panel-singledev">
            {/* <p className="SiteNameText">Brand Name <span style={{ color: "red" }}> *</span></p> */}
            <select
              {...register("PanelMasterId")}
              className="SiteNameTextBox"
              placeholder="Select Brand Name"
              defaultValue={selectedDevice.panelMasterId}
              onChange={(e) => setValue("PanelMasterId", e.target.value)}
            >
              <option key="0" disabled={false} value="">Brand Name*</option>
              {devicesmasterlist &&
                devicesmasterlist.panelTypeMasterList &&
                devicesmasterlist.panelTypeMasterList.map((site) => (
                  <option value={site.panelMasterID} key={site.panelMasterID}>
                    {site.panelName}
                  </option>
                ))}
            </select>
            <input
              type="text"
              {...register("cameraName")}
              className="SiteNameTextBox"
              placeholder="Panel name* e.g. Thane panel"
              defaultValue={selectedDevice.CameraName}
            />
            <input
              {...register("privateIPAddress")}
              className="SiteNameTextBox"
              placeholder="IP address* e.g. 192.168.0.54"
              defaultValue={selectedDevice.privateIPAddress}
            />
            <select
              {...register("SiteID")}
              className="SiteNameTextBox"
              placeholder="Select Site"
              defaultValue={selectedDevice.SiteID}
              onChange={(e) => setValue("SiteID", e.target.value)}
            >
              <option key="0" disabled={false} value="0">Branch Name*</option>
              {sites &&
                sites.map((site) => (
                  <option value={site.SiteID} key={site.SiteID}>
                    {site.SiteName}
                  </option>
                ))}
            </select>
          </div>
          {selectedDevice.ParentCameraID == 0 ? (
            <div className="m-2 text-right" style={{ textAlign: "end" }}>
              <button
                className="NextMoveButton m-3 manageDevices-addPanel-btn"
                onClick={handleSubmit((e) =>
                  OnAddIDSPanel(
                    e,
                    selectedDevice.ParentCameraID,
                    "addPanelDevice"
                  )

                )}
              >
                <span className="ViewUpdate">Add Panel</span>
              </button>
            </div>
          ) : (
            <div className="m-2 text-right" style={{ textAlign: "end" }}>
              <button
                className="NextMoveButton m-3 manageDevices-updatepanel-btn"
                onClick={handleSubmit((e) =>
                  OnAddIDSPanel(
                    e,
                    selectedDevice.ParentCameraID,
                    "updatePanelDevice"
                  )
                )}
              >
                <span className="ViewUpdate">Update Panel</span>
              </button>
            </div>
          )}
        </form>
        {/* <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        /> */}
      </div>
    </div>
  );
});

const ConfigurePanelZones = ({
  selectedDevice,
  devicesmasterlist,
  OnAddIDSPanel,
  setselectedZone,
  selectedZone,
  isDeviceEdit
}) => {
  let ZoneObj = {
    iDSPanelZoneTypeID: 0,
    zoneNo: "",
    zoneName: "",
    idsPanelZonesID: 0,
  };
  const [refreshZoneForm, setRefreshZoneForm] = useState(false);
  const [refreshZoneList, setRefreshZoneList] = useState(true);

  useEffect(() => {
    setRefreshZoneForm(true);
  }, [selectedZone]);

  useEffect(() => {
    setRefreshZoneList(true);
  }, [selectedDevice]);

  return (
    <>
      {
        selectedDevice && selectedDevice.panelMasterId && selectedDevice.panelMasterId != 0 &&
        <div className="association-main-section">
          <h1 className="MainHeaderName mb-0">Manage Zones</h1>
          <div>
            <div className="association-section-header">
              <p className="mb-0" style={{ width: "40%" }}>
                Zones
                <br />
              </p>
              <p className="mb-0">
                <br />
              </p>
            </div>

            <div className="sensors-section">
              <div className="sensors-list">
                {refreshZoneList &&
                  selectedDevice &&
                  selectedDevice.zonesList &&
                  selectedDevice.zonesList.map((item) => {
                    return (
                      <div key={item.zoneNo} >
                        <div
                          className={`cameraList user-list-item ${selectedZone &&
                            item.zoneNo ==
                            selectedZone?.zoneNo &&
                            "selected-user"
                            }`}
                         /*  onClick={!isDeviceEdit ? null : () =>
                            setselectedZone(item)} */
                            onClick={() => setselectedZone(item)}
                        >
                          <p className="mb-0">
                            {item.zoneName + " - " + item.zoneNo}
                          </p>
                          <div style={{ float: "right" }}>
                            <img
                              style={{ paddingRight: "10px" }}
                              onClick={() => {
                                {
                                  setselectedZone(item)
                                }
                              }}
                              src={EditQc}
                              alt="edit icon"
                              className="m-1"
                            />
                            <img
                              src={DeleteQc}
                              alt="delete icon"
                              onClick={() => {
                              /*   if (isDeviceEdit) {
                                  OnAddIDSPanel(
                                    item.iDSPanelZoneTypeID,
                                    selectedDevice.ParentCameraID,
                                    "deleteZone",
                                    item.zoneNo
                                  );
                                } */
                                OnAddIDSPanel(
                                  item.iDSPanelZoneTypeID,
                                  selectedDevice.ParentCameraID,
                                  "deleteZone",
                                  item.zoneNo
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="AddMore" style={{ float: "left" }} disabled={!isDeviceEdit}>
                <a
                  className="UserAddPlus mt-1"
                  onClick={() => setselectedZone(ZoneObj)}
                >
                  Add New Zone +
                </a>
              </div>
            </div>

            {!refreshZoneForm && (
              <AddZoneForm
                selectedZone={selectedZone}
                devicesmasterlist={devicesmasterlist}
                OnAddIDSPanel={OnAddIDSPanel}
                selectedDevice={selectedDevice}
                isDeviceEdit={isDeviceEdit}
              />
            )}
            {refreshZoneForm && (
              <AddZoneForm
                selectedZone={selectedZone}
                devicesmasterlist={devicesmasterlist}
                OnAddIDSPanel={OnAddIDSPanel}
                selectedDevice={selectedDevice}
                isDeviceEdit={isDeviceEdit}
              />
            )}
          </div>
        </div>
      }
    </>
  );
};

const AddZoneForm = React.forwardRef((props, ref) => {
  let { selectedZone, devicesmasterlist, OnAddIDSPanel, selectedDevice, isDeviceEdit } = {
    ...props,
  };

  const { register, handleSubmit, getValues, setValue } = useForm();
  const [addzontype, setaddzontype] = useState(false);
  const [zonetype, setzonetype] = useState("");

  useEffect(() => {
    if (selectedZone && selectedZone != null) {
      setValue("iDSPanelZoneTypeID", selectedZone.iDSPanelZoneTypeID) ||
        setValue("ZoneNo", selectedZone.zoneNo) ||
        setValue("ZoneName", selectedZone.zoneName) ||
        setValue("idsPanelZonesID", selectedZone.idsPanelZonesID);
    }
  }, [selectedZone]);
  return (
    <>
      <form>
        <div className="panel-camera-list">
          <div style={{ marginLeft: "10px" }}>
            <div>
              <Row style={{ marginTop: "25px" }}>
                <Col lg={6}></Col>
                <Col lg={6}>
                  {
                    !addzontype
                      ? <div className="zoneadditiontextDiv" >
                        <p
                          className="zoneTypeAdditiontext"
                          onClick={true ? () => setaddzontype(!addzontype) : null}
                        >
                          + Add Zone Type
                        </p>
                      </div>
                      : null
                  }
                </Col>
              </Row>
            </div>
            {addzontype && (
              <div style={{ display: "flex", margin: "0px 0px 10px 0px" }}>
                <input
                  onChange={(e) => setzonetype(e.target.value)}
                  // disabled={!isDeviceEdit}
                  type="text"
                  className="SiteNameTextBox"
                  placeholder="ex: Fire sensor"
                />

                {/* <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>Add</Tooltip>}
              >
                <p
                  className="zoneTypeAddButton"
                  onClick={() => {
                    OnAddIDSPanel(
                      zonetype,
                      selectedDevice.ParentCameraID,
                      "addPanelZoneTypeMaster"
                    );
                    setaddzontype(false);
                  }}
                >
                  +
                </p>
              </OverlayTrigger> */}
                <button className="addZoneBtn manageZones-addZone-btn"
                  onClick={() => {
                    OnAddIDSPanel(
                      zonetype,
                      selectedDevice.ParentCameraID,
                      "addPanelZoneTypeMaster"
                    );
                    setaddzontype(false);
                  }}
                >
                  <span className="ViewUpdate">Add</span>
                </button>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Close</Tooltip>}
                >
                  <p
                    className="zoneTypeCloseButton"
                    onClick={() => {
                      setzonetype("");
                      setaddzontype(false);
                    }}
                  >
                    x
                  </p>
                </OverlayTrigger>
              </div>
            )}
            <select
              {...register("iDSPanelZoneTypeID")}
              className="SiteNameTextBox"
              placeholder="Select Zone Type"
              // disabled={!isDeviceEdit}
              defaultValue={selectedZone.iDSPanelZoneTypeID}
              onChange={(e) => setValue("iDSPanelZoneTypeID", e.target.value)}
            >
              <option key="0" disabled={false} value="0">Zone Type*</option>
              {devicesmasterlist.zoneTypeMasterList &&
                devicesmasterlist.zoneTypeMasterList.map((site) => (
                  <option value={site.iDSPanelZoneTypeID} key={site.idsPanelZonesID}>
                    {site.zoneType}
                  </option>
                ))}
            </select>

            <input
              type="Number"
              placeholder="Zone Number* ex: 256"
              {...register("ZoneNo")}
              // disabled={!isDeviceEdit}
              className="SiteNameTextBox"
              min={1}
              max={256}
              /* onInput={(event) => {
                if (event.target.value.length > 2) {
                  event.target.value = event.target.value.slice(0, 3);
                }
              }} */
              onInput={(event) => {
                let inputValue = event.target.value;
                if (inputValue.length > 1 && inputValue[0] === "0") {
                  // Remove leading zero
                  inputValue = parseInt(inputValue, 10).toString();
                }
                event.target.value = inputValue;
                if (inputValue.length > 2) {
                  event.target.value = inputValue.slice(0, 3);
                }
              }}
              onKeyDown={(event) => { if (event.key === ".") { event.preventDefault(); } }}
              defaultValue={selectedZone.zoneNo}
            />
            <input
              maxLength={50}
              placeholder="Zone Name* ex: Fire alert"
              {...register("ZoneName")}
              // disabled={!isDeviceEdit}
              className="SiteNameTextBox"
              defaultValue={selectedZone.zoneName}
            />
          </div>
        </div>
        <div className="m-2 text-right" style={{ textAlign: "end" }}>
          {selectedZone.zoneNo == "" ? (
            <button
              className="NextMoveButton m-3 manageZones-addZone-btn"
              // disabled={!isDeviceEdit}
              onClick={handleSubmit((e) =>
                OnAddIDSPanel(e, selectedDevice.ParentCameraID, "addPanelZones")
              )}
            >
              <span className="ViewUpdate">Add Zone</span>
            </button>
          ) : (
            <button
              className="NextMoveButton m-3 manageZones-updateZone-btn"
              onClick={handleSubmit((e) =>
                OnAddIDSPanel(e, selectedDevice.ParentCameraID, "updatePanelZone")
              )}
            >
              <span className="ViewUpdate">Update Zone</span>
            </button>
          )}
        </div>
      </form>
    </>
  );
});

const AddSiteForm = React.forwardRef((props, ref) => {
  let {
    onSubmit,
    site,
    isSiteEdit,
    setIsSiteEdit,
    clients,
    getCliets,
    siteTypes,
    setSiteTypes,
    country,
    countryState,
    setCountryState,
    onSelectSite,
    ertLevels,
    siteERTOnClick,
    selectedERT,
    onAddERTMemberClick,
    addNewERTMemberClick,
    emeContactList,
    onDeleteERT,
    isERTSectionVisible,
    userOptions,
    onUserRoleChange,
    selectedUserRole,
    setSelectedUserRole,
    siteUserFieldsVisibility,
    filteredSites,
    cameradeviceslist,
    onDeviceSelection,
    selectedDevice,
    devicesmasterlist,
    isSubmitting,
    setIsSubmitting,
    sites,
    OnAddIDSPanel,
    paneldeviceslist,
    OnSaveDevice,
    onSearchChange,
    setSelectedSite,
    setSelectedDevice,
    onDeleteChange,
    onAddNewDevice,
    checkForAlertsAndDelDevice,
    getdeviceslistdata,
    interfaceTypeData,
    interfaceSubTypeData,
    setInterfaceSubTypeData,
    interfaceDataList,
    onInterfaceFormSubmit,
    onSelectInterface,
    interfaceDevice,
    setSelectedInterface,
    bulkUpload,
    setBulkUpload,
    isSingleOrBulkSelection,
    setIsSingleOrBulkSelection,
    setBulkDevToUpload,
    selectedCamHeaders,
    OnAddBulkDevicesClick,
    setSelectedCamHeaders,
    clearImportedCamHeaders,
    deviceBulkUploadResponse,
    setImportedFileData,
    setDeviceBulkUploadResponse,
    isInterfaceEdit,
    isDeviceEdit,
    setIsInterfaceEdit,
    setIsDeviceEdit,
    getInterfaceData,
    onDeleteInterfaceChange,
    selectedHeaders,
    onPanelBulkUpload,
    setSelectedHeaders,
    clearImportedPanelHeaders,
    panelBulkUploadResponse,
    panelBulkUploadError,
    setPanelBulkUploadResponse,
    setPanelBulkUploadError,
    setFilteredSites,
    importedFileHeaders,
    setImportedFileHeaders,
    importedPanelHeaders,
    setImportedPanelHeaders,
    selectedZone,
    setselectedZone,
    associateCamSecVisibility,
    setAssociateCamSecVisibility,
    onSensorSelection,
    associatedDevices,
    onAssociate,
    selectedSensor,
    selectedItems,
    setSelectedItems,
    cctvTypeData,
    setInterfaceDataList,
    setcameradeviceslist,
    setpaneldeviceslist,
    setParentIsEncoding,
    parentIsEncoding,
    parentIsPlaybackEncoding,
    setParentIsPlaybackEncoding,
    parentIsEnablingTwoWayDevice,
    setParentIsEnablingTwoWayDevice,
    setParentIsEnablingTwoWayInterface,
    parentIsEnablingTwoWayInterface,
    setParentIsEnablingInternalHooter,
    setParentIsEnablingExternalHooter,
    setParentPanelIsEnablingHooter,
    setSelectedEntitySubtype,
    parentIsEnablingInternalHooter,
    setParentIsEnablingPlaybackTimeStampsToUTC,
    parentIsEnablingPlaybackTimeStampsToUTC,
    parentIsEnablingPlaybackLongDateFormat,
    setParentIsEnablingPlaybackLongDateFormat,
    parentIsEnablingExternalHooter,
    parentPanelIsEnablingHooter,
    rtspValueChanged,
    setRtspValueChanged,
    isDarkTheme,
    setUnknownBrandUrl,
    siteFormObj,
    setSiteFormObj,
    validationError,
    savebtnClick,
    setSavebtnClick,
    setInputValidationError,
    buttonDisable
    // ,
    // selectedInterface
  } = { ...props };
  // const [selected, setSelected] = useState('');
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { isDirty, touchedFields },
  } = useForm({ defaultValue: { ...site, ...interfaceDevice, ...selectedDevice } });
  const [show, setShow] = useState(false);
  const SITES = "SITES";
  const INTERFACES = "INTERFACES";
  const DEVICES = "DEVICES";

  const PANEL = "PANEL";
  const NVRDVR = "NVRDVR";
  const siteRef = React.useRef(null);
  const interfaceRef = React.useRef(null);
  console.log("getValues", getValues());
console.log("rupesh checks interfaceDevice",interfaceDevice)
console.log("rupesh checks interface device rtsp live",interfaceDevice.LiveRTSPURL)
console.log("rupesh checks interface device rtsp playback",interfaceDevice.PlaybackRTSPURL)
  const handleRefClick = () => {
    if(activeTab !== SITES){
      setTimeout(() => {
        siteRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        })
      }, 100)
    }
  }

  const handleInterfaceRefClick = () => {
    if(activeTab !== INTERFACES){
      setTimeout(() => {
        interfaceRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        })
      }, 100)
    }  
  }

  const [activeTab, setActiveTab] = useState(SITES)
  const [disableTab, setDisableTab] = useState(true);
  const [interfaceTab, setInterfaceTab] = useState(true);
  const [interfaceType, setInterfaceType] = useState(NVRDVR);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [channelNumberPlaceholder, setChannelNumberPlaceholder] = useState('Channel Number* e.g. 1');

  const [searchTextSite, setSearchTextSite] = useState('');
  const [searchTextInterface, setSearchTextInterface] = useState('');
  const [searchTextDevice, setSearchTextDevice] = useState('');
  const [isChannelNumberEdited, setIsChannelNumberEdited] = useState(false)

  // Add site form error states
  const [customerError, setCustomerError] = useState('');
  const [siteError, setSiteError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [stateError, setStateError] = useState('');
  const [cityError, setCityError] = useState('');
  const [regionError, setRegionError] = useState('');
  const [pincodeError, setPincodeError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [areaError, setAreaError] = useState('');
  const [branchidError, setBranchidError] = useState('');
  // const [siteTypeError, setSiteTypeError] = useState('');

  // Add Interface error states
    const [interfaceError, setInterfaceError] = useState('');
    const [interfaceTypeError, setInterfaceTypeError] = useState('')
    const [interfaceSubtypeError, setInterfaceSubtypeError] = useState('')
    const [interfaceEntitySubtypeError, setInterfaceEntitySubtypeError] = useState('')
    const [interfaceIPError, setInterfaceIPError] = useState('')
    const [interfacePortError, setInterfacePortError] = useState('')
    const [interfaceUserError, setInterfaceUserError] = useState('')
    const [interfacePasswardError, setInterfacePasswardError] = useState('')
    const [liveStreamFormatError, setLiveStreamFormatError] = useState('');
    const [playbackStreamFormatError, setPlaybackStreamFormatError] = useState('');

  // Add camera form error states
  const [communicationPortError, setCommunicationPortError] = useState('');
  const [httpPortError, setHttpPotError] = useState('');
  const [channelNumberError, setChannelNumberError] = useState('');
  const [liveStreamTypeError, setLiveStreamTypeError] = useState('');
  const [playbackStreamTypeError, setPlaybackStreamTypeError] = useState('');
  const [rtspURLError, setrtspURLError] = useState('');
  const [deviceNameError, setDeviceNameError] = useState('');
  // const [entitySubtypeError, setEntitySubtypeError] = useState('');

  const [addCustomerError, setAddCustomerError] = useState('');

  const clearSiteFields = () => {
    setCustomerError('');
    setSiteError('');
    setCountryError('');
    setStateError('');
    setCityError('');
    setRegionError('');
    setPincodeError('');
    setAddressError('');
    setAreaError('');
    setBranchidError('');
    // setSiteTypeError('');
    setInputValidationError('');
  }

  const clearInterfaceFields = () => {
    setInterfaceError('');
    setInterfaceTypeError('');
    setInterfaceSubtypeError('');
    setInterfaceEntitySubtypeError('');
    setInterfaceIPError('');
    setInterfacePortError('');
    setInterfaceUserError('');
    setInterfacePasswardError('');
    setLiveStreamFormatError('');
    setPlaybackStreamFormatError('');
  }

  const clearCameraFields = () => {
    setCommunicationPortError('');
    setChannelNumberError('');
    setLiveStreamTypeError('')
    setPlaybackStreamTypeError('')
    setrtspURLError('');
    setDeviceNameError('');
    setHttpPotError('')
  }

  useEffect(() => {
    console.log("onSaveSite", validationError);
    if (savebtnClick) {
      let templst = validationError.split(",");
      console.log("onSaveSite", templst);
      for (let i = 0; i < templst.length; i++) {
        if (templst[i].includes("customer-")) {
          setCustomerError(templst[i].replace("customer-", ""));
          continue
        }

        if (templst[i].includes("site-")) {
          console.log("onSaveSite", templst[i]);
          setSiteError(templst[i].replace("site-", ""));
          continue
        }

        if (templst[i].includes("country-")) {
          setCountryError(templst[i].replace("country-", ""));
          continue
        }

        if (templst[i].includes("state-")) {
          setStateError(templst[i].replace("state-", ""));
          continue
        }

        if (templst[i].includes("city-")) {
          setCityError(templst[i].replace("city-", ""));
          continue
        }

        if (templst[i].includes("region-")) {
          setRegionError(templst[i].replace("region-", ""));
          continue
        }

        if (templst[i].includes("pincode-")) {
          setPincodeError(templst[i].replace("pincode-", ""));
          continue
        }

        if (templst[i].includes("address-")) {
          setAddressError(templst[i].replace("address-", ""));
          continue
        }

        if (templst[i].includes("area-")) {
          setAreaError(templst[i].replace("area-", ""));
          continue
        }

        if (templst[i].includes("branchid-")) {
          setBranchidError(templst[i].replace("branchid-", ""));
          continue
        }
      }
      setSavebtnClick(false)
    }
  }, [savebtnClick]);

  useEffect(() => {
    console.log("onSaveSite1", validationError);
    if (savebtnClick) {
      let templst = validationError.split(",");
      console.log("onSaveSite1", templst);
      for (let i = 0; i < templst.length; i++) {
        if (templst[i].includes("interface-")) {
          setInterfaceError(templst[i].replace("interface-", ""));
          continue
        }

        if (templst[i].includes("interface type-")) {
          console.log("onSaveSite1", templst[i]);
          setInterfaceTypeError(templst[i].replace("interface type-", ""));
          continue
        }

        if (templst[i].includes("interface sub type-")) {
          setInterfaceSubtypeError(templst[i].replace("interface sub type-", ""));
          continue
        }

        if (templst[i].includes("interface entity subtype-")) {
          setInterfaceEntitySubtypeError(templst[i].replace("interface entity subtype-", ""));
          continue
        }

        if (templst[i].includes("interface IP-")) {
          setInterfaceIPError(templst[i].replace("interface IP-", ""));
          continue
        }

        // if (templst[i].includes("interface Valid IP-")) {
        //   setInterfaceIPError(templst[i].replace("interface Valid IP-", ""));
        //   continue
        // }

        if (templst[i].includes("interface port-")) {
          setInterfacePortError(templst[i].replace("interface port-", ""));
          continue
        }

        if (templst[i].includes("interface username-")) {
          setInterfaceUserError(templst[i].replace("interface username-", ""));
          continue
        }

        if (templst[i].includes("interface password-")) {
          setInterfacePasswardError(templst[i].replace("interface password-", ""));
          continue
        }

        if (templst[i].includes("interface livestream format-")) {
          setLiveStreamFormatError(templst[i].replace("interface livestream format-", ""));
          continue
        }

        if (templst[i].includes("interface playbackstream format-")) {
          setPlaybackStreamFormatError(templst[i].replace("interface playbackstream format-", ""));
          continue
        }
      }
      setSavebtnClick(false)
    }
  }, [savebtnClick]);

  useEffect(() => {
    console.log("onSaveSite1", validationError);
    if (savebtnClick) {
      let templst = validationError.split(",");
      console.log("onSaveSite1", templst);
      for (let i = 0; i < templst.length; i++) {
        if (templst[i].includes("communication port-")) {
          console.log("onSaveSite1", templst[i]);
          setCommunicationPortError(templst[i].replace("communication port-", ""));
          continue
        }

        if (templst[i].includes("http port-")) {
          setHttpPotError(templst[i].replace("http port-", ""));
          continue
        }

        if (templst[i].includes("channel number-")) {
          setChannelNumberError(templst[i].replace("channel number-", ""));
          continue
        }

        if (templst[i].includes("live stream type-")) {
          setLiveStreamTypeError(templst[i].replace("live stream type-", ""));
          continue
        }

        if (templst[i].includes("playback stream type-")) {
          setPlaybackStreamTypeError(templst[i].replace("playback stream type-", ""));
          continue
        }

        if (templst[i].includes("rtsp URL-")) {
          setrtspURLError(templst[i].replace("rtsp URL-", ""));
          continue
        }

        if (templst[i].includes("device name-")) {
          setDeviceNameError(templst[i].replace("device name-", ""));
          continue
        }
      }
      setSavebtnClick(false)
    }
  }, [savebtnClick]);

  const handleBulkUploadClick = (e) => {
    setBulkUpload(true);
    setBulkDevToUpload([]);
    setIsSingleOrBulkSelection("bulk_upload");
  };

  const handleSingleUploadClick = () => {
    setBulkUpload(false);
    setIsSingleOrBulkSelection("single_device");
  };

  const [isAlreadyRetail, setIsAlreadyRetail] = useState(false);

  useEffect(() => {
    console.log("checkSiteModeID", site.SiteModeID)
    //for getting Country in dropdown
    let tempCountryDetails = country?.filter(item => {
      return item.CountryMasterId == site.CountryId
    })
    if (tempCountryDetails?.length > 0) {
      setValue("CountryName", tempCountryDetails[0].CountryName);
    } else {
      setValue("CountryName", site.CountryName);
    }
    //for getting state in dropdown
    let tempCountryStateDetails = countryState?.filter(item => {
      return item.StateID == site.StateId
    })
    if (tempCountryStateDetails?.length > 0) {
      setValue("StateName", tempCountryStateDetails[0].StateName);
    } else {
      setValue("StateName", site.StateName);
    }

    setValue("clientID", site.clientID);
    setValue("SiteName", site.SiteName);
    setValue("BranchID", site.BranchID);
    setValue("SiteAddress", site.SiteAddress);
    setValue("CityName", site.City);
    setValue("SiteRegionName", site.Region);
    setValue("SitePincode", site.pincode);
    setValue("SiteAddress", site.Address);
    setValue("SiteArea", site.Area);

  if (!site.SiteModeID == null || !site.SiteModeID == 0) {
    setValue("SiteModeID", site.SiteModeID) 
    setIsAlreadyRetail(true);
} else {
  setValue("SiteModeID", 0) 
  setIsAlreadyRetail(false);
}
   
  }, [site]);

  useEffect(() => {
    let tempCountryStateDetails = countryState?.filter(item => {
      return item.StateID == site.StateId
    })
    if (tempCountryStateDetails?.length > 0) {
      setValue("StateName", tempCountryStateDetails[0].StateName);
    } else {
      setValue("StateName", site.StateName);
    }
  }, [countryState])

  useImperativeHandle(ref, () => ({
    isDataChanged() {
      return (
        getValues("SiteName") != site.SiteName ||
        getValues("BranchID") != site.BranchID ||
        getValues("SiteAddress") != site.SiteAddress ||
        getValues("clientID") != site.clientID ||
        getValues("CityName", site.City) ||
        getValues("SiteRegionName", site.Region) ||
        getValues("SitePincode", site.pincode) ||
        getValues("SiteAddress", site.Address) ||
        getValues("SiteArea", site.Area) ||
        getValues("CountryName", site.CountryName) ||
        getValues("StateName", site.StateName)||
        getValues("SiteModeID",site.SiteModeID)
      );
    },
  }));

  function isDataChanged() {
    return (
      getValues("SiteName") != site.SiteName ||
      getValues("BranchID") != site.BranchID ||
      getValues("SiteAddress") != site.SiteAddress ||
      getValues("clientID") != site.clientID ||
      getValues("CityName", site.City) ||
      getValues("SiteRegionName", site.Region) ||
      getValues("SitePincode", site.pincode) ||
      getValues("SiteAddress", site.Address) ||
      getValues("SiteArea", site.Area) ||
      getValues("CountryName", site.CountryName) ||
      getValues("StateName", site.StateName)||
      getValues("SiteModeID",site.SiteModeID)
    );
  }

  const onCancel = (e) => {
    e.stopPropagation();
    if (isDataChanged()) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to cancel the changes made?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#027aaa",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Leave!",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          onSelectSite(site.SiteID, true);
          setValue("SiteName", site.SiteName);
          setValue("BranchID", site.BranchID);
          setValue("SiteAddress", site.SiteAddress);
          setValue("clientID", site.clientID);
          setIsSiteEdit(false);
          // setSelectedUserRole([])
          setValue("CityName", site.City);
          setValue("SiteRegionName", site.Region);
          setValue("SitePincode", site.pincode);
          setValue("SiteAddress", site.Address);
          setValue("SiteArea", site.Area);
          setValue("SiteModeID",site.SiteModeID);
          // setValue("CountryName", site.CountryName);
          let tempCountryDetails = country?.filter(item => {
            return item.CountryMasterId == site.CountryId
          })
          if (tempCountryDetails?.length > 0) {
            setValue("CountryName", tempCountryDetails[0].CountryName);

          } else {
            setValue("CountryName", site.CountryName);
          }


          let tempCountryStateDetails = countryState?.filter(item => {
            return item.StateID == site.StateId
          })
          if (tempCountryStateDetails?.length > 0) {
            setValue("StateName", tempCountryStateDetails[0].StateName);
          } else {
            setValue("StateName", site.StateName);
          }
          clearSiteFields();
        }
      });
    } else {
      setIsSiteEdit(false);
    }
  };

  function isDataChangedInterface() {
    return (
      getValues("InterfaceName") != interfaceDevice.InterfaceName ||
      getValues("InterfaceType") != interfaceDevice.Interfacetypename ||
      getValues("InterfaceSubType") != interfaceDevice.Interfacesubtypename ||
      getValues("InterfaceIP") != interfaceDevice.InterfaceIP ||
      getValues("InterfacePort", interfaceDevice.InterfacePort) ||
      getValues("InterfaceUsername", interfaceDevice.UserName) ||
      getValues("InterfacePassword", interfaceDevice.Password) ||
      getValues("InterfaceTwoWayEnabled", interfaceDevice.IsTwoWayEnabled)||
      getValues("CheckInternalHooter", interfaceDevice.CheckInternalHooter)||
      getValues("CheckExternalHooter", interfaceDevice.CheckExternalHooter)||
      getValues("IsHooterEnabled",interfaceDevice.IsHooterEnabled) ||
      getValues("PlaybackTimeStampsToUTC", interfaceDevice.CheckPlaybackUTCTime) ||
      getValues("PlaybackLongDateFormat", interfaceDevice.CheckPlaybackLongTime)||
      getValues("EntityTypeID ",interfaceDevice.EntityTypeID ) ||
      getValues("LiveRTSPURL", interfaceDevice.LiveRTSPURL) ||
      getValues("PlaybackRTSPURL", interfaceDevice.PlaybackRTSPURL)||
      getValues("CCTVType",interfaceDevice.CCTVType)

    );
  }
  const onCancelInterface = (e) => {
    e.stopPropagation();
    if (isDataChangedInterface()) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to cancel the change made?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#027aaa",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Leave!",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          // setValue("SiteID", interfaceDevice.SiteID)
          setValue("InterfaceName", interfaceDevice.InterfaceName)
          setValue("InterfaceType", interfaceDevice.Interfacetypename)
          setValue("InterfaceSubType", interfaceDevice.Interfacesubtypename)
          setValue("InterfaceIP", interfaceDevice.InterfaceIP)
          setValue("InterfacePort", interfaceDevice.InterfacePort)
          setValue("InterfaceUsername", interfaceDevice.UserName)
          setValue("InterfacePassword", interfaceDevice.Password)
          setValue("LiveRTSPURL", interfaceDevice.LiveRTSPURL)
          setRtspValue(interfaceDevice.LiveRTSPURL)
          setValue("PlaybackRTSPURL", interfaceDevice.PlaybackRTSPURL)
          setPlaybackValue(interfaceDevice.PlaybackRTSPURL)
          setValue("EntityTypeID",interfaceDevice.EntityTypeID)
          setValue("CCTVType",interfaceDevice.CCTVType)
          setIsInterfaceEdit(false);
          clearInterfaceFields();
          if (interfaceDevice.IsTwoWayEnabled == "1") {
            setValue("InterfaceTwoWayEnabled", 1);
            setParentIsEnablingTwoWayInterface(true);
          } else {
            setValue("InterfaceTwoWayEnabled", 0);
            setParentIsEnablingTwoWayInterface(false);
          }

          if (interfaceDevice.CheckPlaybackUTCTime == "1") {
            setValue("PlaybackTimeStampsToUTC", 1);
            setParentIsEnablingPlaybackTimeStampsToUTC(true);
          } else {
            setValue("PlaybackTimeStampsToUTC", 0);
            setParentIsEnablingPlaybackTimeStampsToUTC(false);
          }

          if (interfaceDevice.CheckPlaybackLongTime == "1") {
            setValue("PlaybackLongDateFormat", 1);
            setParentIsEnablingPlaybackLongDateFormat(true);
          } else {
            setValue("PlaybackLongDateFormat", 0);
            setParentIsEnablingPlaybackLongDateFormat(false);
          }


         
        // else {
        // }
        if (interfaceDevice.CheckInternalHooter== "1") {
          setValue("CheckInternalHooter", 1);
          setParentIsEnablingInternalHooter(true);
        } else {
          setValue("CheckInternalHooter", 0);
          setParentIsEnablingInternalHooter(false);
        }

        if (interfaceDevice.CheckExternalHooter == "1") {
          setValue("InterfaceExternalHooter", 1);
          setParentIsEnablingExternalHooter(true);
        } else {
          setValue("InterfaceExternalHooter", 0);
          setParentIsEnablingExternalHooter(false);
        }

        if (interfaceDevice.IsHooterEnabled == "1") {
          setValue("IsHooterEnabled", 1);
        setParentPanelIsEnablingHooter (true);
        } else {
          setValue("IsHooterEnabled", 0);
          setParentPanelIsEnablingHooter(false);
        }
      }
        else {
          setIsInterfaceEdit(true);
        }
      })
    }
  }

  function isDataChangedDevice() {
    return (
      getValues("SiteID") != selectedDevice.SiteID ||
      getValues("cameraName") != selectedDevice.CameraName ||
      getValues("privateIPAddress") != selectedDevice.privateIPAddress ||
      getValues("communicationPort") != selectedDevice.communicationPort ||
      getValues("httpPort") != selectedDevice.httpPort ||
      getValues("userName") != selectedDevice.userName ||
      getValues("userName") != selectedDevice.camUserName ||
      getValues("password") != selectedDevice.password ||
      getValues("password") != selectedDevice.camPassword ||
      getValues("port") != selectedDevice.port ||
      getValues("port") != selectedDevice.camPort ||
      getValues("channelNumber") != selectedDevice.channelNum ||
      // getValues("playbackChannelNumber") != selectedDevice.playbackChannelNum ||
      getValues("liveStreamTypeNumber") != selectedDevice.SubTypeLive ||
      getValues("playbackStreamTypeNumber") != selectedDevice.SubTypePlayback ||
      getValues("rTSPURL") != selectedDevice.RTSPURL ||
      getValues("location") != selectedDevice.Location ||
      getValues("ParentCameraID") != selectedDevice.ParentCameraID ||
      getValues("CameraBrandId") != selectedDevice.cameraBrandId ||

      getValues("CCTVTypeID") != selectedDevice.CCTVTypeID 

    );
  }

  const onCancelDevice = (e) => {
    console.log("onCancelDevice", )
    e.stopPropagation();
    if (isDataChangedDevice()) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to cancel the change made?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#027aaa",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Leave!",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          setValue("SiteID", selectedDevice.SiteID)
          setValue("cameraName", selectedDevice.CameraName)
          setValue("privateIPAddress", selectedDevice.privateIPAddress)
          setValue("communicationPort", selectedDevice.communicationPort)
          setValue("httpPort", selectedDevice.httpPort)
          setValue("userName", selectedDevice.userName || selectedDevice.camUserName)
          setValue("password", selectedDevice.password || selectedDevice.camPassword)
          setValue("port", selectedDevice.port || selectedDevice.camPort)
          setValue("channelNumber", selectedDevice.channelNum)
          // setValue("playbackChannelNumber", selectedDevice.playbackChannelNum)

          let tempCameraBrandId = devicesmasterlist?.cameraTypeMasterList?.filter(item => {
            return item.brandId == selectedDevice.cameraBrandId || selectedDevice.CameraBrandId
          })
          let cameraBrandName = "";
          if(tempCameraBrandId != undefined){
            cameraBrandName = tempCameraBrandId[0]?.brandName
          }else{
            cameraBrandName = "";
          }
          console.log("rupesh clicks on specific device", cameraBrandName)

          //Old
          // if(cameraBrandName == "Hikvision"){
          //   let liveSubType = selectedDevice.SubTypeLive == 1 ? "Main" : selectedDevice.SubTypeLive == 2 ? "Sub" : ""
          //   setValue("liveStreamTypeNumber", liveSubType)
          //   let playbackSubType = selectedDevice.SubTypePlayback == 1 ? "Main" : selectedDevice.SubTypePlayback == 2 ? "Sub" : ""
          //   setValue("playbackStreamTypeNumber", playbackSubType)
          // }else{
          //   let liveSubType = selectedDevice.SubTypeLive == 0 ? "Main" : selectedDevice.SubTypeLive == 1 ? "Sub" : ""
          //   setValue("liveStreamTypeNumber", liveSubType)
          //   let playbackSubType = selectedDevice.SubTypePlayback == 0 ? "Main" : selectedDevice.SubTypePlayback == 1 ? "Sub" : ""
          //   setValue("playbackStreamTypeNumber", playbackSubType)
          // }

          //New
          if(cameraBrandName == "Hikvision" || cameraBrandName == "Hikvision-Hybrid"){
            let liveSubType = selectedDevice.SubTypeLive == "01" ? "Main" : selectedDevice.SubTypeLive == "02" ? "Sub" : selectedDevice.SubTypeLive == "03" ? "Ter" : ""
            setValue("liveStreamTypeNumber", liveSubType)
            let playbackSubType = selectedDevice.SubTypePlayback == "01" ? "Main" : selectedDevice.SubTypePlayback == "02" ? "Sub" : selectedDevice.SubTypePlayback == "03" ? "Ter" : ""
            setValue("playbackStreamTypeNumber", playbackSubType)
          }else{
            let liveSubType = selectedDevice.SubTypeLive == "0" ? "Main" : selectedDevice.SubTypeLive == "1" ? "Sub" : selectedDevice.SubTypeLive == '2' ? "Ter" : ""
            setValue("liveStreamTypeNumber", liveSubType)
            let playbackSubType = selectedDevice.SubTypePlayback == "0" ? "Main" : selectedDevice.SubTypePlayback == "1" ? "Sub" : selectedDevice.SubTypePlayback == "2" ? "Ter" : ""
            setValue("playbackStreamTypeNumber", playbackSubType)
          }
  

          setValue("rTSPURL", selectedDevice.RTSPURL && selectedDevice.RTSPURL.replace("%40", "@"))
          setValue("location", selectedDevice.Location)
          setValue("ParentCameraID", selectedDevice.ParentCameraID)
          setValue("CameraBrandId", selectedDevice.cameraBrandId)
          setValue("CCTVTypeID", selectedDevice.CCTVTypeID)
          setIsDeviceEdit(false);
          clearCameraFields();
        } else {
          setIsDeviceEdit(true);
        }
      })
    }
  }

  const pointerEvent = !isSiteEdit ? "none" : "auto"
  const cursor = !isSiteEdit ? "auto" : ""

  const addCustomer = (e) => {

    if (getValues('AddCustomerName') === '') {
      setAddCustomerError('Please Enter a valid Customer Name!')
        // validationError += "Please Enter a valid Customer Name!"
      // ErrorToast("Please Enter a valid Customer Name!")
      return
    }
    try {
      let addCustomerObj = {
        "ClientName": getValues('AddCustomerName')
      }
      addCustomerName(addCustomerObj)
        .then((resp) => {
          if (resp != undefined) {
            if (resp.status == "ok") {
              // setCountry(resp.message)
              SuccessToast("New customer name added!")
              getCliets()
              setValue('AddCustomerName', '')
              setShow(false)
            }
          }
        })
    } catch (error) {
      console.error(error)
    }
  }

  const handleCountryChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const selectedCountryValue = selectedOption.value;
    const selectedCountryValueId = selectedOption.getAttribute('id');
    try {
      getStateDetails(selectedCountryValueId).then((resp) => {
        if (resp != undefined) {
          if (resp.status == "ok") {
            setCountryState(resp.message)
          } else {
            setCountryState(null)
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }
 

  // const handleSiteTypeChange = (e) => {
  //   debugger
  //    const selectedOption = e.target.options[e.target.selectedIndex];
  //    const selectedSiteValue = selectedOption.value;
  //    const selectedSiteValueId = selectedOption.getAttribute('id');
  //   try {
  //     Getsitetypelist(selectedSiteValueId).then((resp) => {
  //       debugger
  //       if (resp != undefined) {
  //         if (resp.status == "ok") {
  //           setSiteTypes(resp.message)
  //         } else {
  //           setSiteTypes(null)
  //         }
  //       }
  //     })
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }
const interfaceSubTypeHandler = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const selectedInterfaceType = selectedOption.value;
    const selectedInterfaceTypeId = selectedOption.getAttribute('id');
  try {
    getInterfaceSubTypeData(selectedInterfaceTypeId).then((resp) => {
      if (resp != undefined) {
        if (resp.Status == "success") {
          setInterfaceSubTypeData(resp.Data)
          if (selectedInterfaceTypeId == 1) {
            setInterfaceType(NVRDVR)
            setDisplayTwoWay(true)
            // setDisplayHooter(false)
            setEnableHooter(false)
            setValue("CCTVTypeIDInterface", 0);
            setDisplayEntity(true)
          } else {
            setInterfaceType(PANEL)
            setDisplayTwoWay(false)
            setDisplayCamera(false)
            //setDisplayHooter(true)
            setEnableHooter(true)
            setDisplayEntity(false);
          }
        } else {
          setInterfaceSubTypeData(null)
        }
      }
    })
  } catch (error) {
    console.error(error)
  }
}

  useEffect(() => {
    console.log("rupesh clicks on specific device", selectedDevice)
    console.log("checkselectedDevice1", interfaceSubTypeData)
    if (selectedDevice && selectedDevice != null) {

      let tempCameraBrandId = devicesmasterlist?.cameraTypeMasterList?.filter(item => {
        return item.brandId == selectedDevice.cameraBrandId || selectedDevice.CameraBrandId
      })
      let cameraBrandName = "";
      if(tempCameraBrandId != undefined){
        cameraBrandName = tempCameraBrandId[0]?.brandName
      }else{
        cameraBrandName = "";
      }
      console.log("rupesh clicks on specific device", cameraBrandName)

      setValue("SiteID", selectedDevice.SiteID)
        setValue("cameraName", selectedDevice.CameraName)
        setValue("privateIPAddress", selectedDevice.privateIPAddress)
        setValue("communicationPort", selectedDevice.communicationPort)
        setValue("httpPort", selectedDevice.httpPort)
        setValue("userName", selectedDevice.userName || selectedDevice.camUserName)
        setValue("password", selectedDevice.password || selectedDevice.camPassword)
        setValue("port", selectedDevice.port || selectedDevice.camPort)
        setValue("channelNumber", selectedDevice.channelNum)
        // setValue("playbackChannelNumber", selectedDevice.playbackChannelNum)
        // Old
        // if(cameraBrandName == "Hikvision"){
        //   let liveSubType = selectedDevice.SubTypeLive == 1 ? "Main" : selectedDevice.SubTypeLive == 2 ? "Sub" : ""
        //   setValue("liveStreamTypeNumber", liveSubType)
        //   let playbackSubType = selectedDevice.SubTypePlayback == 1 ? "Main" : selectedDevice.SubTypePlayback == 2 ? "Sub" : ""
        //   setValue("playbackStreamTypeNumber", playbackSubType)
        // }else{
        //   let liveSubType = selectedDevice.SubTypeLive == 0 ? "Main" : selectedDevice.SubTypeLive == 1 ? "Sub" : ""
        //   setValue("liveStreamTypeNumber", liveSubType)
        //   let playbackSubType = selectedDevice.SubTypePlayback == 0 ? "Main" : selectedDevice.SubTypePlayback == 1 ? "Sub" : ""
        //   setValue("playbackStreamTypeNumber", playbackSubType)
        // }

        //New
        let liveSubType;
        let playbackSubType;
        if(cameraBrandName == "Hikvision" || cameraBrandName == "Hikvision-Hybrid"){
          liveSubType = selectedDevice.SubTypeLive == "01" ? "Main" : selectedDevice.SubTypeLive == "02" ? "Sub" : selectedDevice.SubTypeLive == "03" ? "Ter" : ""
          setValue("liveStreamTypeNumber", liveSubType)
          playbackSubType = selectedDevice.SubTypePlayback == "01" ? "Main" : selectedDevice.SubTypePlayback == "02" ? "Sub" : selectedDevice.SubTypePlayback == "03" ? "Ter" : ""
          setValue("playbackStreamTypeNumber", playbackSubType)
        }else{
          if(cameraBrandName == "UnV" && selectedDevice.CCTVTypeID == 4){
            liveSubType = selectedDevice.SubTypeLive == "1" ? "Main" : selectedDevice.SubTypeLive == "2" ? "Sub" : selectedDevice.SubTypeLive == '3' ? "Ter" : ""
            setValue("liveStreamTypeNumber", liveSubType)
            playbackSubType = selectedDevice.SubTypePlayback == "1" ? "Main" : selectedDevice.SubTypePlayback == "2" ? "Sub" : selectedDevice.SubTypePlayback == "3" ? "Ter" : ""
            setValue("playbackStreamTypeNumber", playbackSubType)  
          }else{
            liveSubType = selectedDevice.SubTypeLive == "0" ? "Main" : selectedDevice.SubTypeLive == "1" ? "Sub" : selectedDevice.SubTypeLive == '2' ? "Ter" : ""
            setValue("liveStreamTypeNumber", liveSubType)
            playbackSubType = selectedDevice.SubTypePlayback == "0" ? "Main" : selectedDevice.SubTypePlayback == "1" ? "Sub" : selectedDevice.SubTypePlayback == "2" ? "Ter" : ""
            setValue("playbackStreamTypeNumber", playbackSubType)
          }
        }

        setValue("rTSPURL", selectedDevice.RTSPURL && selectedDevice.RTSPURL.replace("%40", "@"))
        setValue("location", selectedDevice.Location)
        setValue("ParentCameraID", selectedDevice.ParentCameraID)
        setValue("CameraBrandId", selectedDevice.cameraBrandId || selectedDevice.CameraBrandId)
        setValue("CCTVTypeID", selectedDevice.CCTVTypeID)

      if (selectedDevice.isTranscoded == "1") {
        setValue("isTranscoded", 1);
        setParentIsEncoding(true);
      } else {
        setValue("isTranscoded", 0);
        setParentIsEncoding(false);
      }

      if (selectedDevice.isPlaybackTranscoded == "1") {
        setValue("isPlaybackTranscoded", 1);
        setParentIsPlaybackEncoding(true);
      } else {
        setValue("isPlaybackTranscoded", 0);
        setParentIsPlaybackEncoding(false);
      }

      if (selectedDevice.isTwoWayEnabled == "1") {
        setValue("isTwoWayEnabledDevice", 1);
        setParentIsEnablingTwoWayDevice(true);
      } else {
        setValue("isTwoWayEnabledDevice", 0);
        setParentIsEnablingTwoWayDevice(false);
      }
      // setValue("isTranscoded", selectedDevice.isTranscoded);
    }
    handleRefClick();
  }, [selectedDevice]);
  console.log("rupesh checks cameradeviceslist Device", cameradeviceslist)

  useEffect(() => {
    //console.log("checkdevicemasterlist.cameraMasterList",devicesmasterlist.cameraTypeMasterList)
    console.log("Update rtsp");
    if (watch("CameraBrandId") && watch("privateIPAddress") && watch("userName") && watch("password") && watch("port") && watch("channelNumber") && watch("liveStreamTypeNumber")) {
      var channelState;
      let liveStreamType = 0;
      
      setIsChannelNumberEdited(prevState => {
        console.log("rupesh checks prevState", prevState)
        channelState = prevState;
        return prevState;
      });

      let tempCameraBrandId = devicesmasterlist.cameraTypeMasterList.filter(item => {
        return item.brandId == getValues("CameraBrandId")
      })
      let cameraBrandName = tempCameraBrandId[0].brandName
      console.log("checkcameraBrandName", cameraBrandName)
      console.log("LiveStreamType", getValues("liveStreamTypeNumber"))
      console.log("Update rtsp", cameraBrandName);
      console.log("Update rtsp", getValues("liveStreamTypeNumber"));
      if(cameraBrandName == "Hikvision" || cameraBrandName == "Hikvision-Hybrid"){
        // liveStreamType = getValues("liveStreamTypeNumber") == "Main" ? 1 : 2
        liveStreamType = getValues("liveStreamTypeNumber") == "Main" ? "01" : getValues("liveStreamTypeNumber") == "Sub" ? "02" : "03"
        console.log("LiveStreamType", liveStreamType)
      }else{
        // liveStreamType = getValues("liveStreamTypeNumber") == "Main" ? 0 : 1
        liveStreamType = getValues("liveStreamTypeNumber") == "Main" ? "0" : getValues("liveStreamTypeNumber") == "Sub" ? "1" : "2"
        console.log("LiveStreamType", liveStreamType)
      }

      let temp = "";
      if (cameraBrandName == "Hikvision") {
        temp =
          "rtsp://" +
          getValues("userName") +
          ":" +
          getValues("password") +
          "@" +
          getValues("privateIPAddress") +
          ":" +
          getValues("port") +
          "/Streaming/Channels/" +
          getValues("channelNumber") +
          liveStreamType;
      } else if (cameraBrandName == "CP Plus" || cameraBrandName == "Dahua") {
        temp =
          "rtsp://" +
          getValues("userName") +
          ":" +
          getValues("password") +
          "@" +
          getValues("privateIPAddress") +
          ":" +
          getValues("port") +
          "/cam/realmonitor?channel=" +
          getValues("channelNumber") +
          "&subtype=" + liveStreamType; //+getValues("channelNumber")
          console.log("cpplusTemp", temp);
      } else if (cameraBrandName == "Sparsh") {
        temp =
          "rtsp://" +
          getValues("userName") +
          ":" +
          getValues("password") +
          "@" +
          getValues("privateIPAddress") +
          ":" +
          getValues("port") +
          "/avstream/channel=" +
          getValues("channelNumber") +
          "/stream=" + liveStreamType + ".sdp";
      } else if (cameraBrandName == "DLink") {
        //=> rtsp://admin:Admin@123@122.170.105.219:554/avstream/channel=1/stream=0.sdp
        temp =
          "rtsp://" +
          getValues("userName") +
          ":" +
          getValues("password") +
          "@" +
          getValues("privateIPAddress") +
          ":" +
          getValues("port") +
          "/avstream/channel=" +
          getValues("channelNumber") +
          "/stream=" + liveStreamType + ".sdp";
        // => rtsp://admin:Admin@@12k@102.168.0.1:7001/avstream/channel=1/stream=0.sdp
      } else if (cameraBrandName == "UnV") {
        console.log("Update rtsp", cameraBrandName);
        console.log("checkselectedDevice", selectedDevice.CCTVTypeID)
        if (selectedDevice.CCTVTypeID == "4") {
          liveStreamType = getValues("liveStreamTypeNumber") == "Main" ? "1" : getValues("liveStreamTypeNumber") == "Sub" ? "2" : "3"
          temp =
            "rtsp://" +
            getValues("userName") +
            ":" +
            getValues("password") +
            "@" +
            getValues("privateIPAddress") +
            ":" +
            getValues("port") +
            "/media/video" +
            liveStreamType;
            // getValues("channelNumber");
            console.log("UnVTemp", temp);
        } else {
          temp =
            "rtsp://" +
            getValues("userName") +
            ":" +
            getValues("password") +
            "@" +
            getValues("privateIPAddress") +
            ":" +
            getValues("port") +
            "/unicast/c" +
            getValues("channelNumber") +
            "/s" + liveStreamType + "/live";
        }
        console.log("Update rtsp", temp);
      } else if (cameraBrandName == "Unknown") {
        // "rtsp://[Username]:[Password]@[IpAddress]:[Port]/Streaming/channels/[ChannelNo]"
        let link = cameradeviceslist[0].LiveRTSPURL
        console.log("rupesh checks link", link)
        temp = link

        const replacements = {
          "[Username]": getValues("userName"),
          "[Password]": getValues("password").replaceAll("@", "%40"),
          "[IpAddress]": getValues("privateIPAddress"),
          "[Port]": getValues("port"),
          "[ChannelNo]": getValues("channelNumber"),
          "[LiveStreamType]": liveStreamType
        };

        for (const [placeholder, value] of Object.entries(replacements)) {
        temp = temp.split(placeholder).join(value);
          // console.log("rupesh checks placeholder replacements",temp)
        }
        setUnknownBrandUrl(temp)

      } else if (cameraBrandName == "Raysharp") {
        // =>rtsp://admin:admin123.@73.14.118.111:3311/rtsp/streaming?channel=01&subtype=0 ---new
        //rtsp://admin:admin123.@73.14.118.111:3311/rtsp/streaming?channel=01&subtype=1 --new
        temp =
           "rtsp://" +
          getValues("userName") +
          ":" +
          getValues("password") +
          "@" +
          getValues("privateIPAddress") +
          ":" +
          getValues("port") +
          "/rtsp/streaming?channel=" +
          getValues("channelNumber") +
          "&subtype=" + liveStreamType;



//  => "rtsp://192.168.1.100:554/rtsp/streaming?channel=1&subtype=0&token=123"---old 
        // temp =
        //   "rtsp://" +
        //   getValues("userName") +
        //   ":" +
        //   getValues("password") +
        //   "@" +
        //   getValues("privateIPAddress") +
        //   ":" +
        //   getValues("port") +
        //   "/rtsp/streaming?channel=" +
        //   getValues("channelNumber") +
        //   "&subtype=" + liveStreamType + "&token=123";

      }
else if (cameraBrandName == "Hikvision-Hybrid") {
  temp =
          "rtsp://" +
          getValues("userName") +
          ":" +
          getValues("password") +
          "@" +
          getValues("privateIPAddress") +
          ":" +
          getValues("port") +
          "/Streaming/Channels/" +
          getValues("channelNumber") +
          liveStreamType;

}

      // console.log("Update rtsp", selectedDevice);
      // console.log("Update rtsp", selectedDevice.RTSPURL);
      console.log("Update rtsp", selectedDevice.SubTypeLive);
      console.log("Update rtsp", liveStreamType);
      if (selectedDevice.RTSPURL === undefined) {
        //add condition
        setValue("rTSPURL", temp?.replace(/%40/g, "@").replace(/%38/g, "&").replace(/%26/g, "&"))
        setrtspURLError('')
      } else if (selectedDevice.RTSPURL !== temp ) {
        console.log("Update rtsp", selectedDevice.SubTypeLive);
        console.log("Update rtsp", liveStreamType);
        setValue("rTSPURL", temp?.replace(/%40/g, "@").replace(/%38/g, "&").replace(/%26/g, "&"))
        setrtspURLError('')
        // if (isChannelNumberEdited == true) {
        //   //edit condition
        //   setValue("rTSPURL", temp?.replace(/%40/g, "@").replace(/%38/g, "&").replace(/%26/g, "&"))
        //   setrtspURLError('')
        // } else {
        //   //fetched(for custom rtsp )
        //   setValue("rTSPURL", (selectedDevice.RTSPURL)?.replace(/%40/g, "@").replace(/%38/g, "&").replace(/%26/g, "&"))
        //   setrtspURLError('')
        // }
      }else{
        setValue("rTSPURL", temp?.replace(/%40/g, "@").replace(/%38/g, "&").replace(/%26/g, "&"))
        setrtspURLError('')
      }
    } else {
      console.log("rupesh clicks on specific device else part")
      // setValue("rTSPURL", "")
    }
  }, [watch("CameraBrandId"), watch("privateIPAddress"), watch("userName"), watch("password"), watch("port"), watch("channelNumber"), watch("liveStreamTypeNumber"), isChannelNumberEdited]);

  function resetrtspurl(e) {
    try {
      if (getValues("CameraBrandId") == 2) {
        e.value =
          "rtsp://" +
          getValues("userName") +
          ":" +
         // getValues("password").replace("@", "%40") +
         getValues("password")+
          "@" +
          getValues("privateIPAddress") +
          ":" +
          getValues("port") +
          "/Streaming/Channels/" +
          getValues("channelNumber");
      } else if (getValues("CameraBrandId") == 1 || getValues("CameraBrandId") == 4) {
        e.value =
          "rtsp://" +
          getValues("userName") +
          ":" +
          // getValues("password").replace("@", "%40") +
          getValues("password")+
          "@" +
          getValues("privateIPAddress") +
          ":" +
          getValues("port") +
          "/cam/realmonitor?channel=" +
          getValues("channelNumber") +
          "&subtype=1"; //+getValues("channelNumber")
      } else if (getValues("CameraBrandId") == 3) {
        e.value =
          "rtsp://" +
          getValues("userName") +
          ":" +
          // getValues("password").replace("@", "%40") +
          getValues("password")+
          "@" +
          getValues("privateIPAddress") +
          ":" +
          getValues("port") +
          "/avstream/channel=" +
          getValues("channelNumber") +
          "/stream=0.sdp";
      } else {
        e.placeholder = "Select brand name first";
      }
    } catch (error) {
      console.error(error);
    }
  }
  const handleOptionChange = (option) => {
    setSelectedBrand(option);
    // Determine the placeholder for the next input based on the selected option
    if (+option === 1) {
      setChannelNumberPlaceholder("Channel Number* e.g. 101");
    } else if (+option === 2) {
      setChannelNumberPlaceholder("Channel Number* e.g. 1");
    } else {
      setChannelNumberPlaceholder("Channel Number* e.g. 1");
    }
  };


  const onNumberOnlyChange = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValid = new RegExp("[0-9]").test(keyValue);
    if (!isValid) {
      event.preventDefault();
      return;
    }
  };

  const onSpecialChangeOnly = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValid = new RegExp("^[A-Za-z0-9.\\-_ ]*$").test(keyValue);
    if (!isValid) {
      event.preventDefault();
      return;
    }
  }


  const onNumberOnlyChange2 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValid = new RegExp("[0-9.]").test(keyValue);
    if (!isValid) {
      event.preventDefault();
      return;
    }
  };

  const onAlphanumericRegex = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValid = new RegExp("^[a-zA-Z0-9\\s_-]$").test(keyValue);
    if (!isValid) {
      event.preventDefault();
      return;
    }
  };

 /*  const handleInputChange = (event) => {
    const newValue = event.target.value;

    if (newValue !== inputValue) {
      setIsChannelNumberEdited(false);
    } else {
      setIsChannelNumberEdited(true);
    }

    // setInputValue(newValue);
  }; */

  const changeTab = (e, type) => {
    switch (type) {
      case SITES:
        setSearchTextSite('');
        break;

      case INTERFACES:
        setSearchTextInterface('');
        break;

      case DEVICES:
        setSearchTextDevice('');
        break;
    }
    setActiveTab(type)
  }

  useEffect(() => {
    if (site.SiteID != 0) {
      setInterfaceTab(false);
    } else {
      setInterfaceTab(true);
    }
  }, [site])

  useEffect(() => {
    if (interfaceDevice.InterfaceId != 0 && site.SiteID != 0) {
      setDisableTab(false);
    } else {
      setDisableTab(true);
    }
  }, [interfaceDevice, site])

  useEffect(() => {
    if (selectedDevice && selectedDevice != null) {
      setValue("PanelMasterId", selectedDevice.panelMasterId || selectedDevice.PanelMasterId || selectedDevice.cameraBrandId) ||
        setValue("SiteID", selectedDevice.SiteID) ||
        setValue("privateIPAddress", selectedDevice.privateIPAddress) ||
        setValue("communicationPort", selectedDevice.communicationPort) ||
        setValue("httpPort", selectedDevice.httpPort) ||
        // setValue("cameraName", selectedDevice.CameraName || selectedDevice.cameraName) 
        setValue("CCTVTypeID", selectedDevice.CCTVTypeID);
        if(interfaceType === NVRDVR){
          setValue("cameraName", selectedDevice.CameraName)  
        }else{
          setValue("cameraName", selectedDevice.CameraName || selectedDevice.cameraName) 
        }
    }
  }, [selectedDevice]);

  const [displayTwoWay, setDisplayTwoWay] = useState(false);
  //const [displayHooter, setDisplayHooter] = useState(false);
  const [displayCamera, setDisplayCamera] = useState(false);
  const [enableHooter,setEnableHooter]=useState(false);
  const [disabledEntity,setDisabledEntity]=useState(false);
  const [displayEntity,setDisplayEntity]=useState(false);

  const [displayLivePlaybackfield, setDisplayLivePlaybackfield] = useState(false);

  const [rtspValue, setRtspValue] = useState("");
  const [playbackValue, setPlaybackValue] = useState("");
  const [rtspValueEntered, setRtspValueEntered] = useState("");
  const [playbackValueEntered, setPlaybackValueEntered] = useState("");
  const RTSP = [
    {
      id: "Username",
      display: "[Username]",
    },
    {
      id: "Password",
      display: "[Password]",
    },
    {
      id: "IpAddress",
      display: "[IpAddress]",
    },
    {
      id: "Port",
      display: "[Port]",
    },
    {
      id: "ChannelNo",
      display: "[ChannelNo]",
    },
    {
      id: "LiveStreamType",
      display: "[LiveStreamType]",
    },
  ];

  const PLAYBACK = [
    {
      id: "Username",
      display: "[Username]",
    },
    {
      id: "Password",
      display: "[Password]",
    },
    {
      id: "IpAddress",
      display: "[IpAddress]",
    },
    {
      id: "Port",
      display: "[Port]",
    },
    {
      id: "ChannelNo",
      display: "[ChannelNo]",
    },
    {
      id: "PlaybackStreamType",
      display: "[PlaybackStreamType]",
    },
  /*   {
      id: "StartDate",
      display: "[StartDate]",
    },
    {
      id: "EndDate",
      display: "[EndDate]",
    },
    {
      id: "DateFormat",
      display: "[DateFormat]",
    }, */
    {
      id: "DateDD",
      display: "[DD]",
    },
    {
      id: "DateMM",
      display: "[MM]",
    },
    {
      id: "DateYY",
      display: "[YY]",
    },
    {
      id: "DateYYYY",
      display: "[YYYY]",
    },
    {
      id: "HoursHH",
      display: "[hh]",
    },
    {
      id: "MinutesMM",
      display: "[mm]",
    },
    {
      id: "SecondsSS",
      display: "[ss]",
    },
  ];
// Refresh
  console.log("CCTVTypeIDInterface", getValues('CCTVTypeIDInterface'));
  useEffect(() => {
    console.log("checkinterfaceDevice",interfaceDevice)
    setValue("InterfaceName", interfaceDevice.InterfaceName);
    setValue("InterfaceType", interfaceDevice.Interfacetypename || interfaceDevice.InterfaceType);
    setValue("InterfaceSubType", interfaceDevice.Interfacesubtypename || interfaceDevice.InterfaceSubType);
    setValue("EntityTypeID",interfaceDevice.EntityTypeID || interfaceDevice.InterfaceType);
    setValue("InterfaceIP", interfaceDevice.InterfaceIP);
    setValue("InterfacePort", interfaceDevice.InterfacePort);
    setValue("InterfaceUsername", interfaceDevice.UserName);
    setValue("InterfacePassword", interfaceDevice.Password);
    setValue("LiveRTSPURL", interfaceDevice.LiveRTSPURL);
    setRtspValueEntered(interfaceDevice.LiveRTSPURL)
    setRtspValue(interfaceDevice.LiveRTSPURL)
    setValue("PlaybackRTSPURL", interfaceDevice.PlaybackRTSPURL);
    setPlaybackValue(interfaceDevice.PlaybackRTSPURL)
    setPlaybackValueEntered(interfaceDevice.PlaybackRTSPURL)
    setValue("", interfaceDevice.InterfaceId);
    setValue("CCTVTypeIDInterface",interfaceDevice.CCTVTypeIDInterface);
    setValue("CCTVType", interfaceDevice.CCTVType);
    // setValue("", interfaceDevice.InterfacetypeId);
    // setValue("", interfaceDevice.InterfacesubtypeId);
    // setValue("", interfaceDevice.siteid);
    // setValue("InterfaceTwoWayEnabled", interfaceDevice.InterfaceTwoWayEnabled);
    if (interfaceDevice.IsTwoWayEnabled == "1") {
      setValue("InterfaceTwoWayEnabled", 1)
      setParentIsEnablingTwoWayInterface(true);
    } else {
      setValue("InterfaceTwoWayEnabled", 0)
      setParentIsEnablingTwoWayInterface(false);
    }

    if (interfaceDevice.CheckPlaybackUTCTime == "1") {
      setValue("PlaybackTimeStampsToUTC", 1)
      setParentIsEnablingPlaybackTimeStampsToUTC(true);
    } else {
      setValue("PlaybackTimeStampsToUTC", 0)
      setParentIsEnablingPlaybackTimeStampsToUTC(false);
    }

    if (interfaceDevice.CheckPlaybackLongTime == "1") {
      setValue("PlaybackLongDateFormat", 1)
      setParentIsEnablingPlaybackLongDateFormat(true);
    } else {
      setValue("PlaybackLongDateFormat", 0)
      setParentIsEnablingPlaybackLongDateFormat(false);
    }

    if (interfaceDevice.CheckInternalHooter == "1") {
      setValue("CheckInternalHooter", 1)
      setParentIsEnablingInternalHooter(true);
    } else {
      setValue("CheckInternalHooter", 0)
      setParentIsEnablingInternalHooter(false);
    }

    if (interfaceDevice.CheckExternalHooter == "1") {
      setValue("CheckExternalHooter", 1)
      setParentIsEnablingExternalHooter(true);
    } else {
      setValue("CheckExternalHooter", 0)
      setParentIsEnablingExternalHooter(false);
    }

    if (interfaceDevice.IsHooterEnabled == "1") {
      setValue("IsHooterEnabled", 1)
      setParentPanelIsEnablingHooter(true);
    } else {
      setValue("IsHooterEnabled", 0)
      setParentPanelIsEnablingHooter(false);
    }


    if (interfaceDevice.InterfaceId == 0) {
      // setDisplayHooter(false)
      setEnableHooter(false)
      setDisplayCamera(false)
      setDisplayLivePlaybackfield(false)
      setDisabledEntity(false)
    }
    else {
      setDisabledEntity(true)
    }
    if (interfaceDevice.InterfaceType == 0 || interfaceDevice.Interfacetypename == "CCTV") {
      setInterfaceType(NVRDVR);
      setBulkUpload(false);
    } else if (interfaceDevice.Interfacetypename == "Alarm Panel") {
      setInterfaceType(PANEL);
      setBulkUpload(false);
      //setDisplayHooter(true);
    } else {
      setInterfaceType(NVRDVR);
      setBulkUpload(false);
      //setDisplayHooter(true);
    }
console.log("checkINterfacetype",interfaceDevice.CCTVType)
    if (interfaceDevice.CCTVType == "Camera") {
      if (interfaceDevice.Interfacesubtypename == "Unknown") {
        setDisplayCamera(false)
        setEnableHooter(false)
      } else {
        setDisplayCamera(true)
        setEnableHooter(false)
      }
    } else if (interfaceDevice.EntityTypeID == 0 && interfaceDevice.Interfacetypename == "Alarm Panel") {
      setDisplayCamera(false)
      setEnableHooter(true)
    }
    else if (interfaceDevice.EntityTypeID == 0) {
      setDisplayCamera(false)
      setEnableHooter(false)
    }
    else if(interfaceDevice.Interfacesubtypename == "Unknown"){
      setDisplayCamera(false)
      setEnableHooter(false)
    }
    else {
      setDisplayCamera(false)
      setEnableHooter(true)
      //setEnableHooter(false)
    }
    
    if (interfaceDevice.Interfacetypename == "CCTV") {
      if (interfaceDevice.Interfacesubtypename == "Unknown") {
        setDisplayTwoWay(false);
        setDisplayEntity(true);
      } else {
        setDisplayTwoWay(true);
        setDisplayEntity(true);
      }
    } else {
      setDisplayTwoWay(false);
      setDisplayEntity(false);
    }

    // if (interfaceDevice.InterfacetypeId == 2) {
    //   setDisplayHooter(true);
    // }else{
    //   setDisplayHooter(false);
    // }

  }, [interfaceDevice])

  useEffect(() => {
    setValue("InterfaceSubType", interfaceDevice.Interfacesubtypename || interfaceDevice.InterfaceSubType);

    // this is for demo/unknown
    if (interfaceDevice.Interfacesubtypename == "Unknown") {
      setDisplayLivePlaybackfield(true);
      //  setDisplayHooter(false)
      //  setDisplayTwoWay(false);
    } else {
      setDisplayLivePlaybackfield(false);
      //  setDisplayHooter(true)
      //  setDisplayTwoWay(true);
    }

  },[interfaceSubTypeData])

  useEffect(()=>{
    setValue("EntitySubtype",interfaceDevice.EntitySubtype || interfaceDevice.InterfaceType);
  },[cctvTypeData])

  useEffect(() => {
    if (watch('InterfaceType') == "Alarm Panel") {
      setInterfaceType(PANEL);
      setBulkUpload(false);
    } else {
      setInterfaceType(NVRDVR);
      setBulkUpload(false);
    }
  }, [watch('InterfaceType')])
  // const InterfaceNameRegex = (e) => {
  //   // let tempValue = e.target.value.replace(/[^\w]/gi, "");
  //   let tempValue = e.target.value.replace(/^[a-zA-Z0-9\s_-]*$/, "");
  //   if(!/[0-9a-zA-Z]/.test(e.key)){
  //       e.preventDefault();
  //   }else{
  //       setValue("InterfaceName",tempValue);
  //   }
  // }

  useEffect(() => {
    console.log("SiteModeID",watch('SiteModeID'));
  }, [watch('SiteModeID')]);
  
  /* const liveEmailHandler = (e, newValue, newPlainTextValue) => {
    console.log("rupesh checks liveEmailHandler", e)
    console.log("rupesh checks liveEmailHandler", newValue)
    console.log("rupesh checks liveEmailHandler", newPlainTextValue)
  } */

  const handleClose = () => {
    setShow(false)
    setAddCustomerError('')
  }
 return (
    <div className="">

      {/* <div id="crumbs">
              <ul>
                <li><a href="#1"><i aria-hidden="true"></i></a></li>
                <li onClick={(e) => changeTab(e, SITES)}><a className={`${activeTab === SITES ? "breadcrumSelected" : ""}`} >Site</a></li>
                <li onClick={(e) => changeTab(e, INTERFACES)}><a className={`${activeTab === INTERFACES ? "breadcrumSelected" : ""}`} >Interface</a></li>
                <li onClick={(e) => changeTab(e, DEVICES)}><a style={disableTab ? { pointerEvents: 'none' } : { }} className={`${activeTab === DEVICES ? "breadcrumSelected" : ""}`} >Device</a></li>
              </ul>
            </div> */}

      {/* <div className="">
              <ul className="progressbar">
                <li className="" ><div className="stepperIconContainer progressdisable" onClick={(e) => changeTab(e, SITES)}><img src={SiteDisable} /></div><span className="StepperTextDisable">Site</span></li>
                <li className="" ><div className="stepperIconContainer" onClick={(e) => changeTab(e, INTERFACES)}><img src={InterfaceDefault} width={18}/></div><span className="stepperText">Interface</span></li>
                <li className="active"><div className="stepperIconContainer progressactive" onClick={(e) => changeTab(e, DEVICES)}> <img src={DevicesSelected} width={17}/></div><span className="stepperText">Devices</span></li>
              </ul>
            </div> */}

      {/* <div className="">
        <ul className="progressbar">
          <li className="active" >
            <div className={`${activeTab === SITES ? "stepperIconContainer progressactive" : "stepperIconContainer"}`} onClick={(e) => changeTab(e, SITES)}>
              <img src={activeTab === SITES ? SiteSelected : SiteDefault} />
            </div>
            <span className="stepperText">Site</span>
          </li>
          <li className="active" >
            <div className={`${activeTab === INTERFACES ? "stepperIconContainer progressactive" : "stepperIconContainer"}`} onClick={(e) => changeTab(e, INTERFACES)}>
              <img src={activeTab === INTERFACES ? InterfaceSelected : InterfaceDefault} width={18} />
            </div>
          <span className="stepperText">Interface</span>
          </li>
          <li className="active">
            <div className={`${activeTab === SITES && disableTab ? "stepperIconContainer progressdisable" : activeTab === DEVICES ? "stepperIconContainer progressactive" : "stepperIconContainer"}`} onClick={(e) => {
              if(disableTab){
                return
              }else{
                changeTab(e, DEVICES) 
              }
            }}> 
            <img src={activeTab === SITES && disableTab ? DevicesDisable : activeTab === DEVICES ? DevicesSelected : DevicesDefault} width={17} />
          </div>
          <span className={`${disableTab ? "StepperTextDisable" : "stepperText"}`}>Devices</span>
          </li>
        </ul>
      </div> */}

      <div className="">
        <ul className="progressbar">
          <li className="active" >
            <div className={`${activeTab === SITES ? "stepperIconContainer progressactive" : "stepperIconContainer"}`}
              onClick={(e) => {
                setFilteredSites(sites)
                changeTab(e, SITES)
                setInterfaceDataList(null)
                setcameradeviceslist(null)
                setpaneldeviceslist(null)
                let tempdeviceObj = {
                  "ParentCameraID": 0,
                  "cameraName": '',
                  "privateIPAddress": '',
                  "SiteID": 0,
                  "userName": '',
                  "password": '',
                  "port": '',
                  "channelNumber": '',
                  // "playbackChannelNumber": '',
                  "liveStreamTypeNumber": '',
                  "playbackStreamTypeNumber": '',
                  "rTSPURL": '',
                  "location": '',
                  "PanelMasterId": 0,
                  "CameraBrandId": 0,
                  "communicationPort" : '',
                  "httpPort" : '',
                  "isTranscoded" : '',
                  "isPlaybackTranscoded" : '',
                  "isTwoWayEnabledDevice" : '',
                  "interfaceId" : '',
                  "CCTVTypeID": 0
              }
              setSelectedDevice(tempdeviceObj);
              handleRefClick();
              let interfaceObjTemp = {
                "siteid": 0,
                "InterfaceId": 0,
                "InterfaceName": '',
                "InterfaceType": 0,
                "InterfaceSubType": 0,
                "InterfaceIP": '',
                "InterfacePort": '',
                "InterfaceUsername": '',
                "InterfacePassword": '',
                "UserId": 0,
                "EntityTypeID": 0,
                "CCTVTypeIDInterface": 0,
                "InterfaceTwoWayEnabled": '',
                "CheckInternalHooter": 0,
                "CheckExternalHooter": 0,
                "IsHooterEnabled": 0,
                "PlaybackTimeStampsToUTC": '',
                "PlaybackLongDateFormat": '',
                "LiveRTSPURL": '',
                "PlaybackRTSPURL": '',
                "CCTVType": ''
              }
              setSelectedInterface(interfaceObjTemp);
              clearInterfaceFields();
              // clearCameraFields();
              }}>
              <img src={activeTab === SITES ? SiteSelected : SiteDefault} />
            </div>
            <span className="stepperText">
              Site <span style={{color: "#8f8e8e", fontSize:"12px"}}>({filteredSites?.length})</span>
            </span>
          </li>
          <li className="active" >
            <div
              className={`${activeTab === SITES && interfaceTab ? "stepperIconContainer progressdisable" : activeTab === INTERFACES ? "stepperIconContainer progressactive" : "stepperIconContainer"}`}
              onClick={(e) => {
                if (interfaceTab) {
                  // WarningToast("Please select a site or add a site.");
                  return
                } else {
                  getInterfaceData()
                  changeTab(e, INTERFACES)
                  if(interfaceDevice.InterfaceId == 0){
                    setIsInterfaceEdit(true)
                  }else{
                    setIsInterfaceEdit(false)
                  }
                  setcameradeviceslist(null)
                  setpaneldeviceslist(null)
                  let tempdeviceObj = {
                    "ParentCameraID": 0,
                    "cameraName": '',
                    "privateIPAddress": '',
                    "SiteID": 0,
                    "userName": '',
                    "password": '',
                    "port": '',
                    "channelNumber": '',
                    // "playbackChannelNumber": '',
                    "liveStreamTypeNumber": '',
                    "playbackStreamTypeNumber": '',
                    "rTSPURL": '',
                    "location": '',
                    "PanelMasterId": 0,
                    "CameraBrandId": 0,
                    "communicationPort" : '',
                    "httpPort" : '',
                    "isTranscoded" : '',
                    "isPlaybackTranscoded" : '',
                    "isTwoWayEnabledDevice" : '',
                    "interfaceId" : '',
                    "CCTVTypeID": 0
                }
                setSelectedDevice(tempdeviceObj);
                handleInterfaceRefClick();
                clearCameraFields();
                clearSiteFields();
                  // setIsInterfaceEdit(true);
                }
              }}>
              <img
                // src={activeTab === INTERFACES ? InterfaceSelected : InterfaceDefault} 
                src={activeTab === SITES && interfaceTab ? InterfaceDisable : activeTab === INTERFACES ? InterfaceSelected : InterfaceDefault}
                alt="interface icon"
                width={18} />
            </div>
            <span className={`${interfaceTab ? "StepperTextDisable" : "stepperText"}`}>
              Interface {interfaceDataList && interfaceDataList?.length > 0 &&<span style={{color: "#8f8e8e", fontSize:"12px"}}>({interfaceDataList?.length})</span>}
            </span>

          </li>

          <li className="active">
            <div className={`${disableTab ? "stepperIconContainer progressdisable" : activeTab === DEVICES ? "stepperIconContainer progressactive" : "stepperIconContainer"}`} onClick={(e) => {
              
              if (disableTab) {
                // WarningToast("Please select a interface or add an interface.");
                return
              } else {
                if(selectedDevice.ParentCameraID != 0){
                  return;
                }
                changeTab(e, DEVICES)
                // let temp = interfaceDevice.InterfacesubtypeId;
                // if (temp === 7) {
                //   temp = 2.;
                // } else if (temp === 8) {
                //   temp = 3;
                // } else if(temp === 9){
                //   temp = 1
                // } 
                // else {
                //   temp = temp;
                // }
                // let otherTemp = 0;
                // if(interfaceDevice.InterfacetypeId == 2){
                //   //It is for Alarm Panel
                //   if(interfaceDevice.InterfacesubtypeId == 9){
                //     otherTemp = 1;
                //   }else if(interfaceDevice.InterfacesubtypeId == 10){
                //     otherTemp = 2;
                //   }else if(interfaceDevice.InterfacesubtypeId == 11){
                //     otherTemp = 3;
                //   }else if(interfaceDevice.InterfacesubtypeId == 12){
                //     otherTemp = 4;
                //   }else{
                //     otherTemp = 5;
                //   }
                // }
                let temp = 0, otherTemp = 0;
                if(interfaceDevice.InterfacetypeId == 1){
                  let arr = devicesmasterlist.cameraTypeMasterList.filter(item => item.brandName == interfaceDevice.Interfacesubtypename);
                  temp = arr[0].brandId; 
                  let deviceObject = {
                    "ParentCameraID": 0,
                    "cameraName": interfaceDevice.InterfaceName,
                    "privateIPAddress": interfaceDevice.InterfaceIP,
                    "SiteID": interfaceDevice.siteid,
                    "userName": interfaceDevice.UserName,
                    "password": interfaceDevice.Password,
                    "port": interfaceDevice.InterfacePort,
                    "channelNumber": '',
                    // "playbackChannelNumber": '',
                    "liveStreamTypeNumber": '',
                    "playbackStreamTypeNumber": '',
                    "rTSPURL": '',
                    "location": '',
                    "PanelMasterId": otherTemp,
                    "CameraBrandId": temp,
                    "communicationPort": '',
                    "httpPort": '',
                    "isTranscoded": 0,
                    "isPlaybackTranscoded": 0,
                    "isTwoWayEnabledDevice": 0,
                    "CCTVTypeID": interfaceDevice.EntityTypeID
                  }
                  setSelectedDevice(deviceObject);
                  setIsDeviceEdit(true)
                  getdeviceslistdata()
                } else{
                  let arr = devicesmasterlist.panelTypeMasterList.filter(item => item.panelName == interfaceDevice.Interfacesubtypename);
                  otherTemp = arr[0].panelMasterID
                  getdeviceslistdata()
                }
              //   let deviceObject = {
              //     "ParentCameraID": 0,
              //     "cameraName": interfaceDevice.InterfaceName,
              //     "privateIPAddress": interfaceDevice.InterfaceIP,
              //     "SiteID": interfaceDevice.siteid,
              //     "userName": interfaceDevice.UserName,
              //     "password": interfaceDevice.Password,
              //     "port": interfaceDevice.InterfacePort,
              //     "channelNumber": '',
              //     "rTSPURL": '',
              //     "location": '',
              //     "PanelMasterId": otherTemp,
              //     "CameraBrandId": temp,
              //     "communicationPort": '',
              //     "httpPort": '',
              //     "isTranscoded": 0,
              //     "CCTVTypeID": 0
              //   }
              //   setSelectedDevice(deviceObject);
              //   setIsDeviceEdit(true)
              //   getdeviceslistdata()
              }

            }}>
              <img src={disableTab ? DevicesDisable : activeTab === DEVICES ? DevicesSelected : DevicesDefault} alt="device icon" width={17} />
            </div>
           
              <span className={`${disableTab ? "StepperTextDisable" : "stepperText"}`}>
              Devices {interfaceType && (cameradeviceslist?.length>0 || paneldeviceslist?.length >0) &&
               !disableTab &&
              <span style={{color: "#8f8e8e", fontSize:"12px"}}>
                ({ interfaceType === NVRDVR ?cameradeviceslist?.length:paneldeviceslist?.length})</span>}
              </span>
          </li>
        </ul>
      </div>

      <div className="">
        {
          activeTab == SITES ?
            <div className="SiteMainContainer">

              <div className="SiteLeftContainer">
                <div className="m-2 HeaderSiteList">
                  <input
                    id="Search_txt_site"
                    className="SearchViewInput1"
                    // ref={focusSearchDiv}
                    type="text"
                    onChange={(e) => {
                      setSearchTextSite(e.target.value)
                      onSearchChange(e.target.value, "Site List")
                    }
                    }
                    placeholder="Search Site"
                    // autoFocus
                    value={searchTextSite}
                  />
                  <div className="SearchTextDivConfigTool">
                    {
                      isDarkTheme ? 
                      <img 
                        className="EyeIcon"
                        src={SearchIcon}
                        alt="search icon"
                        // onClick={() => ToggeleSearch()}
                      /> :
                      <img 
                        className="EyeIcon"
                        src={SearchIconWhite}
                        alt="search icon" height="15"
                        // onClick={() => ToggeleSearch()}
                      />
                    }
                    {/* {!issearch && (
                <img
                  className="EyeIcon"
                  src={Cross}
                  onClick={() => ToggeleSearch()}
                />
              )} */}
                  </div>
                </div>
                <div className="StepperSiteList" id="siteListDiv" >
                  {
                   filteredSites && (
                     filteredSites?.length > 0 ? 
                     filteredSites.map((item) => {
                       return (
                         <div
                           ref={item.SiteID == site?.SiteID ? siteRef : null}
                           className={site?.SiteID === item.SiteID ? "OneList SelectedList" : "OneList"}
                           key={item.SiteID}
                           onClick={(e) => {
                             e.stopPropagation();
                             onSelectSite(item.SiteID, true);
                             setInterfaceDataList(null)
                             clearSiteFields()
                           }}
                         >
                           <p className="ListofText">
                             {item.SiteName}
                             <span className="SiteNameText"> - {item.BranchID}</span>
                           </p>
                           <div>
                             {/* <img className="m-2" src={EditQc} />
                       <img
                         src={DeleteQc}
                         // onClick={(e) =>
                         //   onDeleteChange(e, site.SiteID, site.SiteName)
                         // }
                       /> */}
                           </div>
                         </div>
                       );
                     }) : <div className="no-data-found"><p>No Site Found.</p></div>
                   )  
                  }
                  {!filteredSites && (
                    <div className="no-data-found">No Sites Available.</div>
                  )}
                </div>

              </div>
              <div className="SiteRightContainer">
                    <h1 className="MainHeaderName">Site Details</h1>
                <div className="siteformContainer">
                  <div>
                    <form onSubmit={handleSubmit((e) => onSubmit(e, site.SiteID))}>
                      <div className="site-form" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        {/* <div className="SiteTextBox"> */}
                        <div className="inputMainContainer w40dot75rem">
                          <div style={{display: "flex"}}>
                            <div className="inputTypeLabelContainer" style={{width: "95%"}}>
                            {/* <div className="customerDropdown"> */}
                              <label className='formFieldLabels'>Select Customer</label>
                              <select
                                required
                                disabled={!isSiteEdit}
                                {...register("clientID")}
                                className="inputType ArrowSelectClass"
                                // className="MessageTextBox"
                                // className="SiteNameTextBox ArrowSelect"
                                defaultValue={site.clientID}
                                onChange={(e) => { 
                                  setValue("clientID", e.target.value); 
                                  setCustomerError('') }}
                                  // style={{ width: "96%" }}
                                >
                                <option key="0" disabled={false} value="0">Select Customer*</option>
                                {clients &&
                                  clients.map((val) => (
                                    <option value={val.clientID} key={val.clientName}>
                                      {val.clientName}
                                    </option>
                                  ))}
                              </select>
                            {/* </div> */}
                            </div>
                            <OverlayTrigger
                                placement='bottom'
                                overlay={
                                    <Tooltip>
                                        Add Customer
                                    </Tooltip>
                                }
                                >
                            <button className="addCustomer" style={{marginLeft: "10px"}} onClick={(e) => {
                              e.preventDefault()
                              setShow(!show)
                            }}>
                              <span className="ViewUpdate">
                                <img src={AddNode} alt="add plus icon" />
                              </span>
                            </button>
                            </OverlayTrigger>
                            {/* <p className="Error_P" style={{float: "left"}}><span>{siteFormObj.Customer}</span></p>  */}
                          </div>
                            <p className="Error_P" style={{float: "left"}}><span>{customerError}</span></p> 
                        </div>
                        {/* </div> */}

                        {/* <div className="SiteTextBox"> */}
                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Site Name</label>
                            <input
                              {...register("SiteName")}
                              // {...siteNameInput}
                              // className="SiteNameTextBox"
                              className="inputType"
                              disabled={!isSiteEdit}
                              placeholder="Site Name*"
                              onKeyPress={(e) => {
                                onSpecialChangeOnly(e);
                                setSiteError('')
                              }}
                              onChange={() => {setSiteError('')}}
                            />
                            <p className="Error_P" style={{float: "left"}}><span>{siteError}</span></p>
                          </div>
                        </div>
                        {/* </div> */}

                        {/* <div className="SiteTextBox"> */}
                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Select Country</label>
                            <select
                              {...register("CountryName")}
                              disabled={!isSiteEdit}
                              // className="SiteNameTextBox ArrowSelect"
                              className="inputType ArrowSelectClass"
                              onChange={(e) => {
                                handleCountryChange(e)
                                setCountryError('')
                              }}
                            >
                              <option key="0" disabled={false} value="0" id="0">Select Country*</option>
                              {country &&
                                country.map((val) => (
                                  <option value={val.CountryName} key={val.CountryMasterId} id={val.CountryMasterId}>
                                    {val.CountryName}
                                  </option>
                                ))}
                            </select>
                            <p className="Error_P" style={{float: "left"}}><span> {countryError} </span></p>
                          </div>
                        </div>
                        {/* </div> */}

                        {/* <div className="SiteTextBox"> */}
                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Select State</label>
                            <select
                              {...register("StateName")}
                              disabled={!isSiteEdit}
                              // className="SiteNameTextBox ArrowSelect"
                              className="inputType ArrowSelectClass"
                              onChange={() => {
                                setStateError('')
                              }}
                              >
                              <option key="0" disabled={false} value="0">Select State*</option>
                              {countryState &&
                                countryState.map((val) => (
                                  <option value={val.StateName} key={val.StateID}>
                                    {val.StateName}
                                  </option>
                                ))}
                            </select>
                            <p className="Error_P" style={{float: "left"}}><span> {stateError} </span></p>
                          </div>
                        </div>
                        {/* </div> */}

                        {/*  <div className="SiteTextBox">
                      <select
                        disabled={!isSiteEdit}
                        className="SiteNameTextBox ArrowSelect">
                        <option key="0" disabled={false} value="0">Select City</option>
                      </select>
                    </div> 
                    */}

                        {/* <div className="SiteTextBox"> */}
                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Select City</label>
                            <input
                              {...register("CityName")}
                              // {...cityInput}
                              // className="SiteNameTextBox"
                              className="inputType"
                              disabled={!isSiteEdit}
                              placeholder="Select City*"
                              onKeyPress={(e) => {
                                onSpecialChangeOnly(e);
                                setCityError('')
                              }}
                              onChange={() => setCityError("")}
                            />
                            <p className="Error_P" style={{float: "left"}}><span> {cityError} </span></p>
                          </div>
                        </div>
                        {/* </div> */}

                        {/* <div className="SiteTextBox">
                      <select
                        disabled={!isSiteEdit}
                        className="SiteNameTextBox ArrowSelect">
                        <option key="0" disabled={false} value="0">Select Region</option>
                      </select>
                    </div> 
                    */}

                        {/* <div className="SiteTextBox"> */}
                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Select Region</label>
                              <input
                                {...register("SiteRegionName")}
                                // {...regionInput}
                                // className="SiteNameTextBox"
                                className="inputType"
                                disabled={!isSiteEdit}
                                placeholder="Select Region*"
                                onKeyPress={(e) => {
                                  onSpecialChangeOnly(e);
                                  setRegionError('')
                                }}
                                onChange={() => {setRegionError('')}}
                              />
                              <p className="Error_P" style={{float: "left"}}><span> {regionError} </span></p>
                          </div>
                        </div>
                        {/* </div> */}

                        {/* <div className="SiteTextBox"> */}
                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Pin Code</label>
                              <input
                                {...register("SitePincode")}
                                // className="SiteNameTextBox"
                                className="inputType"
                                disabled={!isSiteEdit}
                                onKeyPress={(e) => {
                                  onNumberOnlyChange(e);
                                  setPincodeError('')
                                }}
                                onChange={() => {setPincodeError('')}}
                                placeholder="Pin Code*"
                              />
                              <p className="Error_P" style={{float: "left"}}><span> {pincodeError} </span></p>
                          </div>
                        </div>
                        {/* </div> */}

                        {/* <div className="SiteTextBox"> */}
                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Address</label>
                              <input
                                maxLength={100}
                                {...register("SiteAddress")}
                                // {...addressInput}
                                // className="SiteNameTextBox"
                                className="inputType"
                                disabled={!isSiteEdit}
                                placeholder="Address*"
                                onChange={() => setAddressError('')}
                              />
                              <p className="Error_P" style={{float: "left"}}><span> {addressError} </span></p>
                          </div>
                        </div>
                        {/* </div> */}

                        {/* <div className="SiteTextBox"> */}
                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Area</label>
                            <input
                              {...register("SiteArea")}
                              // {...areaInput}
                              // className="SiteNameTextBox"
                              className="inputType"
                              disabled={!isSiteEdit}
                              placeholder="Area*"
                              onKeyPress={(e) => {
                                onSpecialChangeOnly(e);
                                setAreaError('')
                              }}
                              onChange={() => {setAreaError('')}}
                            />
                            <p className="Error_P" style={{float: "left"}}><span> {areaError} </span></p>
                          </div>
                        </div>
                        {/* </div> */}

                        {/* Site Name & Branch Name are synonymous */}
                        {/*  <div className="SiteTextBox" style={{display:"none"}}>
                      <input
                        maxLength={100}
                        {...register("SiteName")}
                        className="SiteNameTextBox"
                        disabled={!isSiteEdit}
                        placeholder="Branch Name*"
                      />
                    </div> */}
                        {/* <div className="SiteTextBox"> */}
                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Branch ID</label>
                              <input
                                maxLength={50}
                                {...register("BranchID")}
                                // {...branchIdInput}
                                // className="SiteNameTextBox"
                                className="inputType"
                                disabled={!isSiteEdit}
                                placeholder="Branch ID*"
                                onKeyPress={(e) => {
                                  onSpecialChangeOnly(e);
                                  setBranchidError('')
                                }}
                                onChange={() => {setBranchidError('')}}
                              />
                              <p className="Error_P" style={{float: "left"}}><span> {branchidError} </span></p>
                          </div>
                        </div>
                        {/* </div> */}
                        
                        {/* <div className="SiteTextBox"> */}
                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Select Site Type</label>
                            <select
                              {...register("SiteModeID")}
                              disabled={!isSiteEdit || isAlreadyRetail}
                              // className="SiteNameTextBox ArrowSelect"
                              className="inputType ArrowSelectClass"
                              >
                                defaultValue={site.SiteModeID}
                                onChange={(e) => { setValue("SiteModeID", e.target.value); }}
                              <option key="0" disabled={false} value={0}id="0"> Site Type</option>
                              {siteTypes &&
                              siteTypes.map((val) => (
                                  <option value={val.SiteModeID} key={val.SiteModeID}> 
                                    {val.SiteMode}
                                  </option>
                                ))}
                            </select>
                            {/* <p className="Error_P" style={{float: "left"}}><span> {setSiteTypeError} </span></p> */}
                          </div>
                        </div>
                        {/* </div> */}

                        <div className="BulkText" hidden>
                          <div className="SentenceText">

                          </div>

                          <div className="ButtonText">
                            <button
                              className="AlertViewButton"
                              style={{ visibility: "collapse" }}
                            >
                              <p>Upload CSV</p>
                            </button>
                            {/* {site.SiteID == 0 && (
                            <button type="submit" className="NextMoveButton addSite-next-btn">
                              <span className="ViewUpdate">Next</span>
                            </button>
                          )}
                          {site.SiteID != 0 && (
                            <>
                              {!isSiteEdit && (
                                <button
                                  className="NextMoveButton updateSite-edit-btn"
                                  onClick={() => setIsSiteEdit(true)}
                                >
                                  <span className="ViewUpdate">Edit</span>
                                </button>
                              )}
                              {isSiteEdit && (
                                <>
                                  <button type="submit" className="NextMoveButton updateSite-update-btn">
                                    <span className="ViewUpdate">Update</span>
                                  </button>
                                  <a
                                    className="btn btn-link ml-2"
                                    onClick={(e) => onCancel(e)}
                                  >
                                    Cancel
                                  </a>
                                </>
                              )}
                            </>
                          )} */}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div>
                    {site.SiteID != 0 && (
                      <div className="">
                        <h1 className="MainHeaderName mb-0">Add User to Site</h1>
                        <div className="ManageSiteDiv">
                          <div className="DevicesHeader">
                            <p className="mb-0" style={{ width: "40%" }}>
                              Users
                              <br />
                            </p>
                            <p className="mb-0">
                              Configure Users to Site
                              <br />
                            </p>
                          </div>

                          <div className="site-group">
                            <div className="site-group-list"
                            >
                              {emeContactList &&
                                emeContactList.map((item) => {
                                  return (
                                    <div
                                      id={item.EmergencyContactID}
                                      key={item.EmergencyContactID}
                                      className={`cameraList user-list-item ${selectedERT &&
                                        item.EmergencyContactID ==
                                        selectedERT?.EmergencyContactID &&
                                        "selected-user"
                                        }`}
                                      // isDisabled={!isSiteEdit}
                                      onClick={(e) => {
                                        if (isSiteEdit)
                                          siteERTOnClick(item)
                                      }}
                                    >
                                      {item &&
                                        item.IsCustomerSPOC == true &&
                                        item.IsERT == false ? (
                                        <p className="mb-0">{item.ContactName} - SPOC</p>
                                      ) : (
                                        <p className="mb-0">
                                          {item.ContactName} - {item.ContactLevel}
                                        </p>
                                      )}

                                      <div style={{ float: "right", pointerEvents: pointerEvent, cursor: cursor }}>
                                        <img
                                          height={12}
                                          width={12}
                                          onClick={() => siteERTOnClick(item)}
                                          src={EditQc}
                                          className="m-1"
                                          alt="edit icon"
                                        ></img>
                                        <img
                                          height={12}
                                          width={12}
                                          src={DeleteQc}
                                          className="m-1"
                                          title="Delete"
                                          onClick={(e) => onDeleteERT(site.SiteID, item)}
                                          alt="delete icon"
                                        />
                                      </div>
                                      {/* } */}
                                    </div>
                                  );
                                })}
                            </div>
                            <div style={{ margin: "10px", textAlign: "end" }}>
                              <div className="AddMore" style={{ float: "left" }}>
                                <a
                                  disabled={!isSiteEdit}
                                  className="UserAddPlus mt-1"
                                  onClick={() => addNewERTMemberClick()}
                                >
                                  Add New Member +
                                </a>
                              </div>
                            </div>
                          </div>
                          <AddERTForm
                            onSubmit={onAddERTMemberClick}
                            ertLevels={ertLevels}
                            site={site}
                            selectedERT={selectedERT}
                            userOptions={userOptions}
                            onUserRoleChange={onUserRoleChange}
                            selectedUserRole={selectedUserRole}
                            setSelectedUserRole={setSelectedUserRole}
                            siteUserFieldsVisibility={siteUserFieldsVisibility}
                            isSiteEdit={isSiteEdit}
                          />
                        </div>
                        {/* <ToastContainer
                          position="bottom-left"
                          autoClose={5000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          theme="light"
                        /> */}

                      </div>
                    )}
                  </div>
                  {/* <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  /> */}
                </div>
                <div style={{ textAlign: "center" }}>
                  {
                    site.SiteID != 0 &&
                    (
                      <button className="DataAccessEdit" style={{ width: "100px" }}
                        onClick={() => {
                          setSelectedSite(tempSiteObj);
                          setSelectedInterface(interfaceObj);
                          setIsSiteEdit(true);
                        }}
                      >
                        <span className="ViewUpdate">Add New</span>
                      </button>
                    )
                  }
                  {/* <button className="DataAccessEdit" style={{width: "100px"}}>
                      <span className="ViewUpdate">Bulk upload</span>
                    </button> */}
                  {
                    site.SiteID != 0 && !isSiteEdit &&
                    (
                      <button className="DataAccessEdit" style={{ width: "100px" }}
                        onClick={() => setIsSiteEdit(true)}
                      >
                        <span className="ViewUpdate">Edit</span>
                      </button>
                    )
                  }
                  {
                    site.SiteID == 0 &&
                    (
                      <button className="DataAccessEdit" style={{ width: "100px" }}
                        onClick={handleSubmit((e) => onSubmit(e, site.SiteID))}
                        disabled={buttonDisable}
                      >
                        <span className="ViewUpdate">Save</span>
                      </button>
                    )
                  }
                  {
                    site.SiteID != 0 && isSiteEdit &&
                    (
                      <>
                        <button className="DataAccessEdit" style={{ width: "100px" }}
                          onClick={handleSubmit((e) => onSubmit(e, site.SiteID))}
                          disabled={buttonDisable}
                        >
                          <span className="ViewUpdate">Save</span>
                        </button>
                        <button className="DataAccessEdit" style={{ width: "100px" }} onClick={(e) => onCancel(e)} >
                          <span className="ViewUpdate">Cancel</span>
                        </button>
                      </>
                    )
                  }
                  {
                    site.SiteID != 0 && !isSiteEdit &&
                    (
                      <button className="DataAccessEdit" style={{ width: "100px" }}
                        onClick={(e) => onDeleteChange(e, site.SiteID, site.SiteName)}
                        disabled={buttonDisable}
                        >
                        <span className="ViewUpdate">Delete</span>
                      </button>
                    )
                  }
                </div>
              </div>
            </div>
            : activeTab == INTERFACES ?
              <div className="SiteMainContainer">
                <div className="SiteLeftContainer">
                  <div className="m-2 HeaderSiteList">
                    <input
                      id="Search_txt_interface"
                      className="SearchViewInput1"
                      // ref={focusSearchDiv}
                      type="text"
                      onChange={(e) => {
                        setSearchTextInterface(e.target.value)
                        onSearchChange(e.target.value, 'interface')
                      }
                      }
                      placeholder="Search Interface"
                      autoFocus
                      value={searchTextInterface}
                    />
                    <div className="SearchTextDivConfigTool">
                      {
                        isDarkTheme ?
                        <img
                          className="EyeIcon"
                          src={SearchIcon}
                          alt="search icon"
                        // onClick={() => ToggeleSearch()}
                        /> :
                        <img 
                        className="EyeIcon"
                        src={SearchIconWhite}
                        alt="search icon" height="15"
                        // onClick={() => ToggeleSearch()}
                      />
                      }
                      {/* {!issearch && (
                <img
                  className="EyeIcon"
                  src={Cross}
                  onClick={() => ToggeleSearch()}
                />
              )} */}
                    </div>
                  </div>

                  <div className="StepperSiteList" id="interfaceListDiv">
                    {
                      interfaceDataList && (
                        interfaceDataList?.length > 0 ?
                        interfaceDataList.map((interfaceparam) => {
                          return (
                            <div
                              ref={interfaceDevice.InterfaceId === interfaceparam.InterfaceId ? interfaceRef : null}
                              className={interfaceDevice.InterfaceId === interfaceparam.InterfaceId ? "OneList SelectedList" : "OneList"}
                              key={interfaceparam.InterfaceId}
                              onClick={(e) => {
                                e.stopPropagation();
                                onSelectInterface(interfaceparam.InterfaceId, true);
                                clearInterfaceFields();
                              }}
                            >
                              <p className="ListofText">
                                {interfaceparam.InterfaceName}
                                { <span className="SiteNameText"> - {interfaceparam.Interfacetypename}
                                </span> }
                              </p>
                              <div>
                                {/* <img className="m-2" src={EditQc} />
                                <img
                                  src={DeleteQc}
                                // onClick={(e) =>
                                //   onDeleteChange(e, interfaceparam.InterfaceId, interfaceparam.InterfaceName)
                                // }
                                /> */}
                              </div>
                            </div>
                          );
                        }) : <div className="no-data-found"><p>No Interface Found.</p></div>
                      )
                      }
                    {!interfaceDataList && (
                      <div className="no-data-found">No Interface Available.</div>
                    )}
                  </div>
                </div>
                <div className="SiteRightContainer">
                    <h1 className="MainHeaderName">Interface Details</h1>
                  <div className="siteformContainer">
                    {/* {site.SiteID != 0 && <h1 className="MainHeaderName">1. Update Interface</h1>} */}
                    <form onSubmit={handleSubmit((e) => onInterfaceFormSubmit(e, interfaceDevice.InterfaceId))}>
                      <div 
                      className="site-form"
                      style={{display: "flex", flexDirection: "column", alignItems: "center"}}
                      // style={{height: "100%", textAlign: "center"}}
                      >

                        {/* <div className="SiteTextBox"> */}
                        <div className="inputMainContainer w40dot75rem">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Interface Name</label>
                              <input
                                {...register("InterfaceName")}
                                // {...interfaceNameInput}
                                // className="SiteNameTextBox"
                                className="inputType"
                                disabled={!isInterfaceEdit}
                                placeholder="Enter Interface Name*"
                                // onKeyPress={onAlphanumericRegex}
                                onKeyPress={(e) => {
                                  onSpecialChangeOnly(e)
                                  setInterfaceError('')
                                }}
                                onChange={() => {setInterfaceError('')}}
                              />
                              <p className="Error_P" style={{float: "left"}}><span> {interfaceError} </span></p>
                          </div>
                        </div>
                        {/* </div> */}

                       {/* <div className="SiteTextBox"> */}
                       <div className="inputMainContainer w40dot75rem mt35">
                        <div className="inputTypeLabelContainer">
                          <label className='formFieldLabels'>Interface Type</label>
                            <select
                              {...register("InterfaceType")}
                              // disabled={!isInterfaceEdit}
                              disabled={interfaceDevice.InterfaceId != 0 ? true : false}
                              // className="SiteNameTextBox ArrowSelect"
                              className="inputType ArrowSelectClass"
                              onChange={(e) => {
                                interfaceSubTypeHandler(e)
                                setInterfaceTypeError('')
                                setValue("EntityTypeID", 0)
                                setValue("CheckExternalHooter", 0);
                                setValue("CheckInternalHooter", 0);
                                setValue("IsHooterEnabled", 0);
                                setValue("InterfaceTwoWayEnabled", 0)
                              }}
                            >
                                <option key="0" disabled={false} value="0" id="0">Select Interface Type*</option>
                                {interfaceTypeData &&
                                  interfaceTypeData.map((val) => (
                                    <option
                                      value={val.Interfacetypename} key={val.InterfacetypeId}
                                      id={val.InterfacetypeId}>
                                      {val.Interfacetypename}
                                    </option>
                                  ))}
                            </select>
                            <p className="Error_P" style={{float: "left"}}><span> {interfaceTypeError} </span></p>
                        </div>
                       </div>
                        {/* </div> */}

                      
                       {/* <div className="SiteTextBox"> */}
                       <div className="inputMainContainer w40dot75rem mt35">
                        <div className="inputTypeLabelContainer">
                          <label className='formFieldLabels'>Interface Sub-Type</label>
                            <select
                              {...register("InterfaceSubType")}
                              // disabled={!isInterfaceEdit}
                              disabled={interfaceDevice.InterfaceId != 0 ? true : false}
                              // className="SiteNameTextBox ArrowSelect"
                              className="inputType ArrowSelectClass"
                              onChange={(e) => {
                                console.log("interfacesubtypeeee", e.target.value);
                                setValue("EntityTypeID", 0)
                                setInterfaceSubtypeError('')
                                if (e.target.value == "Unknown") {
                                  setDisplayLivePlaybackfield(true)
                                  setEnableHooter(false)
                                  setDisplayTwoWay(false)
                                  setDisplayCamera(false)
                                } else if (getValues("InterfaceType") == "Alarm Panel") {
                                  setDisplayTwoWay(false);
                                  setEnableHooter(true);
                                  setDisplayLivePlaybackfield(false);
                                } else {
                                  setDisplayLivePlaybackfield(false)
                                  setEnableHooter(false)
                                  setDisplayTwoWay(true)
                                  setDisplayCamera(false);
                                  setValue("IsHooterEnabled", 0);
                                  setValue("InterfaceTwoWayEnabled", 0)

                                }
                              }}
                            >
                              <option key="0" disabled={false} value="0" id="0">Select Interface Sub Type*</option>
                              {interfaceSubTypeData &&
                                interfaceSubTypeData.map((val) => (
                                  <option
                                    value={val.Interfacesubtypename}
                                    key={val.InterfacesubtypeId}
                                    id={val.InterfacesubtypeId}
                                  >
                                    {val.Interfacesubtypename}
                                  </option>
                                ))}
                            </select>
                            <p className="Error_P" style={{float: "left"}}><span> {interfaceSubtypeError} </span></p>
                        </div>
                       </div>
                       {/* </div> */}

                       {
                         displayEntity
                           ?
                           <>

                             {/* <div className="SiteTextBox"> */}
                             <div className="inputMainContainer w40dot75rem mt35">
                              <div className="inputTypeLabelContainer">
                                <label className='formFieldLabels'>Entity Subtype</label>
                                  <select
                                    {...register("EntityTypeID")}
                                    // required={true}
                                    // className="SiteNameTextBox ArrowSelect"
                                    className="inputType ArrowSelectClass"
                                    placeholder="Entity Subtype*"
                                    disabled={disabledEntity}
                                    //disabled={interfaceDevice.InterfaceId != 0?true:false}
                                    defaultValue={interfaceDevice.EntityTypeID}
                                    onChange={(e) => {
                                      console.log("entity", e.target.value)
                                      console.log("entity", e.target.selectedOptions[0].text)
                                      setValue("CCTVType", e.target.selectedOptions[0].text)
                                      setInterfaceEntitySubtypeError()
                                      //  if (e.target.value == 4) {
                                      if (getValues("InterfaceSubType") == "Unknown") {
                                        setDisplayCamera(false)
                                        setEnableHooter(false)
                                        setParentPanelIsEnablingHooter(false)
                                        setParentIsEnablingInternalHooter(false)
                                        setParentIsEnablingExternalHooter(false);
                                      } else {
                                        if (e.target.selectedOptions[0].text == "Camera") {
                                          setDisplayCamera(true)
                                          setEnableHooter(false)
                                          setParentPanelIsEnablingHooter(false);
                                        } else {
                                          setDisplayCamera(false)
                                          setEnableHooter(true)
                                          setParentIsEnablingInternalHooter(false)
                                          setParentIsEnablingExternalHooter(false)
                                          setValue("CheckExternalHooter", 0);
                                          setValue("CheckInternalHooter", 0);
                                          setValue("IsHooterEnabled", 0);
                                          setValue("InterfaceTwoWayEnabled", 0)
                                        }
                                      }

                                      setValue("CCTVTypeIDInterface", e.target.value)
                                      //setSelectedEntitySubtype={setSelectedEntitySubtype}
                                    }
                                    }
                                  >
                                    <option key="0" disabled={false} value="0" id="0">Entity Subtype*</option>
                                    {cctvTypeData && cctvTypeData.map((entity) => (
                                      <option value={entity.CCTVTypeID} name={entity.CCTVType} key={entity.CCTVTypeID}>
                                        {entity.CCTVType}
                                      </option>
                                    ))}
                                  </select>
                                  <p className="Error_P" style={{float: "left"}}><span> {interfaceEntitySubtypeError} </span></p>
                              </div>
                             </div>
                             {/* </div> */}
                           </>
                           : null
                       }

                        {/* <div className="SiteTextBox"> */}
                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Interface IP</label>
                              <input
                                {...register("InterfaceIP")}
                                // className="SiteNameTextBox"
                                className="inputType"
                                disabled={!isInterfaceEdit}
                                placeholder="Enter Interface IP*"
                                onKeyPress={(e) => {
                                  onNumberOnlyChange2(e);
                                  setInterfaceIPError('')
                                }}
                                onChange={() => setInterfaceIPError('')}
                              />
                              <p className="Error_P" style={{float: "left"}}><span> {interfaceIPError} </span></p>
                          </div>
                        </div>
                        {/* </div> */}

                        {/* <div className="SiteTextBox"> */}
                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Interface Port</label>
                              <input
                                {...register("InterfacePort")}
                                // className="SiteNameTextBox"
                                className="inputType"
                                disabled={!isInterfaceEdit}
                                placeholder="Enter Interface Port*"
                                onKeyPress={(e) => {
                                  onNumberOnlyChange(e);
                                  setInterfacePortError('')
                                }}
                                onChange={() => setInterfacePortError('')}
                              />
                              <p className="Error_P" style={{float: "left"}}><span> {interfacePortError} </span></p>
                          </div>
                        </div>
                        {/* </div> */}

                        {/* <div className="SiteTextBox"> */}
                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Interface Username</label>
                              <input
                                {...register("InterfaceUsername")}
                                // {...interfaceUserNameInput}
                                // className="SiteNameTextBox"
                                className="inputType"
                                disabled={!isInterfaceEdit}
                                placeholder="Enter Interface Username*"
                                // onKeyPress={onSpecialChangeOnly}
                                onChange={() => setInterfaceUserError('') }
                              />
                              <p className="Error_P" style={{float: "left"}}><span> {interfaceUserError} </span></p>
                            </div>
                        </div>
                        {/* </div> */}

                        {/* <div className="SiteTextBox"> */}
                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Interface Password</label>
                              <input
                                {...register("InterfacePassword")}
                                // className="SiteNameTextBox"
                                className="inputType"
                                disabled={!isInterfaceEdit}
                                placeholder="Enter Interface Password*"
                                onChange={() => setInterfacePasswardError('')}
                              />
                              <p className="Error_P" style={{float: "left"}}><span> {interfacePasswardError} </span></p>
                          </div>
                        </div>
                        {/* </div> */}
                        
                       {
                        !HIDE_STUFF
                        ?
                        
                          displayLivePlaybackfield ? 
                          <>
                          {/* <div className="SiteTextBox"> */}
                          <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Live Stream Format</label>
                                 <MentionsInput
                                  {...register("LiveRTSPURL")}
                                  disabled={!isInterfaceEdit}
                                  placeholder="Enter live stream URL format"
                                  // className="SiteNameTextBox"
                                  className="inputType long-value-input"
                                  style={{marginLeft: "18px"}}
                                  singleLine
                                  value={rtspValue}
                                  onChange={(e, newValue, newPlainTextValue) => {
                                    // liveEmailHandler(e, newValue, newPlainTextValue)
                                    setRtspValue(e.target.value)
                                    setRtspValueEntered(newPlainTextValue)
                                    setLiveStreamFormatError('')
                                  }}
                                  >
                                  <Mention
                                    data={RTSP}
                                    markup="{__display__}"
                                    trigger={/(@([^@]*))$/}
                                    // displayTransform={(value) => {
                                    //   if(value == "username" || value == "ip")
                                    //   {
                                    //     return `${value}:`
                                    //   }else if(value == "password"){
                                    //     return `${value}@`
                                    //   }else{
                                    //     return `${value}`                                        
                                    //   }
                                    // }}
                                    />
                                 </MentionsInput>
                                 <p className="Error_P" style={{float: "left"}}><span>{liveStreamFormatError}</span></p> 
                          </div>
                          </div>
                                 
                                  {/* <input
                                    {...register("rTSPURL")}
                                    className="SiteNameTextBox"
                                    // onClick={(e) => resetrtspurl(e.target)}
                                    disabled={!isDeviceEdit}
                                    placeholder="RTSP URL* e.g. rtsp://Admin:Abc@123@192.168.0.58:554/Streaming/Channels/1"
                                    defaultValue={selectedDevice.RTSPURL}
                                    onChange={()=>setRtspValueChanged(true)}
                                    /> */}
                        {/* </div> */}

                        {/* <div className="SiteTextBox"> */}
                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Playback Stream Format</label>
                                <MentionsInput
                                  {...register("PlaybackRTSPURL")}
                                  disabled={!isInterfaceEdit}
                                  placeholder="Enter playback URL format"
                                  // className="SiteNameTextBox"
                                  className="inputType long-value-input"
                                  style={{marginLeft: "18px"}}
                                  singleLine
                                  value={playbackValue}
                                  // onChange={(e) => setPlaybackValue(e.target.value)}
                                  onChange={(e, newValue, newPlainTextValue) => {
                                    // liveEmailHandler(e, newValue, newPlainTextValue)
                                    setPlaybackValue(e.target.value)
                                    setPlaybackValueEntered(newPlainTextValue)
                                    setPlaybackStreamFormatError('')
                                  }}
                                  >
                                  <Mention
                                    // className="inputType"
                                    data={PLAYBACK}
                                    markup="{__display__}"
                                    trigger={/(@([^@]*))$/}
                                    style={{ border: "0px impotant" }}
                                    />
                                 </MentionsInput>
                                 <p className="Error_P" style={{float: "left"}}><span>{playbackStreamFormatError}</span></p> 
                          </div>
                        </div>
                        {/* </div> */}
                        
                        {/* <div className="SiteTextBox SiteNameText"> */}
                        <div className="inputMainContainer w40dot75rem mt35">
                        <div className="inputTypeLabelContainer">
                        <label className='formFieldLabels'>UTC Conversion</label>
                          <div className="onlyforEnableCheckbox">
                            <input
                              className="checkbox_custom"
                              type="checkbox"
                              disabled={!isInterfaceEdit}
                              {...register("PlaybackTimeStampsToUTC")}
                              onChange={(e) => {
                                console.log("RUpesh2",Number(e.target.checked));
                                setValue("PlaybackTimeStampsToUTC", Number(e.target.checked))
                                setParentIsEnablingPlaybackTimeStampsToUTC(e.target.checked);
                              }}
                              defaultValue={Number(interfaceDevice.CheckPlaybackUTCTime)}
                            />
                            <label className="mb-0"
                            onClick={() => {
                              if(!isInterfaceEdit){
                                return;
                              }
                              getValues("PlaybackTimeStampsToUTC")
                              console.log("parentIsEnablingPlaybackTimeStampsToUTCChcek",parentIsEnablingPlaybackTimeStampsToUTC)
                              console.log("PlaybackTimeStampsToUTC",getValues("PlaybackTimeStampsToUTC"))
                              if(parentIsEnablingPlaybackTimeStampsToUTC){
                                setValue("PlaybackTimeStampsToUTC",0)
                                setParentIsEnablingPlaybackTimeStampsToUTC(false);
                              }else{
                                setValue("PlaybackTimeStampsToUTC",1)
                                setParentIsEnablingPlaybackTimeStampsToUTC(true);
                              }
                            }}
                            >
                              Playback Timestamps to UTC
                            </label>
                          </div>
                        </div>
                        </div>
                        {/* </div> */}

                        <div className="SiteTextBox SiteNameText" style={{display:"none",height: "55px"}}>
                          <div className="onlyforEnableCheckbox">
                            <input
                              className="checkbox_custom"
                              type="checkbox"
                              disabled={!isInterfaceEdit}
                              {...register("PlaybackLongDateFormat")}
                              onChange={(e) => {
                                console.log("RUpesh2",Number(e.target.checked));
                                setValue("PlaybackLongDateFormat", Number(e.target.checked))
                                setParentIsEnablingPlaybackLongDateFormat(e.target.checked);
                              }}
                              defaultValue={Number(interfaceDevice.CheckPlaybackLongTime)}
                            />
                            <label className="mb-0"
                              onClick={(e) => {
                                if(!isInterfaceEdit){
                                  return;
                                }
                                getValues("PlaybackLongDateFormat")
                                console.log("parentIsEnablingPlaybackLongDateFormatCheck",parentIsEnablingPlaybackLongDateFormat)
                                console.log("PlaybackLongDateFormat",getValues("PlaybackLongDateFormat"))
                                if(parentIsEnablingPlaybackLongDateFormat){
                                  setValue("PlaybackLongDateFormat",0)
                                  setParentIsEnablingPlaybackLongDateFormat(false);
                                }else{
                                  setValue("PlaybackLongDateFormat",1)
                                  setParentIsEnablingPlaybackLongDateFormat(true);
                                }
                              }}
                            >
                              Playback Long Date Format
                            </label>
                          </div>
                        </div>
                        
                          </>
                        : null  
                        
                        : null
                       }

 

                        {
                          displayTwoWay
                          ?
                          <>
                          <div className="inputMainContainer w40dot75rem mt30">
                          {/* <div className="SiteTextBox SiteNameText">  */}
                          <div className="inputTypeLabelContainer">
                          <label className='formFieldLabels'>Two Way Communication</label>
                            <div 
                            className="onlyforEnableCheckbox"
                            // className="form-check"
                            >
                            <input
                              className="checkbox_custom"
                              // className="form-check-input"
                              type="checkbox"
                              disabled={!isInterfaceEdit}
                              {...register("InterfaceTwoWayEnabled")}
                              onChange={(e) => {
                                console.log("RUpesh2",Number(e.target.checked));
                                setValue("InterfaceTwoWayEnabled", Number(e.target.checked))
                                if(Number(e.target.checked)){
                                  setParentIsEnablingTwoWayInterface(true);
                                }else{
                                  setParentIsEnablingTwoWayInterface(false);
                                }
                              }}
                              defaultValue={Number(interfaceDevice.IsTwoWayEnabled)}
                            />
                            <label className="mb-0"
                               onClick={() => {
                                if(!isInterfaceEdit){
                                  return;
                                }
                                getValues("InterfaceTwoWayEnabled")
                                console.log("setParentIsEnablingTwoWayInterfaceChcek",setParentIsEnablingTwoWayInterface)
                                console.log("InterfaceTwoWayEnabled",getValues("InterfaceTwoWayEnabled"))
                                if(parentIsEnablingTwoWayInterface){
                                  setValue("InterfaceTwoWayEnabled",0)
                                  setParentIsEnablingTwoWayInterface(false);
                                }else{
                                  setValue("InterfaceTwoWayEnabled",1)
                                  setParentIsEnablingTwoWayInterface(true);
                                }
                              }}
                              >
                              Two Way Communication Available
                            </label>
                          </div>
                          </div>
                          {/* </div> */}
                          </div>


                        {/* <div className="cctvHooterEnableDisable">
                        <div className="SiteTextBox SiteNameText" style={{height: "55px"}}>
                          <div className="onlyforEnableCheckbox">
                            <input
                              className="checkbox_custom"
                              type="checkbox"
                              disabled={!isInterfaceEdit}
                              {...register("InterfaceTwoWayEnabled")}
                              //hooter
                              // onChange={(e) => {
                              //   console.log("RUpesh2",Number(e.target.checked));
                              //   setValue("InterfaceInternalHooter", Number(e.target.checked))
                              //   setParentIsEnablingInternalHooter(e.target.checked);
                              // }}
                              // defaultValue={Number(interfaceDevice.IsInternalHooterEnabled)}
                            />
                            <p className="mb-0">
                              Enable Internal Hooter
                            </p>
                          </div>
                        </div>
                        <div className="SiteTextBox SiteNameText" style={{height: "55px"}}>
                          <div className="onlyforEnableCheckbox">
                            <input
                              className="checkbox_custom"
                              type="checkbox"
                              disabled={!isInterfaceEdit}
                              {...register("InterfaceTwoWayEnabled")}
                              //hooter
                              // onChange={(e) => {
                              //   console.log("RUpesh2",Number(e.target.checked));
                              //   setValue("InterfaceExternalHooter", Number(e.target.checked))
                              //   setParentIsEnablingExternalHooter(e.target.checked);
                              // }}
                              // defaultValue={Number(interfaceDevice.IsExternalHooterEnabled)}
                            />
                            <p className="mb-0">
                              Enable External Hooter
                            </p>
                          </div>
                        </div>
                      </div> */}
                      </>
                          : null
                        }

                        {
                          !HIDE_STUFF
                          ?
                            //getValues('CCTVTypeIDInterface') == "Camera" || getValues("CCTVTypeIDInterface") == 4 
                            displayCamera
                            ?
                            //  <div className="cctvHooterEnableDisable">
                              <div className="inputMainContainer w40dot75rem">
                                <div className="inputTypeLabelContainer">
                                  <label className='formFieldLabels'>Enable Hooter types</label>
                                  <div className='mt-1 signTypeCheckboxLabels'>
                                    <div className="SiteTextBox SiteNameText mt-0">
                                      <div className="onlyforEnableCheckbox ml-0">
                                        <input
                                          className="checkbox_custom"
                                          type="checkbox"
                                          disabled={!isInterfaceEdit}
                                          {...register("CheckInternalHooter")}
                                          //Internal hooter
                                          onChange={(e) => {
                                            console.log("CheckInternal", Number(e.target.checked));
                                            setValue("CheckInternalHooter", Number(e.target.checked))
                                            setParentIsEnablingInternalHooter(e.target.checked);
                                            if (e.target.checked) {
                                              setValue("CheckExternalHooter", 0);
                                              setParentIsEnablingExternalHooter(false);
                                            }
                                          }}

                                          defaultValue={Number(interfaceDevice.CheckInternalHooter)}
                                        />
                                        <label className="mb-0"
                                          onClick={() => {
                                            if(!isInterfaceEdit){
                                              return;
                                            }
                                            getValues("CheckInternalHooter")
                                            console.log("parentIsEnablingPlaybackTimeStampsToUTCChcek", parentIsEnablingInternalHooter)
                                            console.log("PlaybackTimeStampsToUTC", getValues("CheckInternalHooter"))
                                            if (parentIsEnablingInternalHooter) {
                                              setValue("CheckInternalHooter", 0)
                                              setParentIsEnablingInternalHooter(false);
                                              // setValue("CheckExternalHooter", 1);
                                              // setParentIsEnablingExternalHooter(true);
                                            } else {
                                              setValue("CheckInternalHooter", 1)
                                              setParentIsEnablingInternalHooter(true);
                                              setValue("CheckExternalHooter", 0);
                                              setParentIsEnablingExternalHooter(false);
                                            }
                                          }}
                                        >
                                          Enable Internal Hooter
                                        </label>
                                      </div>
                                    </div>
                                    <div className="SiteTextBox SiteNameText mt-0">
                                      <div className="onlyforEnableCheckbox ml-0">
                                        <input
                                          className="checkbox_custom"
                                          type="checkbox"
                                          disabled={!isInterfaceEdit}
                                          {...register("CheckExternalHooter")}
                                          // External hooter
                                          onChange={(e) => {
                                            console.log("RUpesh2", Number(e.target.checked));
                                            setValue("CheckExternalHooter", Number(e.target.checked))
                                            setParentIsEnablingExternalHooter(e.target.checked);
                                            if (e.target.checked) {
                                              setValue("CheckInternalHooter", 0);
                                              setParentIsEnablingInternalHooter(false);
                                            }
                                          }}

                                          defaultValue={Number(interfaceDevice.CheckExternalHooter)}
                                        />
                                        <label className="mb-0"
                                          onClick={() => {
                                            if(!isInterfaceEdit){
                                              return;
                                            }
                                            getValues("CheckExternalHooter")
                                            console.log("parentIsEnablingExternalHooterCheck", parentIsEnablingExternalHooter)
                                            console.log("CheckExternalHooter", getValues("CheckExternalHooter"))

                                            if (parentIsEnablingExternalHooter) {
                                              setValue("CheckExternalHooter", 0)
                                              setParentIsEnablingExternalHooter(false);
                                              // setValue("CheckInternalHooter", 1);
                                              //  setParentIsEnablingInternalHooter(true);
                                            } else {
                                              setValue("CheckExternalHooter", 1)
                                              setParentIsEnablingExternalHooter(true);
                                              setValue("CheckInternalHooter", 0);
                                              setParentIsEnablingInternalHooter(false);
                                            }

                                          }}
                                        >
                                          Enable External Hooter
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            //  </div>
                             :
                             null
                           :
                           null
                       }

                       {
                         !HIDE_STUFF
                           ?
                           //getValues("CCTVTypeIDInterface")=== "DVR" ||  getValues("CCTVTypeIDInterface")=== "NVR" || getValues("CCTVTypeIDInterface")=== "Hybrid"
                           enableHooter
                             ? (
                               // displayHooter
                               // ? 
                              //  <div className="SiteTextBox SiteNameText">
                              <div className="inputMainContainer w40dot75rem mt30">
                                <div className="inputTypeLabelContainer">
                                <label className='formFieldLabels'>Enable/Disable Hooter</label>
                                 <div className="onlyforEnableCheckbox">
                                   <input
                                     className="checkbox_custom"
                                     type="checkbox"
                                     disabled={!isInterfaceEdit}
                                     {...register("IsHooterEnabled")}
                                     onChange={(e) => {
                                       console.log("checkHooter", Number(e.target.checked));
                                       setValue("IsHooterEnabled", Number(e.target.checked))
                                       setParentPanelIsEnablingHooter(e.target.checked);

                                     }}
                                     defaultValue={Number(interfaceDevice.IsHooterEnabled)}
                                   />
                                   <label className="mb-0"
                                     onClick={() => {
                                      if(!isInterfaceEdit){
                                        return;
                                      }
                                       getValues("IsHooterEnabled")
                                       console.log("setParentPanelIsEnablingHooterCheck", setParentPanelIsEnablingHooter)
                                       console.log("InterfaceTwoWayEnabled", getValues("IsHooterEnabled"))
                                       if (parentPanelIsEnablingHooter) {
                                         setValue("IsHooterEnabled", 0)
                                         setParentPanelIsEnablingHooter(false);
                                       } else {
                                         setValue("IsHooterEnabled", 1)
                                         setParentPanelIsEnablingHooter(true);
                                       }
                                     }}
                                   >
                                     Enable Hooter
                                   </label>
                                 </div>
                                </div>
                              </div>
                              //  </div>
                             ) : null
                           : null
                       }


                       {/* <div className="cctvHooterEnableDisable">
                          <div className="SiteTextBox SiteNameText" style={{height: "55px"}}>
                            <div className="onlyforEnableCheckbox">
                              <input
                                className="checkbox_custom"
                                type="checkbox"
                                disabled={!isInterfaceEdit}
                                {...register("InterfaceTwoWayEnabled")}
                                // onChange={(e) => {
                                //   console.log("RUpesh2",Number(e.target.checked));
                                //   setValue("InterfaceTwoWayEnabled", Number(e.target.checked))
                                //   setParentIsEnablingTwoWayInterface(e.target.checked);
                                // }}
                                defaultValue={Number(interfaceDevice.IsTwoWayEnabled)}
                              />
                              <p className="mb-0">
                                Enable Internal Hooter
                              </p>
                            </div>
                          </div>
                          <div className="SiteTextBox SiteNameText" style={{height: "55px"}}>
                            <div className="onlyforEnableCheckbox">
                              <input
                                className="checkbox_custom"
                                type="checkbox"
                                disabled={!isInterfaceEdit}
                                {...register("InterfaceTwoWayEnabled")}
                                // onChange={(e) => {
                                //   console.log("RUpesh2",Number(e.target.checked));
                                //   setValue("InterfaceTwoWayEnabled", Number(e.target.checked))
                                //   setParentIsEnablingTwoWayInterface(e.target.checked);
                                // }}
                                defaultValue={Number(interfaceDevice.IsTwoWayEnabled)}
                              />
                              <p className="mb-0">
                                Enable External Hooter
                              </p>
                            </div>
                          </div>
                        </div> */}

                       {/* <div className="SiteTextBox">
                                  <input
                                    {...register("communicationPort")}
                                    className="SiteNameTextBox"
                                    disabled={!isInterfaceEdit}
                                    placeholder="Enter Communication Port* e.g. 8080"
                                    // pattern="[0-9]*"
                                    // inputMode="numeric"  
                                    onKeyPress={onNumberOnlyChange}
                                    defaultValue={selectedDevice.communicationPort}
                                  />
                        </div>
                        {
                          interfaceType == NVRDVR
                          ? <div className="SiteTextBox">
                          <select
                            {...register("CCTVTypeID")}
                            // required={true}
                            className="SiteNameTextBox"
                            placeholder="Entity Subtype*"
                            disabled={!isInterfaceEdit}
                            defaultValue={selectedDevice.CCTVTypeID}
                            onChange={(e) => setValue("CCTVTypeID", e.target.value)}
                          >
                            <option key="0" disabled={false} value="0" id="0">Entity Subtype*</option>
                            {cctvTypeData && cctvTypeData.map((entity) => (
                                <option value={entity.CCTVTypeID} key={entity.CCTVType}>
                                  {entity.CCTVType}
                                </option>
                              ))}
                          </select>
                        </div>
                          : null
                        } */}

                       <div className="BulkText" hidden>
                         <div className="SentenceText">

                         </div>

                         <div className="ButtonText">
                           <button
                             className="AlertViewButton"
                            //  style={{ visibility: "collapse" }}
                           >
                              <p>Upload CSV</p>
                            </button>
                            {/* {interfaceDevice.InterfaceId == 0 && ( */}
                            {/*  {interfaceDevice.InterfaceId == 0 && (
                      <button type="submit" className="NextMoveButton addSite-next-btn">
                        <span className="ViewUpdate">Next</span>
                      </button>
                    )} */}
                            {/* {interfaceDevice.InterfaceId != 0 && ( */}
                            {/*  {interfaceDevice.InterfaceId != 0 && (
                      <>
                        {!isSiteEdit && (
                          <button
                            className="NextMoveButton updateSite-edit-btn"
                            onClick={() => setIsSiteEdit(true)}
                          >
                            <span className="ViewUpdate">Edit</span>
                          </button>
                        )}
                        {isSiteEdit && (
                          <>
                            <button type="submit" className="NextMoveButton updateSite-update-btn">
                              <span className="ViewUpdate">Update</span>
                            </button>
                            <a
                              className="btn btn-link ml-2"
                              onClick={(e) => onCancel(e)}
                            >
                              Cancel
                            </a>
                          </>
                        )}
                      </>
                    )} */}
                          </div>
                        </div>
                      </div>
                    </form>
                    {/* <ToastContainer
                      position="bottom-left"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    /> */}
                  </div>
                  {/* <div style={{ textAlign: "center"}}>
                      <button className="DataAccessEdit" style={{width: "100px"}}>
                        <span className="ViewUpdate">Add New</span>
                      </button>
                      <button className="DataAccessEdit" style={{width: "100px"}}>
                        <span className="ViewUpdate">Bulk upload</span>
                      </button>
                      <button className="DataAccessEdit" style={{width: "100px"}}>
                        <span className="ViewUpdate">Edit</span>
                      </button>
                      <button className="DataAccessEdit" style={{width: "100px"}}>
                        <span className="ViewUpdate">Save</span>
                      </button>
                      <button className="DataAccessEdit" style={{width: "100px"}}>
                        <span className="ViewUpdate">Cancel</span>
                      </button>
                      <button className="DataAccessEdit" style={{width: "100px"}}>
                        <span className="ViewUpdate">Delete</span>
                      </button> 
                    </div> */}
                  <div style={{ textAlign: "center" }}>
                    {
                      interfaceDevice.InterfaceId != 0 &&
                      (
                        <button className="DataAccessEdit" style={{ width: "100px" }}
                         onClick={() => {
                           setSelectedInterface(interfaceObj);
                           setIsInterfaceEdit(true)
                           setParentIsEnablingInternalHooter(false)
                           setParentIsEnablingExternalHooter(false)
                           setParentPanelIsEnablingHooter(false)
                           clearInterfaceFields();
                         }}
                        >
                          <span className="ViewUpdate">Add New</span>
                        </button>
                      )
                    }
                    {/* <button className="DataAccessEdit" style={{width: "100px"}}>
                      <span className="ViewUpdate">Bulk upload</span>
                    </button> */}
                    {
                      interfaceDevice.InterfaceId != 0 && !isInterfaceEdit &&
                      (
                        <button className="DataAccessEdit" style={{ width: "100px" }}
                          onClick={() => setIsInterfaceEdit(true)}
                        >
                          <span className="ViewUpdate">Edit</span>
                        </button>
                      )
                    }
                    {
                      interfaceDevice.InterfaceId == 0 &&
                      (
                        <button className="DataAccessEdit" style={{ width: "100px" }}
                          disabled={buttonDisable}
                          onClick={handleSubmit((e) => onInterfaceFormSubmit(e, interfaceDevice.InterfaceId,rtspValueEntered,playbackValueEntered))}
                        >
                          <span className="ViewUpdate">Save</span>
                        </button>
                      )
                    }
                    {
                      interfaceDevice.InterfaceId != 0 && isInterfaceEdit &&
                      (
                        <>
                          <button className="DataAccessEdit" style={{ width: "100px" }}
                          disabled={buttonDisable}
                            onClick={handleSubmit((e) => onInterfaceFormSubmit(e, interfaceDevice.InterfaceId,rtspValueEntered,playbackValueEntered))}
                          >
                            <span className="ViewUpdate">Save</span>
                          </button>
                          <button className="DataAccessEdit" style={{ width: "100px" }} onClick={(e) => onCancelInterface(e)} >
                            <span className="ViewUpdate">Cancel</span>
                          </button>
                        </>
                      )
                    }
                    {
                      interfaceDevice.InterfaceId != 0 && !isInterfaceEdit &&
                      (
                        <button className="DataAccessEdit" style={{ width: "100px" }}
                          // onClick={(e) => onDeleteChange(e, site.SiteID, site.SiteName)}>
                          disabled={buttonDisable}
                          onClick={(e) => onDeleteInterfaceChange(e, interfaceDevice.InterfaceId, interfaceDevice.InterfaceName)}>
                          <span className="ViewUpdate">Delete</span>
                        </button>
                      )
                    }
                  </div>
                </div>
              </div>
              :
              <div className="SiteMainContainer">
                <div className="SiteLeftContainer">
                  <div className="m-2 HeaderSiteList">
                    <input
                      id="Search_txt_device"
                      className="SearchViewInput1"
                      // ref={focusSearchDiv}
                      type="text"
                      onChange={(e) => {
                        setSearchTextDevice(e.target.value)
                        onSearchChange(e.target.value, "Device List", interfaceType)
                      }
                      }
                      placeholder="Search device"
                      autoFocus
                      value={searchTextDevice}
                    />
                    <div className="SearchTextDivConfigTool">
                      {
                        isDarkTheme ?
                        <img
                        className="EyeIcon"
                        src={SearchIcon}
                        alt="search icon"
                      // onClick={() => ToggeleSearch()}
                      /> :
                      <img 
                        className="EyeIcon"
                        src={SearchIconWhite}
                        alt="search icon" height="15"
                        // onClick={() => ToggeleSearch()}
                      />
                      }
                      {/* {!issearch && (
                <img
                  className="EyeIcon"
                  src={Cross}
                  onClick={() => ToggeleSearch()}
                />
              )} */}
                    </div>
                  </div>
                  <div className="StepperSiteList">
                    {
                      interfaceType === NVRDVR
                        ?
                        (
                          cameradeviceslist ? (
                          cameradeviceslist?.length > 0
                            ?
                            cameradeviceslist.map((item) => {
                              return (
                                <div
                                  className={selectedDevice?.ParentCameraID === item.ParentCameraID ? "OneList SelectedList" : "OneList"}
                                  key={item.ParentCameraID}
                                  onClick={(e) => {
                                    onDeviceSelection(item)
                                    setIsChannelNumberEdited(false)
                                    clearCameraFields()
                                  }}
                                >
                                  <p className="ListofText">
                                    {item.CameraName} 
                                    <span className="SiteNameText"> - {item.ParentCameraID}</span>
                                    {/* <span className="SiteNameText"> - {site.BranchID}</span> */}
                                  </p>
                                  <div>
                                    {/* <img className="m-2" src={EditQc} />
                      <img
                        src={DeleteQc}
                        onClick={() => checkForAlertsAndDelDevice(item)}
                        // onClick={(e) =>
                        //   onDeleteChange(e, site.SiteID, site.SiteName)
                        // }
                      /> */}
                                  </div>
                                </div>
                              );
                            })
                            : ( <div className="no-data-found">No Camera Found.</div>)
                          )
                          : (!cameradeviceslist && (
                            <div className="no-data-found">No Camera Available.</div>
                          ))
                          
                        )
                        :
                        (
                          paneldeviceslist ? (
                            paneldeviceslist?.length > 0
                              ?
                              paneldeviceslist.map((item) => {
                                return (
                                  <div
                                    className={selectedDevice?.ParentCameraID === item.ParentCameraID ? "OneList SelectedList" : "OneList"}
                                    key={item.ParentCameraID}
                                    onClick={(e) => onDeviceSelection(item)}
                                  >
                                    <p className="ListofText">
                                      {item.CameraName}
                                      <span className="SiteNameText"> - {item.ParentCameraID}</span>
                                    </p>
                                    <div>
                                      {/* <img className="m-2" src={EditQc} />
                        <img
                          src={DeleteQc}
                          onClick={() =>
                            OnAddIDSPanel(
                              null,
                              item.ParentCameraID,
                              "deletePanel"
                            )
                          }
                        /> */}
                                    </div>
                                  </div>
                                );
                              })
                              : (
                                <div className="no-data-found">No Panel Found.</div>
                              )
                          )
                          : (!paneldeviceslist && (
                            <div className="no-data-found">No Panel Available.</div>
                          ))
                        )
                    }
                  </div>
                </div>
                <div className="SiteRightContainer">
                  {/* <div className="siteformContainer"> */}
                    {
                      interfaceType === NVRDVR
                        ?
                        <>
                          <h1 className="MainHeaderName">Camera Details</h1>
                          {/* <h1 className="MainHeaderName">{`Device Details > Bulk Upload`}</h1> */}
                          <div className="siteformContainer">
                          {
                            bulkUpload
                              ? <CameraBulkUpload
                                selectedCamHeaders={selectedCamHeaders}
                                OnAddBulkDevicesClick={OnAddBulkDevicesClick}
                                setSelectedCamHeaders={setSelectedCamHeaders}
                                clearImportedCamHeaders={clearImportedCamHeaders}
                                deviceBulkUploadResponse={deviceBulkUploadResponse}
                                setImportedFileData={setImportedFileData}
                                setDeviceBulkUploadResponse={setDeviceBulkUploadResponse}
                                handleSingleUploadClick={handleSingleUploadClick}
                                setBulkUpload={setBulkUpload}
                                setIsSingleOrBulkSelection={setIsSingleOrBulkSelection}
                                importedFileHeaders={importedFileHeaders}
                                setImportedFileHeaders={setImportedFileHeaders}
                              />
                              :
                              // <form
                              // onSubmit={handleSubmit((e) => OnSaveDevice(e, selectedDevice.ParentCameraID))}
                              // >
                              
                              <div className="site-form" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                {/* <div className="SiteTextBox"> */}
                                <div className="inputMainContainer w40dot75rem">
                                <div className="inputTypeLabelContainer">
                                  <label className="formFieldLabels">Brand Name</label>
                                  <select
                                    {...register("CameraBrandId")}
                                    // className="SiteNameTextBox"
                                    className="inputType ArrowSelectClass"
                                    disabled={true}
                                    placeholder="Select Brand Name"
                                    defaultValue={selectedDevice.cameraBrandId || selectedDevice.CameraBrandId}
                                    onChange={(e) => {
                                      setValue("CameraBrandId", e.target.value);
                                      handleOptionChange(e.target.value)
                                    }}
                                  >
                                    <option key="0" disabled={false} value="0">Select Brand Name*</option>

                                    {devicesmasterlist &&
                                      devicesmasterlist.cameraTypeMasterList &&
                                      devicesmasterlist.cameraTypeMasterList.map((val) => (
                                        <option value={val.brandId} key={val.brandId}>
                                          {val.brandName}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                </div>
                                {/* </div> */}

                                {/* <div className="SiteTextBox"> */}
                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>IP Address</label>
                                      <input
                                        {...register("privateIPAddress")}
                                        // className="SiteNameTextBox"
                                        className="inputType"
                                        disabled={true}
                                        placeholder="IP Address* e.g. 192.168.0.54"
                                        defaultValue={selectedDevice.privateIPAddress}
                                      />
                                  </div>
                                </div>
                                {/* </div> */}

                                {/* <div className="SiteTextBox"> */}
                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Communication Port</label>
                                    <input
                                      {...register("communicationPort")}
                                      // className="SiteNameTextBox"
                                      className="inputType"
                                      disabled={!isDeviceEdit}
                                      placeholder="Enter Communication Port* e.g. 8080"
                                      // pattern="[0-9]*"
                                      // inputMode="numeric"  
                                      onKeyPress={(e) =>{
                                        onNumberOnlyChange(e);
                                        setCommunicationPortError('')
                                      }}
                                      onChange={() => setCommunicationPortError('')}
                                      defaultValue={selectedDevice.communicationPort}
                                    />
                                    <p className="Error_P" style={{float: "left"}}><span> {communicationPortError} </span></p>
                                  </div>
                                </div>
                                {/* </div> */}

                               {/* <div className="SiteTextBox"> */}
                               <div className="inputMainContainer w40dot75rem mt35">
                                <div className="inputTypeLabelContainer">
                                  <label className='formFieldLabels'>Http Port</label>    
                                  <input
                                    {...register("httpPort")}
                                    // className="SiteNameTextBox"
                                    className="inputType"
                                    disabled={!isDeviceEdit}
                                    placeholder="Enter Http Port* e.g. 443"
                                    // pattern="[0-9]*"
                                    // inputMode="numeric"  
                                    onKeyPress={(e) =>{
                                      onNumberOnlyChange(e);
                                      setHttpPotError('')
                                    }}
                                    onChange={() => setHttpPotError('')}
                                    defaultValue={selectedDevice.httpPort}
                                  />
                                  <p className="Error_P" style={{float: "left"}}><span> {httpPortError} </span></p>
                                </div>
                               </div>
                               {/* </div> */}

                                {/* <div className="SiteTextBox"> */}
                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>User Name</label>
                                    <input
                                      {...register("userName")}
                                      // className="SiteNameTextBox"
                                      className="inputType"
                                      disabled={true}
                                      placeholder="User Name* e.g. Admin"
                                      defaultValue={selectedDevice.camUserName}
                                    />
                                  </div>
                                </div>
                                {/* </div> */}

                                {/* <div className="SiteTextBox"> */}
                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Password</label>
                                    <input
                                      {...register("password")}
                                      // className="SiteNameTextBox"
                                      className="inputType"
                                      disabled={true}
                                      placeholder="Password* e.g. Abc@123"
                                      defaultValue={selectedDevice.camPassword}
                                    />
                                  </div>
                                </div>
                                {/* </div> */}

                                {/* <div className="SiteTextBox"> */}
                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>RTSP Port</label>
                                    <input
                                      {...register("port")}
                                      // className="SiteNameTextBox"
                                      className="inputType"
                                      placeholder="RTSP Port* e.g. 554"
                                      disabled={true}
                                      // pattern="[0-9]*"
                                      // inputMode="numeric"
                                      onKeyPress={onNumberOnlyChange}
                                      defaultValue={selectedDevice.camPort}
                                    />
                                  </div>
                                </div>
                                {/* </div> */}

                                {/* <div className="SiteTextBox"> */}
                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Channel Number</label>
                                    <input
                                      {...register("channelNumber")}
                                      // className="SiteNameTextBox"
                                      className="inputType"
                                      disabled={!isDeviceEdit}
                                      // placeholder="Channel Number* e.g. 1"
                                      placeholder={channelNumberPlaceholder}
                                      onKeyPress={(e)=>{
                                        setIsChannelNumberEdited(true)
                                        onNumberOnlyChange(e)
                                        setChannelNumberError('')
                                      }}
                                      onChange={() => {setChannelNumberError('')}}
                                    //   onChange={
                                    //   ()=>setIsChannelNumberEdited(true)
                                    // }
                                      defaultValue={selectedDevice.channelNum}
                                      />
                                      <p className="Error_P" style={{float: "left"}}><span>{channelNumberError}</span></p>
                                  </div>
                                </div>
                                {/* </div> */}

                                {/* <div className="SiteTextBox">
                                  <input
                                    {...register("channelNumber")}
                                    className="SiteNameTextBox"
                                    disabled={!isDeviceEdit}
                                    // placeholder="Channel Number* e.g. 1"
                                    placeholder={channelNumberPlaceholder}
                                    onKeyPress={()=>{
                                      // setIsChannelNumberEdited(true)
                                      // onNumberOnlyChange()
                                    
                                    }}
                                  //   onChange={
                                  //   ()=>setIsChannelNumberEdited(true)
                                  // }
                                    defaultValue={selectedDevice.SubTypePlayback}
                                    />
                                </div> */}
                                
                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Live Stream Type</label>
                                    {/* <input
                                    {...register("liveStreamTypeNumber")}
                                    // className="SiteNameTextBox"
                                    className="inputType"
                                    disabled={!isDeviceEdit}
                                    placeholder="Stream type (Live)* e.g. 1"
                                    onKeyPress={() => {
                                      // setIsChannelNumberEdited(true)
                                      // onNumberOnlyChange()
                                      setLiveStreamTypeError('')
                                    }}
                                    defaultValue={selectedDevice.SubTypeLive}
                                  /> */}
                                   <select
                                    {...register("liveStreamTypeNumber")}
                                    className="inputType ArrowSelectClass"
                                    disabled={!isDeviceEdit}
                                    placeholder="Stream type (Live)*"
                                    onChange={(e) => {
                                      setValue('liveStreamTypeNumber', e.target.value)
                                      setLiveStreamTypeError("");
                                    }}
                                   >
                                      <option value="">Select Live Stream Type</option>
                                      <option value="Main">Main</option>
                                      <option value="Sub">Sub</option>
                                      <option value="Ter">Ter</option>
                                    </select> 

                                  <p className="Error_P" style={{float: "left"}}><span>{liveStreamTypeError}</span></p> 
                                  </div>
                                </div>

                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Playback Stream Type</label>
                                    {/* <input
                                    {...register("playbackStreamTypeNumber")}
                                    // className="SiteNameTextBox"
                                    className="inputType"
                                    disabled={!isDeviceEdit}
                                    placeholder="Stream type (Playback)* e.g. 1"
                                    onKeyPress={()=>{
                                      // setIsChannelNumberEdited(true)
                                      // onNumberOnlyChange()
                                      setPlaybackStreamTypeError('');
                                    }}
                                    defaultValue={selectedDevice.SubTypePlayback}
                                    /> */}
                                    <select
                                    {...register("playbackStreamTypeNumber")}
                                    className="inputType ArrowSelectClass"
                                    disabled={!isDeviceEdit}
                                    placeholder="Stream type (Playback)*"
                                    onChange={(e) => {
                                      setValue('playbackStreamTypeNumber', e.target.value)
                                      setPlaybackStreamTypeError("");
                                    }}
                                   >
                                      <option value="">Select Playback Stream Type</option>
                                      <option value="Main">Main</option>
                                      <option value="Sub">Sub</option>
                                      <option value="Ter">Ter</option>
                                    </select> 
                                  <p className="Error_P" style={{float: "left"}}><span>{playbackStreamTypeError}</span></p> 
                                  </div>
                                </div>

                                {/* <div className="SiteTextBox"> */}
                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>RTSP URL</label>
                                    <input
                                      {...register("rTSPURL")}
                                      // className="SiteNameTextBox"
                                      className="inputType"
                                      // onClick={(e) => resetrtspurl(e.target)}
                                      disabled={true}
                                      placeholder="RTSP URL* e.g. rtsp://Admin:Abc@123@192.168.0.58:554/Streaming/Channels/1"
                                      defaultValue={selectedDevice.RTSPURL}
                                      onChange={()=>{ setRtspValueChanged(true) 
                                        setrtspURLError('') 
                                      }}
                                      />
                                      <p className="Error_P" style={{float: "left"}}><span>{rtspURLError}</span></p> 
                                  </div>
                                </div>
                                {/* </div> */}

                                {/* <div className="SiteTextBox"> */}
                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Device Name</label>
                                    <input
                                      {...register("cameraName")}
                                      // {...deviceNameInput}
                                      // className="SiteNameTextBox"
                                      className="inputType"
                                      disabled={!isDeviceEdit}
                                      placeholder="Enter Device Name* e.g. Lobby Camera"
                                      defaultValue={selectedDevice.CameraName}
                                      onKeyPress={(e) => {
                                        onSpecialChangeOnly(e);
                                        setDeviceNameError('')
                                      }}
                                      onChange={() => {setDeviceNameError('')}}
                                    />
                                    <p className="Error_P" style={{float: "left"}}><span>{deviceNameError}</span></p> 
                                  </div>
                                </div>
                                {/* </div> */}

                                {/* <div className="SiteTextBox"> */}
                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Branch Name</label>
                                    <select
                                      {...register("SiteID")}
                                      // className="SiteNameTextBox"
                                      className="inputType ArrowSelectClass"
                                      placeholder="Branch*"
                                      disabled={true}
                                      defaultValue={selectedDevice.SiteID}
                                      onChange={(e) => setValue("SiteID", e.target.value)}
                                    >
                                      <option key="0" disabled={false} value="0">Select Branch*</option>
                                      {sites &&
                                        sites.map((site) => (
                                          <option value={site.SiteID} key={site.SiteID}>
                                            {site.SiteName}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </div>
                                {/* </div> */}

                                {/* <div className="SiteTextBox"> */}
                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Entity Subtype</label>
                                    <select
                                      {...register("CCTVTypeID")}
                                      // required={true}
                                      // className="SiteNameTextBox"
                                      className="inputType ArrowSelectClass"
                                      placeholder="Entity Subtype*"
                                      disabled={true}
                                      defaultValue={selectedDevice.CCTVTypeID}
                                      onChange={(e) => 
                                        setValue("CCTVTypeID", e.target.value)
                                        
                                      }
                                    >
                                      <option key="0" disabled={false} value="0" id="0">Entity Subtype*</option>
                                      {cctvTypeData && cctvTypeData.map((entity) => (
                                          <option value={entity.CCTVTypeID} key={entity.CCTVTypeID}>
                                            {entity.CCTVType}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </div>
                                {/* </div> */}

                                        {/* <p className="SiteNameText onlyforEnableCheckbox">
                                    <input
                                      className="checkbox_custom"
                                      type="checkbox"
                                      {...register("EnableTranscoding")}
                                      onChange={(e) => setValue("EnableTranscoding", e.target.value)}
                                    />
                                    <p className="mb-0">
                                      Enable Transcoding to H.264 <sup>(Optional)</sup>
                                    </p>
                                </p> */}
                               {/* <div className="SiteTextBox SiteNameText"> */}
                               <div className="inputMainContainer w40dot75rem mt30">
                                <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Enable Transcoding</label>
                                    <div className='mt-1 signTypeCheckboxLabels'>
                                      <div className="SiteTextBox SiteNameText mt-0">
                                        <div className="onlyforEnableCheckbox ml-0">
                                          <input
                                            className="checkbox_custom"
                                            type="checkbox"
                                            disabled={!isDeviceEdit}
                                            {...register("isTranscoded")}
                                            onChange={(e) => {
                                              setValue("isTranscoded", Number(e.target.checked))
                                              setParentIsEncoding(e.target.checked);
                                            }}
                                            defaultValue={Number(selectedDevice.isTranscoded)}
                                          />
                                          <label className="mb-0"
                                          
                                            onClick={() => {
                                              if(!isDeviceEdit){
                                                return;
                                              }
                                              getValues("isTranscoded")
                                              console.log("setParentIsEncodingCheck", setParentIsEncoding)
                                              console.log("parentIsEncodingCheck", parentIsEncoding)
                                              console.log("isTranscoded", getValues("isTranscoded"))
                                              if (parentIsEncoding) {
                                                setValue("isTranscoded", 0)
                                                setParentIsEncoding(false);
                                              } else {
                                                setValue("isTranscoded", 1)
                                                setParentIsEncoding(true);
                                              }
                                            }}
                                          >
                                            Live Enable Transcoding to H.264 <sup>(Optional)</sup>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="SiteTextBox SiteNameText mt-0">
                                        <div className="onlyforEnableCheckbox">
                                          <input
                                            className="checkbox_custom"
                                            type="checkbox"
                                            disabled={!isDeviceEdit}
                                            {...register("isPlaybackTranscoded")}
                                            onChange={(e) => {
                                              setValue("isPlaybackTranscoded", Number(e.target.checked))
                                              setParentIsPlaybackEncoding(e.target.checked);
                                            }}
                                            defaultValue={Number(selectedDevice.isPlaybackTranscoded)}
                                          />
                                          <label className="mb-0"
                                            onClick={() => {
                                              getValues("isPlaybackTranscoded")
                                              console.log("setParentIsPlaybackEncodingChcek", setParentIsPlaybackEncoding)
                                              console.log("isPlaybackTranscoded", getValues("isPlaybackTranscoded"))
                                              if (parentIsPlaybackEncoding) {
                                                setValue("isPlaybackTranscoded", 0)
                                                setParentIsPlaybackEncoding(false);
                                              } else {
                                                setValue("isPlaybackTranscoded", 1)
                                                setParentIsPlaybackEncoding(true);
                                              }
                                            }}
                                          >
                                            Playback Enable Transcoding to H.264 <sup>(Optional)</sup>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                               </div>
                               {/* </div> */}
                                
                               

                               {interfaceDevice.IsTwoWayEnabled == "1" && 
                              //  <div className="SiteTextBox SiteNameText">
                                <div className="inputMainContainer w40dot75rem">
                                  <div className="inputTypeLabelContainer">
                                  <label className='formFieldLabels'>Enable Two Way Communication</label>
                                  <div className="onlyforEnableCheckbox">
                                    <input
                                      className="checkbox_custom"
                                      type="checkbox"
                                      disabled={!isDeviceEdit}
                                      {...register("isTwoWayEnabledDevice")}
                                      onChange={(e) => {
                                        setValue("isTwoWayEnabledDevice", Number(e.target.checked))
                                        setParentIsEnablingTwoWayDevice(e.target.checked);
                                      }}
                                      defaultValue={Number(selectedDevice.isTwoWayEnabled)}
                                    />
                                    <label className="mb-0"
                                      onClick={() => {
                                        if(!isDeviceEdit){
                                          return;
                                        }
                                        getValues("isTwoWayEnabledDevice")
                                        console.log("setParentIsEnablingTwoWayInterfaceChcek", setParentIsEnablingTwoWayDevice)
                                        console.log("isTwoWayEnabledDevice", getValues("isTwoWayEnabledDevice"))
                                        if (parentIsEnablingTwoWayDevice) {
                                          setValue("isTwoWayEnabledDevice", 0)
                                          setParentIsEnablingTwoWayDevice(false);
                                        } else {
                                          setValue("isTwoWayEnabledDevice", 1)
                                          setParentIsEnablingTwoWayDevice(true);
                                        }
                                      }}
                                    >
                                      Enable Two Way Communication
                                    </label>
                                  </div>
                                  </div>
                                 </div>
                               // </div>
                               }

                              </div>
                          
                            /* <div className="ButtonDiv" style={{margin: "0px 15px 15px"}}>
                             {selectedDevice.ParentCameraID == 0 ? (
                               <button type="submit" className="NextMoveButton addUpdateCamera-btn manageDevices-addCamera-btn"
                               disabled={isSubmitting} >
                                 <span className="ViewUpdate">Add Camera</span>
                               </button>
                             ) : (
                               <button type="submit" className="NextMoveButton addUpdateCamera-btn manageDevices-updateCamera-btn">
                                 <span className="ViewUpdate">Update Camera</span>
                               </button>
                             )}
                           </div> */
                            //  </form>
                          }
                          </div>
                          {/* }
                          </div> */}
                          {/* <ToastContainer
                            position="bottom-left"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                          /> */}
                        </>
                        :
                        <>
                          <h1 className="MainHeaderName">Panel Details</h1>
                          <div className="siteformContainer">
                          {
                            bulkUpload
                              ? <PanelBulkUpload
                                selectedHeaders={selectedHeaders}
                                onPanelBulkUpload={onPanelBulkUpload}
                                setSelectedHeaders={setSelectedHeaders}
                                clearImportedPanelHeaders={clearImportedPanelHeaders}
                                setImportedFileData={setImportedFileData}
                                panelBulkUploadResponse={panelBulkUploadResponse}
                                setPanelBulkUploadResponse={setPanelBulkUploadResponse}
                                panelBulkUploadError={panelBulkUploadError}
                                setPanelBulkUploadError={setPanelBulkUploadError}
                                setBulkUpload={setBulkUpload}
                                setIsSingleOrBulkSelection={setIsSingleOrBulkSelection}
                                importedPanelHeaders={importedPanelHeaders}
                                setImportedPanelHeaders={setImportedPanelHeaders}
                              />
                              :
                              <>
                                <form>
                                  <div className="site-form" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                    {/* <p className="SiteNameText">Brand Name <span style={{ color: "red" }}> *</span></p> */}
                                    {/* <div className="SiteTextBox"> */}
                                    <div className="inputMainContainer w40dot75rem">
                                      <div className="inputTypeLabelContainer">
                                        <label className='formFieldLabels'>Brand Name</label>
                                        <select
                                          {...register("PanelMasterId")}
                                          // className="SiteNameTextBox"
                                          className="inputType ArrowSelectClass"
                                          placeholder="Select Brand Name"
                                          disabled
                                          defaultValue={selectedDevice.panelMasterId}
                                          onChange={(e) => setValue("PanelMasterId", e.target.value)}
                                        >
                                          <option key="0" disabled={false} value="">Brand Name*</option>
                                          {devicesmasterlist &&
                                            devicesmasterlist.panelTypeMasterList &&
                                            devicesmasterlist.panelTypeMasterList.map((site) => (
                                              <option value={site.panelMasterID} key={site.panelName}>
                                                {site.panelName}
                                              </option>
                                            ))}
                                        </select>
                                      </div>
                                    </div>
                                    {/* </div> */}

                                    {/* <div className="SiteTextBox"> */}
                                    <div className="inputMainContainer w40dot75rem">
                                      <div className="inputTypeLabelContainer">
                                        <label className='formFieldLabels'>Panel Name</label>
                                        <input
                                          type="text"
                                          {...register("cameraName")}
                                          // className="SiteNameTextBox"
                                          className="inputType"
                                          // disabled={!isDeviceEdit}
                                          disabled={true}
                                          placeholder="Enter Panel name* e.g. Thane panel"
                                          defaultValue={selectedDevice.CameraName}
                                          onKeyPress={onSpecialChangeOnly}
                                        />
                                      </div>
                                    </div>
                                    {/* </div> */}

                                    {/* <div className="SiteTextBox"> */}
                                    <div className="inputMainContainer w40dot75rem">
                                      <div className="inputTypeLabelContainer">
                                        <label className='formFieldLabels'>IP Address</label>
                                        <input
                                          {...register("privateIPAddress")}
                                          // className="SiteNameTextBox"
                                          className="inputType"
                                          disabled={true}
                                          placeholder="IP address* e.g. 192.168.0.54"
                                          defaultValue={selectedDevice.privateIPAddress}
                                        />
                                      </div>
                                    </div>
                                    {/* </div> */}

                                    {/* <div className="SiteTextBox"> */}
                                    <div className="inputMainContainer w40dot75rem">
                                      <div className="inputTypeLabelContainer">
                                        <label className='formFieldLabels'>Site</label>
                                        <select
                                          {...register("SiteID")}
                                          // className="SiteNameTextBox"
                                          className="inputType ArrowSelectClass"
                                          placeholder="Site"
                                          disabled={true}
                                          defaultValue={selectedDevice.SiteID}
                                          onChange={(e) => setValue("SiteID", e.target.value)}
                                        >
                                          <option key="0" disabled={false} value="0">Branch Name*</option>
                                          {sites &&
                                            sites.map((site) => (
                                              <option value={site.SiteID} key={site.SiteID}>
                                                {site.SiteName}
                                              </option>
                                            ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  {/* </div> */}

                                  {/* {selectedDevice.ParentCameraID == 0 ? (
                        <div className="m-2 text-right" style={{ textAlign: "end" }}>
                          <button
                            className="NextMoveButton m-3 manageDevices-addPanel-btn"
                            onClick={handleSubmit((e) =>
                              OnAddIDSPanel(
                                e,
                                selectedDevice.ParentCameraID,
                                "addPanelDevice"
                              )
            
                            )}
                          >
                            <span className="ViewUpdate">Add Panel</span>
                          </button>
                        </div>
                      ) : (
                        <div className="m-2 text-right" style={{ textAlign: "end" }}>
                          <button
                            className="NextMoveButton m-3 manageDevices-updatepanel-btn"
                            onClick={handleSubmit((e) =>
                              OnAddIDSPanel(
                                e,
                                selectedDevice.ParentCameraID,
                                "updatePanelDevice"
                              )
                            )}
                          >
                            <span className="ViewUpdate">Update Panel</span>
                          </button>
                        </div>
                      )}  */}
                                </form>
                                {associateCamSecVisibility && (
                                  <ConfigurePanelZones
                                    selectedDevice={selectedDevice}
                                    devicesmasterlist={devicesmasterlist}
                                    OnAddIDSPanel={OnAddIDSPanel}
                                    setselectedZone={setselectedZone}
                                    selectedZone={selectedZone}
                                    isDeviceEdit={isDeviceEdit}
                                  />
                                )}
                                {associateCamSecVisibility && (
                                  <AssociateCameraOnSensor
                                    selectedDevice={selectedDevice}
                                    onSensorSelection={onSensorSelection}
                                    associatedDevices={associatedDevices}
                                    onAssociate={onAssociate}
                                    selectedSensor={selectedSensor}
                                    selectedItems={selectedItems}
                                    setSelectedItems={setSelectedItems}
                                    isDeviceEdit={isDeviceEdit}
                                  />
                                )}
                              </> 
                          }
                          </div>
                          {/* <ToastContainer
                            position="bottom-left"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                          /> */}

                        </>
                    }
                  {/* </div> */}
                  <div style={{ textAlign: "center" }}>
                    {
                      selectedDevice.ParentCameraID != 0 && interfaceType === NVRDVR && 
                      (
                        <button className="DataAccessEdit" style={{ width: "100px" }}
                          onClick={() => {
                            //let temp = interfaceDevice.InterfacesubtypeId;
                            // if (temp === 7) {
                            //   temp = 2.;
                            // } else if (temp === 8) {
                            //   temp = 3;
                            // } else if (temp === 9 ){
                            //   temp = 4;
                            // }else{
                            //   temp = temp;
                            // }

                            let temp = interfaceDevice.InterfacesubtypeId;
                            let v1 = devicesmasterlist.cameraTypeMasterList.filter(x => x.brandName.toLowerCase() == interfaceDevice.Interfacesubtypename.toLowerCase());
                            if(v1 != undefined && v1.length > 0){
                              temp = v1[0].brandId;
                            }
                             
                            let deviceObject = {
                              "ParentCameraID": 0,
                              "cameraName": '',
                              "privateIPAddress": interfaceDevice.InterfaceIP,
                              "SiteID": interfaceDevice.siteid,
                              "userName": interfaceDevice.UserName,
                              "password": interfaceDevice.Password,
                              "port": interfaceDevice.InterfacePort,
                              "channelNumber": '',
                              // "playbackChannelNumber": '',
                              "liveStreamTypeNumber": '',
                              "playbackStreamTypeNumber": '',
                              "rTSPURL": '',
                              "location": '',
                              "PanelMasterId": 0,
                              "CameraBrandId": temp,
                              "communicationPort": '',
                              "httpPort": '',
                              "isTranscoded": 0,
                              "isPlaybackTranscoded" :0,
                              "isTwoWayEnabledDevice": 0,
                              "CCTVTypeID": interfaceDevice.EntityTypeID
                            }
                            setSelectedDevice(deviceObject);
                            // setSelectedDevice(deviceObj);
                            setIsDeviceEdit(true)
                            setAssociateCamSecVisibility(false);
                            setParentIsEncoding(false);
                            setParentIsPlaybackEncoding(false);
                            setParentIsEnablingTwoWayDevice(false)
                            setParentIsEnablingTwoWayInterface(false)
                            setParentIsEnablingPlaybackTimeStampsToUTC(false)
                            setParentIsEnablingPlaybackLongDateFormat(false)
                            clearCameraFields()
                          }
                          }
                        >
                          <span className="ViewUpdate">Add New</span>
                        </button>
                      )
                    }
                    {/* rupesh gupta */}
                    {
                      selectedDevice.ParentCameraID == 0 && interfaceType === NVRDVR && !bulkUpload &&
                      (
                        <button className="DataAccessEdit" style={{ width: "100px" }} onClick={() => handleBulkUploadClick()}>
                          <span className="ViewUpdate">Bulk upload</span>
                        </button>
                      )
                    }
                    {
                      selectedDevice.ParentCameraID == 0 && interfaceType === NVRDVR && bulkUpload &&
                      (
                        <button className="DataAccessEdit" style={{ width: "100px" }}
                          onClick={handleSubmit((e) => {
                            OnAddBulkDevicesClick(e, importedFileHeaders);
                          })}
                        >
                          <span className="ViewUpdate">Upload</span>
                        </button>
                      )
                    }
                    {
                      interfaceType === PANEL && !bulkUpload &&
                      (
                        <button className="DataAccessEdit" style={{ width: "100px" }} onClick={() => handleBulkUploadClick()}>
                          <span className="ViewUpdate">Bulk upload</span>
                        </button>
                      )
                    }
                    {
                      interfaceType === PANEL && bulkUpload &&
                      (
                        <button className="DataAccessEdit" style={{ width: "100px" }}
                          onClick={handleSubmit((e) => {
                            onPanelBulkUpload(e, importedPanelHeaders);
                          })}
                        >
                          <span className="ViewUpdate">Upload</span>
                        </button>
                      )
                    }
                    {
                      selectedDevice.ParentCameraID != 0 && !isDeviceEdit && selectedDevice.panelMasterId != 0 && interfaceType === NVRDVR &&
                      (
                        <button className="DataAccessEdit" style={{ width: "100px" }}
                          onClick={() => {
                            setIsDeviceEdit(true);
                          }}
                        >
                          <span className="ViewUpdate">Edit</span>
                        </button>
                      )
                    }

                    {
                      selectedDevice.ParentCameraID == 0 && interfaceType === NVRDVR && !bulkUpload &&
                      (
                        <button className="DataAccessEdit" style={{ width: "100px" }}
                          onClick={handleSubmit((e) => {
                            OnSaveDevice(e, selectedDevice.ParentCameraID)
                          }
                          )}
                          disabled={isSubmitting}
                        >
                          <span className="ViewUpdate">Save</span>
                        </button>
                      )
                    }
                    {
                      selectedDevice.ParentCameraID != 0 && isDeviceEdit && interfaceType === NVRDVR &&
                      (
                        <>
                          <button className="DataAccessEdit" style={{ width: "100px" }}
                            onClick={handleSubmit((e) => {
                              OnSaveDevice(e, selectedDevice.ParentCameraID)
                            })}
                            disabled={isSubmitting}
                          >
                            {/* <span className="ViewUpdate">Update</span> */}
                            <span className="ViewUpdate">Save</span>
                          </button>
                          <button className="DataAccessEdit" style={{ width: "100px" }} onClick={(e) => onCancelDevice(e)}>
                            <span className="ViewUpdate">Cancel</span>
                          </button>
                        </>
                      )
                    }
                    {/* {
                      selectedDevice.ParentCameraID == 0 && interfaceType === PANEL && !bulkUpload &&
                      (
                        <button className="DataAccessEdit" style={{ width: "100px" }}
                          onClick={handleSubmit((e) => OnAddIDSPanel(e, selectedDevice.ParentCameraID, "addPanelDevice"))}
                        >
                          <span className="ViewUpdate">Save</span>
                        </button>
                      )
                    } */}
                    {/* Above Line Commented as discussed with Navanita on 17-09-2023 */}
                    {/* {
                      selectedDevice.ParentCameraID != 0 && isDeviceEdit && interfaceType === PANEL && 
                      (
                        <>
                          <button className="DataAccessEdit" style={{ width: "100px" }}
                            onClick={handleSubmit((e) => OnAddIDSPanel(e, selectedDevice.ParentCameraID, "updatePanelDevice"))}
                          >
                            <span className="ViewUpdate">Save</span>
                          </button>
                          <button className="DataAccessEdit" style={{ width: "100px" }} onClick={(e) => onCancelDevice(e)}>
                            <span className="ViewUpdate">Cancel</span>
                          </button>
                        </>
                      )
                    } */}
                    {
                      selectedDevice.ParentCameraID != 0 && !isDeviceEdit && interfaceType === NVRDVR &&
                      (
                        <button className="DataAccessEdit" style={{ width: "100px" }} onClick={() => {
                          interfaceType === NVRDVR
                            ? checkForAlertsAndDelDevice(selectedDevice)
                            : OnAddIDSPanel(null, selectedDevice.ParentCameraID, "deletePanel")
                        }}>
                          <span className="ViewUpdate">Delete</span>
                        </button>
                      )
                    }
                              </div> 
                </div>
              </div>
        }



        <Modal size="lg" show={show} onHide={() => setShow(false)} dialogClassName="modal-90w" backdrop="static" className="AddCustomerModal">
          <>
            <Modal.Header >
              <Modal.Title id="example-custom-modal-styling-title">
                Add Customer
              </Modal.Title>
              <img 
              src={RemoveIcon}
              style={{cursor: "pointer"}}
              // onClick={handleClose()}
              onClick={() => {
                setShow(false)
                setAddCustomerError('')
              }}
              />
            </Modal.Header>

            <Modal.Body>
            <div className="inputMainContainer w40dot75rem w-100">
            <div className="inputTypeLabelContainer">
              <label className='formFieldLabels'>Customer Name</label>
              <input style={{ width: "40vw" }}
                type="text"
                // className="SiteNameTextBox"
                className="inputType"
                placeholder="Add Customer"
                {...register("AddCustomerName")}
                onChange={(e) => setAddCustomerError('')}
              />
              <p className="Error_P" style={{float: "left"}}><span>{addCustomerError}</span></p> 
            </div>
            </div>
            <button className="DataAccessEdit mt-3" style={{ width: "100px" }}  onClick={addCustomer}>
                            <span className="ViewUpdate">Add</span>
                          </button>
              {/* <button className="NextMoveButton addSite-next-btn" onClick={addCustomer}>
                <span className="ViewUpdate">Add</span>
              </button> */}

            </Modal.Body>
          </>
        </Modal>

        {/* {site.SiteID != 0 && (
          <div className="ManageSite addUser-container">
            <h1 className="MainHeaderName mb-0">2. Add User to Site</h1>
            <div className="ManageSiteDiv">
              <div className="DevicesHeader">
                <p className="mb-0" style={{ width: "40%" }}>
                  Users
                  <br />
                </p>
                <p className="mb-0">
                  Configure Users to Site
                  <br />
                </p>
              </div>

              <div className="site-group">
                <div className="site-group-list"
                >
                  {emeContactList &&
                    emeContactList.map((item) => {
                      return (
                        <div
                          id={item.EmergencyContactID}
                          key={item.EmergencyContactID}
                          className={`cameraList user-list-item ${selectedERT &&
                            item.EmergencyContactID ==
                            selectedERT?.EmergencyContactID &&
                            "selected-user"
                            }`}
                          isDisabled={!isSiteEdit}
                          onClick={(e) => {
                            if (isSiteEdit)
                              siteERTOnClick(item)
                          }}
                        >
                          {item &&
                            item.IsCustomerSPOC == true &&
                            item.IsERT == false ? (
                            <p className="mb-0">{item.ContactName} - SPOC</p>
                          ) : (
                            <p className="mb-0">
                              {item.ContactName} - {item.ContactLevel}
                            </p>
                          )}

                          <div style={{ float: "right", pointerEvents: pointerEvent, cursor: cursor }}>
                            <img
                              height={12}
                              width={12}
                              onClick={() => siteERTOnClick(item)}
                              src={EditQc}
                              className="m-1"
                            ></img>
                            <img
                              height={12}
                              width={12}
                              src={DeleteQc}
                              className="m-1"
                              title="Delete"
                              onClick={(e) => onDeleteERT(site.SiteID, item)}
                            />
                          </div>
                          
                        </div>
                      );
                    })}
                </div>
                <div style={{ margin: "10px", textAlign: "end" }}>
                  <div className="AddMore" style={{ float: "left" }}>
                    <a
                      disabled={!isSiteEdit}
                      className="UserAddPlus mt-1"
                      onClick={() => addNewERTMemberClick()}
                    >
                      Add New Member +
                    </a>
                  </div>
                </div>
              </div>
              <AddERTForm
                onSubmit={onAddERTMemberClick}
                ertLevels={ertLevels}
                site={site}
                selectedERT={selectedERT}
                userOptions={userOptions}
                onUserRoleChange={onUserRoleChange}
                selectedUserRole={selectedUserRole}
                setSelectedUserRole={setSelectedUserRole}
                siteUserFieldsVisibility={siteUserFieldsVisibility}
                isSiteEdit={isSiteEdit}
              />
            </div>
            <ToastContainer
              position="bottom-left"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />

          </div>
        )} */}

      </div>
    </div>
  );
});

const AddSiteGroup = ({
  sites,
  siteGroupID,
  siteGroups,
  onFinish,
  sitesToBind,
  selectedSiteGroup,
  onSiteGroupSelection,
  selectedSites,
  setSelectedSites,
  onAddSelectedSites,
  onDeleteSiteGroup,
  onAddNewSiteGroup,
  onUpdateSiteGroup,
}) => {
  const handleChange = (index) => (event) => {
    const selSite = sitesToBind.find((x) => x.SiteID == event.target.id);
    if (selSite) {
      if (event.target.checked == true) {
        selSite.isSelected = true;
      } else {
        selSite.isSelected = false;
      }
    }
    setSelectedSites({
      ...selectedSites,
      [event.target.id]: event.target.checked,
    });
  };

  return (
    <>
      <div className="LeftAddSide">
        <div className="LeftAddSideHeight">
          <div className="ManageSite ManageSiteGroup-container  ">
            <h1 className="MainHeaderName mb-0">1. Manage Site Group</h1>
            <div className="ManageSiteDiv">
              <div className="DevicesHeader">
                <p className="mb-0" style={{ width: "40%" }}>
                  Site Group
                  <br />
                </p>
                <p className="mb-0">
                  Site List
                  <br />
                </p>
              </div>

              <div className="site-group">
                <div className="site-group-list">
                  {siteGroups &&
                    siteGroups.map((item) => {
                      return (
                        <InlineEditItem
                          key={item.siteGroupID}
                          name={item.groupTitle}
                          selectedItem={item}
                          id={item.siteGroupID}
                          selectedItemId={selectedSiteGroup?.siteGroupID}
                          onClick={() => onSiteGroupSelection(item)}
                          onSave={onUpdateSiteGroup}
                          onDelete={onDeleteSiteGroup}
                        />
                      );
                    })}
                </div>
                <div className="AddMore" style={{ float: "left" }}>
                  {/*   <a style={{ cursor: "not-allowed", color: "gray", pointerEvents: "revert" }}
                    title="Not Permitted"
                    href="#"
                    className="UserAddPlus mt-1"
                    onClick={() => onAddNewSiteGroup()}
                  >
                    Create Site Group +
                  </a> */}
                </div>
              </div>
              <div
                className="SiteList site-list-container"
                style={{ borderBottom: "1px solid #e6e6e6" }}
              >
                <div className="sitegrp-sitelist">
                  <form style={{ height: "100%" }}>
                    {sitesToBind &&
                      sitesToBind.map((value, index, array) => {
                        return (
                          <div className="selection-list-group cameraList"
                            key={value.SiteID}>
                            <input
                              id={value.SiteID}
                              className="checkbox_custom"
                              type="checkbox" disabled
                              key={value.SiteID}
                              onChange={handleChange(index)}
                              name={value.SiteName}
                              defaultChecked={
                                value.isSelected == true ? true : false
                              }
                            />
                            <p className="ListofText">{value.SiteName}</p>
                          </div>
                        );
                      })}
                  </form>
                  {/* <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  /> */}
                </div>
              </div>

              <div className="text-right" style={{ textAlign: "end" }}>
                <button
                  className="NextMoveButton ManageSiteGroup-NextButton"
                  onClick={() => onFinish("alertTemplate")}
                >
                  <span className="ViewUpdate">Next</span>
                </button>
                <button
                  className="NextMoveButton ManageSiteGroup-AddSelectedButton" disabled
                  title="Not Permitted"
                  onClick={() => onAddSelectedSites()}
                >
                  <span className="ViewUpdate">Add Selected</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="RightListSide">
          <div className="m-2 HeaderSiteList">
            {issearch && (
              <>
                <p
                  className={
                    selectedSiteTypeList == "Site List"
                      ? "active-all-light active-block active-text button-common-margin"
                      : "inactive-text button-common-margin"
                  }
                  onClick={() => selectedSiteTypeClick("Site List")}
                >
                  Site List
                </p>
                <p
                  className={
                    selectedSiteTypeList == "Site Group"
                      ? "active-all-light active-block active-text button-common-margin"
                      : "inactive-text button-common-margin"
                  }
                  onClick={() => selectedSiteTypeClick("Site Group")}
                >
                  Site Group
                </p>
              </>
            )}
            {!issearch && selectedSiteTypeList == "Site List" && (
              <input
                id="Search_txt"
                className="SearchViewInput1"
                ref={focusSearchDiv}
                type="text"
                onChange={(e) =>
                  onSearchChange(e.target.value, selectedSiteTypeList)
                }
                placeholder="Search Site"
                autoFocus
              />
            )}
            {!issearch && selectedSiteTypeList == "Site Group" && (
              <input
                id="Search_txt"
                className="SearchViewInput1"
                ref={focusSearchDiv}
                type="text"
                onChange={(e) =>
                  onSearchChange(e.target.value, selectedSiteTypeList)
                }
                placeholder="Search Site Group"
                autoFocus
              />
            )}

            <div className="SearchTextDivConfigTool">
              {issearch && (
                <img
                  className="EyeIcon"
                  src={SearchIcon}
                  onClick={() => ToggeleSearch()}
                />
              )}
              {!issearch && (
                <img
                  className="EyeIcon"
                  src={Cross}
                  onClick={() => ToggeleSearch()}
                />
              )}
            </div>
          </div>
          <div className="SiteListMain">
            {selectedSiteTypeList &&
              selectedSiteTypeList == "Site List" &&
              filteredSites &&
              filteredSites.map((site) => {
                return (
                  <div
                    className="OneList"
                    key={site.SiteID}
                    onClick={(e) => {
                      e.stopPropagation();
                      onSelectSite(site.SiteID, true);
                    }}
                  >
                    <p className="ListofText">
                      {site.SiteName}
                      <span className="SiteNameText"> - {site.BranchID}</span>
                    </p>
                    <div>
                      <img className="m-2" src={EditQc} />
                      <img
                        src={DeleteQc}
                        onClick={(e) =>
                          onDeleteChange(e, site.SiteID, site.SiteName)
                        }
                      />
                    </div>
                  </div>
                );
              })}
            {!filteredSites && selectedSiteTypeList == "Site List" && (
              <div className="no-data-found">No Sites Found</div>
            )}

            {selectedSiteTypeList &&
              selectedSiteTypeList == "Site Group" &&
              filteredSiteGroups &&
              filteredSiteGroups.map((siteGroup) => {
                return (
                  <div
                    className="OneList"
                    key={siteGroup.siteGroupID}
                    onClick={(e) => {
                      e.stopPropagation();
                      onSelectSite(siteGroup.siteGroupID);
                    }}
                  >
                    <p className="ListofText">{siteGroup.groupTitle}</p>
                    <div>
                      <img
                        src={DeleteQc}
                        id={siteGroup.siteGroupID}
                        title="Delete site group"
                        onClick={(e) => onDeleteSiteGroup(e, siteGroup)}
                      />
                    </div>
                  </div>
                );
              })}
            {!filteredSiteGroups && selectedSiteTypeList == "Site Group" && (
              <div className="no-data-found">No Site Groups Found</div>
            )}
          </div>
        </div> */}
    </>
  );
};

const AddERTForm = React.forwardRef((props, ref) => {
  let {
    onSubmit,
    ertLevels,
    site,
    selectedERT,
    userOptions,
    onUserRoleChange,
    selectedUserRole,
    setSelectedUserRole,
    siteUserFieldsVisibility,
    isSiteEdit,
  } = { ...props };
  const { register, handleSubmit, setValue,getValues,formState: { errors } } = useForm({
    defaultValue: { ...selectedERT },
  });

  useEffect(() => {
    setValue("contactLevel", selectedERT.ContactLevel);
    setValue("designation", selectedERT.Designation);
    setValue("mobile", selectedERT.Phone);
    setValue("email", selectedERT.EmailID);
    setValue("isEmail", selectedERT.Email);
    setValue("emergencyContactID", selectedERT.EmergencyContactID);
    setValue("name", selectedERT.ContactName);
    setValue("siteID", selectedERT.SiteID);

    if (selectedERT.IsERT == true && selectedERT.IsCustomerSPOC == true) {
      setSelectedUserRole(userOptions);
    } else if (
      selectedERT.IsERT == true &&
      selectedERT.IsCustomerSPOC == false
    ) {
      const userOptions = [{ value: "ERTMember", label: "ERT Member" }];
      setSelectedUserRole(userOptions);
    } else if (
      selectedERT.IsERT == false &&
      selectedERT.IsCustomerSPOC == true
    ) {
      const userOptions = [{ value: "CustomerSPOC", label: "Customer SPOC" }];
      setSelectedUserRole(userOptions);
    } else {
      setSelectedUserRole([]);
    }
  }, [selectedERT]);

  // const [selectedUserRole, setSelectedUserRole] = useState([])

  const handleChange = (event) => {
    console.log("eventchek",event.target.checked)
    setValue("isEmail", event.target.checked);
  };

  // function regarding on email verification 
  const allowedEmailDomains = ["com", "net", "org", "in"];

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const isAllowedEmailDomain = (email) => {
    const domain = email.split(".").pop().toLowerCase();
    return allowedEmailDomains.includes(domain);
  };

  const validateEmail = (value) => {
    if (!value) {
      return "Email is required";
    }
    if (!isValidEmail(value)) {
      return "Invalid email address";
    }
    if (!isAllowedEmailDomain(value)) {
      return `Email should end with ${allowedEmailDomains.join(", ")}`;
    }
    return true;
  };

  // const [entered, setEntered] = useState("");

  // let dataEntered = {
  //   designation : entered
  // }

  // const changeHandler = (e) => {
  //   setEntered(e.target.value)
  // }

  // const clearField = (e) => {
  //   Array.from(e.target).forEach((e) => (e.value = ""));
  // }

  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   clearField(e);
  //   setEntered("");
  // }


  return (
    <div
      className="SiteList ConfigUserToSite"
    >
      {/* rgr 30/1/23 */}
      <div className="configureUserForm-container">
        <form style={{ width: "100%" }}
          onSubmit={handleSubmit((e) =>
            onSubmit(e, site.SiteID, selectedUserRole))}
        >
          <Select
            isDisabled={!isSiteEdit}
            isMulti
            name="colors"
            options={userOptions}
            className="SiteNameTextBox px-0 border-0"
            hideSelectedOptions={false}
            value={selectedUserRole}
            onChange={(e) => onUserRoleChange(e)}
            placeholder="Select User Role*"
          />

          {siteUserFieldsVisibility && ( // by default form fields are not visible on site selection
            <>
              {selectedUserRole &&
                ((selectedUserRole.find((x) => x.value == "CustomerSPOC") &&
                  selectedUserRole.find((x) => x.value == "ERTMember")) ||
                  selectedUserRole.find((x) => x.value == "ERTMember")) && (
                  //|| (selectedERT.EmergencyContactID == 0 )
                  //&& selectedUserRole.find(x => x.value != 'CustomerSPOC')  )
                  <>
                    {" "}

                    <select
                      {...register("contactLevel")}
                      className="SiteNameTextBox"
                      placeholder="Select Contact Level"
                    >
                      <option value="" selected disabled hidden >Contact Level*</option>
                      {ertLevels &&
                        ertLevels.map((val) => (
                          <option value={val.ertLevelId} key={val.level}>
                            {val.level}
                          </option>
                        ))}
                    </select>
                  </>
                )}
              <input
                {...register("designation")}
                className="SiteNameTextBox"
                placeholder="Designation* e.g. Manager"
                disabled={!isSiteEdit}
              />
              <input
                disabled={!isSiteEdit}
                {...register("name")}
                className="SiteNameTextBox"
                placeholder="Name* e.g. Rupesh"
              />

              <p className="SiteNameText">
                <input
                  className="checkbox_custom"
                  type="checkbox"
                  disabled={true}
                  defaultChecked={true}
                />
                Make Call <span className="mandat-field">*</span>
              </p>
              <input
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                }}
                type={"number"}
                disabled={!isSiteEdit}
                {...register("mobile")}
                className="SiteNameTextBox"
                placeholder="Mobile e.g. 9876543210"
                min={"0"}
                maxLength={"10"}
              />

              {/* validation using useForm */}

              {selectedUserRole &&
                ((selectedUserRole.find((x) => x.value == "CustomerSPOC") &&
                  selectedUserRole.find((x) => x.value == "ERTMember")) ||
                  selectedUserRole.find((x) => x.value == "ERTMember")) && (
                  //|| selectedERT.EmergencyContactID == 0
                  <>
                    <label className="SiteNameText">
                      <input
                        id={selectedERT.EmergencyContactID}
                        className="checkbox_custom"
                        type="checkbox"
                        key={selectedERT.EmergencyContactID}
                        onChange={(e) => handleChange(e)}
                        defaultChecked={selectedERT.Email}
                      />
                      Configure Email
                    </label>

                    <input
                      {...register("email", {
                        validate: validateEmail,
                      })}
                      className="SiteNameTextBox"
                      // type="email"
                      placeholder="Email e.g. abc@xyz.com"
                    />
                    {errors.email && (
                      <p style={{ color: "red" }}>{errors.email.message}</p>
                    )}
                  </>
                )}
              <div className="ButtonDiv addUserToSite-btn-div">
                {selectedERT.EmergencyContactID == 0 ? (
                  <button
                    type="submit"
                    className="NextMoveButton addUserToSite-addMember-btn"
                    disabled={!isSiteEdit}
                  >
                    <span className="ViewUpdate">Add Member</span>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="NextMoveButton addUserToSite-updateMember-btn"
                    disabled={!isSiteEdit}
                  >
                    <span className="ViewUpdate">Update Member</span>
                  </button>
                )}
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
});



const AssociateCameraOnSensor = ({
  selectedDevice,
  onSensorSelection,
  associatedDevices,
  onAssociate,
  selectedSensor,
  selectedItems,
  setSelectedItems,
  isDeviceEdit

}) => {
  // Handle select all/deselect all
  const handleSelectAll = useCallback((event) => {
    // If all items are selected, deselect all
    if (selectedItems.length === associatedDevices && associatedDevices.length) {
      setSelectedItems([]);
    } else {
      // Otherwise select all
      if (event.target.checked == true) {
        setSelectedItems(associatedDevices && associatedDevices.map(item => item.deviceId));
      } else {
        setSelectedItems([])
      }

    }
  }, [associatedDevices, selectedItems]);

  // Handle individual item selection
  const handleSelect = useCallback(id => {
    // If item is already selected, remove it from the selection
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter(itemId => itemId !== id));
    } else {
      // Otherwise add it to the selection
      setSelectedItems([...selectedItems, id]);
    }
  }, [selectedItems]);
  //********************** */

  return (

    <>
      {

        selectedDevice && selectedDevice.zonesList && selectedDevice.zonesList.length > 0 &&
        <div className="association-main-section zone-to-camera">
          <h1 className="MainHeaderName mb-0">Associate Zones to Cameras</h1>
          <div>
            <div className="association-section-header">
              <p className="mb-0" style={{ width: "40%" }}>
                Zones
                <br />
              </p>
              <p className="mb-0">
                Cameras
                <br />
              </p>
            </div>

            <div className="sensors-section">
              <div className="sensors-list">
                {selectedDevice &&
                  selectedDevice.zonesList &&
                  selectedDevice.zonesList.map((item) => {
                    return (
                      <div key={item.zoneNo}>
                        <div
                          // className={`cameraList user-list-item ${selectedSensor &&
                          //   item.iDSPanelZoneTypeID ==
                          //   selectedSensor?.iDSPanelZoneTypeID &&
                          //   "selected-user"
                          //   }`}
                          className={`cameraList user-list-item ${selectedSensor &&
                            item.zoneNo ==
                            selectedSensor?.zoneNo &&
                            "selected-user"
                            }`}
                          onClick={() =>
                            onSensorSelection(item, selectedDevice)
                          }
                        >
                          <p className="mb-0">{item.zoneName}</p>
                          <div style={{ float: "right" }}>
                            <img
                              height={12}
                              width={12}
                              src={EditQc}
                              className="m-1"
                              alt="edit icon"
                            /* onClick={() =>
                              onSensorSelection(item, selectedDevice)
                            } */
                            ></img>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="panel-camera-list">
              {associatedDevices &&
                <>
                  {/* <div className="selection-list-group cameraList">
                    <input type="checkbox"
                      label="Select All"
                      // disabled={!isDeviceEdit}
                      checked={selectedItems.length === (associatedDevices && associatedDevices.length)}
                      onChange={handleSelectAll} />
                    <p className="ListofText"><b>Select All</b></p>
                  </div> */}

                  {associatedDevices.map(item => (
                    <div className="selection-list-group cameraList" key={item.deviceId} >
                      <input type="checkbox"
                        key={item.deviceId}
                        label={item.cameraName}
                        // disabled={!isDeviceEdit}
                        checked={selectedItems.includes(item.deviceId)}
                        onChange={() => handleSelect(item.deviceId)}
                      />

                      <p className="ListofText"
                        onClick={() => handleSelect(item.deviceId)}
                      >{item.cameraName}</p>
                    </div>
                  ))}
                </>
              }
            </div>

            <div className="m-2 text-right" style={{ textAlign: "end" }}>
              <button
                className="NextMoveButton m-3 associateZone-associate-btn"
                onClick={() => onAssociate(selectedDevice)}
                // disabled={!isDeviceEdit}
              >
                <span className="ViewUpdate">Associate</span>
              </button>
            </div>
          </div>
        </div>
      }

    </>
  );
};

const AddInterfaceForm = (() => {
  return (
    <div className="LeftAddSide">
      <div className="LeftAddSideHeight">
        <div className="AddSite">
          <h1 className="MainHeaderName">1. Add Interface</h1>
        </div>
      </div>
    </div>
  )
})