import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { SIGNOUT, ROOT, SIGNUP, FORGETPASSWORD, MOSAIC, RESETPASSWORD, VERIFICATION, AUDITLOG, ABOUTTERMS, TERMSANDCONDITION } from './../navigation/CONSTANTS';

import RootContainer from '../pages/Root/RootContainer';
import MosaicContainer from '../pages/Mosaic/MosaicContainer'
import AuditLog from '../pages/Configuration/AuditLog'

import Signup from './Auth/Signup/Signup'
import Forgetpassword from './Auth/Forgetpassword/Forgetpassword'
import { NotFound } from './NotFound';
import { Login } from './Auth/Login/Login'
import PrivateRoute from './Auth/PrivateRoute';
import { useAuth } from './Auth/ProvideAuth';
import { ResetPassword } from './Auth/ResetPassword/ResetPassword'
import Verification from './Auth/Verification/Verification'
import Termscondition from '../pages/AboutMH/Termscondition';

export const RouterConfig = ({ match }) => {
    let auth = useAuth();
    
    return (
        <Switch>
            {/* List all public routes here */}
            <Route path="/login">
                <Login />
            </Route>
            <Route path={SIGNUP}>
                <Signup />
            </Route>
            <Route path={FORGETPASSWORD}>
                <Forgetpassword />
                {/* <ResetPassword /> */}
            </Route>
            <Route path={VERIFICATION}>
                <Verification />
            </Route>
            <Route path={RESETPASSWORD}>
                {/* <Forgetpassword /> */}
                <ResetPassword />
            </Route>

            <Route path={AUDITLOG}>
                <AuditLog />
            </Route>

            {/* Dispay T&C page after login (Excluding sidebar and header) */}
            <Route path={TERMSANDCONDITION}>
                <Termscondition />
            </Route>


            <Route path={SIGNOUT} component={() => auth.signout()} />

            {/* List all private/auth routes here */}
            <PrivateRoute path={MOSAIC}>
                <MosaicContainer />
            </PrivateRoute>

            <PrivateRoute path={ROOT}>
                <RootContainer />
            </PrivateRoute>

            {/* List a generic 404-Not Found route here */}
            <Route path="*">
                <NotFound />
            </Route>

        </Switch>
    )
}
