import React, { useState, useEffect } from "react";
import { useLocation, useParams } from 'react-router-dom'

import { InfoOrange, ScheduleDisable, ScheduleEnable } from "../../assets/images";

import { Tooltip, OverlayTrigger } from "react-bootstrap";
import Avatar from "react-avatar";
import { DateRangePicker } from "rsuite";

import { GetAlertTemplateAudit, GetSeverityTypeList, getAlertTemplateByIdAPI } from "../../services/configurationServices";
import { loadWorkflows } from "../../services/workflowService";

import ActivityCustomScheduler from "./ActivityCustomScheduler";
import { ConvertDate, onlyDateConverter } from "../../utils/utils";

let parentArrTemp = [
    {
        name: "Jesse Pinkman",
        date: "14-10-2023",
        time: "10:15:30",
        listData: [
            {
                label: "Monday",
                data: [
                    {
                        id: 1,
                        day: "Mon_Old",
                        dayResource: [
                            {
                                monitoringStartTime: "00:00",
                                monitoringEndTime: "00:00",
                                severityId: 3,
                                workflowId: 151
                            }
                        ],
                    },
                    {
                        id: 2,
                        day: "Mon_New",
                        dayResource: [
                            {
                                monitoringStartTime: "00:00",
                                monitoringEndTime: "06:00",
                                severityId: 1,
                                workflowId: 157
                            },
                            {
                                monitoringStartTime: "06:00",
                                monitoringEndTime: "13:00",
                                severityId: 3,
                                workflowId: 157
                            },
                            {
                                monitoringStartTime: "13:00",
                                monitoringEndTime: "23:00",
                                severityId: 4,
                                workflowId: 151
                            }
                        ],
                    }
                ],
            },

            {
                label: "Thursday",
                data: [
                    {
                        id: 1,
                        day: "Thu_Old",
                        dayResource: [

                        ],
                    },
                    {
                        id: 2,
                        day: "Thu_New",
                        dayResource: [
                            {
                                monitoringStartTime: "00:00",
                                monitoringEndTime: "15:00",
                                severityId: 1,
                                workflowId: 151
                            }
                        ],
                    }
                ],
            },

            {
                label: "Friday",
                data: [
                    {
                        id: 1,
                        day: "Fri_Old",
                        dayResource: [
                            {
                                monitoringStartTime: "00:00",
                                monitoringEndTime: "20:00",
                                severityId: 4,
                                workflowId: 151
                            }
                        ],
                    },
                    {
                        id: 2,
                        day: "Fri_New",
                        dayResource: [
                        ],
                    }
                ]
            }
        ]
    },

    {
        name: "Walter White",
        date: "18-11-2023",
        time: "15:30:00",
        listData: [
            {
                label: "Tuesday",
                data: [
                    {
                        id: 1,
                        day: "Tue_Old",
                        dayResource: [
                            {
                                monitoringStartTime: "00:00",
                                monitoringEndTime: "00:00",
                                severityId: 3,
                                workflowId: 151
                            }
                        ],
                    },
                    {
                        id: 2,
                        day: "Tue_New",
                        dayResource: [
                            {
                                monitoringStartTime: "00:00",
                                monitoringEndTime: "04:00",
                                severityId: 1,
                                workflowId: 157
                            },
                            {
                                monitoringStartTime: "06:00",
                                monitoringEndTime: "20:00",
                                severityId: 2,
                                workflowId: 157
                            },
                        ],
                    }
                ],
            },
            {
                label: "Friday",
                data: [
                    {
                        id: 1,
                        day: "Fri_Old",
                        dayResource: [
                            {
                                monitoringStartTime: "00:00",
                                monitoringEndTime: "00:00",
                                severityId: 1,
                                workflowId: 151
                            }
                        ],
                    },
                    {
                        id: 2,
                        day: "Fri_New",
                        dayResource: [
                        ],
                    }
                ]
            }
        ]
    },

    {
        name: "Jon Snow",
        date: "11-10-2023",
        time: "23:00:00",
        listData: [
            {
                label: "Monday",
                data: [
                    {
                        id: 1,
                        day: "Mon_Old",
                        dayResource: [
                            {
                                monitoringStartTime: "00:00",
                                monitoringEndTime: "00:00",
                                severityId: 3,
                                workflowId: 151
                            }
                        ],
                    },
                    {
                        id: 2,
                        day: "Mon_New",
                        dayResource: [
                            {
                                monitoringStartTime: "00:00",
                                monitoringEndTime: "06:00",
                                severityId: 1,
                                workflowId: 157
                            },
                            {
                                monitoringStartTime: "06:00",
                                monitoringEndTime: "13:00",
                                severityId: 3,
                                workflowId: 157
                            },
                            {
                                monitoringStartTime: "13:00",
                                monitoringEndTime: "23:00",
                                severityId: 4,
                                workflowId: 151
                            }
                        ],
                    }
                ],
            },

            {
                label: "Thursday",
                data: [
                    {
                        id: 1,
                        day: "Thu_Old",
                        dayResource: [

                        ],
                    },
                    {
                        id: 2,
                        day: "Thu_New",
                        dayResource: [
                            {
                                monitoringStartTime: "00:00",
                                monitoringEndTime: "15:00",
                                severityId: 1,
                                workflowId: 151
                            }
                        ],
                    }
                ],
            },

            {
                label: "Friday",
                data: [
                    {
                        id: 1,
                        day: "Fri_Old",
                        dayResource: [
                            {
                                monitoringStartTime: "00:00",
                                monitoringEndTime: "20:00",
                                severityId: 4,
                                workflowId: 151
                            }
                        ],
                    },
                    {
                        id: 2,
                        day: "Fri_New",
                        dayResource: [
                        ],
                    }
                ]
            }
        ]
    },
]

const AuditLog = () => {
    const location = useLocation();
    const params = useParams();

    //#region useState start
    const [workflows, setWorkflows] = useState([])
    const [mainAuditLogData, setMainAuditLogData] = useState(parentArrTemp);
    const [auditLogData, setAuditLogData] = useState(parentArrTemp);
    const [severities, setSeverities] = useState([])

    const [alertTemplateName, setAlertTemplateName] = useState("");
    const [activityDays, setActivityDays] = useState([
        {
            id: 0,
            hour: 0,
            selected: false,
            backgroundColor: "none",
            hourLabel: "00",
            severity: "",
            workflow: "",
            hourLabelFull: "00:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 1,
            hour: 1,
            selected: false,
            backgroundColor: "none",
            hourLabel: "01",
            severity: "",
            workflow: "",
            hourLabelFull: "01:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 2,
            hour: 2,
            selected: false,
            backgroundColor: "none",
            hourLabel: "02",
            severity: "",
            workflow: "",
            hourLabelFull: "02:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 3,
            hour: 3,
            selected: false,
            backgroundColor: "none",
            hourLabel: "03",
            severity: "",
            workflow: "",
            hourLabelFull: "03:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 4,
            hour: 4,
            selected: false,
            backgroundColor: "none",
            hourLabel: "04",
            severity: "",
            workflow: "",
            hourLabelFull: "04:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 5,
            hour: 5,
            selected: false,
            backgroundColor: "none",
            hourLabel: "05",
            severity: "",
            workflow: "",
            hourLabelFull: "05:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 6,
            hour: 6,
            selected: false,
            backgroundColor: "none",
            hourLabel: "06",
            severity: "",
            workflow: "",
            hourLabelFull: "06:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 7,
            hour: 7,
            selected: false,
            backgroundColor: "none",
            hourLabel: "07",
            severity: "",
            workflow: "",
            hourLabelFull: "07:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 8,
            hour: 8,
            selected: false,
            backgroundColor: "none",
            hourLabel: "08",
            severity: "",
            workflow: "",
            hourLabelFull: "08:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 9,
            hour: 9,
            selected: false,
            backgroundColor: "none",
            hourLabel: "09",
            severity: "",
            workflow: "",
            hourLabelFull: "09:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 10,
            hour: 10,
            selected: false,
            backgroundColor: "none",
            hourLabel: "10",
            severity: "",
            workflow: "",
            hourLabelFull: "10:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 11,
            hour: 11,
            selected: false,
            backgroundColor: "none",
            hourLabel: "11",
            severity: "",
            workflow: "",
            hourLabelFull: "11:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 12,
            hour: 12,
            selected: false,
            backgroundColor: "none",
            hourLabel: "12",
            severity: "",
            workflow: "",
            hourLabelFull: "12:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 13,
            hour: 13,
            selected: false,
            backgroundColor: "none",
            hourLabel: "13",
            severity: "",
            workflow: "",
            hourLabelFull: "13:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 14,
            hour: 14,
            selected: false,
            backgroundColor: "none",
            hourLabel: "14",
            severity: "",
            workflow: "",
            hourLabelFull: "14:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 15,
            hour: 15,
            selected: false,
            backgroundColor: "none",
            hourLabel: "15",
            severity: "",
            workflow: "",
            hourLabelFull: "15:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 16,
            hour: 16,
            selected: false,
            backgroundColor: "none",
            hourLabel: "16",
            severity: "",
            workflow: "",
            hourLabelFull: "16:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 17,
            hour: 17,
            selected: false,
            backgroundColor: "none",
            hourLabel: "17",
            severity: "",
            workflow: "",
            hourLabelFull: "17:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 18,
            hour: 18,
            selected: false,
            backgroundColor: "none",
            hourLabel: "18",
            severity: "",
            workflow: "",
            hourLabelFull: "18:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 19,
            hour: 19,
            selected: false,
            backgroundColor: "none",
            hourLabel: "19",
            severity: "",
            workflow: "",
            hourLabelFull: "19:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 20,
            hour: 20,
            selected: false,
            backgroundColor: "none",
            hourLabel: "20",
            severity: "",
            workflow: "",
            hourLabelFull: "20:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 21,
            hour: 21,
            selected: false,
            backgroundColor: "none",
            hourLabel: "21",
            severity: "",
            workflow: "",
            hourLabelFull: "21:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 22,
            hour: 22,
            selected: false,
            backgroundColor: "none",
            hourLabel: "22",
            severity: "",
            workflow: "",
            hourLabelFull: "22:00",
            severityName: "",
            workflowName: "",
        },
        {
            id: 23,
            hour: 23,
            selected: false,
            backgroundColor: "none",
            hourLabel: "23",
            severity: "",
            workflow: "",
            hourLabelFull: "23:00",
            severityName: "",
            workflowName: "",
        },
    ]);
    const [inputDate, setInputDate] = useState([]);

    //#endregion useState end

    //#region useEffect start
    useEffect(() => {
        let userid = localStorage.getItem('UserID');
        FetchSeverityTypeList();
        getWorkflows(userid);
        setAuditLogData([]);
        GetAlertTemplateAudit(params.id)
            .then((resp) => {

                if (resp.Status == "success") {
                    parentArrTemp = resp.Data;
                    setAuditLogData(parentArrTemp);
                    setMainAuditLogData(parentArrTemp);
                } else {
                    setAuditLogData([]);
                }
            });

        getAlertTemplateByIdAPI(params.id)
            .then((res) => {
                console.log("AuditLog", res);
                setAlertTemplateName(res.Data.TemplateName)
            }).catch(err => console.log(err));
    }, []);
    //#endregion useEffect end

    const customDate = (e) => {
        if (e == null) {
            setInputDate([])
            setAuditLogData(mainAuditLogData)
        }
    }

    const onHandleDateTimeOkClick = (e) => {
        let sDate = ConvertDate(e[0]);
        let fromDate = onlyDateConverter(sDate.split(" ")[0])

        let eDate = ConvertDate(e[1]);
        let endDate = onlyDateConverter(eDate.split(" ")[0])

        let fromTime = sDate.split(" ")[1];
        let endTime = eDate.split(" ")[1];
        setInputDate([e[0], e[1]])
        // Filter based on date and time.
        //let tempArr = mainAuditLogData.filter((item) => item.date >= fromDate && item.date <= endDate)
        let tempArr = mainAuditLogData.filter((item) => item.date >= fromDate && item.date <= endDate).filter((i) => {
            if (i.date == fromDate && i.date == endDate) {
                console.log("filterList", i.date);
                if (i.time >= fromTime && i.time <= endTime) {
                    return true
                } else {
                    return false;
                }
            }
            else if (i.date == fromDate) {
                console.log("filterList", i.date);
                if (i.time >= fromTime) {
                    return true
                } else {
                    return false;
                }
            }
            else if (i.date == endDate) {
                console.log("filterList", i.date);
                if (i.time <= endTime) {
                    return true
                } else {
                    return false
                }
            } else {
                return true;
            }
        })
        setAuditLogData(tempArr);
    }

    function FetchSeverityTypeList() {
        let data = GetSeverityTypeList().then((resp) => {
            if (resp.status == "Ok") {
                setSeverities(resp.message);
            }
        })
    }

    const getWorkflows = async (userid) => {
        await loadWorkflows(userid).then((resp) => {
            if (resp && resp.status == "Ok") {
                setWorkflows(resp.message)
            }
        })
    }

    return (
        <>
            {
                mainAuditLogData.length == 0
                    ? <div>
                        <p className="templatedevicename">
                            No such alert template exists due to which no audit log exists for the same.
                        </p>
                    </div>
                    :
                    <div >
                        <div className="activityLogTemplateDiv">
                            <div className="mt20" style={{ float: "left" }}>
                                <p className="templatedevicename">
                                    Scheduler Log for: {alertTemplateName}
                                </p>
                            </div>
                            <DateRangePicker
                                style={{ display: "none" }}
                                className="mt20"
                                format="dd-MM-yyyy HH:mm"
                                cleanable={true}
                                editable={false}
                                placeholder="Select date range"
                                onChange={e => customDate(e)}
                                ranges={[]}
                                placement="bottomEnd"
                                value={inputDate}
                                onOk={(e) => { onHandleDateTimeOkClick(e) }}
                                disabledDate={current => {
                                    return current && current > new Date();
                                }}
                            />
                        </div>
                        <div className="AuditLog-form">
                            {/* Sample Two Created for custom scheduler start */}
                            {
                                auditLogData.map((item) => (
                                    <div className="mt-3" style={{ float: "left", width: "100%" }}
                                    >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Avatar
                                                name={`${item.name.split(" ")[0]} ${item.name.split(" ")[1]}`}
                                                round={true}
                                                size={35}
                                            />
                                            <p className="LoguserDetailText ml-2" style={{ textAlign: "left" }}>
                                                <span style={{ fontWeight: "500" }}>{item.name}</span> {item.description} on <span style={{ fontWeight: "500" }}>{item.date}</span> at <span style={{ fontWeight: "500" }}>{item.time}</span>
                                            </p>
                                        </div>

                                        {
                                            item.listData.length > 0 ?
                                                <div className="ActivityLogMobileViewScheduleMainDiv">
                                                    {
                                                        item.listData.map((itemFirst) => (
                                                            <div className="DaySliderBox" key={itemFirst.label} style={{ padding: "10px", float: "left" }}>
                                                                <div className="activityDaysrepeatDiv">
                                                                    <div class="activitydaysTextDiv">
                                                                        <div class="activitydaysText rotate">{itemFirst.label}</div>
                                                                    </div>
                                                                    <div className="activitySchedulerHeaders">
                                                                        <div className="" style={{ width: "32px" }}>
                                                                            <p className="activityOldNewTimeText mb-0">Time</p>
                                                                            <p className="activityOldNewTimeText mb-0">Old</p>
                                                                            <p className="activityOldNewTimeText mb-0">New</p>
                                                                        </div>
                                                                        <div className="" style={{ width: "37px" }}>
                                                                            <p className="activityOldNewTimeText">Status</p>
                                                                            {
                                                                                itemFirst.data[0].isEnabled
                                                                                    ?
                                                                                    <OverlayTrigger
                                                                                        placement="bottom"
                                                                                        overlay={<Tooltip>Scheduler Enabled</Tooltip>}
                                                                                    >
                                                                                        <span className="activityOldNewTimeText" style={{ width: "37px", display: "block" }}>
                                                                                            <img src={ScheduleEnable} style={{ height: "15px" }} alt="Enable" />
                                                                                        </span>
                                                                                    </OverlayTrigger>
                                                                                    :
                                                                                    <OverlayTrigger
                                                                                        placement="bottom"
                                                                                        overlay={<Tooltip>Scheduler Disabled</Tooltip>}
                                                                                    >
                                                                                        <span className="activityOldNewTimeText" style={{ width: "37px", display: "block" }}>
                                                                                            <img src={ScheduleDisable} style={{ height: "15px" }} alt="Disable" />
                                                                                        </span>
                                                                                    </OverlayTrigger>
                                                                            }
                                                                            {
                                                                                itemFirst.data[1].isEnabled
                                                                                    ?
                                                                                    <OverlayTrigger
                                                                                        placement="bottom"
                                                                                        overlay={<Tooltip>Scheduler Enabled</Tooltip>}
                                                                                    >
                                                                                        <span className="activityOldNewTimeText" style={{ width: "37px", display: "block" }}>
                                                                                            <img src={ScheduleEnable} style={{ height: "15px" }} alt="Enable" />
                                                                                        </span>
                                                                                    </OverlayTrigger>
                                                                                    :
                                                                                    <OverlayTrigger
                                                                                        placement="bottom"
                                                                                        overlay={<Tooltip>Scheduler Disabled</Tooltip>}
                                                                                    >
                                                                                        <span className="activityOldNewTimeText" style={{ width: "37px", display: "block" }}>
                                                                                            <img src={ScheduleDisable} style={{ height: "15px" }} alt="Disable" />
                                                                                        </span>
                                                                                    </OverlayTrigger>
                                                                            }
                                                                        </div>
                                                                        <div className="">
                                                                            <div style={{ height: "29px" }}>
                                                                                <ul className="CustomTime">
                                                                                    {activityDays.map((item) => (
                                                                                        <li key={item.id}>{item.hourLabel}</li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                            {
                                                                                itemFirst.data.map((i, index) => {
                                                                                    return (
                                                                                        i.dayResource.length == 0 && index == 0
                                                                                            ?
                                                                                            <div key={i.id} className="noDataAccessMsg activityloginfomaindiv m-0 p-0">
                                                                                                <div>
                                                                                                    <img
                                                                                                        className="informationIcon orangeinfoicon ml-2"
                                                                                                        src={InfoOrange}
                                                                                                        alt="Info"
                                                                                                    />
                                                                                                </div>
                                                                                                <div style={{ width: "100%" }}>
                                                                                                    <h3 className="No-Data-Access-Group m-0" style={{ fontSize: "11px" }}>
                                                                                                        No Old Record.
                                                                                                    </h3>
                                                                                                </div>
                                                                                            </div> :
                                                                                            i.dayResource.length == 0 && index == 1 ?
                                                                                                <div key={i.id} className="noDataAccessMsg activityloginfomaindiv m-0 p-0">
                                                                                                    <div>
                                                                                                        <img
                                                                                                            className="informationIcon orangeinfoicon ml-2"
                                                                                                            src={InfoOrange}
                                                                                                            alt="Info"
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div style={{ width: "100%" }}>
                                                                                                        <h3 className="No-Data-Access-Group m-0" style={{ fontSize: "11px" }}>
                                                                                                            Record Deleted.
                                                                                                        </h3>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                <ActivityCustomScheduler
                                                                                                    key={i.id}
                                                                                                    isAlertTemplateEdit={true}
                                                                                                    resource={i.day}
                                                                                                    sevenDaysData={itemFirst.data}
                                                                                                    setSevenDaysData={() => { }}
                                                                                                    workflows={workflows}
                                                                                                    severities={severities}
                                                                                                    gettingData={false}
                                                                                                    setGettingData={() => { }}
                                                                                                />
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
            }
        </>
    );
};

export default AuditLog;