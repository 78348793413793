import { BASE_CORE_URL, BASE_URL } from './../config/index'

//#region Api calling for Login the application
export const loginDotNet = async (email, password) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password,
      module: "web",
      ipAddress: "string"
    })
  }
  const url = BASE_CORE_URL + "Authenticate/Login";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then((data) => {
      return data;
    })
    .catch(function (error) {
      console.error("Login Error", { error });
    });
}
//#endregion

//#region old Api of Login the application
export const loginCF = async (email, fcm, datetime) => {
  // Simple POST request with a JSON body using fetch

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      Email: email,
      UPassword: null,
      FCMToken: fcm,
      DeviceID: null,
      ComponentType: "Web",
      datetime: datetime,
    }),
  };

  const url = BASE_URL + "login";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("Login Error", { error });
    });
}
//#endregion

//#region old API register the user
export const RegisterUser = async (obj) => {
  // Simple POST request with a JSON body using fetch
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };
  const url = BASE_URL + "registration";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error(error);
    });
}
//#endregion

//#region  Api calling for Logout the application
export const LogoutDotNet = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  };
  let url = BASE_CORE_URL + "Authenticate/Logout?" + new URLSearchParams({
    authCode: localStorage.getItem('authCode')
  });
  return await fetch(url, requestOptions)
    .then(resp => resp.json())
    .then((data) => {
      return data;
    })
    .catch(function (error) {
      console.error("Login Error", { error });
    });
}
//#endregion


//#region old Api of Logout the application
export const LogoutUser = async (UserUniqueId) => {
  // Simple POST request with a JSON body using fetch
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      UserUniqueID: UserUniqueId
    }),
  };

  const url = BASE_URL + "logout";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      localStorage.removeItem('user');
      return data;
    })
    .catch(function (error) {
      console.error("Login Error", { error });
    });
}
//#endregion

//#region api calling for change password
export const changePassword = async (user_id, old_password, new_password) => {
  let requestOptions = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
    body: JSON.stringify({
      userId: user_id,
      oldPassword: old_password,
      newPassword: new_password
    })
  }
  let url = BASE_CORE_URL + "User/ChangePassword";

  return await fetch(url, requestOptions)
    .then(resp => resp.json())
    .then((data) => {
      return data;
    })
    .catch(function (error) {
      console.error("Login Error", { error });
    });
}
//#endregion

//#region Api calling for updateFCMDetails
export const updateFCMDetails = async (userId, fcmToken, datetime) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      UserId: userId,
      Token: fcmToken,
      DateTime: datetime,
      ComponentType: "Web"
    }),
  }
  console.log('Firebase Login', requestOptions)
  const url = BASE_URL + "updatefcmdetails";
  return await fetch(url, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      console.log('Firebase Login', data)
      return data;
    })
    .catch((err) => {
      console.error("Update FCM Details", err);
    })
}
//#endregion

//#region Api calling for reset password
export const resetPassword = async (para) => {
  let requestOptions = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken'),
    },
  }
  let url = BASE_CORE_URL + "Authenticate/ResetPassword?" + new URLSearchParams({
    info: para
  });

  return await fetch(url, requestOptions)
    .then(resp => resp.json())
    .then((data) => {
      console.log("Reset Password", data);
      return data;
    })
    .catch(function (error) {
      console.error("Reset Password Error", { error });
    });
}
//#endregion

//#region Api Calling for update firebase user
export const updateFirebaseUser = async (email, password) => {
  let requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      Email: email,
      NewPassword: password
    })
  }

  let url = BASE_URL + "firebasepasswordchange";
  console.log(url);
  return await fetch(url, requestOptions)
    .then(res => res.json())
    .then((data) => {
      console.log("firebasepasswordchange", data);
      return data;
    })
    .catch((err) => {
      console.log(err);
    })
}
//#endregion

//#region api calling for verification link
export const verificationLink = async (email) => {
  const requestOptions = {
    method: "GET",
    "Content-Type": "application/json",
    "authcode": localStorage.getItem('authCode'),
    "Authorization": "Bearer " + localStorage.getItem('authToken')
  }

  let url = BASE_CORE_URL + `User/GenerateVerificationLink?emailId=` + email
  return await fetch(url, requestOptions)
    .then(res => res.json())
    .then(data => {
      return data;
    })
    .catch((err) => console.log(err));
}
//#endregion

//#region Api calling for setting theme 
export const backgroundThemeFunction = async(userId,darkThemeNumber)=>{
  const requestOptions = {
    method: "GET",
    "Content-Type": "application/json",
    "authcode": localStorage.getItem('authCode'),
    "Authorization": "Bearer " + localStorage.getItem('authToken')
} 
let url = BASE_CORE_URL + `User/SetTheme?pUserId=${userId}&pTheme=${darkThemeNumber}`

return await fetch (url,requestOptions)
    .then(res => res.json())
    .then (data =>{
      return data;
    })
    .catch((err)=> console.log(err));
} 
//#endregion 

//#region Api calling for setting theme 
export const userSoundMode = async(userId,status)=>{
  const requestOptions = {
    method: "GET",
    "Content-Type": "application/json",
    "authcode": localStorage.getItem('authCode'),
    "Authorization": "Bearer " + localStorage.getItem('authToken')
} 
let url = BASE_CORE_URL + `User/SetSoundMode?pUserId=${userId}&pMode=${status}`

return await fetch (url,requestOptions)
    .then(res => res.json())
    .then (data =>{
      return data;
    })
    .catch((err)=> console.log(err));
} 
//#endregion 