import React from 'react'

const HealthEventsList = ({ item }) => {
    return (
        <tr>
            <td>{item.DeviceName}</td>
            <td>{item.SiteName}</td>
            <td>{item.EventType}</td>
            <td>{item.EventDateTime}</td>
        </tr>
    )
}

export default HealthEventsList