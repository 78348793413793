import React from 'react';
import { usePagination} from '../../CustomHooks/UsePagination';
import { PrePageIcon, NextPageIcon } from '../../assets/images';

const Pagination = props => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        pageName,
        setCurrentPage
    } = props;
    
    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

   

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <>
            <span className="PageOfThisText">
                <span className="SelectTextP">{currentPage}</span> 
                <span className={`UnSelectTextP ${pageName == "mosaic" ? "mosaic-pg" : "cam-pg"}`}>{` of `} {Math.ceil(totalCount/pageSize)}</span>
                
            </span>
            <button           
                className="btn paginationm-move"
                onClick={onPrevious}
                disabled={currentPage === 1}>
                <img src={PrePageIcon} alt='prev arrow'/>
            </button>
            <button           
                className="btn paginationm-move"
                onClick={onNext}
                disabled={currentPage === lastPage}>
                <img src={NextPageIcon} alt='next arrow'/>
            </button>
           
        </>
    );
};

export default Pagination;
