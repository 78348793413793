import React, { useEffect, useState, useRef } from 'react'

import { Modal } from "react-bootstrap";
import { DateRangePicker } from "rsuite";
import Pagination from "react-bootstrap/Pagination";
import { useDataAccessPagination } from '../../CustomHooks/useDataAccessPagination';
import HealthModalList from './HealthModalList';
import Skeleton from 'react-loading-skeleton';
import { getBarChartDataFromAPI, getSelectedDeviceHealthData, getSingleDeviceStatusDetails } from '../../services/healthMonitoringService';
import { ConvertDate } from '../../utils/utils';
import Swal from 'sweetalert2';
import { Bar, getElementAtEvent } from "react-chartjs-2";
import { differenceInDays, subDays, subMonths } from 'date-fns';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorToast } from '../../CustomHooks/ErrorToast';
import { InfoBlue } from '../../assets/images';

    const TODAY = "TD";
    const YESTERDAY = "YD";
    const SEVENDAY = "7D";
    const ONEMONTH = "1M";
    const THREEMONTH = "3M";
    const NONE = "NONE";

const HealthModal = (props) => {
    let { show, setShow, selectedDevice, isDarkTheme } = props;
    const chartRef = useRef();
    const [dataLoading, setDataLoading] = useState(false);
    const [statusSelected, setStatusSelected] = useState("");
    const [dateSelected, setDateSelected] = useState("");
    const [timeSelected, setTimeSelected] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [deviceData, setDeviceData] = useState([]);
    const [masterDeviceData, setMasterDeviceData] = useState([]);
    const [totalDevices, setTotalDevices] = useState(0);
    const [totalString, setTotalString] = useState("");
    const [displayStartDate, setDisplayStartDate] = useState("");
    const [displayEndDate, setDisplayEndDate] = useState("");
    const [labelList, setLabelList] = useState([]);
    const [onlineCountList, setOnlineCountList] = useState([])
    const [offlineCountList, setOfflineCountList] = useState([]);
    const [count, setCount] = useState(0);
    const [xLabel, setXLabel] = useState("Hours");
    const [yLabel, setYLabel] = useState("Minutes");
    const [graphType, setGraphType] = useState("Hour");
    const [activeTab, setActiveTab] = useState(TODAY)
    const [inputDate, setInputDate] = useState([]);
    const [barDataFailed, setBarDataFailed] = useState(true);
    const [currentStatus, setCurrentStatus] = useState("Online");
    const [nextClick, prevClick, pageCounter, currentPageNo, limit, totalPages, setCurrentPageNo, setTotalPages, maxPageAllowed, setPageCounter] = useDataAccessPagination(10, 1, 10, 1);
    
    const getData = (deviceId, deviceStatusType, fromDateTime, toDateTime, pageNumber, searchText) => {
        getSelectedDeviceHealthData(deviceId, deviceStatusType, fromDateTime, toDateTime, pageNumber, searchText)
            .then((res) => {
                if(res.Status == "success"){
                    setTotalDevices(parseInt(res.Data.Total));
                    setDeviceData(res.Data.SingleDeviceStatusModels);
                    setMasterDeviceData(res.Data.SingleDeviceStatusModels);
                    setTotalPages(parseInt(res.Data.Total));
                    setDataLoading(false);
                }else{
                    setDataLoading(false);
                }
            })
            .catch(err => {
                console.log(err)
                setDataLoading(false);
            });
    }

    const getBarChartData = (deviceId, deviceType, fromDateTime, toDateTime,deviceStatusType, isYesterday) => {
        getBarChartDataFromAPI(deviceId, deviceType, fromDateTime, toDateTime, deviceStatusType)
            .then((res) => {
                if(res.Status == "success"){
                    let onlineArr = res.Data.LstOnlineCount.some(x => x != 0)
                    let offlineArr = res.Data.LstOfflineCount.some(x => x != 0)
                    if(onlineArr && offlineArr){
                        setBarDataFailed(false);
                    }else if(onlineArr || offlineArr){
                        setBarDataFailed(false);
                    }
                    else{
                        setBarDataFailed(true);
                    }
                    if(parseInt(res.Data.LstOnlineCount[0]) >= 60){
                        if(selectedDevice.DeviceType == "Storage Device"){
                            setCurrentStatus("Normal");
                        }else{
                            setCurrentStatus("Online");
                        }
                    }else{
                        if(selectedDevice.DeviceType == "Storage Device"){
                            setCurrentStatus("Faulty");
                        }else{
                            setCurrentStatus("Offline");
                        }
                    }
                    if(isYesterday == "YESTERDAY"){
                        setLabelList(res.Data.LstLabel.slice(0,-1));
                        setOfflineCountList(res.Data.LstOfflineCount.slice(0,-1));
                        setOnlineCountList(res.Data.LstOnlineCount.slice(0,-1));
                        let c = count+1;
                        setCount(c)    
                        setXLabel("Hours");
                        setGraphType("Hour");
                    }else{
                        let charString = res.Data.LstLabel[0];
                        if(!charString.match(/[a-zA-Z]/g)){
                            setGraphType("Hour")
                            setXLabel("Hours")
                        }
                        setLabelList(res.Data.LstLabel);
                        setOfflineCountList(res.Data.LstOfflineCount);
                        setOnlineCountList(res.Data.LstOnlineCount);
                        let c = count+1;
                        setCount(c)    
                    }
                }else{
                    setBarDataFailed(true);
                    if(isYesterday == "YESTERDAY"){
                        setLabelList([]);
                        setOfflineCountList([]);
                        setOnlineCountList([]);
                        let c = count+1;
                        setCount(c)    
                        setXLabel("Hours");
                        setGraphType("Hour");
                    }else{
                        setLabelList([]);
                        setOfflineCountList([]);
                        setOnlineCountList([]);
                        let c = count+1;
                        setCount(c)    
                    }
                }
            }).catch(err => {
                console.error(err)
                setBarDataFailed(true);   
            })
    }

    const convertToCSV = (objArray) => {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
    
        for (let i = 0; i < array.length; i++) {
          let line = '';
          for (let index in array[i]) {
            if (line != '') line += ','
    
            line += array[i][index];
          }
    
          str += line + '\r\n';
        }
    
        return str;
      }
      const getSingleDeviceStatusDetailsFn = () => {
        // getSingleDeviceStatusDetails(selectedDeviceDeviceId, selectedDeviceDeviceType, fromDateTime, toDateTime, pageNumber2, "searchText:searchValue")
        getSingleDeviceStatusDetails(selectedDevice.DeviceId, selectedDevice.DeviceStatusType, fromDate, endDate, currentPageNo, "")
            .then((res) => {
                if(res.Status == "success"){
                    if (res.Data == undefined || res.Data.SingleDeviceStatusModels == undefined || res.Data.SingleDeviceStatusModels.length <= 0) {
                        Swal.fire({ text: 'No records available to download.' })
                      return;
                    }else{
                         let stemp = res.Data.SingleDeviceStatusModels;
                        stemp.map( x => {
                            delete x['Id']
                            delete x['DeviceId']
                        });

                        let header = {
                            //DeviceId: "Device Id",
                            DeviceName : "Device Name",
                            Status: "Status",
                            Date: "Status Date",
                            Time: "Status Time"
                        }
                        stemp.unshift(header);                        
                        let jsonObject = JSON.stringify(stemp);
                        let csv = convertToCSV(jsonObject);
                        let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                        let link = document.createElement("a");
                        let fileN = "DeviceDetails.csv"
                        if (link.download !== undefined) {
                          let url = URL.createObjectURL(blob);
                          link.setAttribute("href", url);
                          link.setAttribute("download", fileN);
                          link.style.visibility = 'hidden';
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        }
                    }
                }
            }).catch(err => console.log(err));
    }

    const getSearchData = (tempString) => {
        getSelectedDeviceHealthData(selectedDevice.DeviceId, selectedDevice.DeviceStatusType, fromDate, endDate, currentPageNo, tempString)
            .then((res) => {
                if(res.Status == "success"){
                    setTotalDevices(parseInt(res.Data.Total));
                    setDeviceData(res.Data.SingleDeviceStatusModels);
                    setTotalPages(parseInt(res.Data.Total));
                    setCurrentPageNo(1)
                    setPageCounter(1)
                }
            })
            .catch(err => console.log(err));
    }

    useEffect(() => {
        if (selectedDevice != null) {
            if (fromDate === "" || endDate === "") {
                setDataLoading(true);
                let startDate = ConvertDate(new Date(new Date().setHours(0, 0, 0, 0)));
                setFromDate(startDate);
                let endCurrDate = ConvertDate(new Date());
                setEndDate(endCurrDate)
                displayDateSetters(startDate, endCurrDate);
                getData(selectedDevice.DeviceId, selectedDevice.DeviceStatusType, startDate, endCurrDate, currentPageNo, totalString);
                // if(currentPageNo == 1){
                //     getBarChartData(selectedDevice.DeviceId, selectedDevice.DeviceType, startDate, endCurrDate,selectedDevice.DeviceStatusType);
                // }
            } else {
                getData(selectedDevice.DeviceId, selectedDevice.DeviceStatusType, fromDate, endDate, currentPageNo, totalString);
                // if(currentPageNo == 1){
                //     getBarChartData(selectedDevice.DeviceId, selectedDevice.DeviceType, fromDate, endDate, selectedDevice.DeviceStatusType);
                // }
            }
        }
    }, [currentPageNo])

    useEffect(() => {
        if(fromDate === "" || endDate === ""){
            let startDate = ConvertDate(new Date(new Date().setHours(0, 0, 0, 0)));
            let endCurrDate = ConvertDate(new Date());
            getBarChartData(selectedDevice.DeviceId, selectedDevice.DeviceType, startDate, endCurrDate,selectedDevice.DeviceStatusType);
        }else{
            getBarChartData(selectedDevice.DeviceId, selectedDevice.DeviceType, fromDate, endDate, selectedDevice.DeviceStatusType);
        }
    }, []);

    const PaginationComponent = (currentPageNo) => {
        let numbers = [];
        if (maxPageAllowed - 2 != 0 && (currentPageNo === maxPageAllowed || currentPageNo === (maxPageAllowed - 1) || currentPageNo === (maxPageAllowed - 2))) {
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed - 2}
                    active={maxPageAllowed - 2 == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed - 2)}
                >
                    {maxPageAllowed - 2}
                </Pagination.Item>)
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed - 1}
                    active={maxPageAllowed - 1 == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed - 1)}
                >
                    {maxPageAllowed - 1}
                </Pagination.Item>)
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed}
                    active={maxPageAllowed == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed)}
                >
                    {maxPageAllowed}
                </Pagination.Item>)
        }
        else if (maxPageAllowed - 1 != 0 && (currentPageNo === maxPageAllowed || currentPageNo === (maxPageAllowed - 1))) {
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed - 1}
                    active={maxPageAllowed - 1 == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed - 1)}
                >
                    {maxPageAllowed - 1}
                </Pagination.Item>)
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed}
                    active={maxPageAllowed == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed)}
                >
                    {maxPageAllowed}
                </Pagination.Item>)
        }
        else {
            for (let i = currentPageNo; i < currentPageNo + 3; i++) {
                if (i <= maxPageAllowed) {
                    numbers.push(
                        <Pagination.Item
                            key={i}
                            active={i == currentPageNo}
                            onClick={() => setCurrentPageNo(i)}
                        >
                            {i}
                        </Pagination.Item>
                    )
                }
            }
        }
        return numbers;
    }
    
    const onHandleDateTimeOkClick = (e) => {
    const [startDate, endDate] = [e[0],e[1]];
  if (startDate && endDate) {
    const daysDifference = differenceInDays(endDate, startDate);
    if (daysDifference > 90) {
      ErrorToast('Maximum Date range cannot exceeded 90 days.');
      return;
    }
  }
        //To Convert Format
        //2023-07-30 00:00:00, 2023-07-31 10:48:46
        //YYYY-MM-DD HH:MM:SS
        let sDate = ConvertDate(e[0]);
        setFromDate(sDate);
        let eDate = ConvertDate(e[1]);
        setEndDate(eDate);
        setInputDate([e[0],e[1]])
        displayDateSetters(sDate, eDate);
        let diffDays = differenceInDays(e[1], e[0]);
        if(diffDays < 1){
            setGraphType("Hour")
            setXLabel("Hours")
        }else if(diffDays >= 1 && diffDays <= 7){
            setXLabel("Days");
            setGraphType("Day");
        }else{
            setXLabel("Weeks");
            setGraphType("Weekly");
        }
        getData(selectedDevice.DeviceId, selectedDevice.DeviceStatusType, sDate, eDate, currentPageNo, "");
        getBarChartData(selectedDevice.DeviceId, selectedDevice.DeviceType, sDate, eDate,selectedDevice.DeviceStatusType);
        setActiveTab(NONE);
        setStatusSelected("0")
    }

    const onHandleDateTimeChangeClick = (e) => {
        const [startDate, endDate] = [e[0],e[1]];
        if (startDate && endDate) {
          const daysDifference = differenceInDays(endDate, startDate);
          if (daysDifference > 90) {
            return;
          }
        }
        let sDate = ConvertDate(e[0]);
        setFromDate(sDate);
        let eDate = ConvertDate(e[1]);
        setEndDate(eDate);
        setInputDate([])
        displayDateSetters(sDate, eDate);
        getData(selectedDevice.DeviceId, selectedDevice.DeviceStatusType, sDate, eDate, currentPageNo, "");
        getBarChartData(selectedDevice.DeviceId, selectedDevice.DeviceType, sDate, eDate,selectedDevice.DeviceStatusType);
        setActiveTab(NONE);
        setStatusSelected("0")
      }
      
    const statusChanged = (e) => {
        setStatusSelected(e.target.value);
        searchDetails(e.target.value, "STATUS")
    }

    const dateChanged = (e) => {
        setDateSelected(e.target.value);
        searchDetails(e.target.value, "DATE")
    }

    const timeChanged = (e) => {
        setTimeSelected(e.target.value);
        searchDetails(e.target.value, "TIME")
    }

    const searchDetails = (data, type) => {
        let tempStatus = statusSelected;
        if(type == "STATUS"){
            tempStatus = data.replace("0", "");   
        }
        let tempDate = dateSelected
        if(type == "DATE"){
            tempDate = data;
        }
        let tempTime = timeSelected.replaceAll(":", "-")
        if(type == "TIME"){
            tempTime = data.replaceAll(":", "-");
        }
        let obj = {
            "status": tempStatus,
            "date": tempDate,
            "time": tempTime,
        }    
        let stringText = JSON.stringify(obj);
        let tempStringText = stringText.replace(/["'{}]/g, "").replace(/[,]/g, ";")
        setTotalString(tempStringText);
        getSearchData(tempStringText);
    }

    const today = () => {
        let startDate = ConvertDate(new Date(new Date().setHours(0, 0, 0, 0)));
        setFromDate(startDate);
        let endCurrDate = ConvertDate(new Date());
        setEndDate(endCurrDate)
        setInputDate([])
        displayDateSetters(startDate, endCurrDate);
        if(currentPageNo == 1){
            getData(selectedDevice.DeviceId, selectedDevice.DeviceStatusType, startDate, endCurrDate, 1, "");
        }
        setCurrentPageNo(1);
        getBarChartData(selectedDevice.DeviceId, selectedDevice.DeviceType, startDate, endCurrDate,selectedDevice.DeviceStatusType)   
        setGraphType("Hour")
        setXLabel("Hours")
        setActiveTab(TODAY)
        setStatusSelected("0")
    }

    const sevenDay = () => {
        // let startDate = ConvertDate(new Date(new Date().setHours(0, 0, 0, 0)));
        let startDate = ConvertDate(subDays(new Date(new Date().setHours(0, 0, 0, 0)), 6));
        setFromDate(startDate);
        let endCurrDate = ConvertDate(new Date());
        setEndDate(endCurrDate)
        setInputDate([])
        displayDateSetters(startDate, endCurrDate);
        if(currentPageNo == 1){
            getData(selectedDevice.DeviceId, selectedDevice.DeviceStatusType, startDate, endCurrDate, 1, "");
        }
        setCurrentPageNo(1);
        getBarChartData(selectedDevice.DeviceId, selectedDevice.DeviceType, startDate, endCurrDate,selectedDevice.DeviceStatusType)
        setXLabel("Days");
        setGraphType("Day");
        setActiveTab(SEVENDAY)
        setStatusSelected("0")
    }

    const yesterday = () => {
        // let startDate = ConvertDate(new Date(new Date().setHours(0, 0, 0, 0)));
        let startDate = ConvertDate(subDays(new Date(new Date().setHours(0, 0, 0, 0)), 1));
        setFromDate(startDate);
        // let endCurrDate = ConvertDate(new Date());
        let endCurrDate = ConvertDate(subDays(new Date(new Date().setHours(24, 0, 0, 0)), 1));
        // let endCurrDate = ConvertDate(new Date(new Date().setHours(0, 0, 0, 0)));
        setEndDate(endCurrDate)
        setInputDate([])
        displayDateSetters(startDate, endCurrDate);
        if(currentPageNo == 1){
            getData(selectedDevice.DeviceId, selectedDevice.DeviceStatusType, startDate, endCurrDate, 1, "");
        }
        setCurrentPageNo(1);
        getBarChartData(selectedDevice.DeviceId, selectedDevice.DeviceType, startDate, endCurrDate,selectedDevice.DeviceStatusType,"YESTERDAY")
        setXLabel("Hours");
        setGraphType("Hour")
        setActiveTab(YESTERDAY);
        setStatusSelected("0")
    }

    const oneMonth = () => {
        // let startDate = ConvertDate(new Date(new Date().setHours(0, 0, 0, 0)));
        let startDate = ConvertDate(subMonths(new Date(new Date().setHours(0, 0, 0, 0)), 1));
        setFromDate(startDate);
        let endCurrDate = ConvertDate(new Date());
        setEndDate(endCurrDate)
        setInputDate([])
        displayDateSetters(startDate, endCurrDate);
        if(currentPageNo == 1){
            getData(selectedDevice.DeviceId, selectedDevice.DeviceStatusType, startDate, endCurrDate, 1, "");
        }
        setCurrentPageNo(1);
        getBarChartData(selectedDevice.DeviceId, selectedDevice.DeviceType, startDate, endCurrDate,selectedDevice.DeviceStatusType)
        setXLabel("Weeks");
        setGraphType("Weekly");
        setActiveTab(ONEMONTH);
        setStatusSelected("0")
    }

    const displayDateSetters = (startDate, endCurrDate) => {
        let one = startDate.split(" ");
        let tempOne = `${one[0].split("-")[2]}-${one[0].split("-")[1]}-${one[0].split("-")[0]}`;
        setDisplayStartDate(`${tempOne} ${one[1]}`);
        let two = endCurrDate.split(" ");
        let tempTwo = `${two[0].split("-")[2]}-${two[0].split("-")[1]}-${two[0].split("-")[0]}`;
        setDisplayEndDate(`${tempTwo} ${two[1]}`);
    }

    const threeMonth = () => {
        // let startDate = ConvertDate(new Date(new Date().setHours(0, 0, 0, 0)));
        let startDate = ConvertDate(subMonths(new Date(new Date().setHours(0, 0, 0, 0)), 3));
        setFromDate(startDate);
        let endCurrDate = ConvertDate(new Date());
        setEndDate(endCurrDate)
        setInputDate([])
        displayDateSetters(startDate, endCurrDate);
        if(currentPageNo == 1){
            getData(selectedDevice.DeviceId, selectedDevice.DeviceStatusType, startDate, endCurrDate, 1, "");
        }
        setCurrentPageNo(1);
        getBarChartData(selectedDevice.DeviceId, selectedDevice.DeviceType, startDate, endCurrDate,selectedDevice.DeviceStatusType)
        setXLabel("Weeks");
        setGraphType("Weekly");
        setActiveTab(THREEMONTH);
        setStatusSelected("0")
    }
    
    const data = {
        // labels: ["Mon","Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"],
        labels: labelList,
        datasets: [
            {
            label: selectedDevice.DeviceType != "Storage Device" ? 'Online' : 'Normal',
            // backgroundColor: "#8BB0F4",
            backgroundColor: "#7BB526",
            // data: [14, 2, 10, 6, 12, 16,15],\
            data: onlineCountList,
            barPercentage: 0.3,
            categoryPercentage: 1,
            borderRadius: 5,
        }, {
            label: selectedDevice.DeviceType != "Storage Device" ? 'Offline' : 'Faulty',
            // backgroundColor: "#6C6C6C",
            backgroundColor: "#F32C22",
            // data: [2, 21, 13, 3, 24, 7,3],
            data: offlineCountList,
            barPercentage: 0.3,
            categoryPercentage: 1,
            borderRadius: 5,
            
        }],
    }

    const uniqueKey = (data) => {
        return `${data.StatusDate}_${data.StatusTime}`
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        interaction :{
            mode:'index',
        },
        plugins: {
            legend: {
                display: false,
                onHover: (event) => {
                    document.body.style.cursor = 'pointer';
                }
            },
            tooltip: {
                callbacks: {
                    footer: (tooltipItems) => {
                        let sum = 0;
                        tooltipItems.forEach(function(tooltipItem) {
                          sum += tooltipItem.parsed.y;
                        });
                        return "Total: " + sum;
                    }
                }
            }
        },
        scales: {
            x: {
                stacked: true,
                border: {
                    display: true,
                    color: isDarkTheme ? '#e6e6e6' : "white",
                  },
                grid:{
                    display: false,
                    color:"red",
                    borderColor: "red"
                },
                title:{
                    display:true,
                    text:xLabel,
                    // text:"Day",
                    color: isDarkTheme ? "#000000" : "#ffffff",
                },
                ticks: {
                    color: isDarkTheme ? "" : "#bababa",
                },
            },
            y: {
                stacked: true,
                border: {
                    display: true,
                    color: isDarkTheme ? '#e6e6e6' : "white",
                  },
                beginAtZero: true,
                title:{
                    display:true,
                    // text:"Status in hours",
                    text:yLabel,
                    color: isDarkTheme ? "#000000" : "#ffffff",
                },
                grid:{
                    display:false,
                    color:"#faf7f7"
                },
                ticks: {
                    color: isDarkTheme ? "" : "#bababa",
                },
            },
            
        },
    }

    const onClickHandler = (event) => {
        if(graphType != "Day"){
            return;
        }
        const elem = getElementAtEvent(chartRef.current, event)
        onHandleBarClickEvent(elem[0].index, elem[0].datasetIndex)
    }

    const onHandleBarClickEvent = (barIndex, stackIndex) => {
        let temp = labelList.filter((c, index) => index == barIndex);
        let tempOne = temp[0].split("-")[0];
        let tempTwo = temp[0].split("-")[1];
        let sDate = ConvertDate(new Date("2023", tempTwo - 1, tempOne, 0,0,0,0));
        let eDate = ConvertDate(new Date("2023", tempTwo - 1, tempOne, 24,0,0,0));
        displayDateSetters(sDate, eDate);
        setFromDate(sDate);
        setEndDate(eDate);
        getData(selectedDevice.DeviceId, selectedDevice.DeviceStatusType, sDate, eDate, currentPageNo, "");
        getBarChartData(selectedDevice.DeviceId, selectedDevice.DeviceType, sDate, eDate,selectedDevice.DeviceStatusType,"YESTERDAY")

        // setTableData(rawData.filter(c => c.day === barIndex && c.type === 2))
     
        // if(stackIndex === 0) {
        //   setTableData(rawData.filter(c => c.day === barIndex && c.type === 1))
        // }
        // else {
        //   setTableData(rawData.filter(c => c.day === barIndex && c.type === 2))
        // }
      }
   
    return (
        <Modal
            size="xl"
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="healthMonitorModel"
            backdrop="static"
            style={{zIndex: "99999"}}
        //   aria-labelledby="example-custom-modal-styling-title"
        >
            <>
                <Modal.Header closeButton>
                    <Modal.Title
                        id="example-custom-modal-styling-title"
                        className="deviceModelHeader"
                    >
                        <p style={{ fontSize: "15px", marginBottom:"0px" }}>Status Event(s) for - {selectedDevice?.DeviceName}</p>
                        <div style={{ display: "flex", alignItems: "flex-start" }}>
                            <div style={{display : "grid", gridAutoFlow: "column", gridGap:"10px"}}>
                            <button onClick={today} type="button" className={`${activeTab == TODAY ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>TD</button>
                            <button onClick={yesterday} type="button" className={`${activeTab == YESTERDAY ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>YD</button>
                            <button onClick={sevenDay} type="button" className={`${activeTab == SEVENDAY ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>7D</button>
                            <button onClick={oneMonth} type="button" className={`${activeTab == ONEMONTH ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>30D</button>
                            <button onClick={threeMonth} type="button" className={`${activeTab == THREEMONTH ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>90D</button>
                           {/*  <button type="button" class="btn btn-outline-primary">C</button> */}
                           <DateRangePicker
                                className="mr-1"
                                format="dd-MM-yyyy HH:mm:ss"
                                cleanable={false}
                                editable={false}
                                placeholder="Select date range"
                                defaultCalendarValue={[
                                    subMonths(new Date(), 1),
                                    new Date(),
                                ]}
                                value={inputDate}
                                ranges={[]}
                                onOk={(e) => {onHandleDateTimeOkClick(e)}}
                                // onChange={(e) => {onHandleDateTimeChangeClick(e)}}
                                disabledDate={current => {
                                    return current && current > new Date();
                                  }}
                            />
                            <button type="button" className="btn btn-outline-primary"
                            onClick={() =>{
                                getSingleDeviceStatusDetailsFn();
                            }}
                            >Export</button>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        {/* <p className='TrendSelectionDateP'>Device Event Trend: 15-07-2023 11:18:54 to 16-07-2023 10:11:50 </p> */}
                        <div className='LegendModelDiv'>
                            <div className='MainLegendDiv'>
                                <div className='LegendColorDiv' style={{backgroundColor:"#7BB526"}}></div>
                                <p className='mb-0' style={{fontWeight:"500",fontSize:"13px"}}>&nbsp; 
                                {
                                    selectedDevice.DeviceType == "Storage Device"
                                    ? "Normal"
                                    : "Online" 
                                } 
                                </p>

                                <div className='LegendColorDiv' style={{backgroundColor:"#F32C22", marginLeft:"20px"}}></div>
                                <p className='mb-0' style={{fontWeight:"500",fontSize:"13px"}}>&nbsp; 
                                {
                                    selectedDevice.DeviceType == "Storage Device"
                                    ? "Faulty"
                                    : "Offline" 
                                }
                                </p>
                            </div>
                            
                        </div>
                        <p className='TrendSelectionDateP'>
                           <span className='FromDateFontSize'>From : </span> {displayStartDate}
                            <span className='ml-3 FromDateFontSize'>To: </span>  {displayEndDate} </p>
                        <div className="TrendchartDevice mt-0">
                            <div style={{width: "100%", height:"100%"}}>
                                <Bar ref={chartRef} key={`${count}_${isDarkTheme}`} options={options} data={data} 
                                // onClick={onClickHandler}
                                />
                            </div>
                        </div>
                        <div>
                            <table
                                className="table table-text-ext table-fixed report-table healthmonitoringTable"
                                id="alert-activity-report"
                            >
                                <thead className="ReportTableHeader ReportTableHeadReport">
                                    <tr>
                                        <th>
                                            <p>Status</p>
                                            <div className="SearchTextDiv StatusSearchText">
                                                <select
                                                    required
                                                    className="ArrowSelect HMTableSelect"
                                                    value={statusSelected}
                                                    onChange={(e) => statusChanged(e)}
                                                    // disabled={deviceData.length == 0}
                                                    disabled={masterDeviceData.length == 0}
                                                >
                                                    <option key="0" disabled={false} value="0">
                                                        - Select Data -
                                                    </option>
                                                    {
                                                        selectedDevice.DeviceType == "Storage Device" || selectedDevice.DeviceType == "Storage Device"
                                                        ?
                                                        (
                                                            <>
                                                            <option key="1" value="Normal">Normal</option>
                                                            <option key="2" value="Faulty">Faulty</option>
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                            <option key="1" value="Online" >Online</option>
                                                            <option key="2" value="Offline">Offline</option>
                                                            </>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                        </th>
                                        <th>
                                            <p>Date</p>
                                            <div className="SearchTextDiv StatusSearchText" style={{opacity: ".3"}}>
                                                <input
                                                    className="SearchViewInput forDisabled"
                                                    type="date"
                                                    placeholder="Search"
                                                    style={{ width: "100%" }}
                                                    value={dateSelected}
                                                    onChange={(e) => dateChanged(e)}
                                                    disabled={true}
                                                />
                                            </div>
                                        </th>
                                        <th>
                                            <p>Time</p>
                                            <div className="SearchTextDiv StatusSearchText" style={{opacity: ".3"}}>
                                                <input
                                                    className="SearchViewInput forDisabled"
                                                    type="time"
                                                    placeholder="Search"
                                                    step="1"
                                                    style={{ width: "100%" }}
                                                    value={timeSelected}
                                                    onChange={(e) => timeChanged(e)}
                                                    disabled={true}
                                                />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        dataLoading
                                            ?
                                            <tr>
                                                <td>
                                                    <Skeleton className="width70pct" />
                                                </td>
                                                <td>
                                                    <Skeleton className="width70pct" />
                                                </td>
                                                <td>
                                                    <Skeleton className="width70pct" />
                                                </td>
                                            </tr>
                                            :
                                            <>
                                                {
                                                    barDataFailed
                                                    ?
                                                    <div className='noRecordFound'><p className='noRecordFoundText'>No Record Available.</p></div>
                                                    :
                                                    deviceData.length > 0 
                                                    ?
                                                    deviceData.map((item) => (
                                                        // <HealthModalList key={uniqueKey(item)} item={item} status={item.DeviceStatus} />
                                                        <HealthModalList key={item.Id} item={item} status={item.DeviceStatus} isDarkTheme={isDarkTheme} />                                                        
                                                    )) 
                                                    : 
                                                    <div className="noDataAccessMsg">
                                                        <div>
                                                            <img 
                                                            className="informationIcon" 
                                                            src={InfoBlue} 
                                                            alt="Info"
                                                            />
                                                        </div>
                                                        <div style={{ width: "100%" }}>
                                                        {/* <h3 className="No-Data-Access-Group">
                                                            {currentStatus} Since {displayStartDate}
                                                        </h3> */}
                                                        <h3 className="No-Data-Access-Group">
                                                            Device is continuously {currentStatus?.toLowerCase()} in the selected time range.
                                                        </h3>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                    }
                                </tbody>

                                {
                                    totalDevices == 0 || totalPages == 0 || totalPages <= limit
                                        ? <div className="PaginationDiv mb-2 mt-2">
                                        <h5 className='PageNumbers'>{totalDevices == 0 ? 0 : 1} - {totalDevices}</h5>
                                        <span>of</span>
                                        <h5 className='PageNumbers'>{totalDevices}</h5>
                                        <Pagination>
                                            <Pagination.Prev disabled/>
                                            <Pagination.Item active disabled>{1}</Pagination.Item>
                                            <Pagination.Next disabled/>
                                        </Pagination>
                                        </div>  
                                        : <div className="PaginationDiv mb-2 mt-2">
                                            <h5 className="PageNumbers">
                                                {
                                                    totalDevices == 0 ? 0
                                                        : (currentPageNo - 1) * parseInt(limit) + parseInt(1)}
                                                -
                                                {
                                                    (currentPageNo * limit) > (totalDevices)
                                                        ? (totalDevices)
                                                        : currentPageNo * limit
                                                }
                                            </h5>
                                            <span>of</span>
                                            <h5 className="PageNumbers">{totalDevices}</h5>
                                            
                                        <Pagination>
                                            <Pagination.Prev onClick={prevClick} disabled={currentPageNo == 1} />
                                            {
                                                PaginationComponent(currentPageNo)
                                            }
                                            <Pagination.Next onClick={nextClick} disabled={currentPageNo == maxPageAllowed} />
                                        </Pagination>
                                    </div>
                                }

                            </table>
                        </div>
                        <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  /> 
                    </div>
                </Modal.Body>
            </>
        </Modal>
    )
}

export default HealthModal