import React, { useEffect, useState, useRef } from 'react'
import { Switch, useLocation, useHistory } from 'react-router-dom';

import { getAlertList } from "../../services/index";
import { conversationtemplatecrud } from "../../services/chatFlowService";
import { LogoutDotNet } from '../../services/userAuthServices';

import ReactDOM from 'react-dom'
import Swal from 'sweetalert2'

import {
    CONFIGURATION,
    WORKFLOW, ROOT,
    PLAYER, CAMERA_SINGLE_PLAYBACK
} from '../../navigation/CONSTANTS';
import Topbar from "../../components//Topbar/Topbar";
import PrivateRoute from '../../navigation/Auth/PrivateRoute';
import ConfigurationContainer from '../Configuration/ConfigurationContainer'
import WorkflowContainer from '../Workflow/WorkflowContainer';
import ChatbotContainer from '../ChatbotWidget/ChatbotContainer';
import { useAuth } from '../../navigation/Auth/ProvideAuth';
import { NoAccessPage } from '../../navigation/NoAccess';
import { GetAlertDetails } from '../../utils/ChatbotUtil'
import {
    AddAlertRecord, DeleteAlertRecord, GetAllAlertRecord, GetAlertNotAckRecord,
    DeleteChatWindowOpenedTime, AddChatWindowOpenedTime
} from '../../utils/IndexedDBStorageUtil'
import Player from '../Player/Player';
import SinglePlayback from '../Cameras/SinglePlayback';
import DrawerProvider from '../../Context/DrawerContext';
import ChatbotAssociateCamera from '../ChatbotWidget/ChatbotAssociateCamera';
import { useProfile } from '../../CustomHooks/useProfile';
import { WarningToast } from '../../CustomHooks/WarningToast';
import { ALERT_INTERVAL_TIMER, ASSOC_DIRECT_OPEN, CHAT_CAM_CHANGES } from "../../config"
import { ConvertDate } from '../../utils/utils'
import MainContent from './MainContent';
import sound from './Alertbeep.wav'
import { GetSeverity } from '../../services/configurationServices';

export default function RootContainer() {
    let currentUser = useAuth()
    let location = useLocation()
    let history = useHistory()
    let LoggedInUser = JSON.parse(localStorage.getItem('user'))
    let hootRef = useRef(true);

    //#region useState start
    const [alertList, setAlertList] = useState(null)
    const [todaysAlertList, setTodaysAlertList] = useState(null)
    const [rootLoading, setRootLoading] = useState(false);
    const [alertcounts, setalertcounts] = useState({
        "TotalOpen": 0,
        "Extreme": 0,
        "High": 0,
        "Moderate": 0,
        "Low": 0,
        "TotalClosed": 0,
        "ExtremeClosed": 0,
        "HighClosed": 0,
        "ModerateClosed": 0,
        "LowClosed": 0,
        "TodaysAlerts": 0
    })
    const [selectedTab, setSelectedTab] = useState('dashboard')
    const [selectedSubMenu, setSelectedSubMenu] = useState(null)
    const [isSuperUser, setIsSuperUser] = useState(false);
    const { profile, getprofiledata } = useProfile();
    const [uniqueId, setUniqueId] = useState('');
    const [isDarkTheme, setIsDarkTheme] = useState(true);
    const [cctvTypeName, setCctvTypeName] = useState('');
    const [camUserName, setCamUserName] = useState('');
    const [camPassword, setCamPassword] = useState('');
    const [brandName, setBrandName] = useState('');
    const [hootWay, setHootWay] = useState([]);
    const [openButton1, setOpenButton1] = useState(false);
    const [activeCam, setActiveCam] = useState(null);
    const [alertDetail, setAlertDetail] = useState([]);
    const [associateCam, setAssociateCam] = useState([]);
    const [alertSearch, setAlertSearch] = useState(false);
    const [activeSeverity, setActiveSeverity] = useState("all");
    const [childWindow, setChildWindow] = useState(null);
    const [counter, setCounter] = useState(0);
    const [chatHeads, setChatHeads] = useState([])
    const [alertSound, setAlertSound] = useState(0);
    const alertSoundRef = useRef(0);
    const lastAlertNoRef = useRef(0);
    const enableLowSeverityRef = useRef(false);
    const enableModerateSeverityRef = useRef(false);
    const enableHighSeverityRef = useRef(false);
    const enableExtremeSeverityRef = useRef(false);
    const enableSoundRef = useRef(false);
    const firstLoginRef = useRef(false);
    const [isplaySound, setIsPlaySound] = useState(true);
    const [toggleHide, setToggleHide] = useState(false);
    //#endregion useState end

    //#region useEffect start
    useEffect(() => {
        getAll()
    }, [])

    useEffect(() => {
        let arr = JSON.parse(localStorage.getItem('hootWayData'));
        if (arr == null) {
            setHootWay([]);
        } else {
            setHootWay(arr);
        }
    }, []);

    useEffect(() => {
        if (!hootRef.current) {
            let arr = hootWay;
            localStorage.setItem('hootWayData', JSON.stringify(arr));
        }
        return () => {
            hootRef.current = false;
        }
    }, [hootWay]);

    useEffect(() => {
        setUniqueId(LoggedInUser?.UserUniqueID);
        setIsSuperUser(LoggedInUser?.UserRoleID == 1 || LoggedInUser?.UserRoleID == 2)
    }, [LoggedInUser]);

    useEffect(() => {
        (async () => {
            await getprofiledata()
            // if (location.pathname === "/allalerts") {
            await Getopenedchats()
            // }
            if (LoggedInUser && LoggedInUser.UserRoleID == 3) {
                await Getnotackalertslst()
            }
        })();

    }, [])
    //For dashboard alert docker and todays alert count

    useEffect(() => {
        const interval = setInterval(async () => {
            if (isSuperUser && uniqueId) { //tej 17022022 alert docker get refreshed after every 1 min if the current logged in user is root or admin
                // if (location.pathname === "/dashboard" || location.pathname === '/') {
                if (LoggedInUser && localStorage.getItem('authCode')) {
                    await GetAlertCount()
                    await GetTodaysAlertList()
                }
                // }  
            }
            else {
                // await 
                if (LoggedInUser && localStorage.getItem('authCode')) {
                    await GetAlertCount()
                    await GetTodaysAlertList()
                }
            }
        }, ALERT_INTERVAL_TIMER);
        return () => {
            clearInterval(interval);
        }
    }, [uniqueId]);

    //For All alert docker 
    useEffect(() => {
        const interval = setInterval(async () => {
            if (location && location.pathname && (location.pathname === "/allalerts") && !alertSearch) {
                await GetAlertList()
            }
        }, ALERT_INTERVAL_TIMER);
        return () => {
            clearInterval(interval);
        }
    }, [uniqueId, location, alertSearch, activeSeverity]);

    useEffect(() => {
        if (LoggedInUser && localStorage.getItem('authCode')) {
            GetTodaysAlertList();
            GetAlertCount();
        }
    }, [])

    useEffect(() => {
        if (sessionStorage.getItem('mosaicWindowKey') == "mosaicWindow") {
            let interval = setInterval(() => {
                if (childWindow?.closed) {
                    sessionStorage.removeItem('mosaicWindowKey');
                }
            }, 2000);

            return () => {
                clearInterval(interval);
            }

        }
    }, [childWindow, sessionStorage.getItem('mosaicWindowKey')]);

    useEffect(() => {
        // Listen for the beforeunload event in the parent window
        const beforeUnloadListener = () => {
            if (childWindow) {
                sessionStorage.removeItem("mosaicWindowKey")
                childWindow.close();
            }
        };

        window.addEventListener('beforeunload', beforeUnloadListener);
        return () => {
            // Cleanup: Remove the event listener when the parent component unmounts
            window.removeEventListener('beforeunload', beforeUnloadListener);
        };

    }, [childWindow]);

    useEffect(() => {
        let intervalId
        if (childWindow) {
            intervalId = setInterval(() => {
                setCounter(prevCounter => prevCounter + 1);
                if (sessionStorage.getItem('mosaicWindowKey') == null || sessionStorage.getItem('mosaicWindowKey') === undefined || sessionStorage.getItem('mosaicWindowKey') === '') {
                    childWindow.close();
                }
            }, 100); // 100 milliseconds (0.1 seconds)
        }
        return () => {
            clearInterval(intervalId); // Clean up the interval on unmount
        }
    }, [childWindow]);

    //#region functions start
    const GetAlertCount = async () => {
        try {

            var obj = {
                "action": "alertCount",
                "userUniqueId": LoggedInUser?.UserUniqueID,
                "authCode": localStorage.getItem('authCode')
            }
            await getAlertList(obj).then((resp) => {
                if (resp && resp.status == "Ok") {
                    if (resp.message) {
                        setalertcounts(resp.message[0])
                        // setRootLoading(false);
                    }
                } else {
                    // alert(resp.message)
                    if (resp.message == "Unauthorized") {
                        // sessionStorage.removeItem('mosaicWindowKey')
                        if (localStorage.getItem('sessionExpired') == "true") {
                            localStorage.removeItem('sessionExpired');
                            sessionStorage.removeItem('mosaicWindowKey')
                            return;
                        } else {
                            localStorage.setItem('sessionExpired', true)
                            sessionStorage.removeItem('mosaicWindowKey')
                            Swal.fire({
                                text: 'Logged in from another machine, this session is expired you will be logged out from here.',
                                confirmButtonText: 'Ok',
                                allowOutsideClick: false,
                                timer: 5100,
                                timerProgressBar: true
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    LogoutDotNet().then((res) => {
                                        history.push('/login');
                                        localStorage.removeItem('authToken');
                                        localStorage.removeItem('authCode');
                                        localStorage.removeItem('user');
                                        localStorage.removeItem('userPassword');
                                        localStorage.removeItem('userID');
                                        localStorage.removeItem('hootWayData');
                                        localStorage.removeItem('UserID');
                                        localStorage.removeItem('sessionExpired');
                                        sessionStorage.removeItem('Page_Refresh_State');
                                        sessionStorage.removeItem('mosaicWindowKey');
                                        localStorage.removeItem('PlaySound');
                                        childWindow.close();
                                    })
                                }
                            }).catch(err => console.log("catcherr", err))
                            setTimeout(() => {
                                Swal.clickConfirm();
                                // let authCode = localStorage.getItem('authCode');
                                // if(authCode != null){
                                //     LogoutDotNet().then((res) => {
                                //     history.push('/login');
                                //     localStorage.removeItem('authToken');
                                //     localStorage.removeItem('authCode');
                                //     localStorage.removeItem('user');
                                //     localStorage.removeItem('userPassword');
                                //     localStorage.removeItem('hootWayData');
                                //     localStorage.removeItem('userID');
                                //     localStorage.removeItem('UserID');
                                //     localStorage.removeItem('sessionExpired');
                                //     sessionStorage.removeItem('Page_Refresh_State');
                                //     childWindow.close();
                                // })
                                // }else{
                                //     history.push('/login');
                                //     localStorage.removeItem('authToken');
                                //     localStorage.removeItem('authCode');
                                //     localStorage.removeItem('user');
                                //     localStorage.removeItem('userPassword');
                                //     localStorage.removeItem('userID');
                                //     localStorage.removeItem('hootWayData');
                                //     localStorage.removeItem('UserID');
                                //     localStorage.removeItem('sessionExpired');
                                //     sessionStorage.removeItem('Page_Refresh_State');
                                //     childWindow.close();                               
                                // }
                            }, 5000);
                        }
                    }
                }
            })
        } catch (error) {
            console.error(error)
            // setRootLoading(false);
        }
    }

    const GetAlertList = async () => {
        try {
            var obj = {
                "action": "getAlertList",
                "severity": activeSeverity,
                "userUniqueId": LoggedInUser?.UserUniqueID,
                "startIndex": 0,
                "numberOfElements": 50,
                "authCode": localStorage.getItem('authCode')
            }
            await getAlertList(obj).then((resp) => {
                if (resp.status == "Ok") {
                    if (resp.message) {
                        setAlertList(resp.message)
                    }
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const getAll = () => {
        try {
            GetSeverity().then((resp) => {
                if (resp != undefined) {
                    console.log("checkrespget", resp)
                    if (resp.Status == "success") {
                        let extreme = resp.Data.find((item) => item.SeverityTitle == "Extreme");

                        enableExtremeSeverityRef.current = Boolean(parseInt(extreme.PlaySound))
                        let high = resp.Data.find((item) => item.SeverityTitle == "High");

                        enableHighSeverityRef.current = Boolean(parseInt(high.PlaySound))
                        let moderate = resp.Data.find((item) => item.SeverityTitle == "Moderate");

                        enableModerateSeverityRef.current = Boolean(parseInt(moderate.PlaySound))
                        let low = resp.Data.find((item) => item.SeverityTitle == "Low");

                        enableLowSeverityRef.current = Boolean(parseInt(low.PlaySound))

                        if (!enableExtremeSeverityRef.current && !enableHighSeverityRef.current && !enableModerateSeverityRef.current && !enableLowSeverityRef.current) {
                            setToggleHide(false)
                        } else {
                            setToggleHide(true)
                        }
                    }
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const checkEnableSound = () => {
        let user = JSON.parse(localStorage.getItem('user'))
        console.log("temp", user)
        if (user != null || user != undefined) {
            let temp = user.PlaySoundFlag == "1" ? true : false
            console.log("temp", temp)
            enableSoundRef.current = temp;
        }
    }

    const GetTodaysAlertList = async () => {
        checkEnableSound();
        getAll();
        setRootLoading(true);
        try {
            var obj = {
                "action": "getTodaysAlertsList",
                "userUniqueId": LoggedInUser?.UserUniqueID,
                "startIndex": 0,
                "numberOfElements": 50,
                "authCode": localStorage.getItem('authCode')
            }
            await getAlertList(obj).then((resp) => {
                if (resp.status == "Ok") {
                    if (resp.message) {
                        setTodaysAlertList(resp.message)
                        setRootLoading(false);
                        console.log("respmessage", resp.message)
                        //to get data from localstorage (PlaySoundFlag)
                        const soundPlay = localStorage.getItem('PlaySound');
                        let temp = soundPlay == "true" ? true : false
                        let recentAlertId = resp.message.length > 0 ? resp.message[0].alertId : 0
                        console.log("SoundLogic - recent alert id", recentAlertId);
                        // First Check, to check if the enable sound is true or false on the topbar.
                        if (temp) {
                            console.log("SoundLogic size of list", resp.message.length);
                            console.log("SoundLogic last recorded alert id", lastAlertNoRef.current);
                            console.log("SoundLogic", recentAlertId);
                            // if (alertSoundRef.current < resp.message.length && firstLoginRef.current) {
                            // Second Check, to check if the recent alert id is not equal to last saved recent alert
                            if (lastAlertNoRef.current != recentAlertId && firstLoginRef.current) {
                                // let difference = resp.message.length - alertSoundRef.current
                                let difference;
                                // Below Logic is to check the difference to get the count of the newly raised alerts.
                                if (lastAlertNoRef.current == 0) {
                                    difference = resp.message.length;
                                } else {
                                    difference = resp.message[0].alertId - lastAlertNoRef.current;
                                }
                                console.log("SoundLogic", difference)
                                //Below logic is to get the list of the newly raised alerts
                                let newAlert = resp.message.slice(0, difference).map(item => item.severity)
                                console.log("SoundLogic", newAlert)
                                let playsound = false;
                                //Below logic is to iterate through the newly raised alerts and check their severity and raised the alert sound if the severity is enabled.
                                for (let i = 0; i < difference; i++) {
                                    console.log("checkplaysound", newAlert[i])
                                    console.log("checkplaysound", enableExtremeSeverityRef.current)
                                    if (newAlert[i] == "Extreme" && enableExtremeSeverityRef.current) {
                                        playsound = true;
                                        break;
                                    } else if (newAlert[i] == "Moderate" && enableModerateSeverityRef.current) {
                                        playsound = true;
                                        break;
                                    } else if (newAlert[i] == "High" && enableHighSeverityRef.current) {
                                        playsound = true;
                                        break;
                                    } else if (newAlert[i] == "Low" && enableLowSeverityRef.current) {
                                        playsound = true;
                                        break;
                                    } else {
                                        continue;
                                    }

                                }
                                console.log("checkplaysound", playsound)
                                //If playsound flag is true then play the sound otherwise it will go to the else block.
                                if (playsound) {
                                    alertSoundRef.current = resp.message.length
                                    lastAlertNoRef.current = recentAlertId
                                    // let audio = new Audio('./Alertbeep.wav');
                                    let audio = document.getElementById("myAudio")
                                    audio.play()
                                }
                                else {
                                    alertSoundRef.current = resp.message.length
                                    lastAlertNoRef.current = recentAlertId
                                }
                            } else {
                                alertSoundRef.current = resp.message.length
                                lastAlertNoRef.current = recentAlertId
                                console.log("audio dont play the sound")
                            }
                        } else {
                            alertSoundRef.current = resp.message.length
                            lastAlertNoRef.current = recentAlertId
                        }
                        firstLoginRef.current = true
                    }
                }
            })
        } catch (error) {
            console.error(error)
            setRootLoading(false);
        }
    }
    // const playSound = () => {
    //     let audio=document.getElementById("myAudio")
    //     audio.play()
    // }

    let alertnotacktime = 2
    const EscallateAlert = async (id) => {
        try {
            var obj = {
                "action": "escalateAlert",
                "alertId": id,
                "userId": LoggedInUser.UserID,
                "datetime": ConvertDate(new Date())
            }
            return;
            /* ------- Added Return Statement to stop the reassigning alert issue ------- */
            await conversationtemplatecrud(obj).then((resp) => {
                if (resp.status == "Ok") {
                    onCloseAlertChat(id)
                    DeleteChatWindowOpenedTime(id)
                    GetAlertList()
                } else {
                    alert(resp.message)
                }
            })
        } catch (error) {
            console.error(error)
        }
    }
    const Getopenedchats = async () => {
        try {
            var res = await GetAllAlertRecord()
            res.onsuccess = function (event) {
                if (event.target.result.length > 0) {
                    let arr = event.target.result.map((item) => {
                        let obj = {
                            ...item,
                            isOpen: false
                        }
                        return obj;
                    })
                    setChatHeads(arr)
                    // setChatHeads(event.target.result)
                }
            };
        } catch (error) {
            console.error(error)
        }
    }
    const Getnotackalertslst = async () => {
        try {
            var res = await GetAlertNotAckRecord()
            res.onsuccess = function (event) {
                if (event.target.result != []) {
                    event.target.result.map((item) => {
                        var sec = parseInt((new Date() - new Date(item.alertWindowOpenTime)) / 1000)
                        var min = parseInt(sec / 60)
                        if (min > alertnotacktime) {
                            var fulltime = alertnotacktime + alertnotacktime
                            if (min > fulltime) {
                                //alert("AlertId_"+item.alertId+" - Escallate api calling!")
                                EscallateAlert(item.alertId)
                            } else {
                                var lefttime = fulltime - min
                                var escallateimer = setTimeout(() => {
                                    //alert("AlertId_"+item.alertId+" - Escallate api calling!")
                                    clearTimeout(escallateimer)
                                    //DeleteChatWindowOpenedTime(item.alertId)
                                    EscallateAlert(item.alertId)
                                }, lefttime * 60 * 1000)
                                //AddEscallationTimerList(item.alertId,null,escallateimer)
                                AddChatWindowOpenedTime(item.alertId, null, escallateimer)
                            }
                        } else {
                            var lefttime = alertnotacktime - min
                            var notifytimer = setTimeout(() => {
                                var escallateimer = setTimeout(() => {
                                    //alert("AlertId_"+item.alertId+" - Escallate api calling!")

                                    clearTimeout(escallateimer)
                                    EscallateAlert(item.alertId)
                                    //DeleteChatWindowOpenedTime(item.alertId)
                                }, alertnotacktime * 60 * 1000)
                                //AddEscallationTimerList(item.alertId,null,escallateimer)
                                AddChatWindowOpenedTime(item.alertId, null, escallateimer)
                                alert("AlertId_" + item.alertId + " - Please acknowledge your alert, if you don't this alert will get reassigned.")
                                clearTimeout(notifytimer)
                            }, lefttime * 60 * 1000)
                            //AddEscallationTimerList(item.alertId,notifytimer,null)
                            AddChatWindowOpenedTime(item.alertId, notifytimer, null)
                        }
                    })
                }
            };
        } catch (error) {
            console.error(error)
        }
    }

    const Setalertnotacktimeout = (id) => {
        try {
            var notifytimer = setTimeout(() => {
                var escallateimer = setTimeout(() => {
                    //alert("AlertId_"+id+" - Escallate api calling!")

                    clearTimeout(escallateimer)
                    EscallateAlert(id)
                    //DeleteChatWindowOpenedTime(id)
                }, alertnotacktime * 60 * 1000)
                AddChatWindowOpenedTime(id, null, escallateimer)
                alert("AlertId_" + id + " - Please acknowledge your alert, if you don't this alert will get reassigned.")
                clearTimeout(notifytimer)
            }, alertnotacktime * 60 * 1000)
            //AddEscallationTimerList(id,notifytimer,null)
            AddChatWindowOpenedTime(id, notifytimer, null)

        } catch (error) {
            console.error(error)
        }
    }

    async function onAddAlertChat(id) {
        try {
            let res = chatHeads.find(chat => chat.alertId == id)
            console.log("Count", chatHeads.length)
            console.error({ res })
            if (!res) {
                if (chatHeads.length > 3) {
                    WarningToast("Max 4 chat widgets allowed")
                    return false
                }
                return await GetAlertDetails(id).then((resp) => {
                    if (resp) {
                        if (ASSOC_DIRECT_OPEN) {
                            setAlertDetail(resp);
                        }
                        setCctvTypeName(resp.cctvtype)
                        setCamUserName(resp.camUserName)
                        setCamPassword(resp.camPassword)
                        setBrandName(resp.brandName)
                        if (resp.workflowId != null) {
                            setChatHeads(prev => [...prev, resp])
                            AddAlertRecord(resp)
                            let isItDisabled = true;
                            let user_id = localStorage.getItem('UserID');
                            if (resp.assignedTo != null && resp.assignedTo == user_id) {
                                isItDisabled = false
                            }

                            let tempObj = {
                                alertId: id,
                                twoWayStatus: true,
                                hooterStatus: true,
                                btnDisabled: isItDisabled,
                                deviceId: resp.deviceId
                            }
                            setHootWay([...hootWay, tempObj]);
                            if (CHAT_CAM_CHANGES) {
                                let secondObj = {
                                    alertId: id,
                                    alertDetail: resp,
                                    display: false,
                                    logText: "",
                                    shouldRefresh: false
                                }
                                setAssociateCam([...associateCam, secondObj]);
                            }
                            return true
                        } else {
                            Swal.fire({ text: "Workflow not configured for " + resp.alertTemplate })
                            return false
                        }
                    }
                })
            } else {
                return false
            }
        } catch (error) {
            console.error({ error })
            return false
        }
    }

    const onCloseAlertChat = (id) => {
        try {
            let arr = hootWay;
            let tempArr = arr.filter((item) => item.alertId != id.alertId);
            setHootWay(tempArr);
            ReactDOM.unmountComponentAtNode(document.getElementById("Chatbotcomponentdiv:AlertID-" + id.alertId))
        } catch (error) {
            console.error(error)
        }
        let data = chatHeads.filter((value) => {
            return value.alertId !== id.alertId
        })
        setChatHeads(data)
        DeleteAlertRecord(id.alertId)
    }

    const onMaximizeAlertChat = (id) => {
        let data = chatHeads.filter((value) => {
            return value.alertId !== id
        })
        setChatHeads(data)
        DeleteAlertRecord(id)
        history.push(`/chatflow/${id}`)
    }

    const onChatOpen = (alertId) => {
        let arr = chatHeads.map((item) => {
            let obj;
            if (alertId == item.alertId) {
                obj = {
                    ...item,
                    isOpen: !item.isOpen
                }
            } else {
                obj = {
                    ...item
                }
            }
            return obj;
        })
        setChatHeads(arr);
    }

    //#endregion functions end

    function renderSwitch(param, onAddAlert, onCloseAlertChat) {
        return <Switch>
            <PrivateRoute path={PLAYER}>
                <Player />
            </PrivateRoute>
            <PrivateRoute path={CONFIGURATION}>
                {isSuperUser ? <ConfigurationContainer getAllfromParent={getAll} isDarkTheme={isDarkTheme} /> : <NoAccessPage />}
            </PrivateRoute>
            <PrivateRoute path={WORKFLOW}>
                {isSuperUser ? <WorkflowContainer /> : <NoAccessPage />}
            </PrivateRoute>
            {/*  <PrivateRoute path={ABOUTMH}>
                <AboutContainer/>
            </PrivateRoute> */}
            <PrivateRoute path={ROOT}>
                <MainContent
                    isSuperUser={isSuperUser}
                    alerts={alertList}
                    onAddAlertChat={onAddAlert}
                    ResetAlertList={GetAlertList}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    selectedSubMenu={selectedSubMenu}
                    setSelectedSubMenu={setSelectedSubMenu}
                    alertcounts={alertcounts}
                    rootLoading={rootLoading}
                    ResetAlertCounts={GetAlertCount}
                    todaysAlertList={todaysAlertList}
                    uniqueId={uniqueId}
                    cctvTypeName={cctvTypeName}
                    camUserName={camUserName}
                    camPassword={camPassword}
                    brandName={brandName}
                    hootWay={hootWay}
                    setHootWay={setHootWay}
                    alertSearch={alertSearch}
                    setAlertSearch={setAlertSearch}
                    activeSeverity={activeSeverity}
                    setActiveSeverity={setActiveSeverity}
                    childWindow={childWindow}
                    setChildWindow={setChildWindow}
                    isDarkTheme={isDarkTheme}
                    setIsDarkTheme={setIsDarkTheme}
                    setAlertDetail={setAlertDetail}
                    setOpenButton1={setOpenButton1}
                    openButton1={openButton1}
                    activeCam={activeCam}
                    setActiveCam={setActiveCam}
                    setCctvTypeName={setCctvTypeName}
                    setCamUserName={setCamUserName}
                    setCamPassword={setCamPassword}
                    setBrandName={setBrandName}
                    associateCam={associateCam}
                    setAssociateCam={setAssociateCam}
                    checkEnableSound={checkEnableSound}
                    setIsPlaySound={setIsPlaySound}
                    isplaySound={isplaySound}
                />
            </PrivateRoute>
            <PrivateRoute path={CAMERA_SINGLE_PLAYBACK}>
                <SinglePlayback />
            </PrivateRoute>
        </Switch>
    }

    return (
        <>
            <DrawerProvider>
                <div className="container-fluid p-0">
                    <Topbar profile={profile} isDarkTheme={isDarkTheme} setIsDarkTheme={setIsDarkTheme} childWindow={childWindow} setIsPlaySound={setIsPlaySound} isplaySound={isplaySound} toggleHide={toggleHide}
                        setChildWindow={setChildWindow} />
                    <audio id="myAudio">
                        <source src={sound} type="audio/wav" />
                    </audio>

                    {/* All Config Routes here */}
                    <main className="mainDiv">
                        {
                            renderSwitch(
                                location.pathname,
                                onAddAlertChat)
                        }
                    </main>
                </div>
                {
                    currentUser &&
                    <ChatbotContainer
                        chatHeads={chatHeads}
                        onCloseAlertChat={onCloseAlertChat}
                        onMaximizeAlertChat={onMaximizeAlertChat}
                        ResetAlertList={GetAlertList}
                        onChatOpen={onChatOpen}
                        cctvTypeName={cctvTypeName}
                        camUserName={camUserName}
                        camPassword={camPassword}
                        brandName={brandName}
                        hootWay={hootWay}
                        setHootWay={setHootWay}
                        openButton1={openButton1}
                        setOpenButton1={setOpenButton1}
                        setAlertDetail={setAlertDetail}
                        activeCam={activeCam}
                        setActiveCam={setActiveCam}
                        associateCam={associateCam}
                        setAssociateCam={setAssociateCam}
                    />
                }

                {
                    CHAT_CAM_CHANGES
                        ?
                        associateCam.map((item) => (
                            <>
                                {
                                    item.display
                                        ?
                                        <ChatbotAssociateCamera key={item.alertId} id={item.alertId} openButton1={item.display} setOpenButton1={setOpenButton1} alertDetail={item.alertDetail} associateCam={associateCam} setAssociateCam={setAssociateCam} />
                                        : null
                                }
                            </>
                        ))
                        :
                        openButton1 && <ChatbotAssociateCamera openButton1={openButton1} setOpenButton1={setOpenButton1} activeCam={activeCam} setActiveCam={setActiveCam} alertDetail={alertDetail} />
                }
            </DrawerProvider>
        </>
    )
}