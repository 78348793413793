import React, { useEffect, useImperativeHandle, useState } from 'react'
import { useForm } from 'react-hook-form';

import { EditShape, Cross, SearchIcon, SearchIconWhite, AddNode } from '../../assets/images'

import Swal from 'sweetalert2'
import Modal from "react-bootstrap/Modal";
import ReactFlow, { ReactFlowProvider, Controls } from "react-flow-renderer";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Button, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';

import DataAccessGroup from './DataAccessGroup';
import { WarningToast } from '../../CustomHooks/WarningToast';
import { ErrorToast } from '../../CustomHooks/ErrorToast';
import { getDataAccess } from '../../services/dataAccessService';
import { verificationLink } from '../../services/userAuthServices';

export default function UserTemplateStep({
    pageState,
    users,
    setSelectedDataAccessGroupId,
    userGroups,
    onSaveUser,
    selectedUser,
    onSelectUser,
    setIsNotValidPassword,
    setChangePassword,
    setIsPassUpdated,
    onMoveNext,
    isUserEdit,
    setIsUserEdit,
    siteRef,
    usersToBind,
    selectedUsers,
    setSelectedUsers,
    onAddSelectedUsers,
    onAddNewUserGroup,
    onUpdateUserGroup,
    filteredUsers,
    onSearchChange,
    selectedUserTypeList,
    setselectedUserTypeList,
    filteredUserGroups,
    siteGroups,
    siteGroupOnClick,
    selectedSiteGroup,
    workflows,
    nodes,
    setNodes,
    onLoad,
    editMode,
    setEditMode,
    isNodeSelected,
    setIsNodeSelected,
    setSelectedNode,
    selectedNode,
    disableAdd,
    setDisableAdd,
    onNavigate,
    setIsItemClick,
    setSelectedSubMenu,
    setIsUserVerified,
    isDarkTheme,
    validationError,
    savebtnClick,
    setSavebtnClick,
    buttonDisable,
    setButtonDisable
}) {

    //region useState start
    const [issearch, setissearch] = useState(true)
    //region useState end

    function toggleSearch() {
        try {
            setissearch(!issearch)
            document.getElementById("Search_txt").value = ""
            onSearchChange("", selectedUserTypeList)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <div className="col-lg-12 mt-2" style={{ height: "calc(100% - 80px)" }}>
                {pageState == "Add User" &&
                    <AddUserForm
                        nodes={nodes}
                        setSelectedDataAccessGroupId={setSelectedDataAccessGroupId}
                        setNodes={setNodes}
                        onSubmitUser={onSaveUser}
                        user={selectedUser}
                        onSelectUser={onSelectUser}
                        isUserEdit={isUserEdit}
                        setIsUserEdit={setIsUserEdit}
                        ref={siteRef}
                        workflows={workflows}
                        onNavigate={onNavigate}
                        setIsItemClick={setIsItemClick}
                        setSelectedSubMenu={setSelectedSubMenu}
                        setIsNotValidPassword={setIsNotValidPassword}
                        setChangePassword={setChangePassword}
                        setIsPassUpdated={setIsPassUpdated}
                        setIsUserVerified={setIsUserVerified}
                        isDarkTheme={isDarkTheme}
                        filteredUsers={filteredUsers}
                        selectedUser={selectedUser}
                        onSearchChange={onSearchChange}
                        validationError={validationError}
                        savebtnClick={savebtnClick}
                        setSavebtnClick={setSavebtnClick}
                    buttonDisable={buttonDisable}
                    setButtonDisable={setButtonDisable}
                    />
                }
                {
                    pageState == "FR Assignment" &&
                    <AssignFRsToSiteGroup
                        userGroups={userGroups}
                        onFinish={onMoveNext}
                        usersToBind={usersToBind}
                        selectedUsers={selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                        onAddSelectedUsers={onAddSelectedUsers}
                        onAddNewUserGroup={onAddNewUserGroup}
                        onUpdateUserGroup={onUpdateUserGroup}
                        siteGroups={siteGroups}
                        users={users}
                        siteGroupOnClick={siteGroupOnClick}
                        selectedSiteGroup={selectedSiteGroup}
                    />
                }
                {pageState == "Data Access Group" && (
                    <DataAccessGroup workflows={workflows}
                        nodes={nodes}
                        setNodes={setNodes}
                        onLoad={onLoad}
                        isNodeSelected={isNodeSelected}
                        setIsNodeSelected={setIsNodeSelected}
                        setSelectedNode={setSelectedNode}
                        selectedNode={selectedNode}
                        disableAdd={disableAdd}
                        setDisableAdd={setDisableAdd}
                        editMode={editMode}
                        setEditMode={setEditMode}
                        isDarkTheme={isDarkTheme}
                        setButtonDisable={setButtonDisable}
                        buttonDisable={buttonDisable}
                        
                    />
                )}
                {
                    pageState == "Data Access Group" || pageState == "Add User" ? null : (
                        <div className="RightListSide">
                            <div className="HeaderSiteList m-2">
                                {issearch && <p className={selectedUserTypeList == "User List" ? "active-all-light active-block active-text button-common-margin" : "inactive-text button-common-margin"}
                                    onClick={() => setselectedUserTypeList('User List')}>User List</p>}

                                {!issearch &&
                                    <input autoFocus id="Search_txt" className="SearchViewInput1" type="text"
                                        onChange={e => onSearchChange(e.target.value, selectedUserTypeList)} placeholder="Search User" />
                                }

                                <div className="SearchTextDivConfigTool">
                                    {issearch && <img className="EyeIcon" alt='search icon' src={SearchIcon} onClick={() => toggleSearch()} />}
                                    {!issearch && <img className="EyeIcon" src={Cross} alt='cancel icon' onClick={() => toggleSearch()} />}
                                </div>
                            </div>
                            <div className="SiteListMain">
                                {selectedUserTypeList && selectedUserTypeList == "User List" &&
                                    filteredUsers && (
                                        filteredUsers?.length > 0 ?
                                            filteredUsers.map(item => {
                                                return <div className={selectedUser?.Email === item.Email ? "OneList SelectedList" : "OneList"} key={item.UserID} onClick={() => onSelectUser(item)}>
                                                    <p className="ListofText">{item.FirstName || item.FullName}
                                                        <span className="SiteNameText"> - {item.RoleName || item.roleName}</span></p>

                                                    <div>
                                                        <img className="m-2" src={EditShape} alt="edit icon" />
                                                    </div>
                                                </div>
                                            }) : <div className="no-data-found"><p>No User Found.</p></div>
                                    )
                                }

                                {selectedUserTypeList && selectedUserTypeList == "User List" && !filteredUsers &&
                                    <div class="no-report-found"><p class="noRecordFoundText">No Users Available.</p></div>

                                }

                                {selectedUserTypeList && selectedUserTypeList == "User Group" &&
                                    filteredUserGroups && filteredUserGroups.map(item => {
                                        return <div className="OneList" key={item.userGroupID} onClick={() => onSelectUser(item)}>
                                            <p className="ListofText">{item.groupTitle}
                                            </p>
                                            <div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}

const AddUserForm = React.forwardRef((props, ref) => {
    let { onSubmitUser, user, onSelectUser, isUserEdit, setIsUserEdit, nodes, setNodes, setSelectedDataAccessGroupId, onNavigate, setIsItemClick, setSelectedSubMenu, setIsNotValidPassword, setChangePassword, setIsPassUpdated, filteredUsers, isDarkTheme, selectedUser, onSearchChange, validationError,
        savebtnClick,
        setSavebtnClick,buttonDisable } = { ...props }
    const { register, handleSubmit, setValue, getValues, watch } = useForm({ ...user });

    //region useState start
    const [webBoxDisabled, setWebBoxDisabled] = useState(false);
    const [mobileBoxDisabled, setMobileBoxDisabled] = useState(false);
    const [password, setPassword] = useState("");
    const [adminRoleSelected, setAdminRoleSelected] = useState(false);
    const [mouseDown, setMouseDown] = useState(false);
    const [show, setShow] = useState(false);
    // Add user form error states
    const [userNameError, setUserNameError] = useState('');
    const [userEmailError, setUserEmailError] = useState('');
    const [userContactError, setUserContactError] = useState('');
    const [userRoleError, setUserRoleError] = useState('');
    const [userPasswardError, setUserPasswordError] = useState('');
    const [userDataAccessError, setUserDataAccessError] = useState('');

    //region useState end   

    //region useEffect start
    useEffect(() => {
        if (savebtnClick) {
            let templst = validationError.split(",");
            for (let i = 0; i < templst.length; i++) {
                if (templst[i].includes("name-")) {
                    setUserNameError(templst[i].replace("name-", ""));
                    continue
                }
                if (templst[i].includes("email-")) {
                    setUserEmailError(templst[i].replace("email-", ""));
                    continue
                }
                if (templst[i].includes("contact-")) {
                    setUserContactError(templst[i].replace("contact-", ""));
                    continue
                }
                if (templst[i].includes("userrole-")) {
                    setUserRoleError(templst[i].replace("userrole-", ""));
                    continue
                }
                if (templst[i].includes("password-")) {
                    setUserPasswordError(templst[i].replace("password-", ""));
                    continue
                }
                if (templst[i].includes("access role-")) {
                    setUserDataAccessError(templst[i].replace("access role-", ""));
                    continue
                }
            }
            setSavebtnClick(false)
        }
    }, [savebtnClick]);

    useEffect(() => {
        if (user != null) {
            setValue('FirstName', user.FirstName)
            setValue('Email', user.Email)
            setValue('Phone', user.Phone)
            setValue('UserRoleID', user.UserRoleID)
            setValue('isWebLoginAllowed', user.isWebLoginAllowed);
            setValue('isMobileLoginAllowed', user.isMobileLoginAllowed)
            setValue('dataAccessGroupId', user.dataAccessGroupId);
            setValue('isEnabled', user.isEnabled);
            if (user.UserID != 0) {
                setValue('Password', user.Password || "********")
                setPassword(user.Password || "********")
            } else {
                setValue('Password', user.Password)
                setPassword(user.Password);
            }
            let selectedUserRoleId = user.UserRoleID
            if (selectedUserRoleId == 4) {
                setMobileBoxDisabled(true);
                setWebBoxDisabled(true);
                setAdminRoleSelected(false);
            } else if (selectedUserRoleId == 3) {
                setMobileBoxDisabled(false);
                setWebBoxDisabled(true);
                setAdminRoleSelected(false);
            } else if (selectedUserRoleId == 2) {
                setMobileBoxDisabled(false);
                setWebBoxDisabled(true);
                setAdminRoleSelected(true);
            } else {
                setMobileBoxDisabled(false);
                setWebBoxDisabled(false);
                setAdminRoleSelected(false);
            }
        }
        setIsPassUpdated(false);
        setChangePassword(false);
    }, [user])

    useEffect(() => {
        if (user.UserID == 0 || isUserEdit) {
            let selectedUserRoleId = getValues('UserRoleID');
            if (selectedUserRoleId == 4) {
                setValue('isMobileLoginAllowed', true)
                setValue('isWebLoginAllowed', false);
                setMobileBoxDisabled(true);
                setWebBoxDisabled(true);
                // setSelectedDataAccessGroupId(null);
                // setValue('dataAccessGroupId', null);
                setAdminRoleSelected(false);
            } else if (selectedUserRoleId == 3) {
                setValue('isWebLoginAllowed', true);
                setValue('isMobileLoginAllowed', false)
                setMobileBoxDisabled(false);
                setWebBoxDisabled(true);
                // setSelectedDataAccessGroupId(null);
                // setValue('dataAccessGroupId', null);
                setAdminRoleSelected(false);
            } else if (selectedUserRoleId == 2) {
                setValue('isWebLoginAllowed', true);
                setValue('isMobileLoginAllowed', false)
                setMobileBoxDisabled(false);
                setWebBoxDisabled(true);
                setSelectedDataAccessGroupId("1");
                setValue('dataAccessGroupId', "Super Admin");
                setAdminRoleSelected(true);
            } else {
                setMobileBoxDisabled(false);
                setWebBoxDisabled(false);
                setAdminRoleSelected(false);
            }
        }
    }, [watch('UserRoleID')])

    useEffect(() => {
        if (user.UserID != 0 && isUserEdit) {
            if (getValues('isEnabled') == false) {
                setValue('Password', "");
                setPassword("");
                setChangePassword(true);
                WarningToast("Please enter a new password, for enabling the user");
                // if (passwordInput.current) {
                //     passwordInput.current.focus();
                // }
            }
        }
    }, [watch('isEnabled')])

    useEffect(() => {
        window.addEventListener('mousedown', handleMouseDown);
        window.addEventListener('mouseup', handleMouseUp);
        return () => {
            window.removeEventListener('mousedown', handleMouseDown);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);

    useEffect(() => {
        getNodes();
        setSelectedDataAccessGroupId(null);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (show) {
                document.querySelector('.react-flow__controls-fitview').click();
            }
        }, 100)
    }, [show])

    //region useEffect end

    //region functions start
    const clearUserFields = () => {
        setUserNameError('');
        setUserEmailError('');
        setUserContactError('');
        setUserRoleError('');
        setUserPasswordError('');
        setUserDataAccessError('');
    }

    const handlePasswordChange = (event) => {
        if (user.UserID != 0) {
            setIsPassUpdated(true);
        }
        let tempValue = event.target.value[event.target.value.length - 1];
        if (tempValue == " ") {

            ErrorToast('Space is not Allowed!');
            return;
        }

        const newPassword = event.target.value.replace(" ", "");
        setPassword(newPassword);
        setValue('Password', newPassword)
        setIsNotValidPassword(!(
            newPassword.length > 0 &&
            isCapitalCase(newPassword) &&
            isSpecialCharacter(newPassword) &&
            isNumberInclude(newPassword) &&
            isMinCharacter(newPassword) &&
            isMaxCharacter(newPassword)
        ))
    };

    const isCapitalCase = (pass) => {
        const pattern = /[A-Z]/;
        return pattern.test(pass)
    }
    const isSpecialCharacter = (pass) => {
        const pattern = /[\W_]/;
        return pattern.test(pass)
    };

    const isNumberInclude = (pass) => {
        const pattern = /\d/;
        return pattern.test(pass)
    }
    const isMinCharacter = (pass) => {
        return pass.length >= 8;
    };
    const isMaxCharacter = (pass) => {
        return pass.length <= 15 && pass.length >= 1;
    };

    //region functions end

    const popover = (
        <Popover id="popover-basic" style={{ width: "200px", transform: "translate(1090px, 207px)" }}>
            <Popover.Content>
                <p className={isCapitalCase(password) ? "popoverP Valid" : "popoverP Invalid"}>
                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>One capital case
                </p>

                <p className={isSpecialCharacter(password) ? "popoverP Valid" : "popoverP Invalid"}>
                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>One special character
                </p>

                <p className={isNumberInclude(password) ? "popoverP Valid" : "popoverP Invalid"}>
                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>One number
                </p>

                <p className={isMinCharacter(password) ? "popoverP Valid" : "popoverP Invalid"}>
                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>Minimum 8 Character
                </p>

                <p className={isMaxCharacter(password) ? "popoverP Valid" : "popoverP Invalid"}>
                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>Maximum 15 Character
                </p>
            </Popover.Content>
        </Popover>
    );

    const tempComponent = (
        <Popover>

        </Popover>
    )

    //#region Edit and cancel

    useImperativeHandle(ref, () => ({
        isDataChanged() {
            return getValues('FirstName') != user.FirstName ||
                getValues('Email') != user.Email ||
                getValues('Phone') != user.Phone ||
                getValues('UserRoleID') != user.UserRoleID ||
                getValues('Password') != user.Password ||
                getValues('UserRoleId') != user.UserRoleID ||
                getValues('isWebLoginAllowed') != user.isWebLoginAllowed ||
                getValues('isMobileLoginAllowed') != user.isMobileLoginAllowed ||
                getValues('isEnabled') != user.isEnabled ||
                getValues('dataAccessGroupId') != user.dataAccessGroupId
        }
    }))

    function isDataChanged() {
        return getValues('FirstName') != user.FirstName ||
            getValues('Email') != user.Email ||
            getValues('Phone') != user.Phone ||
            getValues('UserRoleID') != user.UserRoleID ||
            getValues('Password') != user.Password ||
            getValues('UserRoleId') != user.UserRoleID ||
            getValues('isWebLoginAllowed') != user.isWebLoginAllowed ||
            getValues('isMobileLoginAllowed') != user.isMobileLoginAllowed ||
            getValues('isEnabled') != user.isEnabled ||
            getValues('dataAccessGroupId') != user.dataAccessGroupId
    }

    const onCancel = (e) => {
        e.stopPropagation()
        if (isDataChanged()) {
            Swal.fire({
                title: 'Are you sure?',
                text: "Do you want to cancel the changes made?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#027aaa',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Leave!'
            }).then((result) => {
                if (result.isConfirmed) {
                    if (user != null) {
                        setValue('FirstName', user.FirstName)
                        setValue('Email', user.Email)
                        setValue('Phone', user.Phone)
                        setValue('UserRoleID', user.UserRoleID)
                        setValue('Password', user.Password || "********")
                        setValue('isWebLoginAllowed', user.isWebLoginAllowed);
                        setValue('isMobileLoginAllowed', user.isMobileLoginAllowed)
                        setValue('isEnabled', user.isEnabled)
                        setValue('dataAccessGroupId', user.dataAccessGroupId)
                    }
                    setIsUserEdit(false)
                    clearUserFields();
                }
            })
        } else {
            setIsUserEdit(false)
        }
    }
    //#endregion
    const showSuccessToast = (msg) => {
        toast.success(msg, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const addName = (e) => {
        let tempValue = e.target.value.replace(/[-’/`~!#*$@_%+=.,^&\d(){}[\]|;:”<>?\\]/gi, "");
        if (!/[0-9a-zA-Z]/.test(e.key)) {
            e.preventDefault();
        } else {
            setValue("FirstName", tempValue);
        }
    }

    const handleMouseDown = () => {
        setMouseDown(true);
    };

    const handleMouseUp = () => {
        setMouseDown(false);
    };

    const onUserVerifiedClick = () => {
        verificationLink(user.Email).then((res) => {
            if (res.Status === "success") {
                showSuccessToast(res.Message)
            }
            else {
                ErrorToast(res.message)
            }
        }).catch((error) => {
            console.error(error)
        })
    }

    const getNodes = () => {
        getDataAccess()
            .then((resp) => {
                buildWorkflowTree(resp.Data);
            }).catch(err => {
                console.log(err);
            })
    }

    const buildWorkflowTree = (arr) => {
        let tempArr = [];
        // This is for plotting the nodes.
        for (let i = 0; i < arr.length; i++) {
            let tempxpos = arr[i].Position_X === "" ? 250 : parseInt(arr[i].Position_X);
            let tempypos = arr[i].Position_Y === "" ? 5 : parseInt(arr[i].Position_Y);

            let obj = {
                id: arr[i].Id.toString(),
                type: i === 0 ? 'input' : 'default',
                position: { x: tempxpos, y: tempypos },
                data: {
                    label: (
                        <>
                            <div className='NodeTitleDescr'>
                                <p className='NodeHeading'>{arr[i].Name}</p>
                                <p className='NodeDescreption'>{arr[i].Description}</p>
                            </div>
                            <div className='nodeDeleteIcon'>
                            </div>
                        </>
                    )
                },
                databaseId: arr[i].Id,
                parentId: arr[i].ParentId
            }
            tempArr.push(obj);
        }
        //This is for plotting the edges.
        for (let x = 0; x < arr.length; x++) {
            let parentId = 0;
            let source = "";
            let target = "";
            if (arr[x].ParentId !== null) {
                parentId = arr[x].ParentId;
                target = arr[x].Id.toString();
                source = arr[x].ParentId.toString();
                let edgeObj = {
                    id: `e${source}-${target}`,
                    source: source,
                    target: target,
                    type: 'edge',
                    arrowHeadType: 'arrowclosed',
                    style: { strokeWidth: 2, stroke: 'black', }
                }
                tempArr.push(edgeObj);
            }
        }
        setNodes(tempArr);
    }

    const nodeSelected = (event, element) => {
        setSelectedDataAccessGroupId(element.id);
        setValue('dataAccessGroupId', element.data.label.props.children[0].props.children[0].props.children)
        setShow(false);
    }

    return (
        <div className='SiteMainContainer'>
            <div className='SiteLeftContainer'>
                <div className="m-2 HeaderSiteList">
                    <input
                        id="Search_txt_site"
                        className="SearchViewInput1"
                        type="text"
                        onChange={e => onSearchChange(e.target.value, "User List")}
                        placeholder="Search User"
                    />
                    <div className="SearchTextDivConfigTool">
                        {
                            isDarkTheme ?
                                <img
                                    className="EyeIcon"
                                    src={SearchIcon}
                                    alt="search icon"
                                /> :
                                <img
                                    className="EyeIcon"
                                    src={SearchIconWhite}
                                    alt="search icon" height="15"
                                />
                        }
                    </div>
                </div>
                <div className='UserLeftList'>
                    {
                        filteredUsers && (
                            filteredUsers?.length > 0 ?
                                filteredUsers.map(item => {
                                    return <div className={selectedUser?.Email === item.Email ? "OneList SelectedList" : "OneList"} key={item.UserID}
                                        onClick={() => {
                                            onSelectUser(item);
                                            clearUserFields()
                                        }}
                                    >
                                        <p className="ListofText">{item.FirstName || item.FullName}
                                            <span className="SiteNameText"> - {item.RoleName || item.roleName}</span></p>
                                    </div>
                                }) : <div className="no-data-found"><p>No User Found.</p></div>
                        )
                    }
                    {
                        !filteredUsers && (
                            <div className="no-data-found">No Users Available.</div>
                        )
                    }
                </div>
            </div>
            <div className='SiteRightContainer'>
                {user.UserID == 0 && <h1 className="MainHeaderName">1. Add User</h1>}
                {user.UserID != 0 && <h1 className="MainHeaderName">1. Update User</h1>}
                <div className="userformContainer">
                    <form onSubmit={handleSubmit((e) => onSubmitUser(e, user))}>
                        <div className='user-form' style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <div className="inputMainContainer w40dot75rem">
                                <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Name</label>
                                    <input
                                        className="inputType"
                                        placeholder="Name* e.g. Rupesh"
                                        {...register("FirstName")}
                                        defaultValue={user.FirstName}
                                        disabled={!isUserEdit}
                                        onChange={(e) => {
                                            addName(e)
                                            setUserNameError('')
                                        }}
                                    />
                                    <p className="Error_P" style={{ float: "left" }}><span> {userNameError} </span></p>
                                </div>
                            </div>
                            <div className="inputMainContainer w40dot75rem mt35">
                                <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Email</label>
                                    <input
                                        className="inputType"
                                        placeholder="Email* e.g. rupesh@xyz.com"
                                        {...register("Email")}
                                        defaultValue={user.Email}
                                        disabled={!isUserEdit || user.UserID != 0}
                                        onChange={() => setUserEmailError('')}
                                    />
                                    <p className="Error_P" style={{ float: "left" }}><span> {userEmailError} </span></p>
                                </div>
                            </div>

                            <div className="inputMainContainer w40dot75rem mt35">
                                <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Contact</label>
                                    <div>
                                        <div className="ContactDropdown">
                                            <span className="btn TextRoot contactNumUser">+91</span>
                                        </div>
                                        <input
                                            className='inputType contactinputfield'
                                            placeholder="Contact* e.g. 9876543210"
                                            {...register("Phone")}
                                            defaultValue={user.Phone}
                                            disabled={!isUserEdit}
                                            onChange={() => setUserContactError('')}
                                        />
                                    </div>
                                    <p className="Error_P" style={{ float: "left" }}><span> {userContactError} </span></p>
                                </div>
                            </div>

                            <div className="inputMainContainer w40dot75rem mt35">
                                <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>User Role</label>
                                    <select
                                        className="inputType ArrowSelectClass"
                                        placeholder="Select User Role*"
                                        {...register("UserRoleID")}
                                        defaultValue={user.UserRoleID}
                                        onChange={(e) => {
                                            setValue('UserRoleID', e.target.value)
                                            setUserRoleError('')
                                        }}
                                        disabled={!isUserEdit}>
                                        <option key="0" disabled={false} value="0">Select Role*</option>
                                        <option value={2}>Admin</option>
                                        <option value={3}>Operator</option>
                                        <option value={4}>Field Responder</option>
                                    </select>
                                    <p className="Error_P" style={{ float: "left" }}><span> {userRoleError} </span></p>
                                </div>
                            </div>

                            <div className="inputMainContainer w40dot75rem mt35">
                                <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Password</label>
                                    <OverlayTrigger rootClose trigger="click" placement="right" overlay={password === "********" ? tempComponent : popover}>
                                        <input
                                            autoComplete="off"
                                            name="Password"
                                            className="inputType"
                                            placeholder="Password*"
                                            {...register("Password")}
                                            value={password}
                                            defaultValue={user.Password}
                                            onChange={(e) => {
                                                handlePasswordChange(e)
                                                setUserPasswordError('')
                                            }}
                                            disabled={!isUserEdit} />
                                    </OverlayTrigger>
                                    <p className="Error_P" style={{ float: "left" }}><span> {userPasswardError} </span></p>
                                </div>
                            </div>
                            <>
                                <div className="inputMainContainer w40dot75rem mt35">
                                    <div style={{ display: "flex" }}>
                                        <div className="inputTypeLabelContainer" style={!isUserEdit || adminRoleSelected ? { width: "100%" } : { width: "95%" }}>
                                            <label className='formFieldLabels'>Select Data Access</label>
                                            <input
                                                type="text"
                                                name="Data Access"
                                                className="inputType"
                                                placeholder="Select Data Access*"
                                                {...register("dataAccessGroupId")}
                                                disabled
                                                defaultValue={user.dataAccessGroupId === null ? "" : user.dataAccessGroupId}
                                                onChange={() => setUserDataAccessError('')}
                                            />
                                        </div>
                                        <OverlayTrigger
                                            placement='bottom'
                                            overlay={
                                                <Tooltip>
                                                    Add Data Access
                                                </Tooltip>
                                            }
                                        >
                                            <button className="addCustomer ml-2" style={!isUserEdit || adminRoleSelected ? { display: "none" } : {}} onClick={(e) => {
                                                e.preventDefault()
                                                setShow(!show)
                                            }}>
                                                <span className="ViewUpdate">
                                                    <img src={AddNode} alt="add plus icon" />
                                                </span>
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                    <p className="Error_P" style={{ float: "left" }}><span> {userDataAccessError} </span></p>
                                </div>

                                <div className="inputMainContainer w40dot75rem mt35">
                                    <div className="inputTypeLabelContainer">
                                        <label className='formFieldLabels'>Sign in type</label>
                                        <div className='mt-1 signTypeCheckboxLabels'>
                                            <div className="SiteTextBox mt-0">
                                                <div
                                                    className="onlyforEnableCheckbox ml-0"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox_custom"
                                                        id="exampleCheck1"
                                                        {...register("isWebLoginAllowed")}
                                                        defaultChecked={user?.isWebLoginAllowed}
                                                        disabled={!isUserEdit || webBoxDisabled}
                                                    />
                                                    <label className="" htmlFor="exampleCheck1">
                                                        Web Application Login
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="SiteTextBox mt-0">
                                                <div
                                                    className="onlyforEnableCheckbox ml-0"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox_custom"
                                                        id="exampleCheck2"
                                                        {...register("isMobileLoginAllowed")}
                                                        defaultChecked={user?.isMobileLoginAllowed}
                                                        disabled={!isUserEdit || mobileBoxDisabled}
                                                    />
                                                    <label className="" htmlFor="exampleCheck2">
                                                        Mobile Application Login
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    user.UserID != 0 ?
                                        <div className="inputMainContainer w40dot75rem mt30">
                                            <div className="inputTypeLabelContainer">
                                                <label className='formFieldLabels'>Enable/Disable User</label>
                                                <div className="onlyforEnableCheckbox">
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox_custom"
                                                        id="exampleCheck3"
                                                        {...register("isEnabled")}
                                                        defaultChecked={user?.isEnabled}
                                                        disabled={!isUserEdit}
                                                    />
                                                    <label className="" htmlFor="exampleCheck3">
                                                        User Disabled
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }
                            </>
                        </div>
                        <div className="BulkText userTemplate-next-btn userTemplate-next-btn-div">
                        </div>
                    </form>

                    {/* Data Access modal onClick on PlusIcon */}
                    <Modal
                        size="xl"
                        show={show}
                        onHide={() => setShow(false)}
                        backdrop="static"
                        dialogClassName=""
                    >
                        <>
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    Data Access Workflow
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <ReactFlowProvider>
                                    <ReactFlow
                                        className="workflowPreview UserDataAccessWorkflow"
                                        elements={nodes}
                                        onElementClick={nodeSelected}
                                        style={{ cursor: mouseDown ? "grabbing" : "all-scroll", }}
                                        onMoveStart={handleMouseDown}
                                        onMoveEnd={handleMouseUp} >
                                    </ReactFlow>
                                    <Controls />

                                </ReactFlowProvider>

                            </Modal.Body>
                        </>
                        <div style={{ textAlign: "end", margin: "10px" }}>
                            <Button onClick={() => {
                                onNavigate('Data Access Group');
                                setIsItemClick(false);
                                setSelectedSubMenu('Data Access Group');
                            }
                            }>
                                Go to data access</Button>
                        </div>
                    </Modal>
                </div>
                <div style={{ textAlign: "center" }}>
                    {
                        user.UserID != 0 &&
                        !isUserEdit &&
                        <button type="submit" className="DataAccessEdit" style={{ width: "100px" }} onClick={() => {
                            onNavigate('Add User');
                            setIsItemClick(false);
                            setSelectedSubMenu('Add User');
                        }}>
                            <span className="ViewUpdate">Add New</span>
                        </button>
                    }
                    {
                        (user.UserID !== 0 && user.EmailConfirmed == "False") &&
                        <button type='button' className="DataAccessEdit" style={{ width: "auto" }} onClick={() => onUserVerifiedClick()}>
                            <span className="ViewUpdate">Resend Verification Link </span>
                        </button>
                    }
                    {
                        user.UserID != 0 &&
                        !isUserEdit &&
                        <button type="submit" className="DataAccessEdit" style={{ width: "100px" }} onClick={() => setIsUserEdit(true)}>
                            <span className="ViewUpdate">Edit</span>
                        </button>
                    }
                    {
                        isUserEdit &&
                        <>
                            {
                                user.UserID == 0 &&
                                <>
                                    <button className="DataAccessEdit" style={{ width: "100px" }}
                                        onClick={handleSubmit((e) => onSubmitUser(e, user))}
                                    >
                                        <span className="ViewUpdate">Next</span>
                                    </button>
                                </>
                            }
                            {
                                user.UserID != 0 &&
                                <>
                                    <button className="DataAccessEdit" style={{ width: "100px" }}
                                    disabled={buttonDisable}
                                        onClick={handleSubmit((e) => onSubmitUser(e, user))}
                                    >
                                        <span className="ViewUpdate">Update</span>
                                    </button>
                                    <button className="DataAccessEdit" style={{ width: "100px" }} type="submit" onClick={(e) => onCancel(e)} >
                                        <span className="ViewUpdate">Cancel</span>
                                    </button>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    )
});

const AssignFRsToSiteGroup = ({
    usersToBind,
    selectedUsers,
    setSelectedUsers,
    onAddSelectedUsers,
    siteGroups,
    users,
    siteGroupOnClick,
    selectedSiteGroup
}) => {

    const handleChange = index => event => {
        // handle FR level start
        const selUser = usersToBind.find(x => x.userID == event.target.id)
        if (selUser) {
            if (event.target.checked == true) {
                selUser.isSelected = true
                selUser.contactLevel = Object.entries(selectedUsers).filter(x => x[1] == true).length + 1
            }
            else {
                const maxIndex = usersToBind.length
                if (selUser.contactLevel != maxIndex) {
                    for (let i = 0; i < usersToBind.length; i++) {
                        const fruser = usersToBind[i];
                        if (fruser && fruser.userID != selUser.userID && fruser.contactLevel != null) {
                            if (fruser.contactLevel > selUser.contactLevel) {
                                var currentLength = fruser.contactLevel - 1
                                fruser.contactLevel = currentLength
                            }
                        }
                    }
                }
                selUser.isSelected = false
                selUser.contactLevel = null
            }
        }
        // handle FR level end
        setSelectedUsers({ ...selectedUsers, [event.target.id]: event.target.checked })
    }

    return <>
        <div className="LeftAddSide">
            <div className='LeftAddSideHeight'>
                <div className="ManageSite userTemplate-FRAssignment-container">
                    <h1 className="MainHeaderName mb-0">2. Assign Field Responder to Site Group</h1>
                    <div className="ManageSiteDiv">
                        <div className="DevicesHeader">
                            <p className="mb-0" style={{ width: "40%" }}>Site Group<br /></p>
                            <p className="mb-0">Field Responder List<br /></p>
                        </div>

                        {/* --- */}

                        <div className="site-group">
                            <div className="site-group-list">
                                {
                                    siteGroups &&
                                    siteGroups.map(item => {
                                        return <div
                                            id={item.siteGroupID}
                                            key={item.siteGroupID}
                                            onClick={() => siteGroupOnClick(item)}
                                            className={`cameraList user-list-item ${selectedSiteGroup && item.siteGroupID == selectedSiteGroup?.siteGroupID && "selected-user"}`}
                                        >
                                            <p className="mb-0">{item.groupTitle}</p>
                                            <div style={{ float: "right" }}>
                                            </div>

                                        </div>
                                    })
                                }
                            </div>
                        </div>

                        <div className="SiteList" style={{ borderBottom: "1px solid #e6e6e6" }}>
                            <div className='sitegrp-sitelist' style={{ textAlign: "center" }}>
                                <form >
                                    {
                                        usersToBind ? usersToBind.map((value, index, array) => {
                                            return <div className="selection-list-group cameraList"
                                                key={value.userID}
                                            >
                                                <input
                                                    id={value.userID}
                                                    className="checkbox_custom"
                                                    type="checkbox"
                                                    key={value.userID}
                                                    onChange={handleChange(index)}
                                                    name={value.firstName}
                                                    defaultChecked={value.isSelected == true ? true : false}
                                                />
                                                <p className="ListofText">{value.firstName}
                                                    <span className="SiteNameText">{value.isSelected && ` - Level-${value.contactLevel}`}</span>
                                                </p>
                                            </div>
                                        }) : <div className='NoFRMsg'>
                                            No FRs added to this site group.
                                        </div>
                                    }
                                </form>
                            </div>
                        </div>

                        <div className="m-2 text-right" style={{ textAlign: "end" }}>
                            <button className="NextMoveButton assignFieldResponder-AddSelected-btn" onClick={() => onAddSelectedUsers()}>
                                <span className="ViewUpdate">Add Selected</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}