import React from 'react'

const HealthListView = ({ setShow, show, data, selectedItem, isNotPointer, isSensor, showFour }) => {
  return (
    <tr onClick={() => {
      selectedItem(data)
    }}>
      <td style={{ cursor: isNotPointer ? 'default' : 'pointer' }}>{isSensor ? data.ZoneName : data.DeviceName}</td>
      <td style={{ cursor: isNotPointer ? 'default' : 'pointer' }}>{data.InterfaceName}</td>
      {/* <td>{data.DeviceType}</td> */}
      <td style={{ cursor: isNotPointer ? 'default' : 'pointer' }}>{data.SiteName}</td>
      {
        showFour
        ?
        <>
        {/* Here Entity Data Type will come in place of data.SiteName, once API changes are done */}
        <td style={{ cursor: isNotPointer ? 'default' : 'pointer' }}>{data.DeviceType}</td>
        </>
        : 
          <>
            <td
              style={{ cursor: isNotPointer ? 'default' : 'pointer' }}
              className={data.DeviceStatus == "Online" || data.DeviceStatus == "Normal" || data.DeviceStatus == "Zone Restoral" ? "online-color" : data.DeviceStatus == "Zone Alert" || data.DeviceStatus == "Offline" || data.DeviceStatus == "Faulty" ? "offline-color" : ""}>{data.DeviceStatus}</td>
            <td style={{ cursor: isNotPointer ? 'default' : 'pointer' }}>{data.StatusDateTime}</td>
          </>
      }
    </tr>
  )
}

export default HealthListView