import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { IS_HEALTH_MONITORING_TAB_HIDDEN, IS_ALERTS_TAB_HIDDEN, IS_SITE_MANAGEMENT_TAB_HIDDEN, IS_CAMERAS_TAB_HIDDEN, DASHBOARD_INTERVAL_TIMER } from '../../config'
import { ClosedAlertIcon, WFIcon, ErrorIcon, SystemHealth, AlertWhiteIcon, SiteWhiteIcon, CameraWhiteIcon } from '../../assets/images'

import { GetDashboardDetails, SystemHealthDevicesCountDash } from "../../services/dashboardService"
import { GetEventTemplateList, DeleteEventTemplate } from '../../services/configurationServices'
import { getAlertList } from "../../services/alertAuthService";
import { LogoutDotNet } from '../../services/userAuthServices'

import DashboardWidget from './DashboardWidget'
import DashboardMiniWidget from './DashboardMiniWidget'
import { CheckForPrivilege } from '../../pages/Profile/PrivilegeExtension'
import { AllDateFormat, ConvertDate } from '../../utils/utils'
import { mixpanel } from '../../utils/MixPanelUtil'
import AlertTable from './AlertTable'
import LocationList from './LocationList'
import EventTemplateList from './EventTemplateList'

import Swal from 'sweetalert2'
import InfiniteScroll from "react-infinite-scroll-component"
import Skeleton from "react-loading-skeleton";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LineElement, LinearScale, PointElement, TimeScale, ScatterController } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import HealthIntegratedChart from '../HealthMonitoring/HealthIntegratedChart'
import SampleChart from '../HealthMonitoring/SampleChart'

ChartJS.register(ArcElement, Tooltip, Legend, TimeScale, LineElement, LinearScale, PointElement, ScatterController);

export default function DashboardContainer(props) {
    let history = useHistory()

    //useState Block Start
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [dashboardData, setDashboardData] = useState(null)
    const [eventTemplates, setEventTemplates] = useState(null)
    const [isSuperUser, setIsSuperUser] = useState(null);
    const [selectedSiteTab, setSelectedSiteTab] = useState('topThreeSites')
    const [systemHealthDevicesOfflineCount, setSystemHealthDevicesOfflineCount] = useState(0);
    const [systemHealthDevicesOnlineCount, setSystemHealthDevicesOnlineCount] = useState(0);
    const [onlinePercent, setOnlinePercent] = useState(0);
    const [darkPieGraph, setDarkPieGraph] = useState(props.isDarkTheme);
    const [loading, setLoading] = useState(false);
    const [dashboardLoading, setDashboardLoading] = useState(false);
    const [alert] = useState(CheckForPrivilege("ALERT"))
    const [camera] = useState(CheckForPrivilege("CAMERALIST_VIEW"))
    const [todaysAlertList, setTodaysAlertList] = useState([])
    const [resetDashRefresh, setReseDashRefresh] = useState("");
    const [nonIntegratedDevicesCount, setNonIntegratedDevicesCount] = useState(0);
    //useState Block Start

    // useEffect Block Start
    useEffect(() => {
        props.setSelectedTab('dashboard')
    }, [])

    useEffect(() => {
        let tempUser = JSON.parse(localStorage.getItem('user'))
        if (tempUser) {
            let check = tempUser?.UserRoleID == 1 || tempUser?.UserRoleID == 2 || tempUser?.roleId == 1 || tempUser?.roleId == 2;
            setIsSuperUser(check);
            setLoggedInUser(tempUser);

            SystemHealthDevicesCount();
        }
    }, [localStorage.getItem('user')]);

    useEffect(() => {
        let tempUser = JSON.parse(localStorage.getItem('user'))

        if (tempUser) {
            GetUserDashboardDetails()
            getEventTemplatesList()
            try {
                mixpanel.track("Clicks on Dashboard")
            } catch (error) {
                console.error(error)
            }
        }
    }, [loggedInUser])

    useEffect(() => {
        (async () => {
            if (props.todaysAlertList && props.todaysAlertList.length > 0) {
                setTodaysAlertList(props.todaysAlertList)
            }
        })();
    }, [props.todaysAlertList])

    useEffect(() => {
        let c = onlinePercent;
        setOnlinePercent(c)
        let d = props.isDarkTheme;
        setDarkPieGraph(d);
    }, [props]);

    useEffect(() => {
        const interval = setInterval(async () => {
            GetUserDashboardDetails();
            SystemHealthDevicesCount();
        }, DASHBOARD_INTERVAL_TIMER);
        return () => {
            clearInterval(interval)
        }
    }, [])
    // useEffect Block End

    //Function Block Start
    // To Get Dashboard Details 
    const GetUserDashboardDetails = async () => {
        let tempUser = JSON.parse(localStorage.getItem('user'))
        console.log("temuser", tempUser)
        setDashboardLoading(true);
        try {
            console.log("checkuserId", loggedInUser)
            let obj = {
                "action": "dashboardDetails",
                "userId": tempUser?.UserID,
                "todaysdate": ConvertDate(new Date())
            }
            GetDashboardDetails(obj).then((resp) => {
                if (resp.status == "Ok") {
                    let refreshDate = new Date();
                    setReseDashRefresh(AllDateFormat(refreshDate))
                    console.log("lastRefresh", (AllDateFormat(refreshDate)))
                    setDashboardData(resp.message)
                    setDashboardLoading(false);
                }
            })
        } catch (error) {
            console.error(error)
            setDashboardLoading(false);
        }
    }

    //get alert template/event template list
    const getEventTemplatesList = () => {
        GetEventTemplateList().then((resp) => {
            if (resp && resp.length > 0) {
                setEventTemplates(resp)
            }
        })
    }

    //Delete alert template/event template
    const onDeleteTemplate = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you really want to delete this template?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteEventTemplate(id).then((resp) => {
                    if (resp.status == "Ok") {
                        getEventTemplatesList()
                        Swal.fire(
                            'Deleted!',
                            "Alert template deleted successfully.",
                            'success'
                        )
                    }
                    else {
                        Swal.fire(
                            'Erroe!',
                            resp.message,
                            'failed'
                        )
                    }
                })

            }
        })
    }

    const fetchMoreData = () => {
        try {
            let startindex = 50
            if (parseInt(todaysAlertList.length) >= parseInt(props.alertcounts['TodaysAlerts'])) { return }
            SortingList(todaysAlertList, startindex)
        } catch (error) {
            console.error(error)
        }
    }

    const SortingList = async (templist, index) => {
        try {
            let currentindex = index
            if (templist.length < currentindex) {
                currentindex = templist.length - 1
            } else {
                currentindex = templist.length
            }
            let obj = {
                "action": "getTodaysAlertsList",
                "userUniqueId": loggedInUser?.UserUniqueID || loggedInUser.userUniqueID,
                "startIndex": currentindex,
                "numberOfElements": index,
                "authCode": localStorage.getItem('authCode')
            }

            await getAlertList(obj).then((resp) => {
                if (resp.status == "Ok") {
                    if (resp.message) {
                        let newList = (todaysAlertList.concat(resp.message))
                        console.log("newlist", newList)
                        setTodaysAlertList(newList)
                    }
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const SystemHealthDevicesCount = () => {
        setLoading(true);
        let tempUser = JSON.parse(localStorage.getItem('user'));
        let userUniqueID = tempUser.UserUniqueID
        try {
            SystemHealthDevicesCountDash(userUniqueID).then((resp) => {
                if (resp != undefined) {
                    if (resp.message == "Unauthorized") {
                        LogoutDotNet().then((res) => {
                            localStorage.removeItem('authToken');
                            localStorage.removeItem('authCode');
                            localStorage.removeItem('user');
                            localStorage.removeItem('userPassword');
                            localStorage.removeItem('userID');
                            localStorage.removeItem('hootWayData');
                            localStorage.removeItem('sessionExpired');
                            localStorage.removeItem('UserID');
                            sessionStorage.removeItem('Page_Refresh_State');
                            localStorage.removeItem('PlaySound');
                            history.push('/login');
                        })
                        return;
                    }
                    if (resp.Status == "success") {
                        let totalSystemHealthOffline = resp.Data.filter(item => item.Description == "System Health" && item.Status == "0")
                        setSystemHealthDevicesOfflineCount(totalSystemHealthOffline[0]?.Count || 0)
                        let offlineCount = totalSystemHealthOffline[0]?.Count || 0;
                        let totalSystemHealthOnline = resp.Data.filter(item => item.Description == "System Health" && item.Status == "1")
                        setSystemHealthDevicesOnlineCount(totalSystemHealthOnline[0]?.Count || 0)
                        let onlineCount = totalSystemHealthOnline[0]?.Count || 0
                        let total = parseInt(onlineCount) + parseInt(offlineCount)
                        let percent = (onlineCount / total) * 100;
                        if (isNaN(percent)) {
                            setOnlinePercent(0);
                        } else {
                            setOnlinePercent(percent.toFixed(0));
                        }

                        let nonIntegratedDevices = resp.Data.filter(item => item.Description == "System Health" && item.Status == "-1");
                        setNonIntegratedDevicesCount((nonIntegratedDevices[0]?.Count || 0));
                        setLoading(false);

                    } else {
                        setLoading(false);
                    }
                }
            })
        } catch (error) {
            console.error(error)

        }
    }

    //#endregion function region End

    /* Chart data start here */
    const data = {
        labels: ['Online', 'Offline'],
        datasets: [
            {
                label: 'Values',
                data: [systemHealthDevicesOnlineCount, systemHealthDevicesOfflineCount],
                borderRadius: 0,
                backgroundColor: ['#1ab243', '#dddddd'],
                borderWidth: 0,
                //hoverOffset: 4
            }
        ],

    }
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false
            }
        },
        cutout: "85%",

    }
    const plugins = [
        {
            beforeDraw: function (chart) {
                let width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;
                ctx.restore();
                ctx.font = "bolder 25px sans-serif";
                ctx.textBaseline = "middle";
                let text = `${onlinePercent}%`,
                    textX = Math.round((width - ctx.measureText(text).width) / 2),
                    textY = height / 2;
                ctx.fillStyle = darkPieGraph ? '' : '#BABABA';
                ctx.fillText(text, textX, textY);
                ctx.save();
            },
        },

    ]
    /* Chart data end here */


    return (
        <div className='MainContentMargin'>
            <div className="row col-lg-12" style={{ margin: "0 auto", padding: "0px" }}>
                <div className="col-lg-12 pr-0 dashboardHeadingInnerDiv">
                    <div className="dashboardheadingMain">
                        <div className="dashboardheading" style={{ width: "100%" }}>
                            <p style={{ float: "left" }}>Welcome to MonitoringHub <span>{loggedInUser && " " + (loggedInUser.UserName || loggedInUser.FullName)}</span></p>
                            <div className='RefreshText' style={{ float: "right" }}>
                                <h4 style={{ marginTop: "4px" }}>Refreshed on - {resetDashRefresh}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='dashboardScroll'>
                <div className="RowClass">
                    <div className="grid-container grid-container--fit">
                        <div
                            className='systemHealth'
                            style={{
                                cursor: 'pointer',
                                display: IS_HEALTH_MONITORING_TAB_HIDDEN ? 'none' : 'block'
                            }} onClick={(e) => { history.push('/healthmonitoring') }}>
                            <div className='CardBoxContent' style={{ display: "flex", margin: "10px 10px 10px 20px" }}>
                                <div style={{ float: "left", width: "51%" }}>
                                    <div className="CardBoxImage" style={{ backgroundColor: "#1e93c2" }}>
                                        <img className='dashboard-icon' src={SystemHealth} alt="System health" />
                                    </div>
                                    <h4 className="SystemHealthCardText">System Health</h4>
                                    <div style={{ float: "left" }}>
                                        <div style={{ textAlign: "center" }}>
                                            {
                                                loading
                                                    ? (
                                                        <>
                                                            <Skeleton width={50} className="statusValueText" />
                                                            <Skeleton width={70} className="statusValueP" />
                                                        </>
                                                    )
                                                    : (
                                                        <>
                                                            <p className="statusValueP">{(parseInt(systemHealthDevicesOfflineCount)) + (parseInt(systemHealthDevicesOnlineCount)) + (parseInt(nonIntegratedDevicesCount))}</p>
                                                            <p className="statusValueText">Total Devices</p>
                                                        </>
                                                    )
                                            }
                                        </div>
                                        <div className="mt-4" style={{ display: "flex" }}>
                                            <div style={{ textAlign: "center" }}>
                                                {/* <p className="statusValueP" style={{fontSize:"20px"}}>{integratedDevicesCount}</p> */}
                                                {
                                                    loading
                                                        ?
                                                        <>
                                                            <Skeleton width={50} className="statusValueText" />
                                                            <Skeleton width={70} className="statusValueP" />
                                                        </>
                                                        :
                                                        <>
                                                            <p className="statusValueP" style={{ fontSize: "20px" }}>{(parseInt(systemHealthDevicesOfflineCount)) + (parseInt(systemHealthDevicesOnlineCount))}</p>
                                                            <p className="statusValueText" style={{ fontSize: "12px" }}>Integrated Devices</p>
                                                        </>
                                                }
                                            </div>
                                            <div style={{ textAlign: "center" }} className="ml-2">
                                                {
                                                    loading
                                                        ?
                                                        <>
                                                            <Skeleton width={50} className="statusValueText" />
                                                            <Skeleton width={70} className="statusValueP" />
                                                        </>
                                                        :
                                                        <>
                                                            <p className="statusValueP" style={{ fontSize: "20px" }}>{nonIntegratedDevicesCount}</p>
                                                            <p className="statusValueText" style={{ fontSize: "12px" }}>Non Integrated Devices</p>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ float: "right", width: "50%" }}>
                                    <div className="DonutChartWidthDiv" style={{ width: "auto" }}>
                                        <div style={{ width: "100%", height: "170px", display: "flex", justifyContent: "flex-end" , position:"relative" , marginTop:"15px"}}>
                                            <HealthIntegratedChart isDarkTheme={props.isDarkTheme} systemHealthDevicesOfflineCount={systemHealthDevicesOfflineCount} systemHealthDevicesOnlineCount={systemHealthDevicesOnlineCount} nonIntegratedDevicesCount={nonIntegratedDevicesCount} />
                                         {/*  <SampleChart /> */}
                                        </div>
                                    </div>
                                </div>

                                {/* Commented by Rupali */}
                                {/* <div className="CardBoxImage" style={{ backgroundColor: "#1e93c2" }}>
                                    <img className='dashboard-icon' src={SystemHealth} alt="System health" />
                                </div>
                                <div className='CardBoxText' style={{ display: "flex", justifyContent: "space-between", float: "left" }}>

                                    <div>
                                        <h4>System Health</h4>
                                        <div className="StatusText" style={{ marginTop: "35px" }}>
                                            <div className="StatusValueDiv" style={{ width: "auto" }}>
                                                {
                                                    loading
                                                        ? (
                                                            <>
                                                                <Skeleton width={70} className="statusValueText" />
                                                                <Skeleton width={50} className="statusValueP" />
                                                            </>
                                                        )
                                                        : (
                                                            <>
                                                                <p className="statusValueP">{(parseInt(systemHealthDevicesOfflineCount)) + (parseInt(systemHealthDevicesOnlineCount))}</p>
                                                                <p className="statusValueText">Total Devices</p>
                                                            </>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ width: "55%" }}>
                                        <div style={{ width: "100%", height: "160px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                            <Doughnut key={`${onlinePercent}_${darkPieGraph}`} data={data} options={options} plugins={plugins} />
                                        </div>
                                    </div>

                                </div>
                                <div className='integratedDevice' style={{marginTop: "-8px"}}>
                                    <p>Integrated devices count only</p>
                                </div> */}
                            </div>
                        </div>

                        <div
                            className='KPI_Margin'>
                            {/* <DashboardWidget dashboardLoading={props.rootLoading} count={props?.alertcounts['TodaysAlerts'] || 0} type="Today's Alerts" pointer={IS_ALERTS_TAB_HIDDEN} bgColor="#DA8012" imgIcon={AlertWhiteIcon} redirectTo="/allalerts" /> */}
                            <DashboardWidget dashboardLoading={!dashboardData} count={dashboardData?.alertCount || 0} type="Today's Alerts" pointer={IS_ALERTS_TAB_HIDDEN} bgColor="#DA8012" imgIcon={AlertWhiteIcon} redirectTo="/allalerts" />
                            <DashboardMiniWidget text={dashboardData && dashboardData.alertsMostlyGenerated != null && dashboardData.alertsMostlyGenerated + " Severity"} label="Alerts mostly generated" icon={ErrorIcon} />
                        </div>
                        <div
                            className='KPI_Margin'>
                            <DashboardWidget dashboardLoading={dashboardLoading} count={dashboardData?.totalSites} type="Sites" pointer={IS_SITE_MANAGEMENT_TAB_HIDDEN} bgColor="#6459D6" imgIcon={SiteWhiteIcon} redirectTo="/configuration?initialTemplate=0" />
                            <DashboardMiniWidget text={dashboardData?.mostUsedWF} label="Most used workflow template" icon={WFIcon} />
                        </div>

                        <div
                            className='KPI_Margin'>
                            <DashboardWidget dashboardLoading={dashboardLoading} count={dashboardData?.totalCameras} type="Cameras" pointer={IS_CAMERAS_TAB_HIDDEN} bgColor="#27AD93" imgIcon={CameraWhiteIcon} redirectTo="/cameras/true" />
                            <DashboardMiniWidget text={dashboardData?.closedAlerts} label="Today's closed alerts" icon={ClosedAlertIcon} />
                        </div>
                    </div>
                </div>

                <div className="RowClass">
                    <div className="WrapperTable">
                        <div className="alertTable KPI_Margin">
                            <div className="alertTableList">
                                <p>
                                    Today's Alert(s)</p>
                                {alert && props && props.alertcounts && props.alertcounts['TodaysOpenAlert'] !== undefined && (
                                    <button className="viewAllListBtn" onClick={() => history.push('/allalerts')}><p>View All Open Alerts</p></button>
                                )}
                            </div>
                            <table className="table sticky-table text-center removeClickStyle">
                                <thead>
                                    <tr>
                                        <td>ID</td>
                                        <td>Customer</td>
                                        <td>Site</td>
                                        <td>Device</td>
                                        <td>ACK By</td>
                                        <td>ACK On</td>
                                        <td>Closed On</td>
                                        <td>Status</td>
                                        <td>Severity</td>
                                        <td>Type</td>
                                    </tr>
                                </thead>
                                <tbody className="tbody-dashboard">
                                    {
                                        !todaysAlertList || todaysAlertList?.length === 0
                                            ?
                                            (
                                                <tr>
                                                    {
                                                        <>
                                                            <td>
                                                                <Skeleton className="width70pct" />
                                                            </td>
                                                            <td>
                                                                <Skeleton className="width70pct" />
                                                            </td>
                                                            <td>
                                                                <Skeleton className="width70pct" />
                                                            </td>
                                                            <td>
                                                                <Skeleton className="width70pct" />
                                                            </td>
                                                            <td className="online-color">
                                                                <Skeleton className="width70pct" />
                                                            </td>
                                                            <td>
                                                                <Skeleton className="width70pct" />
                                                            </td>
                                                            <td>
                                                                <Skeleton className="width70pct" />
                                                            </td>
                                                            <td>
                                                                <Skeleton className="width70pct" />
                                                            </td>
                                                            <td>
                                                                <Skeleton className="width70pct" />
                                                            </td>
                                                            <td>
                                                                <Skeleton className="width70pct" />
                                                            </td>
                                                        </>
                                                    }
                                                </tr>
                                            )
                                            :
                                            (
                                                <>
                                                    <div id="scrollableDiv">
                                                        <InfiniteScroll
                                                            dataLength={todaysAlertList && todaysAlertList.length}
                                                            next={fetchMoreData}
                                                            hasMore={true}
                                                            scrollableTarget="scrollableDiv"
                                                        >
                                                            {
                                                                todaysAlertList?.length > 0
                                                                    ?
                                                                    todaysAlertList.filter(x => x.actualTime.split(' ')[0] == ConvertDate(new Date()).split(' ')[0]).map((item, index) =>
                                                                        <AlertTable
                                                                            key={item.alertId}
                                                                            id={item.alertId}
                                                                            props={item}
                                                                            onAddAlertChat={props.onAddAlertChat}
                                                                        />
                                                                    )
                                                                    : <div className='noRecordFound'><p className='noRecordFoundText'>No Record Found.</p></div>
                                                            }
                                                        </InfiniteScroll>
                                                    </div>
                                                    {
                                                        !todaysAlertList && (
                                                            <div className='noRecordFound'><p className='noRecordFoundText'>No Record Available.</p></div>
                                                        )
                                                    }
                                                </>
                                            )

                                    }
                                </tbody>
                            </table>

                        </div>

                        <div className='pr-2'>
                            <div className="dashboard-topLocations-widget KPI_Margin">
                                <div className="kpi-card topLocations-widget">
                                    <div className="section-head">
                                        <span className="location-alerts">Location Generated Alerts</span>
                                    </div>
                                    <div>
                                        <ul className="nav nav-pills tabs-list">
                                            <li className="nav-item">
                                                <div className={selectedSiteTab == "topThreeSites" ? "nav-link active top-three-sites" : "inactive-text-dashboard top-three-sites"}
                                                    onClick={() => setSelectedSiteTab('topThreeSites')}>
                                                    Top 3 sites</div>
                                            </li>
                                            <li className="nav-item">
                                                <div className={selectedSiteTab == "bottomThreeSites" ? "nav-link active bottom-three-sites" : "inactive-text-dashboard bottom-three-sites"}
                                                    onClick={() => setSelectedSiteTab('bottomThreeSites')}>
                                                    Bottom 3 sites</div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tab-content">
                                        {selectedSiteTab && selectedSiteTab == "topThreeSites" &&
                                            (<div className="tab-pane container active template-list pl-0 pr-0" id="top-3">
                                                {(dashboardData &&
                                                    dashboardData.topThreeAlertsGeneratedSites && dashboardData.topThreeAlertsGeneratedSites.length > 0 ?
                                                    dashboardData.topThreeAlertsGeneratedSites.map((item, index) =>
                                                        <LocationList key={item.siteId}
                                                            id={item.siteId}
                                                            sites={item} />
                                                    ) : (
                                                        <div className='noRecordFound'><p className='noRecordFoundText' style={{ left: "35%" }}>No Record Available.</p></div>
                                                    )
                                                )}
                                            </div>)
                                        }
                                        {selectedSiteTab && selectedSiteTab == "bottomThreeSites" &&
                                            (<div className="tab-pane container active template-list pl-0 pr-0" id="bottom-3">
                                                {(dashboardData &&
                                                    dashboardData.bottomThreeAlertsGeneratedSites && dashboardData.bottomThreeAlertsGeneratedSites.length > 0 ?
                                                    dashboardData.bottomThreeAlertsGeneratedSites.map((item, index) =>
                                                        <LocationList key={item.siteId}
                                                            id={item.siteId}
                                                            sites={item} />
                                                    ) : (
                                                        <div className='noRecordFound'><p className='noRecordFoundText' style={{ left: "35%" }}>No Record Available.</p></div>
                                                    )
                                                )}
                                            </div>)
                                        }

                                    </div>
                                </div>
                            </div>

                            <div className='mt-3'>
                                {
                                    isSuperUser &&
                                    <div className="dashboard-quick-config-widget KPI_Margin">
                                        <div className="kpi-card quick-config-widget">
                                            <div className="section-head quick">
                                                <span className="location-alerts quick-config-widget">Quick Configurations</span>
                                            </div>
                                            <div className='dashboard-quickconfig-alerttemplate-outer'>
                                                <div className='dashboard-quickconfig-alerttemplate-inner'>
                                                    <p className='dashboard-quickconfig-alerttemplate-text'>Alert Template</p>
                                                </div>
                                            </div>

                                            <div className="tab-content">
                                                <div className="tab-pane container active pl-0 pr-0 template-list mt-1 QuickConfigHeight" id="alerts" style={{ height: "175px" }}>

                                                    {(
                                                        eventTemplates &&
                                                        eventTemplates?.map((item, index) =>
                                                            <EventTemplateList
                                                                key={item.AlertTemplateId}
                                                                id={item.AlertTemplateId}
                                                                template={item}
                                                                onDeleteTemplate={onDeleteTemplate}
                                                                history={history} />
                                                        )
                                                    )}
                                                    {
                                                        !eventTemplates &&
                                                        <div className='noRecordFound'><p className='noRecordFoundText' style={{ left: "35%" }}>No Record Available.</p></div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}