import React, { useEffect, useState } from 'react'

import { ReportIcon, DownloadIcon, DropArrow, monitoringHubLogo_dataURL } from '../../assets/images';

import Accordion from 'react-bootstrap/Accordion'
import Swal from 'sweetalert2'
import { Row, Col, Dropdown } from 'react-bootstrap'
import { jsPDF } from "jspdf";

import NoReportFound from './NoReportFound'
import ActivityReportTable from './ActivityReportTable';

export default function AlertActivityReport(props) {
  //#region useState Block Start 
  const [disableDownload, setDisableDownload] = useState(false);
  //#endregion useState Block End 
  
  //#region useEffect Block Start
  useEffect(() => {
    console.log("propsData", props);
    if (props.list === null) {
      setDisableDownload(true);
    } else {
      setDisableDownload(false);
    }
  }, [props.list]);
  //#endregion useEffect Block End 

  const csvData = [
    [
      'Alert ID', 'Site Name', 'Device Type', 'Alert Type', 'Severity', 'Generated at', 'Acknowledge at', 'Operator', 'Workflow Associated', 'Alert Closed at', 'Resolution time',
    ],
    ...props.list.map((item) => [
      item.alertId, item.siteName, item.alertSource, item.alertType, item.severityTitle, item.actualTime, item.ackOn, item.operatorName, item.workflowName, item.closedOn,
      item.timeToCloseAlert === 'NA' ? 'NA' : item.timeToCloseAlert,
    ]),
  ];

  const onDownloadReportCSV = (e) => {
    e.stopPropagation();
    if (props.list == null || props.list.length == 0) {
      Swal.fire({ text: 'No records available to download.' })
      return;
    }
    const csvContent = csvData.map((row) => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const csvURL = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = csvURL;
    link.setAttribute('download', 'Alert_Activity_Report.csv');
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(csvURL);
    document.body.removeChild(link);
  };

  const onDownloadReportPDF = () => {
    if (props.list === null || props.list.length == 0) {
      Swal.fire({ text: 'No records available to download.' });
      return;
    }
    var doc = new jsPDF("p", "pt", "a4");
    doc.autoTable({
      html: "#alert-activity-report",
      startY: 50,
      styles: {
        fontSize: 5,
        cellWidth: "wrap",
      },
      headStyles: {
        fillColor: "5083BD",
      },
      didDrawPage: function (data) {
        doc.setFontSize(20);
        doc.setTextColor(40);
        doc.addImage(monitoringHubLogo_dataURL, 'JPEG', 35, 5, 75, 40);
        doc.text("Alert Activity Report", 210, 30);
      },
      margin: { top: 60 }
    });
    doc.save("Alert-Activity-Report.pdf");
  };

  return (
    <>
      <div className="ReportAccordionMainContainer">
        <Accordion defaultActiveKey="1" style={{ paddingBottom: "10px" }}>
          <div className="ReportAccordionHeader">
            <>
              <>
                <div>
                  <Row>
                    <Col lg={10} className="AlertActivityReportheading">
                      <div className="ReportAccordianTitleIcon">
                        <img src={ReportIcon} alt="Report Icon" />
                      </div>
                      <div className="ReportAccordianTitleText">
                        <h5
                          className="mb-0"
                          style={{ marginLeft: "1rem" }}
                        >
                          {props.title} {`(${props?.list?.length})`}
                        </h5>
                        <p className="ReportSubTitleText">
                          This Report Displays List of Closed Alerts
                        </p>
                      </div>
                    </Col>
                    {
                      disableDownload
                        ? null
                        :
                        <Col lg={2} className="ReportDownloadLink">
                          <a className="ReportRightOptions">
                            <Dropdown
                              className="dropdown profileDpDwn w-0">
                              <Dropdown.Toggle
                                as={ProfileDropDown}
                                id="dropdown-custom-components"
                              >
                                <img src={DownloadIcon} alt='download icon' />
                                Download
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  eventKey="1"
                                  onClick={onDownloadReportCSV}
                                  disabled={disableDownload}
                                >
                                  CSV
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item
                                  eventKey="2"
                                  onClick={onDownloadReportPDF}
                                  disabled={disableDownload}
                                >
                                  PDF
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </a>
                        </Col>
                    }
                  </Row>
                </div>
              </>
              <div className="col-2 align-items-center justify-content-end">
              </div>
            </>
          </div>
          <>
            {props && props.list && (
              <div className="card-body p-0">
                {props.list.length > 0 && (
                  <table
                    className="table table-text-ext table-fixed report-table"
                    id="alert-activity-report"
                  >
                    <thead className="ReportTableHeader ReportTableHeadReport">
                      <tr>
                        <th scope="col">Alert ID</th>
                        <th scope="col">Site Name</th>
                        <th scope="col">Device Type</th>
                        <th scope="col">Alert Type</th>
                        <th scope="col">Severity</th>
                        <th scope="col">Generated at</th>
                        <th scope="col">Acknowledge at</th>
                        <th scope="col">Operator</th>
                        <th scope="col">Workflow Associated</th>
                        <th scope="col">Alert Closed at</th>
                        <th scope="col">Resolution time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.list &&
                        props.list.map((item, index) => (
                          <ActivityReportTable
                            key={item.alertId}
                            id={item.alertId}
                            props={item}
                          />
                        ))}
                    </tbody>
                  </table>
                )}
                {props.list.length == 0 && <NoReportFound />}
              </div>
            )}
          </>
        </Accordion>
      </div>
    </>
  );
}

const ProfileDropDown = React.forwardRef(({ children, onClick }, ref) => (
  <span
    ref={ref}
    className="profileDpDwn profile-name"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    {/* &#x25bc; */}
    <DropArrow style={{ color: "black" }} alt="drop arrow" className="m-2" />
  </span>
));