import { BASE_URL, BASE_CORE_URL } from './../config/index'

//Site  API'S

//#region Api Calling for Getting the Sitelist
export const Getsitelist = async () => {
  let userid = localStorage.getItem('UserID');
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  let url = BASE_URL + "getsitelist?UserID=" + userid;
  return await fetch(url, requestOptions)
    .then((resp) => {
      return resp.json();
    })
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("getsitelist () Exception: ", error);
    });
}
//#endregion

//#region Api Calling for Getting SiteTypeList
export const Getsitetypelist = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  };
  let url = BASE_CORE_URL + "Site/GetSiteTypeList";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("GetSiteTypeList () Exception: ", error);
    });

}
//#endregion

//#region Old Api Calling for Add Site 
export const Addsitedetails = async (obj) => {

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  let url = BASE_URL + "addsitedetails";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("addsitedetails () Exception: ", error);
    });
}
//#endregion

//#region Old Api Calling for Update Site 
export const Updatesitedetails = async (obj) => {

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  let url = BASE_URL + "updatesitedetails";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("updatesitedetails () Exception: ", error);
    });
}
//#endregion

//#region  Api Calling for Adding the Customer Name
export const addCustomerName = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  let url = BASE_URL + "addclient";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("Add Customer Name () Exception: ", error);
    });
}
//#endregion

//#region Api Calling for Getting for Country
export const getCountryDetails = async () => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  let url = BASE_URL + "countrydetails";

  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("countryDetails () Exception: ", error);
    });
}
//#endregion

//#region Api Calling for Getting the State
export const getStateDetails = async (CountryMasterId) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  let url = BASE_URL + "statedetails?CountryMasterId=" + CountryMasterId;

  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("state details () Exception: ", error);
    });
}
//#endregion

//#region old Api Calling for Deleting the Site 
export const deletesite = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  let url = BASE_URL + "deletesite";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("deletesite () Exception: ", error);
    });
}
//#endregion

//#region Api Calling for Getting Site List
export const getSiteList = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  };

  let url = BASE_CORE_URL + "Site/GetSiteList";

  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("getSiteList () Exception: ", error);
    });
}
//#endregion

//#region Api Calling for Getting the SiteList basis on some parameter
export const SiteList = async (id, deviceTypeId, alertType, showAll) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  }
  let url = BASE_CORE_URL + "Site/SiteList?clientId=" + id + "&deviceTypeId=" + deviceTypeId + "&alertType=" + alertType + "&showAll=" + showAll;
  return await fetch(url, requestOptions)
    .then((resp) => {
      console.log("test", resp);
      return resp.json();
    })
    .then(function (data) {
      console.log("test", data);
      return data;
    })
    .catch(function (error) {
      console.error("SiteList () Exception", error);
    });
}
//#endregion

//MasterTableList 

//#region Api Calling for Getting Master table List
export const Getmastertablelist = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };
  const url = BASE_URL + "getmastertablelist";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data
    })
    .catch(function (error) {
      console.error(error);
    });
}
//#endregion

//Event Template 

//#region Api Calling for Adding Event Template
export const AddNewEventTemplate = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };
  const url = BASE_URL + "addeventtemplate";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("AddNewEventTemplate() Exception: " + error);
    });
}
//#endregion

//#region Api Calling for Deleting Event Template
export const DeleteEventTemplate = async (eventTemplateID) => {
  if (eventTemplateID != null) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        alertTemplateID: eventTemplateID,
      }),
    };

    const url = BASE_URL + "deleteeventtemplate";
    return await fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then(function (data) {
        return data;
      })
      .catch(function (error) {
        console.error(error);
      });
  } else {
    return undefined;
  }
}
//#endregion

//#region Api Calling for Getting the Event TemplateList
export const GetEventTemplateList = async () => {
  let userid = localStorage.getItem('UserID');
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      UserID: userid,
    }),
  };

  const url = BASE_URL + "geteventtemplatelist";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data.message;
    })
    .catch(function (error) {
      console.error(error);
    });
}
//#endregion

//Template (Alert)

//#region old Api Calling for Adding the Alert Template
export const addTemplate = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  let url = BASE_URL + "addeventtemplate";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("addTemplate () Exception: ", error);
    });
}
//#endregion

//#region  old Api Calling for Updating the Alert Template
export const updateTemplate = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  let url = BASE_URL + "updateeventtemplate";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("updateTemplate () Exception: ", error);
    });
}
//#endregion

//#region old Api Calling for Alert Template Crud
export const Alerttemplatecrud = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  const url = BASE_URL + "alerttemplatecrud";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error(error);
    });
}
//#endregion

//#region old Api Calling for Getting Severity type 
export const GetSeverityTypeList = async () => {
  let url = BASE_URL + "getseveritytypelist";
  return await fetch(url)
    .then((resp) => resp.json())
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      console.error(error);
    });
};
//#endregion

//#region Api Callimg for Getting the Alert Template
export const GetAlertTemplate = async (pType, pageNumber, searchText, filterText) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  };
  let url = BASE_CORE_URL + "AlertTemplate/GetAlertTemplate?pType=" + pType + "&pageNumber=" + pageNumber + "&searchText=" + searchText + "&filterText=" + filterText;
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("GetAlertTemplate () Exception: ", error);
    });
};
//#endregion

//#region Api Calling for Getting Schedule Log 
export const GetAlertTemplateAudit = async (id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  };
  let url = BASE_CORE_URL + "AlertTemplate/GetScheduleLog?id=" + id;
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("GetAlertTemplateAudit () Exception: ", error);
    });
};
//#endregion

//#region Api Calling for AlertTemplate DataToFilter
export const GetDataToFilter = async (pType, pSelectedIds) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  };
  let url = BASE_CORE_URL + "AlertTemplate/GetDataToFilter?pType=" + pType + "&pSelectedIds=" + pSelectedIds;
  console.log("CheckAlert", url);
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("GetDataToFilter () Exception: ", error);
    });
};
//#endregion

//#region Api Calling for Add Alert Template
export const AddAlertTemplateApI = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
    body: JSON.stringify(obj),
  };
  let url = BASE_CORE_URL + "AlertTemplate/AddAlertTemplate";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      console.log(data);
      return data;
    })
    .catch(function (error) {
      console.error("AddAlertTemplateApI () Exception: ", error);
    });
}
//#endregion

//#region Api Calling for Update Alert Template
export const UpdateAlertTemplateApI = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
    body: JSON.stringify(obj),
  };
  let url = BASE_CORE_URL + "AlertTemplate/UpdateAlertTemplate";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      console.log(data);
      return data;
    })
    .catch(function (error) {
      console.error("UpdateAlertTemplateApI () Exception: ", error);
    });
}
//#endregion

//#region Api Calling for Separate AlertTemplateByDevice
export const seperateAlertTemplateByDevice = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
    body: JSON.stringify(obj),
  };
  let url = BASE_CORE_URL + "AlertTemplate/SeperateALertTemplateByDevice";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("UpdateAlertTemplateApI () Exception: ", error);
    });
}
//#endregion

//#region Api Calling for SplitAlertTemplateByDevice 
export const splitAlertTemplateByDevice = async (alertTemplateId, deviceId, userId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  };

  let url = BASE_CORE_URL + `AlertTemplate/SplitAlertTemplateByDevice?alertTemplateId=${alertTemplateId}&deviceId=${deviceId}&userId=${userId}`;

  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("getDeviceTypeList () Exception: ", error);
    });
}
//#endregion

//#region Api Calling for Getting Alert Template by Id
export const getAlertTemplateByIdAPI = async (templateId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  }

  let url = BASE_CORE_URL + "AlertTemplate/GetAlertTemplateById?id=" + templateId;

  return await fetch(url, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch(err => {
      console.log(err);
    })
}
//#endregion

//#region Api Calling for Getting the AlertTemplate ByDeviceId
export const getAlertTemplateByDeviceIdAPI = async (deviceId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  }

  let url = BASE_CORE_URL + "AlertTemplate/GetAlertTemplateByDeviceId?deviceId=" + deviceId;

  return await fetch(url, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch(err => {
      console.log(err);
    })

}
//#endregion

//#region Api Calling for RemoveDevicefrom Temp
export const removeDeviceFromTemp = async (alertTemplateId, deviceId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  }

  let url = BASE_CORE_URL + `AlertTemplate/RemoveDevice?alertTemplateId=${alertTemplateId}&deviceId=${deviceId}`;

  return await fetch(url, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch(err => {
      console.log(err);
    })
}
//#endregion

//#region Api Calling for Getting ZoneType Alert Template
export const getZoneTypeAlertTemp = async (deviceIds, showAll, alertCodeId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken'),
    },
  }
  let url = BASE_CORE_URL + `Site/GetZoneAlertTemp?deviceIds=${deviceIds}&showAll=${showAll}&alertCodeId=${alertCodeId}`;

  return await fetch(url, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch(err => {
      console.log(err);
    })
}
//#endregion

//#region Api Calling for Delete the Alert Template
export const DeleteAlertTemplate2 = async (eventTemplateID) => {
  console.log("rupesh checks Templateid", eventTemplateID)
  if (eventTemplateID != null) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "authcode": localStorage.getItem('authCode'),
        "Authorization": "Bearer " + localStorage.getItem('authToken'),
      },
    };

    const url = BASE_CORE_URL + "AlertTemplate/DeleteAlertTemplate?id=" + eventTemplateID;
    return await fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then(function (data) {
        return data;
      })
      .catch(function (error) {
        console.error(error);
      });
  } else {
    return undefined;
  }
}
//#endregion

//Device 

//#region Api Calling for Deviceconfigcrud Details()
export const deviceconfigcrud = async (obj) => {

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  let url = BASE_URL + "deviceconfigcrud";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      console.log("Vyenkatesh Check : ", data)
      return data;
    })
    .catch(function (error) {
      console.error("deviceconfigcrud () Exception: ", error);
    });
}
//#endregion

//#region Api Calling for Bulk upload Camera 
export const bulkUploadCamera = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
    body: JSON.stringify(obj),
  };

  let url = BASE_CORE_URL + "Device/DeviceBulkUpload";
  return await fetch(url, requestOptions)
    .then(res => res.json())
    .then(data => {
      console.log("New Bulk Upload", data);
      return data;
    })
    .catch(err => {
      console.log(err);
    })
}
//#endregion

//#region Api Calling for Bulk upload Panel
export const bulkUploadPanel = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
    body: JSON.stringify(obj),
  };

  /* ---------------------- Endpoint needs to be changed for panel --------------------- */
  let url = BASE_CORE_URL + "Device/PanelBulkUpload";
  console.log("New Bulk Upload", url);
  return await fetch(url, requestOptions)
    .then(res => res.json())
    .then(data => {
      return data;
    })
    .catch(err => {
      console.log(err);
    })
}
//#endregion

//#region  Api Calling for Getting DeviceTypeList
export const getDeviceTypeList = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  };

  let url = BASE_CORE_URL + "Device/DeviceTypeList";

  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("getDeviceTypeList () Exception: ", error);
    });
}
//#endregion

//#region Api Calling for Getting the Device Data
export const Getdeviceslistdata = async () => {
  let user = JSON.parse(localStorage.getItem('user'));
  let useruniqueid = user.UserUniqueID
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      UserUniqueID: useruniqueid,
    }),
  };

  const url = BASE_URL + "getstreamingurllist";
  return fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data.message;
    })
    .catch(function (error) {
      console.error({ error });
    });
}
//#endregion

//#region Api Calling for Adding the Device
export const SaveDeviceDetails = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  let url = BASE_URL + "addcameraconfigdetails";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("SaveDeviceDetails() Exception: ", error);
    });
}
//#endregion

//#region Api Calling for Update the Device
export const UpdateDeviceDetails = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  let url = BASE_URL + "updatecameraconfigdet";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("UpdateDeviceDetails() Exception: ", error);
    });
}
//#endregion

//#region Api Calling for Getting Device List 
export const GetDeviceList = async (siteIds, deviceTypeId, IDSPanelZoneTypeID, alertType, showAll) => {
  // let user = JSON.parse(localStorage.getItem('user'));
  // let userUniqueId = user.UserUniqueID
  // console.log("checkId",userUniqueId)
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  }

  let url = BASE_CORE_URL + `Device/GetDeviceList?siteIds=${siteIds}&deviceTypeId=${deviceTypeId}&IDSPanelZoneTypeID=${IDSPanelZoneTypeID}&alertType=${alertType}&showAll=${showAll}`
  return await fetch(url, requestOptions)
    .then((resp) => {
      return resp.json();
    })
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("GetDeviceList () Exception", error);
    });
}
//#endregion

//#region Api Calling for PanelZoneType
export const getPanelZoneType = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  };
  let url = BASE_CORE_URL + "Device/GetPanelZoneType";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("getPanelZoneType () Exception: ", error);
    });
}
//#endregion

// Interfaces 

//#region Api Calling for Getting InterfaceType
export const getInterfaceTypeData = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  };

  let url = BASE_CORE_URL + "InterfaceType/Get";

  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("getInterfaceTypeData () Exception: ", error);
    });
}
//#endregion

//#region Api Calling for Getting InterfaceSubType
export const getInterfaceSubTypeData = async (interfaceType) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  };

  let url = BASE_CORE_URL + "InterfaceSubType/Get?id=" + interfaceType;

  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("getInterfaceSubTypeData () Exception: ", error);
    });
}
//#endregion

//#region Api Calling for Getting InterfaceList
export const getInterfaceList = async (siteid) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  };

  let url = BASE_CORE_URL + "Interface/Get?siteId=" + siteid;
  return await fetch(url, requestOptions)
    .then((resp) => {
      return resp.json();
    })
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("getInterfaceList () Exception: ", error);
    });
}
//#endregion

//#region Api Calling for Adding the Interface 
export const addInterfaceDetails = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
    body: JSON.stringify(obj),
  };

  let url = BASE_CORE_URL + "Interface/Add";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("addInterfaceDetails () Exception: ", error);
    });
}
//#endregion

//#region Api Calling for Update the Interaface
export const updateInterfaceDetails = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
    body: JSON.stringify(obj),
  };

  let url = BASE_CORE_URL + "Interface/Update";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("updateInterfaceDetails () Exception: ", error);
    });
}
//#endregion

//#region Api Calling for Delete the Interface
export const deleteInterfaceDetails = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
    body: JSON.stringify(obj),
  };

  let url = BASE_CORE_URL + "Interface/Delete";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      console.log(data);
      return data;
    })
    .catch(function (error) {
      console.error("deleteInterfaceDetails () Exception: ", error);
    });
}
//#endregion

//entity subtype

//#region Api Calling for Getting Entity Subtype
export const getCCTVType = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  };

  let url = BASE_CORE_URL + "CCTVType/Get";

  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("CCTVType () Exception: ", error);
    });
}
//#endregion

//#region Api Calling for Customer List
export const getCustomerList = async (deviceTypeId, alertType, showAll) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  }

  let url = BASE_CORE_URL + `Customer/CustomerList?deviceTypeId=${deviceTypeId}&alertType=${alertType}&showAll=${showAll}`;

  return await fetch(url, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch(err => {
      console.log(err);
    })
}
//#endregion

//Alert Prepost 

//#region Api Calling for Add AlertPrepost
export const addAlertPrePostDetails = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
    body: JSON.stringify(obj),
  };

  let url = BASE_CORE_URL + "PrePost/Add";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("addAlertPrePostDetails () Exception: ", error);
    });
}
//#endregion

//#region Api Calling for Update AlertPrePost 
export const updateAlertPrePostDetails = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
    body: JSON.stringify(obj),
  };

  let url = BASE_CORE_URL + "PrePost/Update";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("updateAlertPrePostDetails () Exception: ", error);
    });
}
//#endregion

//#region Api Calling for Getting AlertPrepostList
export const getAlertPrePostList = async (clientId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  };

  let url = BASE_CORE_URL + "PrePost/Get?clientId=" + clientId;
  return await fetch(url, requestOptions)
    .then((resp) => {
      return resp.json();
    })
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("getAlertPrePostList () Exception: ", error);
    });
}
//#endregion

//#region Api calling for Getting Alert Type
export const getAlertTypelist = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  };

  let url = BASE_CORE_URL + "PrePost/GetAlertTypeList";
  return await fetch(url, requestOptions)
    .then((resp) => {
      return resp.json();
    })
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("GetAlertTypeList () Exception: ", error);
    });
}

//#region Api calling for Getting AllSeverity
export const GetSeverity = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  };
  let url = BASE_CORE_URL + "SoundSetting/GetAllSeverity";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("GetAllSeverity () Exception: ", error);
    });
}
//#endregion

//#region Api calling for Update the SoundSetting Function
export const UpdateSoundSettingFunction = async(extreme,high,moderate,low)=>{
  const requestOptions = {
    method: "GET",
    "Content-Type": "application/json",
    "authcode": localStorage.getItem('authCode'),
    "Authorization": "Bearer " + localStorage.getItem('authToken')
} 


let url = BASE_CORE_URL + `SoundSetting/Update?extreme=${extreme}&high=${high}&moderate=${moderate}&low=${low}`;

return await fetch (url,requestOptions)
    .then(res => res.json())
    .then (data =>{
      return data;
    })
    .catch(function (error) {
      console.error("Update () Exception: ", error);
    });
} 
//#endregion

//#region Api calling for Panel Setting List
export const getAllPanelList = async (pageNumber,searchText, filterText) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    }, 
  }

  let url = BASE_CORE_URL + "Device/GetAllPanelList?pageNumber=" + pageNumber + "&searchText=" + searchText + "&filterText=" + filterText;

  return await fetch(url, requestOptions)
  .then((res) => res.json())
  .then((data) => {
    console.log(data);
    return data;
  })
  .catch(err => {
    console.log(err);
  })
}
//#endregion

//#region Api calling for Panel Setting detailsbyId
export const getPanelSettingDetailsById = async (deviceId) => {
  const requestOptions = {
    method: "GET",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
   },
  };

  let url = BASE_CORE_URL + "Device/GetPanelListById?deviceId=" + deviceId;
  return await fetch(url, requestOptions)
    .then((resp) => {
      return resp.json();
    })
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("getPanelSettingDetails () Exception: ", error);
    })
}
//#endregion

export const GetPanelFilterData = async (pType, pSelectedIds) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  };
  let url = BASE_CORE_URL + "Device/GetPanelDataToFilter?pType=" + pType + "&pSelectedIds=" + pSelectedIds;
  console.log("CheckAlert", url);
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("GetPanelFilterData () Exception: ", error);
    });
};