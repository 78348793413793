import React, { useState, useEffect} from 'react'

const CurrentTime = () => {
    const [currentDT, setCurrentDT] = useState(new Date())

    useEffect(() => {
        let timerId = setInterval(() => {
            setCurrentDT(new Date());
        }, 1000);
        return () => clearInterval(timerId);
    }, [])
    
  return (
    <div className='mainDivDateTime'>
    <div className="topDate">{currentDT.toUTCString().slice(0,-12)}</div>
    <div className="topTime">{currentDT.toLocaleTimeString('en', { hour: 'numeric', hour12: true, minute: 'numeric', second: 'numeric' })}</div>
    </div>
  )
}

export default CurrentTime