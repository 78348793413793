import React, { useState, useEffect } from 'react'

import { SearchIcon, SearchIconWhite } from '../../assets/images'
import 'react-toastify/dist/ReactToastify.css';

import { getExportStatus, getStatusChangeReport } from '../../services/reportService';

import EventReportList from './EventReportList';
import { useDataAccessPagination } from "../../CustomHooks/useDataAccessPagination";
import { ConvertDate } from '../../utils/utils';
import { ErrorToast } from '../../CustomHooks/ErrorToast';

import Skeleton from "react-loading-skeleton";
import Pagination from "react-bootstrap/Pagination";
import { DateRangePicker, DatePicker } from "rsuite";
import { differenceInDays, subDays, subMonths } from 'date-fns';
import Swal from 'sweetalert2';
import { ToastContainer } from 'react-toastify';

const TODAY = "TD";
const YESTERDAY = "YD";
const SEVENDAY = "7D";
const ONEMONTH = "1M";
const THREEMONTH = "3M";
const NONE = "NONE";

export const EventActivityReport = (props) => {
  //useState Block Start 
  // let { tableLoading, setTableLoading } = props;
  const [show, setShow] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [totalDevices, setTotalDevices] = useState(0);
  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [deviceSearch, setDeviceSearch] = useState("");
  const [interfaceSearch, setInterfaceSearch] = useState("");
  const [typingStart, setTypingStart] = useState(false);
  const [clientSearch, setClientSearch] = useState("");
  const [siteSearch, setSiteSearch] = useState("");
  const [displayStartDate, setDisplayStartDate] = useState("");
  const [displayEndDate, setDisplayEndDate] = useState("");
  const [eventSearch, setEventSearch] = useState("");
  const [activeTab, setActiveTab] = useState(TODAY)
  const [inputDate, setInputDate] = useState([]);
  const [nextClick, prevClick, pageCounter, currentPageNo, limit, totalPages, setCurrentPageNo, setTotalPages, maxPageAllowed, setPageCounter] = useDataAccessPagination(10, 1, 10, 1);
  //useState Block End 

  //useEffect Block Start
  useEffect(() => {
    if (fromDate === "" || endDate === "") {
      let loggedInUser = JSON.parse(localStorage.getItem('user'))
      let startDate = ConvertDate(new Date(new Date().setHours(0, 0, 0, 0)));
      setFromDate(startDate);
      let endCurrDate = ConvertDate(new Date());
      setEndDate(endCurrDate)
      displayDateSetters(startDate, endCurrDate);
      getData(loggedInUser.UserUniqueID, startDate, endCurrDate, currentPageNo);
    } else {
      let loggedInUser = JSON.parse(localStorage.getItem('user'))
      getData(loggedInUser.UserUniqueID, fromDate, endDate, currentPageNo);
    }

  }, [currentPageNo]);

  useEffect(() => {
    if (clientSearch.length > 0) {
      setTypingStart(true);
    }
    if (clientSearch === "" && typingStart) {
      //Call the API Here.
      searchListDetails()
    }
  }, [clientSearch])

  useEffect(() => {
    if (deviceSearch.length > 0) {
      setTypingStart(true);
    }
    if (deviceSearch === "" && typingStart) {
      searchListDetails()
    }
  }, [deviceSearch])

  useEffect(() => {
    if (siteSearch.length > 0) {
      setTypingStart(true);
    }
    if (siteSearch === "" && typingStart) {
      //Call the API Here.
      searchListDetails()
    }
  }, [siteSearch])

  useEffect(() => {
    if (interfaceSearch.length > 0) {
      setTypingStart(true);
    }
    if (interfaceSearch === "" && typingStart) {
      //Call the API Here.
      searchListDetails()
    }
  }, [interfaceSearch])

  useEffect(() => {
    if (eventSearch.length > 0) {
      setTypingStart(true);
    }
    if (eventSearch === "" && typingStart) {
      //Call the API Here.
      searchListDetails()
    }
  }, [eventSearch])
  //useEffect Block End 
  const displayDateSetters = (startDate, endCurrDate) => {
    let one = startDate.split(" ");
    let tempOne = `${one[0].split("-")[2]}-${one[0].split("-")[1]}-${one[0].split("-")[0]}`;
    setDisplayStartDate(`${tempOne} ${one[1]}`);
    let two = endCurrDate.split(" ");
    let tempTwo = `${two[0].split("-")[2]}-${two[0].split("-")[1]}-${two[0].split("-")[0]}`;
    setDisplayEndDate(`${tempTwo} ${two[1]}`);
  }

  const today = () => {
    let startDate = ConvertDate(new Date(new Date().setHours(0, 0, 0, 0)));
    setFromDate(startDate);
    let endCurrDate = ConvertDate(new Date());
    setEndDate(endCurrDate)
    setInputDate([])
    displayDateSetters(startDate, endCurrDate);
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    getData(loggedInUser.UserUniqueID, startDate, endCurrDate, currentPageNo, searchString);
    setActiveTab(TODAY)
  }

  const sevenDay = () => {
    let startDate = ConvertDate(subDays(new Date(new Date().setHours(0, 0, 0, 0)), 6));
    setFromDate(startDate);
    let endCurrDate = ConvertDate(new Date());
    setEndDate(endCurrDate)
    setInputDate([])
    displayDateSetters(startDate, endCurrDate);
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    getData(loggedInUser.UserUniqueID, startDate, endCurrDate, currentPageNo, searchString);
    setActiveTab(SEVENDAY)
  }

  const yesterday = () => {
    let startDate = ConvertDate(subDays(new Date(new Date().setHours(0, 0, 0, 0)), 1));
    setFromDate(startDate);
    let endCurrDate = ConvertDate(subDays(new Date(new Date().setHours(24, 0, 0, 0)), 1));
    setEndDate(endCurrDate)
    setInputDate([])
    displayDateSetters(startDate, endCurrDate);
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    getData(loggedInUser.UserUniqueID, startDate, endCurrDate, currentPageNo, searchString);
    setActiveTab(YESTERDAY);
  }

  const oneMonth = () => {
    let startDate = ConvertDate(subMonths(new Date(new Date().setHours(0, 0, 0, 0)), 1));
    setFromDate(startDate);
    let endCurrDate = ConvertDate(new Date());
    setEndDate(endCurrDate)
    setInputDate([])
    displayDateSetters(startDate, endCurrDate);
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    getData(loggedInUser.UserUniqueID, startDate, endCurrDate, currentPageNo, searchString);
    setActiveTab(ONEMONTH);
  }

  const threeMonth = () => {
    let startDate = ConvertDate(subMonths(new Date(new Date().setHours(0, 0, 0, 0)), 3));
    setFromDate(startDate);
    let endCurrDate = ConvertDate(new Date());
    setEndDate(endCurrDate)
    setInputDate([])
    displayDateSetters(startDate, endCurrDate);
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    getData(loggedInUser.UserUniqueID, startDate, endCurrDate, currentPageNo, searchString);
    setActiveTab(THREEMONTH);
  }

  const onHandleDateTimeOkClick = (e) => {
    //To Convert Format
    //2023-07-30 00:00:00, 2023-07-31 10:48:46
    //YYYY-MM-DD HH:MM:SS
    const [startDate, endDate] = [e[0], e[1]];
    if (startDate && endDate) {
      const daysDifference = differenceInDays(endDate, startDate);
      if (daysDifference > 90) {
        ErrorToast('Maximum Date range cannot exceeded 90 days.');
        return;
      }
    }
    let sDate = ConvertDate(e[0]);
    setFromDate(sDate);
    let eDate = ConvertDate(e[1]);
    setEndDate(eDate);
    setInputDate([e[0], e[1]])
    displayDateSetters(sDate, eDate);
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    getData(loggedInUser.UserUniqueID, sDate, eDate, 1, "");
    setActiveTab(NONE);
  }

  const onHandleDateTimeChangeClick = (e) => {
    const [startDate, endDate] = [e[0], e[1]];
    if (startDate && endDate) {
      const daysDifference = differenceInDays(endDate, startDate);
      if (daysDifference > 90) {
        return;
      }
    }
    let sDate = ConvertDate(e[0]);
    setFromDate(sDate);
    let eDate = ConvertDate(e[1]);
    setEndDate(eDate);
    displayDateSetters(sDate, eDate);
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    getData(loggedInUser.UserUniqueID, sDate, eDate, 1, "");
    setActiveTab(NONE);
  }


  const getData = (uniqueId, startDate, endDate, pageNumber) => {
    try {
      setTableLoading(true);
      getStatusChangeReport(uniqueId, startDate, endDate, pageNumber, searchString)
        .then((res) => {
          if (res.Status == "success") {
            setData(res.Data.DeviceStatusHMReportModels);
            setTotalPages(parseInt(res.Data.Total));
            setTotalDevices(parseInt(res.Data.Total));
            setTableLoading(false);
          }
        })
        .catch(err => {
          console.log(err);
          setTableLoading(false);
        })
    } catch (error) {

    }
  }

  const getSearchDataReports = (searchText) => {
    try {
      setTableLoading(true);
      let loggedInUser = JSON.parse(localStorage.getItem('user'))
      getStatusChangeReport(loggedInUser.UserUniqueID, fromDate, endDate, currentPageNo, searchText)
        .then((res) => {
          if (res.Status == "success") {
            setData(res.Data.DeviceStatusHMReportModels);
            setTotalPages(parseInt(res.Data.Total));
            setTotalDevices(parseInt(res.Data.Total));
            setCurrentPageNo(1)
            setPageCounter(1)
            setTableLoading(false);
          }
        })
        .catch(err => {
          console.log(err);
        })
    } catch (error) {

    }
  }

  const convertToCSV = (objArray) => {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line != '') line += ','

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }

  const exportHandler = () => {
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    getExportStatus(loggedInUser.UserUniqueID, fromDate, endDate, 1, "")
      .then((res) => {
        if (res.Status == "success") {
          let stemp = res.Data.DeviceStatusHMReportModels;
          if (res.Data.DeviceStatusHMReportModels.length == 0) {
            Swal.fire({ text: 'No records available to download.' })
            return
          }
          let header = {
            ClientName: "Customer Name",
            DeviceName: "Device Name",
            InterfaceName: "Interface Name",
            InterfaceIP: "Interface IP",
            InterfacePort: "Interface Port",
            InterfaceSubType: "Interface Subtype",
            SiteName: "Site Name",
            EventName: "Event Name",
            StatusDateTime: "Status Datetime"
          }
          stemp.unshift(header);
          let jsonObject = JSON.stringify(stemp);
          let csv = convertToCSV(jsonObject);
          let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          let link = document.createElement("a");
          let fileN = "Health Status Change Report.csv"
          if (link.download !== undefined) {
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", fileN);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const searchListDetails = (data, type) => {
    let obj = {
      "devicename": deviceSearch,
      "interfacename": interfaceSearch,
      "sitename": siteSearch,
      "eventname": eventSearch,
      "clientname": clientSearch,
    }
    let stringText = JSON.stringify(obj);
    let tempStringText = stringText.replace(/["'{}]/g, "").replace(/[,]/g, ";")
    setSearchString(tempStringText);
    getSearchDataReports(tempStringText);
  }

  const PaginationComponent = (currentPageNo) => {
    let numbers = [];
    if (maxPageAllowed - 2 != 0 && (currentPageNo === maxPageAllowed || currentPageNo === (maxPageAllowed - 1) || currentPageNo === (maxPageAllowed - 2))) {
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed - 2}
          active={maxPageAllowed - 2 == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed - 2)}
        >
          {maxPageAllowed - 2}
        </Pagination.Item>)
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed - 1}
          active={maxPageAllowed - 1 == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed - 1)}
        >
          {maxPageAllowed - 1}
        </Pagination.Item>)
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed}
          active={maxPageAllowed == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed)}
        >
          {maxPageAllowed}
        </Pagination.Item>)
    }
    else if (maxPageAllowed - 1 != 0 && (currentPageNo === maxPageAllowed || currentPageNo === (maxPageAllowed - 1))) {
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed - 1}
          active={maxPageAllowed - 1 == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed - 1)}
        >
          {maxPageAllowed - 1}
        </Pagination.Item>)
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed}
          active={maxPageAllowed == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed)}
        >
          {maxPageAllowed}
        </Pagination.Item>)
    }
    else {
      for (let i = currentPageNo; i < currentPageNo + 3; i++) {
        if (i <= maxPageAllowed) {
          numbers.push(
            <Pagination.Item
              key={i}
              active={i == currentPageNo}
              onClick={() => setCurrentPageNo(i)}
            >
              {i}
            </Pagination.Item>
          )
        }
      }
    }
    return numbers;
  }

  const uniqueKey = (data) => {
    return `${data.DeviceName}_${data.StatusDateTime}`;
  }

  return (
    <div className='MainContentMargin'>
      <div className='col-lg-12 HMHeader mt-3 mb-3'>
        <div className="NameHeading1" style={{ width: "100%" }}>
          <p className="config-tool-head" style={{ float: "left" }}>
            Reports &gt; {`Health Report`}
          </p>
          <div style={{ display: "flex", alignItems: "flex-start", float: "right" }}>
            <div style={{ display: "grid", gridAutoFlow: "column", gridGap: "10px", alignItems: "center" }}>
              <button onClick={today} type="button" className={`${activeTab == TODAY ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>TD</button>
              <button onClick={yesterday} type="button" className={`${activeTab == YESTERDAY ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>YD</button>
              <button onClick={sevenDay} type="button" className={`${activeTab == SEVENDAY ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>7D</button>
              <button onClick={oneMonth} type="button" className={`${activeTab == ONEMONTH ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>30D</button>
              <button onClick={threeMonth} type="button" className={`${activeTab == THREEMONTH ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>90D</button>
              {/* <button type="button" class="btn btn-outline-primary">C</button> */}
              <DateRangePicker
                className="mr-1"
                format="dd-MM-yyyy HH:mm:ss"
                cleanable={false}
                editable={false}
                defaultCalendarValue={[
                  subMonths(new Date(), 1),
                  new Date(),
                ]}
                value={inputDate}
                ranges={[]}
                placeholder="Select date range"
                placement='bottomEnd'
                onOk={(e) => { onHandleDateTimeOkClick(e) }}
                // onChange={(e) => {onHandleDateTimeChangeClick(e)}}
                disabledDate={current => {
                  return current && current > new Date();
                }}
              />
              {/* <ToastContainer /> */}
              <button type="button" onClick={exportHandler} class="btn btn-outline-primary">Export</button>
            </div>
          </div>
        </div>
      </div>

      <div className="DeviceCurrentStatusDiv">
        <div className="DeviceTableHeader">
          <span className='HeaderSpanHR'>Health Report</span>
          <span className='mr-4'>
            <span className='FromDateFontSize'>From : </span>{displayStartDate}
            <span className='ml-3 FromDateFontSize'>To: </span>{displayEndDate}
          </span>
        </div>

        <div className="card-body p-0">
          <table
            className="table table-text-ext table-fixed report-table EventReportTable mb-2"
            id="event-activity-report"
          >
            <thead className="ReportTableHeader ReportTableHeadReport">
              <tr>
                <th>
                  <p>Customer Name(s)</p>
                  <div className="SearchTextDiv StatusSearchText">
                    <input
                      className="SearchViewInput"
                      type="search"
                      placeholder="Search"
                      value={clientSearch}
                      onChange={(e) => setClientSearch(e.target.value)}
                    />
                    {
                      props.isDarkTheme ?
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        /> :
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite} height="15"
                          onClick={() => searchListDetails()}
                        />
                    }
                  </div>
                </th>

                <th>
                  <p>Site Name(s)</p>
                  <div className="SearchTextDiv StatusSearchText">
                    <input
                      className="SearchViewInput"
                      type="search"
                      placeholder="Search"
                      value={siteSearch}
                      onChange={(e) => setSiteSearch(e.target.value)}
                    />
                    {
                      props.isDarkTheme ?
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        /> :
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite} height="15"
                          onClick={() => searchListDetails()}
                        />
                    }
                  </div>
                </th>

                <th>
                  <p>Device Name(s)</p>
                  <div className="SearchTextDiv StatusSearchText">
                    <input
                      className="SearchViewInput"
                      type="search"
                      placeholder="Search"
                      value={deviceSearch}
                      onChange={(e) => setDeviceSearch(e.target.value)}
                    />
                    {
                      props.isDarkTheme ?
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        /> :
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite} height="15"
                          onClick={() => searchListDetails()}
                        />
                    }
                  </div>
                </th>
                <th>
                  <p>Interface Name(s)</p>
                  <div className="SearchTextDiv StatusSearchText">
                    <input
                      className="SearchViewInput"
                      type="search"
                      placeholder="Search"
                      value={interfaceSearch}
                      onChange={(e) => setInterfaceSearch(e.target.value)}
                    />
                    {
                      props.isDarkTheme ?
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        /> :
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite} height="15"
                          onClick={() => searchListDetails()}
                        />
                    }
                  </div>
                </th>
                {/* <th>
                  <p>Device Type(s)</p>
                  <div className="SearchTextDiv StatusSearchText">
                    <select
                      required
                      className="SiteNameTextBox ArrowSelect HMTableSelect"
                    //   value={interfaceTypeSearch}
                    //   onChange={(e) => interfaceTypeChange(e)}
                    >
                      <option key="0" disabled={false} value="0">
                        - Select Data -
                      </option>
                      <option key="1" value="Camera">
                        Camera
                      </option>
                      <option key="2" value="NVR">
                        NVR
                      </option>
                      <option key="3" value="HDD">
                        HDD
                      </option>
                    </select>
                  </div>
                </th> */}




                {/* <th>
                  <p>Status(s)</p>
                  <div className="SearchTextDiv StatusSearchText">
                    <select
                      required
                      className="SiteNameTextBox ArrowSelect HMTableSelect"
                    //   value={currentStatusSearch}
                    //   onChange={(e) => currentStatusChange(e)}
                    >
                      <option
                        // hidden
                        key="0" value="0">
                        - Select Data -
                      </option>
                      <option key="1" value="Online">
                        Online
                      </option>
                      <option key="2" value="Offline">
                        Offline
                      </option>
                      <option key="3" value="Normal">
                        Normal
                      </option>
                      <option key="4" value="Faulty">
                        Faulty
                      </option>
                    </select>
                  </div>
                </th> */}
                <th>
                  <p>Event(s)</p>
                  <div className="SearchTextDiv StatusSearchText">
                    <input
                      className="SearchViewInput"
                      type="search"
                      placeholder="Search"
                      value={eventSearch}
                      onChange={(e) => setEventSearch(e.target.value)}
                    />
                    {
                      props.isDarkTheme ?
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        /> :
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite} height="15"
                          onClick={() => searchListDetails()}
                        />
                    }
                  </div>
                </th>



                <th>
                  <p>Date Time</p>
                  <div className="mt-1">
                    <DatePicker
                      disabled={true}
                      format="dd-MM-yyyy HH:mm:ss"
                      placement="autoVerticalEnd"
                    //   onOk={(e) => dateTimeHandler(e)}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                tableLoading
                  ?
                  (
                    <tr onClick={() => setShow(!show)}>
                      {
                        <>
                          <td>
                            <Skeleton className="width70pct" />
                          </td>
                          <td>
                            <Skeleton className="width80pct" />
                          </td>
                          <td>
                            <Skeleton className="width60pct" />
                          </td>
                          <td>
                            <Skeleton className="width70pct" />
                          </td>
                          <td>
                            <Skeleton className="width60pct" />
                          </td>
                          <td>
                            <Skeleton className="width80pct" />
                          </td>
                        </>
                      }
                    </tr>
                  )
                  :
                  (
                    <>
                      {
                        data.length > 0
                          ?
                          data?.map((item) => (
                            <EventReportList item={item}
                              // key={item.StatusDateTime}
                              key={uniqueKey(item)}
                            />
                          ))
                          : <div className='noRecordFound'><p className='noRecordFoundText'>No Record Available.</p></div>
                      }

                      {/* {
                        !healthData && (
                          <div><p>No Record Available.</p></div>
                        )
                      } */}
                    </>
                  )
              }
            </tbody>
            {
              totalDevices == 0 || totalPages == 0 || totalPages <= limit
                ? <div className="PaginationDiv mb-2 mt-2">
                  <h5 className='PageNumbers'>{totalDevices == 0 ? 0 : 1} - {totalDevices}</h5>
                  <span>of</span>
                  <h5 className='PageNumbers'>{totalDevices}</h5>
                  <Pagination>
                    <Pagination.Prev disabled />
                    <Pagination.Item active disabled>{1}</Pagination.Item>
                    <Pagination.Next disabled />
                  </Pagination>
                </div>
                :
                <div className="PaginationDiv mb-2 mt-2">
                  <h5 className="PageNumbers">
                    {
                      totalDevices == 0 ? 0
                        : (currentPageNo - 1) * parseInt(limit) + parseInt(1)}
                    -
                    {
                      (currentPageNo * limit) > (totalDevices)
                        ? (totalDevices)
                        : currentPageNo * limit
                    }
                  </h5>
                  <span>of</span>
                  <h5 className="PageNumbers">{totalDevices}</h5>

                  <Pagination>
                    <Pagination.Prev onClick={prevClick} disabled={currentPageNo == 1} />
                    {
                      PaginationComponent(currentPageNo)
                    }
                    <Pagination.Next onClick={nextClick} disabled={currentPageNo == maxPageAllowed} />
                  </Pagination>
                </div>
            }
          </table>
        </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  )
}
