import React, { useState, useEffect, useRef } from 'react'

import { ClockIcon, LinkIcon, RemoveIcon, MosaicTwowayStop, MosaicTwowayStart, MosaicHooterOn, MosaicHooterOff, AssociateCamera } from '../../assets/images';

import { ToastContainer } from 'react-toastify';

import ChatflowContainer from '../Chatflow/ChatflowContainer'
import { HooterOnOffRequest, TwoWayCommunicationRequest } from "../../services/alertAuthService"
import { WarningToast } from '../../CustomHooks/WarningToast';
import { ErrorToast } from '../../CustomHooks/ErrorToast';
import { ASSOC_DIRECT_OPEN, CHAT_CAM_CHANGES } from '../../config';
import { mixpanel } from '../../utils/MixPanelUtil'

export default function ChatbotContainer({ chatHeads, onCloseAlertChat, onMaximizeAlertChat, ResetAlertList, cctvTypeName, onChatOpen, hootWay, setHootWay, openButton1, setOpenButton1, setAlertDetail, associateCam, setAssociateCam }) {

    return (
        <div className="col-lg-12 mt-3 pr-1 OverlayChat">
            {

                chatHeads &&
                chatHeads.map((chat, index) => {
                    return <ChatbotWidget
                        chat={chat}
                        key={chat.alertId}
                        onCloseAlertChat={onCloseAlertChat}
                        onMaximizeAlertChat={onMaximizeAlertChat}
                        ResetAlertList={ResetAlertList}
                        cctvTypeName={cctvTypeName}
                        onChatOpen={onChatOpen}
                        hootWay={hootWay}
                        setHootWay={setHootWay}
                        openButton1={openButton1}
                        setOpenButton1={setOpenButton1}
                        setAlertDetail={setAlertDetail}
                        associateCam={associateCam}
                        setAssociateCam={setAssociateCam}
                    />
                })
            }
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

const ChatbotWidget = ({
    chat,
    onCloseAlertChat,
    onMaximizeAlertChat,
    ResetAlertList,
    cctvTypeName,
    onChatOpen,
    hootWay,
    setHootWay,
    openButton1,
    setOpenButton1,
    setAlertDetail,
    associateCam,
    setAssociateCam
}) => {

    //region useStart start
    const [open, setOpen] = useState(false)
    const [isHooterOn, setIsHooterOn] = useState(true);
    const [isSoundOn, setIsSoundOn] = useState(true);
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    //region useState end

    const messagesEndRef = useRef(null);

    //region useEffect start
    useEffect(() => {
        scrollToBottom();
    }, []);

    useEffect(() => {
        setOpen(chat.isOpen);
    }, [chat])

    useEffect(() => {
        if (ASSOC_DIRECT_OPEN) {
            ToggleChatWindow(chat.alertId);
            handleDragOpenTwo();
        }
    }, []);

    useEffect(() => {
        try {
            mixpanel.track("Chatroom - Alert Docker", {
                'ConversationId': "AlertID_" + chat.alertId + "_UserID_" + chat.assignedTo,
                'AlertTemplate': chat.alertTemplate,
                'AlertId': chat.alertId,
                'Severity': chat.severity
            })
            mixpanel.time_event("Chatroom Duration")
        } catch (error) {
            console.error(error)
        }
    }, [])

    useEffect(() => {
        if (hootWay != null) {
            let arr = hootWay?.filter((item) => item.alertId == chat.alertId);
            let htStatus = arr[0]?.hooterStatus;
            let twoWayStatus = arr[0]?.twoWayStatus;
            let disabledBtn = arr[0]?.btnDisabled;
            setIsHooterOn(htStatus);
            setIsSoundOn(twoWayStatus);
            setIsBtnDisabled(disabledBtn);
        }
    }, [hootWay]);

    //region useEffect end

    const ToggleChatWindow = (alertId) => {
        try {
            setOpen(!open)
            onChatOpen(alertId)
        } catch (error) {
            console.error(error)
        }
    }
    const CloseChatWindow = (e, chat) => {
        try {
            e.stopPropagation();
            onCloseAlertChat(chat)
            if (CHAT_CAM_CHANGES) {
                let arr = associateCam.filter((item) => item.alertId != chat.alertId)
                setAssociateCam(arr)
            }
            let isPresent = localStorage.getItem('hooterData');
            if(isPresent){
                let arr = JSON.parse(localStorage.getItem('hooterData'));
                let filteredArr = arr.filter((item) => item.alertId != chat.alertId);
                if(filteredArr.length == 0){
                    localStorage.removeItem('hooterData');
                  }else{
                    localStorage.setItem('hooterData', JSON.stringify(filteredArr));
                  }
            }

            //if(open == true){
            mixpanel.track("Chatroom Duration", {
                "ConversationId": "AlertID_" + chat.alertId + "_UserID_" + chat.assignedTo,
                "AlertTemplate": chat.alertTemplate,
                "AlertId": chat.alertId,
                "Severity": chat.severity
            })
            //}
        } catch (error) {
            console.error(error)
        }
    }

    function HooterClick(hooter, chat, isCloseBtn) {
        var resp = null
        if (hooter && hooter == 'on') {
            // let isAlreadySelected = hootWay.some((item) => item.hooterStatus === false);
            // if(isAlreadySelected){
            //     WarningToast("Hooter already enabled for another alert, close it and then start this.");
            //     return;
            // }
            setIsHooterOn(false)
            if (!isCloseBtn) {
                let tempArr = hootWay.map((item) => {
                    let obj;
                    if (item.alertId == chat.alertId) {
                        obj = {
                            ...item,
                            hooterStatus: false
                        }
                    } else {
                        obj = {
                            ...item
                        }
                    }
                    return obj;
                })
                setHootWay(tempArr);
            }
            resp = HooterOnOffReq(chat.privateIpAddress, chat.port, 'on', chat.panelBrand, cctvTypeName, chat.camUserName, chat.camPassword, chat.brandName)
        }
        if (hooter && hooter == 'off') {
            setIsHooterOn(true)
            if (!isCloseBtn) {
                let tempArr = hootWay.map((item) => {
                    let obj;
                    if (item.alertId == chat.alertId) {
                        obj = {
                            ...item,
                            hooterStatus: true
                        }
                    } else {
                        obj = {
                            ...item
                        }
                    }
                    return obj;
                })
                setHootWay(tempArr);
            }
            resp = HooterOnOffReq(chat.privateIpAddress, chat.port, 'off', chat.panelBrand, cctvTypeName, chat.camUserName, chat.camPassword, chat.brandName)
        }
        // if (resp && resp != null) {
        //     Swal.fire({text:resp})
        // }
    }

    function TwoWayAudioClick(audio, chat, isCloseBtn) {
        var resp = null;
        var resp1 = "";
        if (audio && audio == 'start') {
            let isAlreadySelected = hootWay.some((item) => item.twoWayStatus === false);
            if (isAlreadySelected) {
                WarningToast("Two way talk already enabled for another alert, close it and then start this.");
                return;
            }
            setIsSoundOn(false)
            if (!isCloseBtn) {
                let tempArr = hootWay.map((item) => {
                    let obj;
                    if (item.alertId == chat.alertId) {
                        obj = {
                            ...item,
                            twoWayStatus: false
                        }
                    } else {
                        obj = {
                            ...item
                        }
                    }
                    return obj;
                })
                setHootWay(tempArr);
            }
            resp1 = TwoWayCommunicationReq(0, 0, 'stop', 0, 0, 0)
            resp = TwoWayCommunicationReq(chat.privateIpAddress, chat.communicationPort, 'start', chat.camUserName, chat.camPassword, chat.brandName)
        }
        if (audio && audio == 'stop') {
            setIsSoundOn(true)
            if (!isCloseBtn) {
                let tempArr = hootWay.map((item) => {
                    let obj;
                    if (item.alertId == chat.alertId) {
                        obj = {
                            ...item,
                            twoWayStatus: true
                        }
                    } else {
                        obj = {
                            ...item
                        }
                    }
                    return obj;
                })
                setHootWay(tempArr);
            }
            resp = TwoWayCommunicationReq(chat.privateIpAddress, chat.communicationPort, 'stop', chat.camUserName, chat.camPassword, chat.brandName)
        }
    }


    const TwoWayCommunicationReq = async (privateIpAddress, communicationPort, action, user, password, brandName) => {
        try {
            if (privateIpAddress && communicationPort && user && password) {
                TwoWayCommunicationRequest(privateIpAddress, communicationPort, action, user, password, brandName).then((resp) => {
                    return resp
                })
            }
            else {
                TwoWayCommunicationRequest(privateIpAddress, communicationPort
                    , action, user, password, brandName).then((resp) => {

                        return resp
                    })
            }
        } catch (error) {
            console.error(error)
        }
    }

    const HooterOnOffReq = async (privateIpAddress, port, action, panelBrand, cctvTypeName, user, password, brandName) => {
        try {
            if (privateIpAddress) {
                HooterOnOffRequest(privateIpAddress, port, action, panelBrand, cctvTypeName, user, password, brandName).then((resp) => {
                    return resp
                })
            }
        } catch (error) {
            console.error(error)
        }
    }

    const scrollToBottom = () => {
        setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: "auto" })
        }, 1000)
    }
    const handleDragOpen = () => {
        if (openButton1) {
            ErrorToast("Kindly close the existing associate camera window, to open a new one.");
        } else {
            setOpenButton1(true)
            setAlertDetail(chat);
        }
    }

    const handleDragOpenTwo = () => {
        if (openButton1) {
            if (!ASSOC_DIRECT_OPEN) {
                ErrorToast("Kindly close the existing associate camera window, to open a new one.");
            }
        } else {
            setOpenButton1(true)
            setAlertDetail(chat);
        }
    }
    return (
        <>
            <div className="ChatOver ChatBox">
                <div>
                    <div className="chatFlowMobileHeader" style={!open ? { height: "60px" } : { height: "122px" }}>
                        <div className="chatFlowMobileHeaderTop" onClick={() => ToggleChatWindow(chat.alertId)}>
                            <div className="HeaderFireAlert">
                                <p className="chatFlowMobileAlertTypeText FireAlertTextp">Alert Id: {chat.alertId}</p>
                                <div className={`SeverityBGDiv ${chat.severity.toLowerCase()}-bg`}>
                                    <span className="ExtremeValue">
                                        {chat.severity}
                                    </span>
                                </div>
                                <div className="chatFlowClockDiv ml-0" hidden>
                                    <img className="chatFlowClockClass" src={ClockIcon} />
                                    <p className="chatFlowMobileAlertTime">01:59</p>
                                </div>
                                <div className="LinkButtonDiv">
                                    <button className="LinkButtonView" onClick={(event) => {
                                        event.stopPropagation()
                                        onMaximizeAlertChat(chat.alertId)
                                    }
                                    }>
                                        <img src={LinkIcon} />
                                    </button>
                                    <button className="LinkButtonView" onClick={(e) => {
                                        CloseChatWindow(e, chat)
                                        HooterClick('off', chat, true)
                                        TwoWayAudioClick('stop', chat, true)
                                    }
                                    }>
                                        <img src={RemoveIcon} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={`chatFlowMobileHeaderBottom ${open ? "panel-collapse" : "panel-collapse panel-close"}`}>
                            <div className='ChatbotTextButtonDiv'>
                                <div>
                                    <p className="chatFlowMobileAlertBtoomText">{chat.alertType}</p>
                                </div>
                                <div>
                                    <p className="chatFlowMobileDetailText">{chat.site} | {chat.actualTime} |  {chat.deviceName}</p>
                                </div>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                {
                                    !true
                                        ?
                                        <button
                                            data-title='Associate Camera'
                                            onClick={() => handleDragOpen()}
                                            className='AssociateCamera AssociateDataTitle'>
                                            <img
                                                style={{ height: "22px" }}
                                                alt="Twoway"
                                                src={AssociateCamera}
                                            />
                                        </button>
                                        : null
                                }
                                {
                                    false
                                        ?
                                        <>
                                            <span>
                                                {isSoundOn &&
                                                    <button
                                                        disabled={isBtnDisabled}
                                                        data-title='TwowayStart'
                                                        className='TwoWayAudioToggle'>
                                                        <img
                                                            style={{ padding: "2px", height: "22px" }}
                                                            alt="Twoway Start"
                                                            src={MosaicTwowayStart}
                                                            onClick={() => TwoWayAudioClick('start', chat)} />
                                                    </button>
                                                }
                                                {!isSoundOn &&
                                                    <button
                                                        disabled={isBtnDisabled}
                                                        data-title='TwowayStop'
                                                        className='TwoWayAudioToggle'>
                                                        <img
                                                            style={{ padding: "2px", height: "22px" }}
                                                            src={MosaicTwowayStop}
                                                            alt="Twoway"
                                                            onClick={() => TwoWayAudioClick('stop', chat)} />
                                                    </button>
                                                }
                                            </span>
                                            <span>
                                                {isHooterOn &&
                                                    <button disabled={isBtnDisabled} data-title='HooterOn' className='TwoWayAudioToggle'>
                                                        <img style={{ padding: "1px", height: "26px", paddingBottom: "1px" }}
                                                            src={MosaicHooterOn}
                                                            alt="Hooter on"
                                                            onClick={() => HooterClick('on', chat)} />
                                                    </button>}
                                                {!isHooterOn &&
                                                    <button disabled={isBtnDisabled} data-title=' HooterOff' className='TwoWayAudioToggle'>
                                                        <img style={{ padding: "1px", height: "26px", paddingBottom: "1px" }}
                                                            src={MosaicHooterOff}
                                                            alt="Hooter off"
                                                            onClick={() => HooterClick('off', chat)} />
                                                    </button>}

                                            </span>
                                        </>
                                        : null
                                }
                            </div>
                        </div>
                        <div className={`chatFlowMobileHeaderBottom ${open ? "panel-collapse" : "panel-collapse panel-close"}`} style={{ display: "none" }} >
                            <div className="chatFlowMobileBtmOne">
                                <p className="chatFlowMobileAlertBtoomText">{chat.alertType}</p>
                            </div>

                            <div className='hooter-twoway-div' >
                                {
                                    !true
                                        ?
                                        <button
                                            data-title='Associate Camera'
                                            onClick={() => handleDragOpen()}
                                            className='AssociateCamera'>
                                            <img
                                                alt="Associate"
                                                style={{ height: "22px" }}
                                                src={AssociateCamera}
                                            />
                                        </button>
                                        : null
                                }
                            </div>

                            <div className="chatFlowMobileBtmTwo">
                                <p className="chatFlowMobileDetailText">{chat.site} | {chat.actualTime} |  {chat.deviceName}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`ChatDetailsDiv ${open ? "panel-collapse" : "panel-collapse panel-close"}`}>
                    <div id={"Chatbotcomponentdiv:AlertID-" + chat.alertId}></div>
                    <div className='multi-chatbot-container-div'>
                        <ChatflowContainer
                            scrollToBottom={scrollToBottom}
                            AlertDetails={chat}
                            ConversationId={"AlertID_" + chat.alertId + "_UserID_" + localStorage.getItem('UserID')}
                            ResetAlertList={ResetAlertList}
                            hootWay={hootWay}
                            setHootWay={setHootWay}
                            isHooterOn={isHooterOn}
                            associateCam={associateCam}
                            setAssociateCam={setAssociateCam}
                        />
                        <div ref={messagesEndRef} />
                    </div>
                </div>
            </div>
        </>
    )

}