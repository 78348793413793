export const ROOT = "/";
export const LOGIN = "/login";

//tej alert pages S
export const ALERTS = "/alerts";
export const ALLALERTS = "/allalerts";
export const MYALERTS = "/myalerts";
export const ALERTDETAILS = "/alertdetails/:id";
//alert pages E 

export const SIGNOUT = "/signout";
export const DASHBOARD = "/dashboard";
export const HOMEPAGE = "/homepage";
export const SIGNUP = "/signup";
export const FORGETPASSWORD = "/forgetpassword/:emailid";
export const VERIFICATION ="/verification/:email";
export const RESETPASSWORD = "/resetpassword/:emailid";
export const CONFIGURATION = "/configuration";
export const ALLNOTIFICATIONS = "/allnotifications";
export const WORKFLOW = "/workflow/:id";
export const AUDITLOG = "/auditlog/:id";

//tej Reports S
export const REPORTS = "/alertactivity";
export const ALERTACTIVITYREPORT = "/alertactivityreport";
export const ALERTARMDISARMREPORT = "/alertarmdisarmreport";
export const EVENTACTIVITYREPORT = "/eventactivity";
//Reports E

//tej Camera S
export const CAMERAS = "/cameras/:flag";
export const CAMERA_SINGLE_PLAYBACK = "/playback";
export const SYNCREPLAY = "/syncreplay/:devicelist";
export const MOSAIC = "/mosaic";
//Camera E

export const PROFILE = "/profile";
export const CHATFLOW = "/chatflow/:alertId";
export const ALERTREPORT = "/alertreport/:alertId";
export const DOWNLOAD = "/download";

export const PLAYER = '/player-test'

export const ONE_MINUTE = 60000;
export const FIVE_MINUTES = 5 * 60 * 1000;
export const TEN_MINUTES = 10 * 60 * 1000;
export const THIRTY_MINUTES = 30 * 60 * 1000;

export const HEALTHMONITORING = "/healthmonitoring";
export const DISPLAYSITE = "/healthmonitoring/SiteName";

export const NOTIFICATON = "/notification";

export const PAGE = "/page"

export const ABOUTMH = '/about';
export const ABOUTSUPPORT = '/support';
export const ABOUTTERMS = '/termscondition';

export const TERMSANDCONDITION = '/termsandcondition'

export const PANELCONTROL = '/panelcontrol';