import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { GetSeverity, UpdateSoundSettingFunction } from '../../services/configurationServices';
import { SuccessToast } from '../../CustomHooks/SuccessToast';
import { toast } from 'react-toastify';

export default function SoundSetting({getAllfromParent, buttonDisable, setButtonDisable}) {
    // region useState start 
    const [enableLowSeverity, setEnableLowSeverity] = useState(false);
    const [enableModerateSeverity, setEnableModerateSeverity] = useState(false);
    const [enableHighSeverity, setEnableHighSeverity] = useState(false);
    const [enableExtremeSeverity, setEnableExtremeSeverity] = useState(false);
    const [isSoundSettingEdit, setIsSonudSettingEdit] = useState(false);
    const [soundSettingUpdate,setSoundSettingUpdate]=useState(false);
      //region useState  end
      
      useEffect(() => {
        getAll()
      }, [])

const getAll = () =>{
  try {
    GetSeverity().then((resp) => {
        if (resp != undefined) {
          console.log("checkrespget",resp)
          if (resp.Status == "success") {
            let  extreme = resp.Data.find((item)=> item.SeverityTitle == "Extreme");
            setEnableExtremeSeverity(Boolean(parseInt(extreme.PlaySound)));
            let  high = resp.Data.find((item)=> item.SeverityTitle == "High");
            setEnableHighSeverity(Boolean(parseInt(high.PlaySound)));
            let  moderate = resp.Data.find((item)=> item.SeverityTitle == "Moderate");
            setEnableModerateSeverity(Boolean(parseInt(moderate.PlaySound)));
            let  low = resp.Data.find((item)=> item.SeverityTitle == "Low");
            setEnableLowSeverity(Boolean(parseInt(low.PlaySound)));
            
          } 
        }
      })
} catch (error) {
    console.error(error)
}
}

const updateSoundPage = () => {
  console.log("checkupdate")
  setButtonDisable(true);
  try { 
    let extreme = enableExtremeSeverity ? "1" :"0";
    let high = enableHighSeverity ? "1" : "0";
    let moderate = enableModerateSeverity ? "1" : "0";
    let low = enableLowSeverity ? "1" : "0";

    UpdateSoundSettingFunction(extreme,high,moderate,low).then((resp) => {
        if (resp != undefined) {
          console.log("checkrespupdet",resp)
          if (resp.Status == "success") {
            SuccessToast(resp.Message);
            setIsSonudSettingEdit(false)
            getAllfromParent()
            setButtonDisable(false);
          } else {
            showErrorToast(resp.Message)
            setButtonDisable(false);
          }
        }
      })
} catch (error) {
    console.error(error)
}
}

      const onCancel = (e) => {
        e.stopPropagation()
        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to cancel the changes made?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#027aaa",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Leave!"
        }).then((result) => {
          if (result.isConfirmed) {
            getAll()
            setIsSonudSettingEdit(false)
          }
        })
      }
      const showErrorToast = (msg) => {
        toast.error(msg, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      };

  return (
    <div className="col-lg-12 mt-2">
        <div className="SiteMainContainer">
        <div className="soundContainer" 
        // style={{width: "100%"}}
        >
            <>
            <form>
                <div className="PrepostSettingForm PrePostSettingDiv paddingLR300">
                <div className="inputMainContainer w40dot75rem mt300">
                                    <div className="inputTypeLabelContainer h60">
                                        <label className='formFieldLabels'>Play sound by alert severity</label>
                                        <div className='mt-2 signTypeCheckboxLabels'>
                                            <div className="SiteTextBox minWidth150 mt-0">
                                                <div
                                                    className="onlyforEnableCheckbox ml-0"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox_custom"
                                                        id="exampleCheck1"
                                                        style={{ marginLeft: "2px" }}
                                                        checked={enableLowSeverity}
                                                        disabled={!isSoundSettingEdit}
                                                        onChange={() => {
                                                          setEnableLowSeverity(!enableLowSeverity)
                                                        }}

                                                        // {...register("isWebLoginAllowed")}
                                                        // defaultChecked={user?.isWebLoginAllowed}
                                                        // disabled={!isUserEdit || webBoxDisabled}
                                                    />
                                                    <label className="" htmlFor="exampleCheck1">
                                                        Low
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="SiteTextBox minWidth150 mt-0">
                                                <div
                                                    className="onlyforEnableCheckbox ml-0"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox_custom"
                                                        id="exampleCheck2"
                                                        style={{ marginLeft: "2px" }}
                                                        checked={enableModerateSeverity}
                                                        disabled={!isSoundSettingEdit}
                                                        onChange={() => {
                                                          setEnableModerateSeverity(!enableModerateSeverity)
                                                        }}
                                                        // {...register("isMobileLoginAllowed")}
                                                        // defaultChecked={user?.isMobileLoginAllowed}
                                                        // disabled={!isUserEdit || mobileBoxDisabled}
                                                    />
                                                    <label className="" htmlFor="exampleCheck2">
                                                        Moderate
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="SiteTextBox minWidth150 mt-0">
                                                <div
                                                    className="onlyforEnableCheckbox ml-0"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox_custom"
                                                        id="exampleCheck3"
                                                        style={{ marginLeft: "2px" }}
                                                        checked={enableHighSeverity}
                                                        disabled={!isSoundSettingEdit}
                                                        onChange={() => {
                                                          setEnableHighSeverity(!enableHighSeverity)
                                                        }}
                                                        // {...register("isMobileLoginAllowed")}
                                                        // defaultChecked={user?.isMobileLoginAllowed}
                                                        // disabled={!isUserEdit || mobileBoxDisabled}
                                                    />
                                                    <label className="" htmlFor="exampleCheck3">
                                                        High
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="SiteTextBox minWidth150 mt-0">
                                                <div
                                                    className="onlyforEnableCheckbox ml-0"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox_custom"
                                                        id="exampleCheck4"
                                                        style={{ marginLeft: "2px" }}
                                                        checked={enableExtremeSeverity}
                                                        disabled={!isSoundSettingEdit}
                                                        onChange={() => {
                                                          setEnableExtremeSeverity(!enableExtremeSeverity)
                                                        }}
                                                        // {...register("isMobileLoginAllowed")}
                                                        // defaultChecked={user?.isMobileLoginAllowed}
                                                        // disabled={!isUserEdit || mobileBoxDisabled}
                                                    />
                                                    <label className="" htmlFor="exampleCheck4">
                                                        Extreme
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                </div>
            </form>

          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            {
             !isSoundSettingEdit &&
              (
                <button
                  className="DataAccessEdit"
                  style={{ width: "100px" }}
                  onClick={() => {
                    // setIsPrePostEdit(false);
                    setIsSonudSettingEdit(true);
                  }}
                >
                  <span className="ViewUpdate">Edit</span>
                </button>
              )
            }

            {/* {
            //   selectedBrandMaster != null && !isPrePostEdit && alertPrePostId == "" &&
              (
                <button
                  className="DataAccessEdit"
                  style={{ width: "100px" }}
                 //onClick={onSaveAlertPrePost}

                >
                  <span className="ViewUpdate">Save</span>
                </button>
              )
            } */}

            {
             isSoundSettingEdit &&
              (
                <>
                  <button
                    className="DataAccessEdit"
                    style={{ width: "100px" }}
                    onClick={updateSoundPage}
                    disabled={buttonDisable}
                  >
                    <span className="ViewUpdate">Update</span>
                  </button>
                  <button
                    className="DataAccessEdit"
                    style={{ width: "100px" }}
                    onClick={(e) => {
                      onCancel(e);
                      //getAll()
                      //setIsSonudSettingEdit(false);
                  }}
                  >
                    <span className="ViewUpdate">Cancel</span>
                  </button>
                </>
              )
            }
          </div>
            </>
        </div>
      </div>
    </div>
  )
}
