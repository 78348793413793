import React, {useState, useEffect} from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { NotificationOfflineInfo,NotificationOnlineInfo, AlertNotifExtreme, AlertNotifHigh, AlertNotifLow, AlertNotifMedium, HMNotifOnline, HmNotifOffline, HMNotificationOnline, HMNotificationOffline } from '../../assets/images'

const NotificationList = ({item, markAsRead}) => {
    const [showMore, setShowMore] = useState(false);
    const [severityType, setSeverityType] = useState("");
    const [deviceName, setDeviceName] = useState("");

//  let checkOfflineOrFaulty = (item.value.message).includes("Faulty") || (item.value.message).includes("offline");
//  let checkOnlineOrNormal = (item.value.message).includes("Normal") || (item.value.message).includes("online");

 useEffect(() => {
    if(item?.value?.message?.includes("Faulty") || item?.value?.message?.includes("offline")){
        setSeverityType("offline")
       }else if(item?.value?.message?.includes("Normal") || item?.value?.message?.includes("online")){
        setSeverityType("online")
       }else if(item?.value?.severity == "1"){
        setSeverityType("extreme")
       }else if(item?.value?.severity == "2"){
        setSeverityType("high")
       }else if(item?.value?.severity == "3"){
        setSeverityType("moderate")
       }else if(item?.value?.severity == "4"){
        setSeverityType("low")
       }else{
        setSeverityType("")
       }
 }, [item]);

//  useEffect(() => {
//     let deviceName = ""
//     if (item.value.message.split("\n")[1].split("-").length > 2) {
//         deviceName = item.value.message.split("\n")[1].split("-")[1] + "-" + item.value.message.split("\n")[1].split("-")[2];
//     }else {
//         deviceName = item.value.message.split("\n")[1].split("-")[1];
//     }
//     setDeviceName(deviceName)
//  })

  return (
    <div className='NotificationListItem'>
    <div className='ItemOnlineStatus'>
        
              {/* {checkOfflineOrFaulty &&
                  <div className='AlertImgBg' style={{ backgroundColor: "#D3D3D3" }}>
                      <img className='AlertImgIcon' src={NotificationOfflineInfo} alt="Notification Alert Red" />
                  </div>
              } 

              {checkOnlineOrNormal &&
                  <div className='AlertImgBg' style={{ backgroundColor: "#8BB0F4" }}>
                      <img className='AlertImgIcon' src={NotificationOnlineInfo} alt="Notification Alert Red" />
                  </div>
              } */}

              {
                severityType == "extreme"
                ? 
                <div className='AlertImgBg' style={{ backgroundColor: "#FFC3C3" }}>
                      <img className='AlertImgIcon' src={AlertNotifExtreme} alt="Notification Alert Red" />
                </div> 
                : severityType == "high"
                ? 
                <div className='AlertImgBg' style={{ backgroundColor: "#FFD0BF" }}>
                    <img className='AlertImgIcon' src={AlertNotifHigh} alt="Notification Alert Red" />
                </div>
                : severityType == "moderate"
                ? 
                <div className='AlertImgBg' style={{ backgroundColor: "#FFEFC4" }}>
                    <img className='AlertImgIcon' src={AlertNotifMedium} alt="Notification Alert Red" />
                </div>
                : severityType == "low"
                ? 
                <div className='AlertImgBg' style={{ backgroundColor: "#E7FFC3" }}>
                    <img className='AlertImgIcon' src={AlertNotifLow} alt="Notification Alert Red" />
                </div>
                : severityType == "online"
                ?
                <div className='AlertImgBg' 
                // style={{ backgroundColor: "#8BB0F4" }}
                // style={{ backgroundColor: "#7BB526" }}
                style={{ backgroundColor: "#E6FFC3" }}
                >
                    <img className='AlertImgIcon' 
                    // src={HMNotifOnline}
                    src={HMNotificationOnline} 
                    alt="Notification Alert Red" />
                </div>
                : severityType == "offline"
                ?
                <div className='AlertImgBg' 
                // style={{ backgroundColor: "#D3D3D3" }}
                // style={{ backgroundColor: "#F32C22" }}
                style={{ backgroundColor: "#FFC3C3" }}
                >
                    <img className='AlertImgIcon' 
                    // src={HmNotifOffline} 
                    src={HMNotificationOffline} 
                    alt="Notification Alert Red" />
                </div>
                : null
              }

        {/* <div className='AlertImgBg' style={{backgroundColor : "#B5EACC"}}>
            <img className='AlertImgIcon' src={NotificationAlertGreen} alt="Notification Alert Red"/>                                    
        </div> */}
        <div className='CameraStatusNotifyDiv'>
            <div className='NotificationTextDiv'>
                {
                    // severityType == "extreme" ? <p className='CameraStatusNameText'>Extreme Severity Alert</p>
                    // : severityType == "high" ? <p className='CameraStatusNameText'>High Severity Alert</p>
                    // : severityType == "moderate" ? <p className='CameraStatusNameText'>Moderate Severity Alert</p>
                    // : severityType == "low" ? <p className='CameraStatusNameText'>Low Severity Alert</p>
                    // : severityType == "online" ? <p className='CameraStatusNameText'>Device Online Notification</p>
                    // : severityType == "offline" ? <p className='CameraStatusNameText'>Device Offline Notification</p>
                    // : <p className='CameraStatusNameText'>{item.value.title}</p>
                }
                <p className='CameraStatusNameText'>
                    {item?.value?.title}
                    {/* {deviceName} */}
                    </p>
                {
                    item.value.status == "unread"
                    ?
                    <OverlayTrigger placement='left'  overlay={ <Tooltip>Mark as read</Tooltip>}>
                    <button onClick={(e) => markAsRead(item.key)} style={{background:"transparent"}}>
                        <div className='MarkStatusDiv'></div>
                    </button>
                </OverlayTrigger>
                    : null
                }
                
            </div>
            <div>
                <p className='CameraStatusDetailsP'>
                    {showMore ? item?.value?.message : item?.value?.message?.substring(0, 55)}
                    {/* {item.value.message} */}
                    <button className='MoreBtn' onClick={() => 
                    {setShowMore(!showMore)
                    markAsRead(item?.key)
                }}
                    >
                        {showMore ? "Less" : "...More"}
                    </button>
                </p>
                <p className='DateTimeText'>{item?.value?.time}</p>
            </div>                                    
        </div>
    </div>
</div>
  )
}

export default NotificationList