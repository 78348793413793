import React, { useEffect, useState } from "react";

import "rsuite/dist/rsuite.min.css";
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-alice-carousel/lib/alice-carousel.css';
import {
  CameraBlock, CameraStatus, DVRStatus, DeviceGrayIcon, FaultyDevice, HDDstatus, InfoBlue, InfoBlueWhite, OpenPopupIconBlueWhite,
  OpenPopupIconBlue, RefreshBtnIcon, SystemHealth, PanelStatus, SensorStatusIcon, ArrowLeft, ArrowRight
} from "../../assets/images";

import { AllWidgetData } from "../../services/healthMonitoringService";

import Skeleton from "react-loading-skeleton";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import AliceCarousel from 'react-alice-carousel';
import { Chart as ChartJS, ArcElement, Legend, LinearScale, CategoryScale, PointElement, TimeScale, BarElement } from 'chart.js';

import HealthCard from "./HealthCard";
import HealthTable from "./HealthTable";
import HealthOfflineDevices from "./HealthOfflineDevices";
import HealthEvents from "./HealthEvents";
import SensorStatus from "./SensorStatus";
import HealthPercent from "./HealthPercent";
import { HEALTHMONITORING_INTERVAL_TIMER } from "../../config";
import { AllDateFormat } from "../../utils/utils";
import HealthIntegratedChart from "./HealthIntegratedChart";
import SampleChart from "./SampleChart";

//charts---
ChartJS.register(ArcElement, Legend, TimeScale, LinearScale, CategoryScale, PointElement, BarElement);

const tooltip = (
  <Tooltip id="tooltip">
    Camera Tampering includes, Camera Block, Scene Change and Camera Tampering
  </Tooltip>
);

const HealthMonitoring = ({ isDarkTheme }) => {
  //region useState start
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [refreshButton, setRefreshButton] = useState(false);
  const [cameraOnlineCount, setCameraOnlineCount] = useState(0);
  const [cameraOfflineCount, setCameraOfflineCount] = useState(0);
  const [nvrOnlineCount, setNVROnlineCount] = useState(0);
  const [nvrOfflineCount, setNVROfflineCount] = useState(0);
  const [hddOnlineCount, setHDDOnlineCount] = useState(0);
  const [hddOfflineCount, setHDDOfflineCount] = useState(0);
  const [totalCameraBlockCount, setTotalCameraBlockCount] = useState(0);
  const [systemHealthDevicesOfflineCount, setSystemHealthDevicesOfflineCount] = useState(0);
  const [systemHealthDevicesOnlineCount, setSystemHealthDevicesOnlineCount] = useState(0);
  const [onlinePercent, setOnlinePercent] = useState(0);
  const [top3OfflineDevices, setTop3OfflineDevices] = useState([]);
  const [show, setShow] = useState(false);
  const [panelOnlineCount, setPanelOnlineCount] = useState(0);
  const [panelOfflineCount, setPanelOfflineCount] = useState(0);
  const [sensorStatus, setSensorStatus] = useState([]);
  const [sensorCount, setSensorCount] = useState([]);
  const [bgcolour, setBgColour] = useState([]);
  const [sensorsum, setSensorSum] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [resetHmRefresh, setResetHmRefresh] = useState(false);
  const [nonIntegratedDevicesCount, setNonIntegratedDevicesCount] = useState(0);
  //region useState end

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  //region useEffect Start
  useEffect(() => {
    getAllWidgetData();
  }, [])

  useEffect(() => {
    const interval = setInterval(async () => {
      refreshData();
    }, HEALTHMONITORING_INTERVAL_TIMER);
    return () => {
      clearInterval(interval)
    }
  }, []);

  //region useEffcet End

  //region functions start
  const handleShow = () => setShow(true);

  const refreshData = () => {
    setRefreshButton(true);
    getAllWidgetData();
  }

  const getAllWidgetData = () => {
   setLoading(true);
    let tempUser = JSON.parse(localStorage.getItem('user'));
    let userUniqueID = tempUser.UserUniqueID
    try {
      AllWidgetData(userUniqueID).then((resp) => {
        if (resp != undefined) {
          if (resp.Status == "success") {
            let refreshDateHM = new Date();
            //setResetHmRefresh(AllDateFormat(refreshDateHM))
            console.log("lastRefresh", (AllDateFormat(refreshDateHM)))
            let onlineCount = 0;
            let offlineCount = 0;
            let total = 0
            let sum = 0;
            let totalSystemHealthOffline = resp.Data.filter(item => item.Description == "System Health" && item.Status == "0")
            setSystemHealthDevicesOfflineCount(totalSystemHealthOffline[0]?.Count || 0)
            offlineCount = totalSystemHealthOffline[0]?.Count || 0
            let totalSystemHealthOnline = resp.Data.filter(item => item.Description == "System Health" && item.Status == "1")
            setSystemHealthDevicesOnlineCount(totalSystemHealthOnline[0]?.Count || 0)
            onlineCount = totalSystemHealthOnline[0]?.Count || 0

            let nonIntegratedDevices = resp.Data.filter(item => item.Description == "System Health" && item.Status == "-1");
            setNonIntegratedDevicesCount((nonIntegratedDevices[0]?.Count || 0));
            let tempCount = 0
            let tempOfflineNVR = resp.Data.filter(item => (item.Description == "NVR" || item.Description == "DVR" || item.Description == "Hybrid") && item.Status == "0")
            if (tempOfflineNVR != undefined && tempOfflineNVR.length > 0) {
              tempOfflineNVR?.map(x => tempCount += parseInt(x.Count))
            }
            // setNVROfflineCount(tempOfflineNVR[0]?.Count || 0)
            setNVROfflineCount(tempCount)

            tempCount = 0
            let tempOnlineNVR = resp.Data.filter(item => (item.Description == "NVR" || item.Description == "DVR" || item.Description == "Hybrid") && item.Status == "1")
            if (tempOnlineNVR != undefined && tempOnlineNVR.length > 0) {
              tempOnlineNVR?.map(x => tempCount += parseInt(x.Count))
            }
            // setNVROnlineCount(tempOnlineNVR[0]?.Count || 0)
            setNVROnlineCount(tempCount)

            let tempOfflineCamera = resp.Data.filter(item => item.Description == "Camera" && item.Status == "0")
            setCameraOfflineCount(tempOfflineCamera[0]?.Count || 0)
            let tempOnlineCamera = resp.Data.filter(item => item.Description == "Camera" && item.Status == "1")
            setCameraOnlineCount(tempOnlineCamera[0]?.Count || 0)

            let tempOfflineHDD = resp.Data.filter(item => item.Description == "HDD" && item.Status == "0")
            setHDDOfflineCount(tempOfflineHDD[0]?.Count || 0)
            let tempOnlineHDD = resp.Data.filter(item => item.Description == "HDD" && item.Status == "1")
            setHDDOnlineCount(tempOnlineHDD[0]?.Count || 0)

            let totalCameraBlock = resp.Data.filter(item => item.Description == "CameraBlockAlert")
            setTotalCameraBlockCount(totalCameraBlock[0]?.Count || 0)

            let top3OfflineDevices = resp.Data.filter(item => item.Description == "OfflineDevice")
            setTop3OfflineDevices(top3OfflineDevices)

            let tempOfflinePanel = resp.Data.filter(item => item.Description == "PanelStatus" && item.Status == "0")
            setPanelOfflineCount(tempOfflinePanel[0]?.Count || 0)
            let tempOnlinePanel = resp.Data.filter(item => item.Description == "PanelStatus" && item.Status == "1")
            setPanelOnlineCount(tempOnlinePanel[0]?.Count || 0)

            let tempSensorStatus = resp.Data.filter(item => item.Description == "SensorStatus")
            let sensorCount = tempSensorStatus.map(item => parseInt(item.Count))
            setSensorCount(sensorCount)
            for (let i = 0; i < sensorCount.length; i++) {
              sum += sensorCount[i];
            }
            setSensorSum(sum)

            let sensorStatusLocal = tempSensorStatus.map(item => item.Status)
            setSensorStatus(sensorStatusLocal)

            let backgroundColor = []
            for (let i = 0; i < tempSensorStatus.length; i++) {
              if (tempSensorStatus[i].Status == "Zone Restoral") {
                backgroundColor.push("#7BB526")
              } else if (tempSensorStatus[i].Status == "Zone Alert") {
                backgroundColor.push("#F32C22");
              } else if (tempSensorStatus[i].Status == "No Information") {
                backgroundColor.push("#131415");
              }
            }
            setBgColour(backgroundColor)
            setLoading(false);

            total = parseInt(onlineCount) + parseInt(offlineCount)
            let percent = (onlineCount / total) * 100;
            if (isNaN(percent)) {
              setOnlinePercent(0);
            } else {
              setOnlinePercent(percent.toFixed(0));
            }
          } else {
            setLoading(false);
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }
  //region functions end

  //region tooltip UI start
  const tooltip1 = (
    <Tooltip id="tooltip1">
      <div style={{ textAlign: "left" }}>
        <p style={{ maxWidth: "150px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }} className="mb-0">{top3OfflineDevices[0]?.Count.slice(0, -3)}</p>
        <div style={{ height: "auto" }}>
          <p className="deviceNameTooltipText mb-0">Device Name - </p>
          <p className="deviceNameTooltipName mb-0">{top3OfflineDevices[0]?.DeviceName}</p>
        </div>
        <div style={{ height: "auto" }}>
          <p className="siteNameTooltipText mb-0">Site Name - </p>
          <p className="siteNameTooltipName mb-0">{top3OfflineDevices[0]?.SIteName}</p>
        </div>
      </div>
    </Tooltip>
  );

  const tooltip2 = (
    <Tooltip id="tooltip2">
      <div style={{ textAlign: "left" }}>
        <p style={{ maxWidth: "150px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }} className="mb-0">{top3OfflineDevices[1]?.Count.slice(0, -3)}</p>
        <div style={{ height: "auto" }}>
          <p className="deviceNameTooltipText mb-0">Device Name - </p>
          <p className="deviceNameTooltipName mb-0">{top3OfflineDevices[1]?.DeviceName}</p>
        </div>
        <div style={{ height: "auto" }}>
          <p className="siteNameTooltipText mb-0">Site Name - </p>
          <p className="siteNameTooltipName mb-0">{top3OfflineDevices[1]?.SIteName}</p>
        </div>
      </div>
    </Tooltip>
  );

  const tooltip3 = (
    <Tooltip id="tooltip3">
      <div style={{ textAlign: "left" }}>
        <p className="timeTooltipCount mb-0">{top3OfflineDevices[2]?.Count.slice(0, -3)}</p>
        <div style={{ height: "auto" }}>
          <p className="deviceNameTooltipText mb-0">Device Name - </p>
          <p className="deviceNameTooltipName mb-0">{top3OfflineDevices[2]?.DeviceName}</p>
        </div>
        <div style={{ height: "auto" }}>
          <p className="siteNameTooltipText mb-0">Site Name - </p>
          <p className="siteNameTooltipName mb-0">{top3OfflineDevices[2]?.SIteName}</p>
        </div>
      </div>
    </Tooltip>
  );
  //region tooltip UI end

  const items = [
    <div className="item" data-value="1" onClick={() => setActiveIndex(0)}>
      <div>
        <div className="CardBoxWithBorder">
          <div className='CardBoxContent float-left w-100' style={{margin:"10px 20px"}}>
            <div className="IntegratedTextDiv">
              <div className="CardBoxImage" style={{ backgroundColor: "#1e93c2" }}>
                <img src={SystemHealth} alt="System health" />
              </div>
              <h4 className="SystemHealthCardText">System Health</h4>

              <div style={{ float: "left" }}>
                <div style={{ textAlign: "center" }}>
                  {
                    loading
                      ? (
                        <>
                          <Skeleton width={50} className="statusValueText" />
                          <Skeleton width={70} className="statusValueP" />
                        </>
                      )
                      : (<>
                        <p className="statusValueP">{(parseInt(systemHealthDevicesOfflineCount)) + (parseInt(systemHealthDevicesOnlineCount)) + (parseInt(nonIntegratedDevicesCount))}</p>
                        <p className="statusValueText">Total Devices</p>
                      </>)
                  }
                </div>

                <div className="mt-2" style={{ display: "flex" }}>
                  <div style={{ textAlign: "center" }}>
                    {
                      loading
                        ? (
                          <>
                            <Skeleton width={50} className="statusValueText" />
                            <Skeleton width={70} className="statusValueP" />
                          </>
                        )
                        : (<>
                          <p className="statusValueP" style={{ fontSize: "20px" }}>{(parseInt(systemHealthDevicesOfflineCount)) + (parseInt(systemHealthDevicesOnlineCount))}</p>
                          <p className="statusValueText" style={{ fontSize: "12px" }}>Integrated Devices</p>
                        </>)
                    }
                  </div>
                  <div style={{ textAlign: "center" }} className="ml-2">
                    {
                      loading
                        ? (
                          <>
                            <Skeleton width={50} className="statusValueText" />
                            <Skeleton width={70} className="statusValueP" />
                          </>
                        )
                        : (<>
                          <p className="statusValueP" style={{ fontSize: "20px" }}>{nonIntegratedDevicesCount}</p>
                          <p className="statusValueText" style={{ fontSize: "12px" }}>Non Integrated Devices</p>
                        </>)
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="IntegratedChartDiv">
              <div className="DonutChartWidthDiv" style={{ width: "100%",float:"right", position:"relative" }}>
                <div style={{ width: "100%", height: "140px" }}>
                  {
                    loading && !onlinePercent
                      ? null
                      :
                      <>
                        {/* <HealthPercent systemHealthDevicesOnlineCount={systemHealthDevicesOnlineCount} systemHealthDevicesOfflineCount={systemHealthDevicesOfflineCount} onlinePercent={onlinePercent} isDarkTheme={isDarkTheme} /> */}
                        <HealthIntegratedChart isDarkTheme={isDarkTheme} systemHealthDevicesOfflineCount={systemHealthDevicesOfflineCount} systemHealthDevicesOnlineCount={systemHealthDevicesOnlineCount} nonIntegratedDevicesCount={nonIntegratedDevicesCount} />
                        {/* <SampleChart/> */}
                      </>
                  }
                </div>
              </div>
            </div>

           {/*  <div className='CardBoxText' style={{ display: "flex", justifyContent: "space-between", width:"85%" }}>
              <div>
                <div className="StatusText">
                  <div className="StatusValueDiv" style={{ width: "auto" }}>
                    {
                      loading
                        ? (
                          <>
                            <Skeleton width={70} className="statusValueText" />
                            <Skeleton width={50} className="statusValueP" />
                          </>
                        )
                        : (<>
                          <p className="statusValueP">{(parseInt(systemHealthDevicesOfflineCount)) + (parseInt(systemHealthDevicesOnlineCount))}</p>
                          <p className="statusValueText">Total Devices</p>
                        </>)
                    }
                    <p className="integratedDeviceText">Integrated devices count only</p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <HealthCard
          cardName="Storage Device Status"
          imageName={HDDstatus}
          loading={loading}
          altImageName="HDD status"
          bgColor="#561400"
          offlineCount={hddOfflineCount}
          onlineCount={hddOnlineCount}
          totalCount={(parseInt(hddOfflineCount)) + (parseInt(hddOnlineCount))}
        />
      </div>
    </div>,

    <div className="item" data-value="2">
      <div>
        <HealthCard
          cardName="DVR/NVR Status"
          imageName={DVRStatus}
          loading={loading}
          altImageName="DVR/NVR status"
          bgColor="#da8012"
          offlineCount={nvrOfflineCount}
          onlineCount={nvrOnlineCount}
          totalCount={(parseInt(nvrOfflineCount)) + (parseInt(nvrOnlineCount))}
        />
        <div className="CardBoxWithBorder">
          <div className='CardBoxContent float-left w-100'>
            <div className="CardBoxImage" style={{ backgroundColor: "#1d8cdb" }}>
              <img src={CameraBlock} alt="Camera Block" />
            </div>
            <div className='CardBoxText' style={{ position: "relative", display: "block" }}>
              <div className="CameraInfoDiv" >
                <OverlayTrigger placement="left" overlay={tooltip}>
                  <button style={{ background: "transparent" }}>
                    {
                      isDarkTheme ?
                        <img alt="InfoBlue" src={InfoBlue} /> :
                        <img alt="InfoWhite" src={InfoBlueWhite} height="17" />
                    }

                  </button>
                </OverlayTrigger>

                <OverlayTrigger placement="right" overlay={<Tooltip>View details</Tooltip>}>
                  <button style={{ background: "transparent" }} onClick={handleShow}>
                    {
                      isDarkTheme ?
                        <img alt="OpenPopup" src={OpenPopupIconBlue} /> :
                        <img alt="OpenPopupWhite" src={OpenPopupIconBlueWhite} height="17p" />

                    }
                  </button>
                </OverlayTrigger>
              </div>

              <h4>Camera Tampering For Today</h4>

              <div className="OnlySingleValue">
                {loading ? (
                  <Skeleton width={50} />
                ) : (
                  <p className="statusValueP">{totalCameraBlockCount}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,

    <div className="item" data-value="3">
      <div>
        <HealthCard
          cardName="Camera Status"
          imageName={CameraStatus}
          loading={loading}
          altImageName="Camera status"
          bgColor="#6459d6"
          offlineCount={cameraOfflineCount}
          onlineCount={cameraOnlineCount}
          totalCount={(parseInt(cameraOfflineCount)) + (parseInt(cameraOnlineCount))}
        />
        <div className="CardBoxWithBorder">
          <div className='CardBoxContent float-left w-100'>
            <div className="CardBoxImage" style={{ backgroundColor: "#27ad93" }}>
              <img src={FaultyDevice} alt="Faulty Device" />
            </div>
            <div className='CardBoxText'>

              <div>
                <h4 style={{ marginTop: "0px" }}>Top 3 Offline Camera Devices For Today</h4>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                <div className="StatusText1">
                  <div className="StatusValueDiv">
                    <p className="statusValueP">
                      <span>{top3OfflineDevices[1]?.Count.slice(0, -3)}</span>
                      <OverlayTrigger placement="bottom" overlay={tooltip2}>
                        <span className="InfoImg">
                          <button style={{ background: "transparent" }}>
                            {
                              isDarkTheme ?
                                <img alt="InfoBlue" src={InfoBlue} /> :
                                <img alt="InfoWhite" src={InfoBlueWhite} height="17" />
                            }
                          </button>
                        </span>
                      </OverlayTrigger>
                    </p>
                    <div className="mb-1">
                      <div className="OfflineDeviceDiv">
                        <img alt="Device" src={DeviceGrayIcon} />
                        <p className="offlineDeviceText">{top3OfflineDevices[1]?.DeviceName}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="StatusText1">
                  <div className="StatusValueDiv">
                    <p className="statusValueP">
                      <span>{top3OfflineDevices[2]?.Count.slice(0, -3)}</span>
                      <OverlayTrigger placement="bottom" overlay={tooltip3}>
                        <span className="InfoImg">
                          <button style={{ background: "transparent" }}>
                            {
                              isDarkTheme ?
                                <img alt="InfoBlue" src={InfoBlue} /> :
                                <img alt="InfoWhite" src={InfoBlueWhite} height="17" />
                            }
                          </button>
                        </span>
                      </OverlayTrigger>
                    </p>
                    <div className="mb-1">
                      <div className="OfflineDeviceDiv">
                        <img alt="Device" src={DeviceGrayIcon} />
                        <p className="offlineDeviceText">{top3OfflineDevices[2]?.DeviceName}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="StatusText1">
                  <div className="StatusValueDiv">
                    <p className="statusValueP">
                      <span>{top3OfflineDevices[0]?.Count.slice(0, -3)}</span>
                      <OverlayTrigger placement="bottom" overlay={tooltip1}>
                        <span className="InfoImg">
                          <button style={{ background: "transparent" }}>
                            {
                              isDarkTheme ?
                                <img alt="InfoBlue" src={InfoBlue} /> :
                                <img alt="InfoWhite" src={InfoBlueWhite} height="17" />
                            }
                          </button>
                        </span>
                      </OverlayTrigger>
                    </p>
                    <div className="mb-1">
                      <div className="OfflineDeviceDiv">
                        <img alt="Device" src={DeviceGrayIcon} />
                        <p className="offlineDeviceText">{top3OfflineDevices[0]?.DeviceName}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Old UI */}
              <div style={{ display: "none" }}>
                {
                  loading
                    ?
                    <>
                      <div className="StatusValueDiv">
                        <div className="mb-1">
                          <Skeleton width={70} />
                          <Skeleton width={70} />
                        </div>
                        <Skeleton width={70} className="statusValueP" />

                      </div>
                      <div className="StatusValueDiv">
                        <div className="mb-1">
                          <Skeleton width={70} />
                          <Skeleton width={70} />
                        </div>
                        <Skeleton width={70} className="statusValueP" />
                      </div>
                      <div className="StatusValueDiv">
                        <div className="mb-1">
                          <Skeleton width={70} />
                          <Skeleton width={70} />
                        </div>
                        <Skeleton width={70} className="statusValueP" />
                      </div>
                    </>
                    : <>
                      {
                        top3OfflineDevices.map((item) => (
                          <HealthOfflineDevices key={item.DeviceName} item={item} />
                        ))
                      }
                    </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,

    <div className="item" data-value="3">
      <div>
        <HealthCard
          cardName="Panel Status"
          imageName={PanelStatus}
          loading={loading}
          altImageName="Panel status"
          bgColor="#776700"
          offlineCount={panelOfflineCount}
          onlineCount={panelOnlineCount}
          totalCount={(parseInt(panelOfflineCount)) + (parseInt(panelOnlineCount))}
        />
        <div className="CardBoxWithBorder">
          <div className='CardBoxContent float-left w-100'>
            <div className="CardBoxImage" style={{ backgroundColor: "#98309F" }}>
              <img src={SensorStatusIcon} alt="System health" />
            </div>
            <div className='CardBoxText' style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h4>Sensor States</h4>
                {/* <div className="StatusText">
                                                  <div className="StatusValueDiv" style={{width:"auto"}}>
                                                      {
                                                        loading 
                                                        ? (
                                                          <>
                                                          <Skeleton width={70} className="statusValueText" />
                                                          <Skeleton width={50} className="statusValueP" />
                                                          </>
                                                        )
                                                        : (<>
                                                          <p className="statusValueP">{(parseInt(systemHealthDevicesOfflineCount)) + (parseInt(systemHealthDevicesOnlineCount))}</p>                            
                                                          <p className="statusValueText">Total Devices</p>
                                                        </>)
                                                      }

                                                  </div>
                                              </div> */}
              </div>

              <div className="DonutChartWidthDiv">
                <div style={{ width: "100%", height: "125px", display: "flex", justifyContent: "flex-end" }}>
                  {
                    loading && !onlinePercent
                      ? null
                      : <SensorStatus isDarkTheme={isDarkTheme} sensorStatus={sensorStatus} sensorCount={sensorCount} bgcolour={bgcolour} sensorsum={sensorsum} />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ]

  const slidePrev = () => setActiveIndex(activeIndex - 1);
  const slideNext = () => {
    setActiveIndex(activeIndex + 1)
  };

  return (
    <>
      <div className='MainContentMargin'>
        <div className="col-lg-12 HMHeader mt-3 mb-3">
          <div className="NameHeading1" style={{ width: "100%" }}>
            <p className="config-tool-head" style={{ float: "left" }}>
              Health Monitoring
            </p>
            <div className="RefreshText" style={{ display: "flex", justifyContent: "flex-end" }}>
              {/* <h4>page refreshed on - {resetHmRefresh}</h4> */}

              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    Refresh
                  </Tooltip>
                }
              >
               <button
                  className="DataAccessEdit mt-1"
                  style={{ width: "40px", float: "right" }}
                  onClick={refreshData}
                  disabled={loading}
                >
                  <img src={RefreshBtnIcon} alt="Refresh" />
                  {/* <span className="ViewUpdate ml-1">Update Data</span> */}
                </button>

              </OverlayTrigger>
            </div>

            <div className="ml-3" style={{ marginBottom: "3px" }}>
            </div>
          </div>
        </div>

        <div className="HeightScrollCameraGrid HeightScrollSitesGrid" style={{ position: "relative" }}>
          <div className="HMWidgetMainDiv">
            <AliceCarousel
              key={activeIndex}
              items={items}
              responsive={responsive}
              disableDotsControls
              disableButtonsControls
              activeIndex={activeIndex}
            />
            <div key="btns" className="b-refs-buttons" style={{ textAlign: "center", position: "relative", top: "1%" }}>
              <button disabled={activeIndex === 0} className="CarouselPrev" onClick={slidePrev}><img style={{ height: "13px", width: "15px" }} alt="Left" src={ArrowLeft} /></button>
              <button disabled={activeIndex === 1} className="CarouselNext" onClick={slideNext}><img style={{ height: "13px", width: "15px" }} alt="Right" src={ArrowRight} /></button>
            </div>
          </div>
          <HealthTable tableLoading={tableLoading} setTableLoading={setTableLoading} refreshButton={refreshButton} setRefreshButton={setRefreshButton} isDarkTheme={isDarkTheme} />
        </div>
        {
          show
            ? <HealthEvents show={show} setShow={setShow} isDarkTheme={isDarkTheme} />
            : null
        }
      </div>
    </>
  );
};

export default HealthMonitoring;