import { db } from '../services/firebase'


let LoggedInUser = JSON.parse(localStorage.getItem('user'))

//#region firebase realtime database

let user_uid = LoggedInUser?.UserUniqueID //localStorage.getItem('UserUniqueID');

const getAll = (uid) => {
    //#region firebase realtime database
    return db.ref(`user/` + uid + `/Notifications`).orderByChild('time').limitToLast(10)
};

const DeleteConversation = (alertid) => {
    return db.ref(`user/` + user_uid + '/Conversations/' + alertid).remove()
}

const getertmessage = () => {
    return db.ref(`user/` + user_uid + '/Conversations')
};
// const create = (notificationID, data) => {
//     return db.ref(`user/admin/${notificationID}`).set(data)//.push(data);
// };

const removeOne = (key) => {
    return db.ref(`user/` + user_uid).child(key).remove();
}

const removeAll = () => {
    return db.ref(`user/` + user_uid).remove()
}
const updateStatus = (key, value) => {
    let LoggedInUserLocal = JSON.parse(localStorage.getItem('user'))
//#region firebase realtime database
    let user_uid_local = LoggedInUserLocal?.UserUniqueID //localStorage.getItem('UserUniqueID');
    console.log("markAsRead", user_uid_local, key, value);
    return db.ref('user/' + user_uid_local + '/Notifications/' + key + '/').update({ status: value })
}

//#endregion

export {
     getAll, 
     DeleteConversation, 
     getertmessage, 
     removeOne, 
     removeAll, 
     updateStatus 
}