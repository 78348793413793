import React, { useState } from 'react'

// import cognyfloLogo from '../../assets/images/cognyfloLogo.png'

import { Modal } from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component'

import { ZeroCountAlert } from './ZeroCountAlert'
import AlertGrid from './AlertGrid'

export default function AlertGridView({
    extremeAlertLst,
    highAlertLst,
    moderateAlertLst,
    lowAlertsLst,
    severity,
    allAlerts,
    onAddAlertChat,
    resetInfiniteScroll,
    selectedtablist,
    fetchMoreData,
    handleScroll
}) {
    /* region useState start */
    const [isimage, setisimage] = useState(false)
    const [isOpenmodal, setisOpenmodal] = useState(false)
    const [snapshoturl, setsnapshoturl] = useState([])
    /* region useState start */
    
    const AddMixpanelEvent = (id) => {
        try{
            //mixpanel.track("Clicks on Similar Alerts", { "AlertId": id })
        }catch(error){
            console.error(error)
        }
    }

    const ClosePreview = () => {
        setisOpenmodal(false)
    }
    const handleChange = (isimg, snapurl) => {
        setisimage(isimg)
        setisOpenmodal(true)
        setsnapshoturl(snapurl)
    }

    return (
        <>
            <Modal
                show={isOpenmodal}
                dialogClassName={"activityUpdateModel"}
                onHide={ClosePreview}
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton style={{ padding: "0 1rem", borderBottom: "0" }}></Modal.Header>
                <Modal.Body>
                    <div id="EvidenceViewContainer">
                        <div style={{ display: (isimage ? 'block' : 'none') }}>
                            <img
                                style={{ height: "500px", width: "465px" }}
                                src={snapshoturl}
                                alt='snapshot image'
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* alert grid view start */}
            <div id="scrollableDiv" className="infinite-scroll-div" onScroll={handleScroll}>
            <InfiniteScroll
                        key={resetInfiniteScroll}
                        dataLength={selectedtablist.length}
                        next={fetchMoreData}
                        hasMore={true}
                        // loader={<h4>Loading...</h4>}
                        scrollableTarget="scrollableDiv"
                    >
            <div className="col-lg-12 pr-2 pt-1 MainAlertsFridDiv" id="extreme-severity">
                {
                    severity == 'extreme' &&
                    extremeAlertLst &&
                    extremeAlertLst.map((item, index) =>
                        <AlertGrid
                            key={item.alertId}
                            id={item.alertId}
                            alert={item}
                            onAddAlertChat={onAddAlertChat} 
                            AddMixpanelEvent={AddMixpanelEvent}
                            handleChange={handleChange}/>//method name
                    )
                }
                {
                    severity == 'high' &&
                    highAlertLst &&
                    highAlertLst.map((item, index) =>
                        <AlertGrid
                            key={item.alertId}
                            id={item.alertId}
                            alert={item}
                            onAddAlertChat={onAddAlertChat} 
                            AddMixpanelEvent={AddMixpanelEvent}
                            handleChange={handleChange}/>
                    )
                }
                {
                    severity == 'moderate' &&
                    moderateAlertLst &&
                    moderateAlertLst.map((item, index) =>
                        <AlertGrid
                            key={item.alertId}
                            id={item.alertId}
                            alert={item}
                            onAddAlertChat={onAddAlertChat} 
                            AddMixpanelEvent={AddMixpanelEvent}
                            handleChange={handleChange}/>
                    )
                }
                {
                    severity == 'low' &&
                    lowAlertsLst &&
                    lowAlertsLst.map((item, index) =>
                        <AlertGrid
                            key={item.alertId}
                            id={item.alertId}
                            alert={item}
                            onAddAlertChat={onAddAlertChat} 
                            AddMixpanelEvent={AddMixpanelEvent}
                            handleChange={handleChange}/>
                    )
                }
                {
                    severity == 'all' &&
                    allAlerts &&
                    allAlerts.map((item, index) =>
                        <AlertGrid
                            key={item.alertId}
                            id={item.alertId}
                            alert={item}
                            onAddAlertChat={onAddAlertChat} 
                            AddMixpanelEvent={AddMixpanelEvent}
                            handleChange={handleChange}/>
                    )
                }

                </div>
                <div className="col-lg-12 pr-2 pt-1">
                    {
                        severity == "extreme" && extremeAlertLst.length == 0 && <ZeroCountAlert  type={severity}/>
                    }
                    {
                        severity == "high" && highAlertLst.length == 0 && <ZeroCountAlert  type={severity}/>
                    }
                    {
                        severity == "moderate" && moderateAlertLst.length == 0 && <ZeroCountAlert  type={severity}/>
                    }
                    {
                        severity == "low" && lowAlertsLst.length == 0 && <ZeroCountAlert  type={severity}/>
                    }
                    {
                        severity == "all" && allAlerts.length == 0 && <ZeroCountAlert  type={severity}/>
                    }
                </div>
                    </InfiniteScroll>
            </div>
            {/* alert grid view end */}
        </>
    )
}

