import React, {useState} from 'react'
// import cognyfloLogo1 from '../../assets/images/cognyfloLogo1.png'
import { Modal } from 'react-bootstrap'
import { ZeroCountAlert } from './ZeroCountAlert'
import InfiniteScroll from 'react-infinite-scroll-component'
import AlertList from './AlertList'

export default function AlertListView({
    severity,
    extremeAlertLst,
    highAlertLst,
    moderateAlertLst,
    lowAlertsLst,
    allAlerts,
    onAddAlertChat,
    resetInfiniteScroll,
    selectedtablist,
    fetchMoreData,
    handleScroll
}) {
    const [isimage, setisimage] = useState(false)
    const [isOpenmodal, setisOpenmodal] = useState(false)
    const [snapshoturl, setsnapshoturl] = useState([])

    const ClosePreview = () => {
        setisOpenmodal(false)
    }
    const handleChange = (isimg, snapurl) => {
        setisimage(isimg)
        setisOpenmodal(true)
        setsnapshoturl(snapurl)
    }

    return (
        <>
        <Modal
                show={isOpenmodal}
                dialogClassName={"activityUpdateModel"}
                onHide={ClosePreview}
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton style={{ padding: "0 1rem", borderBottom: "0" }}></Modal.Header>
                <Modal.Body>
                    <div id="EvidenceViewContainer">
                        <div style={{ display: (isimage ? 'block' : 'none') }}>
                            <img
                                style={{ height: "500px", width: "465px" }}
                                src={snapshoturl}
                                alt='sanpshot image'
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <div className="col-lg-12 pr-0">
                <div className="MainListDiv">
                    <div className="newListofAlert">
                        <div className="ListMain" style={{height: "55px"}}>
                            <div className="headerOperateByDiv">
                                <p className="OperateBy">
                                    Code
                                </p>
                            </div>
                            <div className="headerOperateByDiv">
                                <p className="OperateBy">
                                    ID
                                </p>
                            </div>
                            <div className="headerOperateByDiv">
                                <p className="OperateBy">
                                    Site
                                </p>
                            </div>
                            <div className="headerOperateByDiv">
                            <p className="OperateBy">
                                    Customer
                                </p>
                            </div>
                            <div className="headerOperateByDiv">
                            <p className="OperateBy">
                                    Device
                                </p>
                            </div>
                            <div className="headerOperateByDiv">
                            <p className="OperateBy">
                                    ACK By
                                </p>
                            </div>
                            <div className="headerOperateByDiv">
                                <p className="OperateBy">
                                    Date & Time
                                </p>
                            </div>
                            <div className="headerOperateByDiv">
                                <p className="OperateBy">
                                    Status
                                </p>
                            </div>
                            <div className="headerOperateByDiv">
                                <p className="OperateBy">
                                    Severity
                                </p>
                            </div>
                            <div className="headerOperateByDiv">
                                <p className="OperateBy">
                                    Type
                                </p>
                            </div>
                            <div className="headerOperateByDiv">
                                <p className="OperateBy">
                                    Actions
                                </p>
                            </div>
                            <div className="ChartsButtonDiv">
                                {/* <button className="AlertViewButton">
                                    <p>View Alert</p>
                                </button> */}
                            </div>
                        </div>
                    </div>
                    <div id="scrollableDivOne" className="infinite-scroll-div-list" onScroll={handleScroll}>
                    <InfiniteScroll
                        key={resetInfiniteScroll}
                        dataLength={selectedtablist.length}
                        next={fetchMoreData}
                        hasMore={true}
                        // loader={<h4>Loading...</h4>}
                        scrollableTarget="scrollableDivOne"
                    >
                    <div className="scrollbar" id="style-3">
                    
                        {
                            severity == 'extreme' &&
                            extremeAlertLst &&
                            extremeAlertLst.map((item, index) =>
                                <AlertList 
                                    key={item.alertId} 
                                    id={item.alertId} 
                                    alert={item} 
                                    onAddAlertChat={onAddAlertChat}
                                    handleChange={handleChange}/>
                            )
                        }

                        {
                            severity == 'high' &&
                            highAlertLst &&
                            highAlertLst.map((item, index) =>
                                <AlertList 
                                    key={item.alertId} 
                                    id={item.alertId} 
                                    alert={item} 
                                    onAddAlertChat={onAddAlertChat}
                                    handleChange={handleChange}/>
                            )
                        }

                        {
                            severity == 'moderate' &&
                            moderateAlertLst &&
                            moderateAlertLst.map((item, index) =>
                                <AlertList 
                                    key={item.alertId} 
                                    id={item.alertId} 
                                    alert={item}
                                    onAddAlertChat={onAddAlertChat}
                                    handleChange={handleChange} />
                            )
                        }

                        {
                            severity == 'low' &&
                            lowAlertsLst &&
                            lowAlertsLst.map((item, index) =>
                                <AlertList 
                                    key={item.alertId} 
                                    id={item.alertId} 
                                    alert={item} 
                                    onAddAlertChat={onAddAlertChat}
                                    handleChange={handleChange}/>
                            )
                        }
                        {
                            severity == 'all' &&
                            allAlerts &&
                            allAlerts.map((item, index) =>
                                <AlertList
                                    key={item.alertId}
                                    id={item.alertId}
                                    alert={item}
                                    onAddAlertChat={onAddAlertChat} 
                                    handleChange={handleChange}/>
                            )
                        }
                    </div>
                    <div>
                    {
                        severity == "extreme" && extremeAlertLst.length == 0 && <ZeroCountAlert  type={severity}/>
                    }
                    {
                        severity == "high" && highAlertLst.length == 0 && <ZeroCountAlert  type={severity}/>
                    }
                    {
                        severity == "moderate" && moderateAlertLst.length == 0 && <ZeroCountAlert  type={severity}/>
                    }
                    {
                        severity == "low" && lowAlertsLst.length == 0 && <ZeroCountAlert  type={severity}/>
                    }
                    {
                        severity == "all" && allAlerts.length == 0 && <ZeroCountAlert  type={severity}/>
                    }
                    </div>
                    </InfiniteScroll>
                    </div>
                </div>
            </div>
        </>
    )
}