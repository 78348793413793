import React from 'react'
import Previewicon from '../../assets/images/preview-icon.svg'
import { useHistory } from 'react-router-dom'
// import WorkflowPractice from './WorkflowPractice'

export default function ReviewStep({
    sites,
    deviceslist,
    eventTemplates,
    users,
    pageState
}) {

    let history = useHistory()
    return (
        <>
            <div className="col-lg-12">
                {
                    pageState == "Site Template" &&
                    <div className="pb-3" style={{ display: "inline-block", width: "100%",padding: "5px" }}>
                        <div className="review-section">
                            <h1 className="MainHeaderName" style={{ borderBottom: "0" }}>1.  Site Template</h1>
                            <>
                                <table className="table table-text-ext table-fixed">
                                    <colgroup>
                                        <col span="1" style={{ "width": "10%;" }} />
                                        <col span="1" style={{ "width": "300%;" }} />
                                        <col span="1" style={{ "width": "50%;" }} />
                                        <col span="1" style={{ "width": "10%;" }} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th colSpan="1" scope="col">Site ID</th>
                                            <th colSpan="2">Site Name</th>
                                            <th colSpan="5">Location</th>
                                            <th colSpan="2">Branch ID</th>
                                        </tr>
                                    </thead>
                                    <tbody className='review-table-body'>
                                        {
                                            sites &&
                                            sites.map((text) => (
                                                <tr id={text.SiteID}>
                                                    <td colSpan="1">{text.SiteID}</td>
                                                    <td colSpan="2">{text.SiteName}</td>
                                                    <td colSpan="5">{text.SiteAddress}</td>
                                                    <td colSpan="2" className='review-table-body-td'>{text.BranchID}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </>
                        </div>
                        {/* <WorkflowPractice /> */}
                    </div>
                }

                {pageState == "Devices" &&
                    <div className="pb-3" style={{ display: "inline-block", width: "100%" , padding: "5px"}}>
                        <div className="review-section">
                            <h1 className="MainHeaderName" style={{ borderBottom: "0" }}>2.  Devices</h1>
                            {/* <div className="card-body"> */}
                                <table className="table table-text-ext table-fixed">
                                    <thead>
                                        <tr>
                                        <th colSpan="1">Device Type</th>
                                            <th colSpan="1">Device Name</th>
                                            <th colSpan="1">Device ID</th>
                                            <th colSpan="1">Location</th>
                                            <th colSpan="1">Site ID</th>
                                            <th colSpan="1">Branch ID</th>
                                        </tr>
                                    </thead>
                                    <tbody className='review-table-body'>
                                        {
                                            deviceslist &&
                                            deviceslist.map((text) => (
                                                <tr id={text.ParentCameraID}>
                                                    <td colSpan="1">{text.DeviceTypeID && text.DeviceTypeID == 1? "Camera Device":"Panel Device"}</td>
                                                    <td colSpan="1">{text.CameraName}</td>
                                                    <td colSpan="1">{text.ParentCameraID}</td>
                                                    <td colSpan="1">{text.SiteName}</td>
                                                    <td colSpan="1">{text.SiteID}</td>
                                                    <td colSpan="1">{text.BranchID}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            {/* </div> */}
                        </div>
                    </div>
                }

                {pageState == "Alert Template" &&
                    <div className="pb-3" style={{ display: "inline-block", width: "100%" , padding : "5px"}}>
                        <div className="review-section">
                            <h1 className="MainHeaderName" style={{ borderBottom: "0" }}>3.  Alert Template</h1>
                            {/* <div className="card-body"> */}
                                <table className="table table-text-ext table-fixed">
                                    <thead>
                                        <tr>
                                            <th colSpan="1">Template Name</th>
                                            <th colSpan="1">Severity</th>
                                            {/* <th colSpan="1">Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody className='review-table-body'>
                                        {
                                            eventTemplates &&
                                            eventTemplates.map((text) => (
                                                <tr id={text.AlertTemplateId}>
                                                    <td>{text.TemplateName}</td>
                                                    <td>{text.SeverityTitle}</td>
                                                    {/* <td>
                                                        <a>
                                                            Preview
                                                            <img src={Previewicon} alt="" />
                                                        </a>
                                                    </td> */}
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            {/* </div> */}
                        </div>
                    </div>
                }
                {pageState == "Users" &&
                    <div className="pb-3" style={{ display: "inline-block", width: "100%" , padding:"5px"}}>
                        <div className="review-section">
                            <h1 className="MainHeaderName" style={{ borderBottom: "0" }}>4. Users</h1>
                            {/* <div className="card-body"> */}
                                <table className="table table-text-ext table-fixed">
                                    <thead>
                                        <tr>
                                            <th scope="col">User name</th>
                                            <th scope="col">User ID</th>
                                            <th scope="col">Role</th>
                                            <th scope="col" colSpan="2">Email</th>
                                            <th scope="col">Mobile</th>
                                        </tr>
                                    </thead>
                                    <tbody className='review-table-body'>
                                        {
                                            users &&
                                            users.map((text) => (
                                                <tr id={text.UserID || text.userId} key={text.UserID || text.userId}>
                                                    <td>{text.FirstName || text.FullName}</td>
                                                    <td>{text.UserID || text.userId}</td>
                                                    <td>{text.RoleName || text.roleName}</td>
                                                    <td colSpan="2">{text.Email || text.email}</td>
                                                    <td>{text.Phone || text.PhoneNumber}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            {/* </div> */}
                        </div>
                    </div>
                }
                <button type="submit" className="NextMoveButton float-end" onClick={() => history.push('/')} style={{marginLeft : "5px"}}>
                    <span className="ViewUpdate">Review & Finish</span>
                </button>
            </div>
        </>
    )
}
