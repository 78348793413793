import React from 'react'

export default function ActivityReportTable({ props }) {
    return <>
    <tr>
      <td>{props.alertId}</td>
      <td className="ReportTableFontBold">{props.siteName}</td>
      <td>{props.alertSource}</td>
      <td>{props.alertType}</td>
      <td className={`ReportTableAlertSeverity ${props.severityTitle.toLowerCase()}`}>{props.severityTitle}</td>
      <td>{props.actualTime}</td>
      <td>{props.ackOn}</td>
      <td>{props.operatorName}</td>
      <td className="ReportTableFontBold">{props.workflowName}</td>
      <td>{props.closedOn}</td>
      <td className="ReportTableFontBold">{props.timeToCloseAlert && props.timeToCloseAlert == 'NA' ? 'NA' : props.timeToCloseAlert} </td>
    </tr>
  </>
}
