import React, { useState, useEffect } from 'react'

import { SearchIcon, SearchIconWhite } from "../../assets/images";

import { Modal } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { DatePicker } from "rsuite";
import Skeleton from "react-loading-skeleton";

import { getCameraTampAlerts } from '../../services/healthMonitoringService';

import { useDataAccessPagination } from '../../CustomHooks/useDataAccessPagination';
import { ConvertDate } from '../../utils/utils';
import HealthEventsList from './HealthEventsList';

const HealthEvents = ({ show, setShow, isDarkTheme }) => {
    //region useState start
    const [totalDevices, setTotalDevices] = useState(0);
    const [deviceName, setDeviceName] = useState("");
    const [siteName, setSiteName] = useState("");
    const [eventType, setEventType] = useState("");
    const [eventDate, setEventDate] = useState("");
    const [totalString, setTotalString] = useState("");
    const [loading, setLoading] = useState(false);
    const [deviceData, setDeviceData] = useState([]);
    const [displayStartDate, setDisplayStartDate] = useState("");
    const [displayEndDate, setDisplayEndDate] = useState("");
    const [typingStart, setTypingStart] = useState(false);
    //region useState end

    const [nextClick, prevClick, pageCounter, currentPageNo, limit, totalPages, setCurrentPageNo, setTotalPages, maxPageAllowed, setPageCounter] = useDataAccessPagination(10, 1, 10, 1);

    //region useEffect start
    useEffect(() => {
        let startDate = ConvertDate(new Date(new Date().setHours(0, 0, 0, 0)));
        let endCurrDate = ConvertDate(new Date());
        displayDateSetters(startDate, endCurrDate)
    }, []);

    useEffect(() => {
        setLoading(true);
        let tempUser = JSON.parse(localStorage.getItem('user'));
        let userUniqueID = tempUser.UserUniqueID
        getEvents(userUniqueID, currentPageNo, totalString);
    }, [currentPageNo]);

    useEffect(() => {
        if (deviceName.length > 0) {
            setTypingStart(true);
        }
        if (deviceName === "" && typingStart) {
            // Call API Here
            searchDetails();
        }
    }, [deviceName]);

    useEffect(() => {
        if (siteName.length > 0) {
            setTypingStart(true);
        }
        if (siteName === "" && typingStart) {
            // Call API Here
            searchDetails();
        }
    }, [siteName]);
    //region useEffect end

    //region functions start
    const getEvents = (uniqueId, pageNumber, searchText) => {
        try {
            getCameraTampAlerts(uniqueId, pageNumber, searchText)
                .then((res) => {
                    if (res.Status == "success") {
                        setDeviceData(res.Data.CameraBlockAlertModels);
                        setTotalDevices(parseInt(res.Data.Total));
                        setTotalPages(parseInt(res.Data.Total));
                        setLoading(false);
                    } else {
                        setLoading(false);
                    }
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false);
                })
        } catch (error) {
            console.error(error);
        }
    }

    const getSearchData = (uniqueId, pageNumber, searchText) => {
        try {
            getCameraTampAlerts(uniqueId, pageNumber, searchText)
                .then((res) => {
                    if (res.Status == "success") {
                        setDeviceData(res.Data.CameraBlockAlertModels);
                        setTotalDevices(parseInt(res.Data.Total));
                        setTotalPages(parseInt(res.Data.Total));
                        setLoading(false);
                        setCurrentPageNo(1);
                        setPageCounter(1);
                    } else {
                        setLoading(false);
                    }
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false);
                })
        } catch (error) {
            console.log(error);
        }
    }

    const PaginationComponent = (currentPageNo) => {
        let numbers = [];
        if (maxPageAllowed - 2 != 0 && (currentPageNo === maxPageAllowed || currentPageNo === (maxPageAllowed - 1) || currentPageNo === (maxPageAllowed - 2))) {
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed - 2}
                    active={maxPageAllowed - 2 == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed - 2)}
                >
                    {maxPageAllowed - 2}
                </Pagination.Item>)
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed - 1}
                    active={maxPageAllowed - 1 == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed - 1)}
                >
                    {maxPageAllowed - 1}
                </Pagination.Item>)
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed}
                    active={maxPageAllowed == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed)}
                >
                    {maxPageAllowed}
                </Pagination.Item>)
        }
        else if (maxPageAllowed - 1 != 0 && (currentPageNo === maxPageAllowed || currentPageNo === (maxPageAllowed - 1))) {
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed - 1}
                    active={maxPageAllowed - 1 == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed - 1)}
                >
                    {maxPageAllowed - 1}
                </Pagination.Item>)
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed}
                    active={maxPageAllowed == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed)}
                >
                    {maxPageAllowed}
                </Pagination.Item>)
        }
        else {
            for (let i = currentPageNo; i < currentPageNo + 3; i++) {
                if (i <= maxPageAllowed) {
                    numbers.push(
                        <Pagination.Item
                            key={i}
                            active={i == currentPageNo}
                            onClick={() => setCurrentPageNo(i)}
                        >
                            {i}
                        </Pagination.Item>
                    )
                }
            }
        }
        return numbers;
    }

    const searchDetails = (data, type) => {
        let tempEventType = eventType.replace("0", "");
        if (type === "EVENT_TYPE") {
            tempEventType = data.replace("0", "");
        }
        let tempEventDate = eventDate;
        if (type === "DATETIME") {
            tempEventDate = data;
        }
        let obj = {
            "devicename": deviceName,
            "sitename": siteName,
            "eventtype": tempEventType,
            // "eventDate": tempEventDate
        }
        let stringText = JSON.stringify(obj);
        let tempStringText = stringText.replace(/["'{}]/g, "").replace(/[,]/g, ";")
        setTotalString(tempStringText);
        let tempUser = JSON.parse(localStorage.getItem('user'));
        let userUniqueID = tempUser.UserUniqueID
        getSearchData(userUniqueID, currentPageNo, tempStringText);
        //Call API Below
    }

    const onSelectEvent = (e) => {
        setEventType(e.target.value);
        searchDetails(e.target.value, "EVENT_TYPE")
    }

    const dateTimeHandler = (e) => {
        let selectedDate = ConvertDate(new Date(e)).replaceAll(":", "-");
        setEventDate(selectedDate);
        searchDetails(selectedDate, "DATETIME");
    }

    const displayDateSetters = (startDate, endCurrDate) => {
        let one = startDate.split(" ");
        let tempOne = `${one[0].split("-")[2]}-${one[0].split("-")[1]}-${one[0].split("-")[0]}`;
        setDisplayStartDate(`${tempOne} ${one[1]}`);
        let two = endCurrDate.split(" ");
        let tempTwo = `${two[0].split("-")[2]}-${two[0].split("-")[1]}-${two[0].split("-")[0]}`;
        setDisplayEndDate(`${tempTwo} ${two[1]}`);
    }

    const uniqueKey = (item) => {
        return `${item.DeviceName}_${item.EventDateTime}`
    }
    //region functions end
    return (
        <Modal
            size="xl"
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="healthMonitorModel CameraTamperingModel"
            backdrop="static"
        >
            <>
                <Modal.Header closeButton>
                    <Modal.Title
                        id="example-custom-modal-styling-title"
                        className="deviceModelHeader"
                    >
                        <p style={{ fontSize: "15px", marginBottom: "0px" }}>Camera Tampering Events</p>
                        <div style={{ display: "flex", alignItems: "flex-start" }}>
                        </div>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <div>
                            <p className='TrendSelectionDateP'><span className='FromDateFontSize'>From : </span> {displayStartDate}
                                <span className='ml-3 FromDateFontSize'>To: </span>  {displayEndDate} </p>
                            <table className="table table-text-ext table-fixed report-table healthmonitoringTable" id="alert-activity-report">
                                <thead className="ReportTableHeader ReportTableHeadReport">
                                    <tr>
                                        <th>
                                            <p>Device Name(s)</p>
                                            <div className="SearchTextDiv StatusSearchText">
                                                <input
                                                    className="SearchViewInput"
                                                    type="search"
                                                    placeholder="Search"
                                                    value={deviceName}
                                                    onChange={(e) => setDeviceName(e.target.value)}
                                                />
                                                {
                                                    isDarkTheme ?
                                                        <img className="EyeIcon" alt="Search-icon" src={SearchIcon} onClick={() => searchDetails()} /> :
                                                        <img className="EyeIcon" alt="Search-icon" src={SearchIconWhite} height="15" onClick={() => searchDetails()} />
                                                }
                                            </div>
                                        </th>
                                        <th>
                                            <p>Site Name(s)</p>
                                            <div className="SearchTextDiv StatusSearchText">
                                                <input
                                                    className="SearchViewInput"
                                                    type="search"
                                                    placeholder="Search"
                                                    value={siteName}
                                                    onChange={(e) => setSiteName(e.target.value)}
                                                />
                                                {
                                                    isDarkTheme ?
                                                        <img className="EyeIcon" alt="Search-icon" src={SearchIcon} onClick={() => searchDetails()} /> :
                                                        <img className="EyeIcon" alt="Search-icon" src={SearchIconWhite} height="15" onClick={() => searchDetails()} />
                                                }
                                            </div>
                                        </th>
                                        <th>
                                            <p>Event Type</p>
                                            <div className="SearchTextDiv StatusSearchText">
                                                <select
                                                    required
                                                    value={eventType}
                                                    onChange={(e) => onSelectEvent(e)}
                                                    className="ArrowSelect HMTableSelect">
                                                    <option key="0" disabled={false} value="0">
                                                        - Select Data -
                                                    </option>
                                                    <option key="1" value="Camera Block">
                                                        Camera Block
                                                    </option>
                                                    <option key="2" value="Camera Tamper">
                                                        Camera Tamper
                                                    </option>
                                                    <option key="3" value="Camera View Change">
                                                        Camera View Change
                                                    </option>
                                                </select>
                                            </div>
                                        </th>
                                        <th>
                                            <p>Event Date Time</p>
                                            <div className="mt-1">
                                                <DatePicker
                                                    format="dd-MM-yyyy HH:mm:ss"
                                                    placement="autoVerticalEnd"
                                                    disabled={true}
                                                    onOk={(e) => dateTimeHandler(e)}
                                                />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        loading
                                            ?
                                            (<tr>
                                                <td><Skeleton className="width60pct" /></td>
                                                <td><Skeleton className="width60pct" /></td>
                                                <td><Skeleton className="width60pct" /></td>
                                                <td><Skeleton className="width60pct" /></td>
                                            </tr>)
                                            :
                                            (
                                                <>
                                                    {
                                                        deviceData && (
                                                            deviceData?.length > 0 ?
                                                                deviceData.map((item) => (
                                                                    <HealthEventsList item={item} key={uniqueKey(item)} />
                                                                )) : <div className='noRecordFound'><p className='noRecordFoundText'>No Record Found.</p></div>
                                                        )
                                                    }
                                                    {
                                                        !deviceData && (
                                                            <div className="no-data-found">No Record Available.</div>
                                                        )
                                                    }
                                                </>
                                            )
                                    }
                                </tbody>

                                {
                                    totalPages == 0 || totalPages <= limit
                                        ? <div className="PaginationDiv mb-2 mt-2">
                                            <h5 className='PageNumbers'>{totalDevices == 0 ? 0 : 1} - {totalDevices}</h5>
                                            <span>of</span>
                                            <h5 className='PageNumbers'>{totalDevices}</h5>
                                            <Pagination>
                                                <Pagination.Prev disabled />
                                                <Pagination.Item active disabled>{1}</Pagination.Item>
                                                <Pagination.Next disabled />
                                            </Pagination>
                                        </div>
                                        :
                                        <div className="PaginationDiv mb-2 mt-2">
                                            <h5 className="PageNumbers">
                                                {
                                                    totalDevices == 0 ? 0
                                                        : (currentPageNo - 1) * parseInt(limit) + parseInt(1)}
                                                -
                                                {
                                                    (currentPageNo * limit) > (totalDevices)
                                                        ? (totalDevices)
                                                        : currentPageNo * limit
                                                }
                                            </h5>
                                            <span>of</span>
                                            <h5 className="PageNumbers">{totalDevices}</h5>

                                            <Pagination>
                                                <Pagination.Prev onClick={prevClick} disabled={currentPageNo == 1} />
                                                {
                                                    PaginationComponent(currentPageNo)
                                                }
                                                <Pagination.Next onClick={nextClick} disabled={currentPageNo == maxPageAllowed} />
                                            </Pagination>
                                        </div>
                                }
                            </table>
                        </div>
                    </div>
                </Modal.Body>
            </>
        </Modal>
    )
}

export default HealthEvents