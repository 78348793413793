// A wrapper for <Route> that redirects to the login

import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "./ProvideAuth";
import { getTermsCondition } from "../../services/termsConditionService";
import { ErrorToast } from "../../CustomHooks/ErrorToast";
import { TERMSANDCONDITION } from "../CONSTANTS";

// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
    let { user } = useAuth()
    let authToken = localStorage.getItem("authToken");
    const [EulaData, setEulaData] = useState(null)

    useEffect(() => {
        let UserID = JSON.parse(localStorage.getItem('UserID'));
        getTermsCondition(UserID).then(resp => {
            if (resp?.Status.toLowerCase() === "success".toLowerCase()) {
                console.log("Eula logs => ", resp);
                setEulaData(resp?.Data)
            }
            // else {
            //     ErrorToast("login failed! Please try again.");
            // }
        });
    }, [])

    console.log("EulaData from private route => ", EulaData);

    return (
        <>
            <Route
                {...rest}
                render={props => {
                    return authToken
                        // && EulaData?.EulaAccept === "1"
                        ? <>{children}</>
                        : <Redirect to="/login" />
                }}>
            </Route>

            {/* <Route
                {...rest}
                render={({ location }) =>
                    (auth.user &&
                        JSON.parse(localStorage.getItem('user')) != null) ? (
                            <>
                                {children}
                                <br />
                            </>
                        ) : (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: { from: location },
                                }}
                                render={<></>}
                            />
                        )
                }
            /> */}
        </>

    );
}
export default PrivateRoute;