export function checkspecialcharacters(_title) {
    try {
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (format.test(_title)) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error(error)
    }
}

export function ConvertDate(date) {
    var _smonth = date.getMonth() + 1
    var sdates = date.getFullYear() + "-" + ('0' + _smonth).slice(-2) + "-" + ('0' + date.getDate()).slice(-2)
    var stime = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2);
    var finalDate = sdates + " " + stime
    return finalDate
}

export function ConvertDateTwo(date) {
    var _smonth = date.getMonth() + 1
    var sdates = date.getFullYear() + "_" + ('0' + _smonth).slice(-2) + "_" + ('0' + date.getDate()).slice(-2)
    var stime = ('0' + date.getHours()).slice(-2) + '_' + ('0' + date.getMinutes()).slice(-2) + '_' + ('0' + date.getSeconds()).slice(-2);
    var finalDate = sdates + "_" + stime
    return finalDate.toString();
}

export function ConvertDateThree(date) {
    var sdate = ('0' + date.getDate()).slice(-2) + "_" + ('0' + (date.getMonth() + 1)).slice(-2) + "_" + date.getFullYear();
    var stime = ('0' + date.getHours()).slice(-2) + "_" + ('0' + date.getMinutes()).slice(-2) + "_" + ('0' + date.getSeconds()).slice(-2);
    var formattedDate = sdate + '_' + stime ;
    return formattedDate;
}

export function ConvertDateToUTC(date) {
    var _smonth = date.getUTCMonth() + 1
    var sdates = date.getUTCFullYear() + "-" + ('0' + _smonth).slice(-2) + "-" + ('0' + date.getUTCDate()).slice(-2)
    var stime = ('0' + date.getUTCHours()).slice(-2) + ':' + ('0' + date.getUTCMinutes()).slice(-2) + ':' + ('0' + date.getUTCSeconds()).slice(-2);
    var finalDate = sdates + " " + stime
    return finalDate
  }

export function parseTimeIntoDate(time) {
    let dt = time.split(":");
    let newDate = new Date();
    newDate.setHours(dt[0]);
    newDate.setMinutes(dt[1]);
    return newDate;
  }

export function validateTime(fromTime, toTime) {
    if (fromTime.getTime() > toTime.getTime()) {
        return false;
    }
    return true;
};


export const to12HrFormat = (date)=>{
    const str = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    })
    let finalDT = `${date.toLocaleDateString()} ${str}`
    return finalDT
}

export const frontDateConverter = (d) => {
    let first = d.split(" ");
    let arr = first[0].split("-");
    let temp = `${arr[2]}-${arr[1]}-${arr[0]} ${first[1]}`;
    return temp;
}

export const onlyDateConverter = (d) => {
    return d.split("-")[2] + "-" + d.split("-")[1] + "-" + d.split("-")[0]; 
}

export function getBrowserVisibilityProp() {
    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      return "visibilitychange"
    } else if (typeof document.msHidden !== "undefined") {
      return "msvisibilitychange"
    } else if (typeof document.webkitHidden !== "undefined") {
      return "webkitvisibilitychange"
    }
  }
  
  export function getBrowserDocumentHiddenProp() {
    if (typeof document.hidden !== "undefined") {
      return "hidden"
    } else if (typeof document.msHidden !== "undefined") {
      return "msHidden"
    } else if (typeof document.webkitHidden !== "undefined") {
      return "webkitHidden"
    }
  }
  
  export function getIsDocumentHidden() {
    return !document[getBrowserDocumentHiddenProp()]
  }

  export const isCapitalCase = (pass) => {
    const pattern = /[A-Z]/;
    return pattern.test(pass)
  }
  
  export const isSpecialCharacter = (pass) => {
    const pattern = /[\W_]/;
    return pattern.test(pass)
  };

  export const isNumberInclude = (pass) => {
    const pattern = /\d/;
    return pattern.test(pass)
  }
  
  export const isMinCharacter = (pass) => {
    return pass.length >= 8;
  };

  export const isMaxCharacter = (pass) => {
    return pass.length <= 15 && pass.length >= 1;
  };

export const formatDatetoDay = (d) => {
  let daydummy = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  let formatedate = onlyDateConverter(d);
  const f = new Date(formatedate);
  let daydate = f.getDay()
  return daydummy[daydate]
};

export function AllDateFormat(date) {
  var sdate = ('0' + date.getDate()).slice(-2) + "-" + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + date.getFullYear();
  var stime = ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2) + ":" + ('0' + date.getSeconds()).slice(-2);
  var formattedDate = sdate + ' ' + stime ;
  return formattedDate;
}
